import React, { Component } from 'react';
import { Table, Header, Button, Grid,Dropdown,Popup,Input,Icon,Dimmer,Loader,Sidebar,Segment,Form,Message,Image,Breadcrumb,Modal,TextArea } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {alertActions,recipesActions,categoryRecipeActions,clientActions,paymentActions} from '../_actions'
import SideLeftUncoverProductions from './SidebarProductions';
import {history,toaster_dispatch,isNumeric,toaster_info,trim,unitWeight,unitVolume, MAX_NUMBER_RECIPES_FREE_SUBSCRIBER, 
    typeYield,isWeight,isVolume,mgrams,kgrams,cliters,grams,mliters,liters,pack_unit_key_reserved,isQuantity} from '../_helpers';

import {FormattedMessage,FormattedNumber,injectIntl} from 'react-intl';
import {isEmpty,uniqueId} from "lodash";
import {styles} from './Styles';

import img_pan from '../Assets/pan.png'
import img_planning from '../Assets/planning.png'
import img_performance from '../Assets/performance.png'
import './FloatingMenu.css';
//import OutsideClickHandler from 'react-outside-click-handler';

import "react-datepicker/dist/react-datepicker.css"
import DatePicker from "react-datepicker"
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';
import en from 'date-fns/locale/en-US';
import PhoneInput from 'react-phone-number-input'
import { isValidPhoneNumber,isPossiblePhoneNumber } from 'react-phone-number-input'

const DUPLICATE_ADD_INGREDIENT_MESSAGE_TITLE = <FormattedMessage id="newestimate.toaster.ingredient.add.duplicate.message.title"
                                                  defaultMessage='Adding recipe ...'/>
         
const DUPLICATE_ADD_INGREDIENT_MESSAGE_CONTENT = <FormattedMessage id="newestimate.toaster.ingredient.add.duplicate.message.content"
                                                  defaultMessage='Already added to your order'/>


const UNITS = <FormattedMessage id="newestimate.quantity.unit.plural.text"
                        defaultMessage='unit(s)'/>
                       
//['draft','sent','production ongoing','refused','ended','paid','not recoverable']
const optionsStatus = [
                { key: 1, text: <FormattedMessage id="newestimate.options.draft"
                                                  defaultMessage='Draft'/>, value: 'draft' },
                 { key: 2, text: <FormattedMessage id="newestimate.options.sent"
                                                   defaultMessage='Sent'/>, value: 'sent' },
                 { key: 3, text: <FormattedMessage id="newestimate.options.accepted"
                                                    defaultMessage='Accepted'/>, value: 'accepted' },
                 { key: 4, text: <FormattedMessage id="newestimate.options.onproducing"
                                                  defaultMessage='Production ongoing'/>, value: 'production' },
                { key: 5, text: <FormattedMessage id="newestimate.options.refused"
                                                     defaultMessage='Refused'/>, value: 'refused' },
                { key: 6, text: <FormattedMessage id="newestimate.options.ended"
                                                     defaultMessage='Ended'/>, value: 'ended' }    ]                     


class NewEstimate extends Component {
    constructor(props) {
        super(props);
        let userIn = JSON.parse(localStorage.getItem('userIn'));
        const {location,intl} = props;
        this.filterNameList=this.filterNameList.bind(this);
        this.filterCategoryList=this.filterCategoryList.bind(this);

        this.state = { prevRecipe : props.recipes,
                       filtering: false,
                       orderList:location && location.state && location.state.recipesList?location.state.recipesList : [],
                       availableRecipesList:[],
                       emptyOrders: true,
                       units: location && location.state && location.state.recipesUnits?location.state.recipesUnits : [],
                       isMobile: false,
                       currency : userIn.user.currency,
                       newQuantity:0,
                       estimateName : 'XYZ_123',
                       currentDate: new Date(),//.toLocaleDateString(props.intl.locale),
                       dueDate: new Date(),//.toLocaleDateString(props.intl.locale),
                       note:"",
                       activeModalPremium: false,
                       activeModalNewClient: false,
                       status:'draft',
                       client_name: "",
                       client_company: "",
                       client_phone: "",
                       client_address: "",
                       client_zipcode: "",
                       client_city: "",
                       client_country: "",
                       client_state: "",
                       client_email:"",
                       client_note:"",
                       client_selected: "",
                    }
        props.dispatch(recipesActions.getOrdersRecipes(0,'infinite', 0));
        props.dispatch(categoryRecipeActions.getAll()); 
        props.dispatch(paymentActions.getSubscriptionState());
        props.dispatch(paymentActions.getCustomerMembership());
        props.dispatch(clientActions.getAll());
        this.hideModal = this.hideModal.bind(this);

        registerLocale('en', en)
        registerLocale('fr', fr)
        if(intl.locale == 'fr'){
            setDefaultLocale('fr',fr)
        }else{
            setDefaultLocale('en',en)
        }
    }


    static getDerivedStateFromProps(props, state) {
        if((props.recipes!==state.prevRecipe)&&(props.recipes.items)){
            return { prevRecipe : props.recipes,
                     availableRecipesList: props.recipes.items };
        }
        return null;
    }

    componentDidMount() {
        const {orderList} = this.state;

        window.addEventListener("resize", this.resize.bind(this));
        this.resize();

        if (orderList && orderList.length > 0){
            //this.setState({ orderList:orderList});
            this.setState({ emptyOrders: false});
            history.replace(this.props.location.state, null);
        }

        //this.setState({ selectedDate: this.props.location.state.date});
    }

    resize() {
        this.setState({isMobile: window.innerWidth <= 760});
    }

    setCurrentDate (date){
        this.setState({ currentDate: date});
    }

    setDueDate (date){
        this.setState({ dueDate: date});
    }

    OnNoteChange = (e) => {
        this.setState({ note: e.target.value });
    }
 

    onRecipientChange = (e) => {
        this.setState({ recipient: e.target.value });
    }

    onRecipientEmailChange = (e) => {
        this.setState({ recipient_email: e.target.value });
    }

    onStatusChange = (e, data) => {
        this.setState({ status: data.value});
    }

    onNameChange = (e) => {
         this.setState({ estimateName: e.target.value });
    }


    onNoteChange = (e) => {
        this.setState({ note:  e.target.value  });
    }
 
    seeRecipe (event,index) {
        event.preventDefault();
        history.push('/recipedetails/'+index);
    }

    createRecipe(){
        history.push('/newrecipe/');
    }

    onNewQuantity = (e,item) => {
        this.setState({ newQuantity: e.target.value});
    }

    addClientClickHandler (event) {
        event.preventDefault();
        this.setState({ activeModalNewClient: true});
    };

    editClientsListHandler (event) {
        event.preventDefault();
        history.push('/clients/');
    };

    OnNameChange = (e) => {
         this.setState({ client_name: e.target.value });
    }
    OnCompanyChange = (e) => {
         this.setState({ client_company: e.target.value });
    }
    
    OnEmailChange = (e) => {
         this.setState({ client_email: e.target.value });
    }

    OnPhoneChange = (number) => {
        if (number == undefined){
            number = ''
        }

        this.setState({ client_phone: number });
    }
    OnAdressChange = (e) => {
        this.setState({ client_address: e.target.value });
    }

    OnCityChange = (e) => {
        this.setState({ client_city: e.target.value });
    }

    OnCountryChange = (e) => {
       this.setState({ client_country: e.target.value });
    }

    OnZipcodeChange = (e) => {
       this.setState({ client_zipcode: e.target.value });
    } 

   OnStateChange = (e) => {
       this.setState({ client_state: e.target.value });
   }

   OnClientNoteChange = (e) => {
    this.setState({ client_note: e.target.value });
   }

   addClient = (e) => {
        e.preventDefault();
        const { client_name,client_company,client_address,client_email,client_zipcode,client_city,
            client_country,client_state,client_phone,client_note} = this.state;
        this.setState({ submitted: true });

        if (client_name && client_name !='' && this.handleValidation()){
            const client_infos = {
                         name:trim(client_name),
                         company_name: trim(client_company),
                         phone:client_phone,
                         address: client_address,
                         zipcode: client_zipcode,
                         city: client_city,
                         country: client_country,
                         state: client_state,
                         email:client_email,
                         note:client_note,
            }
            this.props.dispatch(clientActions.add(client_infos));
            this.setState({ submitted: false });
            this.hideModal();
        }
    }

    onClientChange = (e,data) => {
        e.preventDefault();
        if (data.value==""){
              this.setState({ client_selected: "" });
              data.value = 0
        }else{
    
              this.setState({ client_selected:data.value});
        }
    }

    handleValidation(){
            const { client_email,/* client_zipcode, */client_phone } = this.state;
    
            if (!this.handleNameValidation()){ 
                return false;
            }
    /*         if (!isNumeric(client_zipcode)){
                return false;
            } */
            if (!this.handleEmailValidation(client_email)){ 
                return false;
            }
    
            if (!this.handlePhoneValidation(client_phone)){ 
                return false;
            }
    
            return true;
        }
    
    handleNameValidation(){
            const { client_name } = this.state;
            if(client_name == ""){
                return false
            }
            return true
        }

     handleZipCodeValidation(zipcode){
    /*         if(zipcode !== ""){
                if (!isNumeric(zipcode)){
                    return false;
                }
            } */
            return true
        }
    
    handlePhoneValidation(number){
            
            if(number && number !== ""){
                return isPossiblePhoneNumber(number) //&& isValidPhoneNumber(number)
            }
    
            return true;
        }
    
    handleEmailValidation(email){
    /*         if(!email){
                return false;
            } */
    
            if(email !== ""){
                let lastAtPos = email.lastIndexOf('@');
                let lastDotPos = email.lastIndexOf('.');
          
                if (!(lastAtPos < lastDotPos 
                    && lastAtPos > 0 
                    && email.indexOf('@@') == -1 
                    && lastDotPos > 2 
                    && (email.length - lastDotPos) > 2)) {
                  return false;
                }
            }
            return true;
    }


    addToCartList (event,recipe) {
        event.preventDefault();
        const {intl} = this.props;
        var found = this.state.orderList.find(x => x.id == recipe.id)

        if (!found){
            let updateOrderList = [...this.state.orderList,recipe]  
             //Sort list alphabetically
            updateOrderList = updateOrderList.sort(function (a, b) {
                return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
            })
            this.setState({ orderList:updateOrderList});
            //this.setState({ orderList: [...this.state.orderList,recipe]});  
            this.setState({ units: [...this.state.units,{id: recipe.id, 
                                                         value: "1", 
                                                         quantity_type:typeYield(recipe.yield_units),
                                                         is_nb_recipes: false,
                                                         unit: recipe.yield_units.toLowerCase() }]});

            this.setState({ emptyOrders: false});
        }else{
            toaster_info(intl.formatMessage({ id: 'newestimate.toaster.ingredient.add.duplicate.message.title' }),
                         intl.formatMessage({ id: 'newestimate.toaster.ingredient.add.duplicate.message.content' }))
        }  
    }

    removeFromCartList (event,recipe) {
        event.preventDefault();
        const {orderList,units} = this.state;

        //Manage orders removal based on recipe name
        let updatedList = orderList.filter((item) => {
            return item.id!=recipe.id
        });

        if (updatedList.length == 0){
            this.setState({ orderList: []});  
            this.setState({ units: []}); 
        }
        else{
            this.setState({ orderList: updatedList});  
        }   

        //Manage units list removal based on index
        let updatedUnits = units.filter((item) => {
            return item.id!=recipe.id
          });
        this.setState({ units: updatedUnits});  

        if (updatedList.length==0){
            this.setState({ emptyOrders: true});
        }
    }

    assertQuantityPermittedIncluded(elem){
        let isFound = false;
        let unitPart = "";
        let inputs= elem.value.replace(/,/g, '.').toLowerCase();
        inputs = inputs.split(' ').filter(Boolean);

        if (inputs.length > 2)
          return false;
        
        if (inputs.length === 1){
          if (elem.quantity_type === 'quantity'){
            if (!isNumeric(inputs)) 
                  return false;
          }else{
            //let tokens = inputs[0].split(/(\d+)/).filter(Boolean);
            //let tokens = inputs[0].split(/[-+]?[0-9]*\.?[0-9]*/).filter(Boolean);
            let tokens = inputs[0].match(/[a-z+|[^a-z]+/gi);
            if (tokens.length !== 2)
              return false;
            for (let i of tokens){
              if (!isNumeric(i)) unitPart = i;
            }
          }
    
        }else{
          if (inputs.length > 2)
              return false
          if (!isNumeric(inputs[0]) && !isNumeric(inputs[1]))
              return false
          for (let i of inputs){
            if (!isNumeric(i)) unitPart = i;
          }
        }
    
        if (elem.quantity_type === 'volume'){
          for (let unit of unitVolume ){
            if (unitPart === unit){
                isFound = true;
                break;
            }
          }
        }else if (elem.quantity_type === 'weight'){
          for (let unit of unitWeight ){
            if (unitPart === unit){
              isFound = true;
              //console.log('isFound');
              break;
            }
         }
        }else {
            if (inputs.length > 1)
                return false
            isFound = true;
        }
        return isFound;
      }
    


    handleAllRecipesValidation(){
        const { units} = this.state;
        let ret = true;
        units.forEach((item)=>{
            item.value = trim(item.value).toLowerCase().replace(/,/g, '.')
            if ((item.value == "") || (item.value == "0")|| (item.value.length == 0) || !isNumeric(item.value)){
                ret = false;    
            }     
            if (Math.trunc(item.value).toString().length>=9){
                ret = false;
            }
        });
        return ret;
    }


    cleanI18nDecimals(){
        const {units} = this.state;
        let cleanUnits=[]

          for (let u of units){
            cleanUnits.push({id:u.id,
                             value:trim(u.value).toLowerCase().replace(/,/g, '.'),
                             quantity_type:u.quantity_type,
                             is_nb_recipes: u.is_nb_recipes,
                             unit: u.unit});
          }
          this.setState({ units: cleanUnits});
      }
    

    createEstimate = (e) => {
        e.preventDefault();
        const { dispatch,customer } = this.props;
        let user = null;
        let membership = null;
            
        if (customer){
            membership = customer.membership;
            user = customer.user;
        }

        if (membership && user &&
            ((membership.membership_type=="Entreprise") || (user.bypass==true))) 
        {
            dispatch(alertActions.clear());
            this.setState({ submitted: true });
    
            this.cleanI18nDecimals();
    
            if (!this.handleAllRecipesValidation()){
                const title = <FormattedMessage id="newestimate.quantity.createorder.check.error.title"
                newestimate='Quantity update error'/>
                const content =  <FormattedMessage id="newestimate.quantity.createorder.check.error.message"
                                                      defaultMessage='Quantity non numeric or invalid'/>
                dispatch(alertActions.error({title:'newestimate.quantity.createorder.check.error.title',
                                                 content:'newestimate.quantity.createorder.check.error.message'}));   
    
                return;
            }
        
            let properties = {
                    ref_name: trim(this.state.estimateName).substring(0,80),
                    type : 'estimate',
                    recipesUnits:this.state.units,
                    created: this.state.currentDate,
                    due: this.state.dueDate,
                    client_id : this.state.client_selected,
                    state: this.state.status,
                    note: this.state.note,
            }
            this.props.dispatch(recipesActions.saveEstimate(properties,false));  

        }else{
            this.setState({ activeModalPremium: true});
        }  
    
    } 

    filterNameList(event){
        event.preventDefault();
        const {availableRecipesList} = this.state;
        this.setState({nameSelected: event.target.value});
        this.setState({categorySelected: ""});

        let updatedList = availableRecipesList.filter((item) => {
                return (item.name).normalize('NFKD').replace(/[^\w]/g, '').toLowerCase()
                            .search(event.target.value.normalize('NFKD').replace(/[^\w]/g, '').toLowerCase()) !== -1;
              });
        this.setState({newItems: updatedList});
        this.setState({filtering: true});
    }

    filterCategoryList(event,data){
        event.preventDefault();
        const {categoriesRecipe} = this.props;
        const {availableRecipesList} = this.state;

        this.setState({filtering: false});
        this.setState({categorySelected: ""});
        this.setState({nameSelected: ""});

        if (data.value!="")
        {
            let category_id = data.value;
            let elem = categoriesRecipe.options.find(category => category.value == category_id)

            if (elem){
                let updatedList = availableRecipesList.filter((item) => {
                    if (item.category && item.category.id){
                        return item.category.id == elem.value
                    }
                    return null;
                });
                this.setState({newItems: updatedList});
                this.setState({filtering: true});
                this.setState({categorySelected: {id:category_id,
                                                  name:elem.text}});
            }
        }
    }

    onTypeQuantityChange = (event,data,id) => {
        event.preventDefault();

        let unitsList = this.state.units.map((item) => {
                    if (item.id == id){
                        if (data.value == 'recipe') {
                           item.is_nb_recipes = true
                        }else{
                           item.is_nb_recipes = false
                        }
                    }
                    return item;
            }); 
        this.setState({ units: unitsList});
    }

    onTypeQuantityValue = (id,type) => {
        const {intl} = this.props;
        let toReturn = 'unit' 
        let found
   
        if (type =='recipe'){
            found = this.state.units.find(unit => unit.id == id)
            if (found){
                if (found.is_nb_recipes) {
                    toReturn = 'recipe'
                 }else{
                    toReturn = found.unit
                 }
            } 
        }/*else{
            found = this.state.ingredQuantities.find(unit => unit.id == id)
            if (found){
                    if (found.unit== '') {
                        toReturn = intl.formatMessage({ id: 'newestimate.quantity.unit.plural.text' }) 
                     }else{
                        toReturn = found.unit
                     }
            } 
        } */
        return toReturn
    }

    onInputQuantityValue = (id,type) => {
        let toReturn = '1' 
        let found
        if ( type =='recipe'){
            found = this.state.units.find(unit => unit.id == id)
            if (found){
                toReturn = found.value
            } 
        }/* else{
            found = this.state.ingredQuantities.find(unit => unit.id == id)
            if (found){
                toReturn = found.value
            } 
        } */
        return toReturn
    }

    onInputQuantityChange = (event,id) => {
        event.preventDefault();

        let unitsList = this.state.units.map((item) => {
                    if (item.id == id){
                        //let input  = event.target.value;
                        //input = Number(trim(input.toString()).replace(/,/g, '.')).toFixed(3);
                        item.value = event.target.value
                    }
                    return item;
        }); 
        //console.log(unitsList)
        this.setState({ units: unitsList});
    }

    getRelevantOptions = (unit,type,purchase_pack) => {
        const {intl} = this.props;
        let options = []
        let emptyUnit = intl.formatMessage({ id: 'newestimate.quantity.unit.plural.text' }) 
        
        unit =  unit.toLowerCase()
        if (type == 'recipe'){
            options= [{key:unit,text:unit,value:unit},
                      {key:'recipe',text:<FormattedMessage id="newestimate.quantity.recipe.text"
                                                           defaultMessage='recipe(s)'/>,value:'recipe'}] 
        }else{
            if (isWeight(unit)){
                 if (mgrams.includes(unit)){
                    options= [{key:pack_unit_key_reserved,text: purchase_pack,value :pack_unit_key_reserved},
                              {key:unit,text:unit,value:unit},
                              {key:'g',text:'g',value:'g'},
                              {key:'kg',text:'kg',value:'kg'}]
                 }else if (grams.includes(unit)){
                    options= [{key:pack_unit_key_reserved,text: purchase_pack,value:pack_unit_key_reserved},
                              {key:unit,text:unit,value:unit},
                              {key:'kg',text:'kg',value:'kg'}]
                }else if (kgrams.includes(unit)){
                    options= [{key:pack_unit_key_reserved,text: purchase_pack,value:pack_unit_key_reserved},
                              {key:unit,text:unit,value:unit},
                              {key:'g',text:'g',value:'g'}]
                 }
                 else{
                    options= [{key:pack_unit_key_reserved,text: purchase_pack,value:pack_unit_key_reserved},
                              {key:unit,text:unit,value:unit}]
                 }   
            }
            else if (isVolume(unit)){
                if (cliters.includes(unit)){
                    options= [{key:pack_unit_key_reserved,text: purchase_pack,value:pack_unit_key_reserved},
                              {key:unit,text:unit,value:unit},
                              {key:'ml',text:'ml',value:'ml'},
                              {key:'l',text:'l',value:'l'}]
                 }
                 else if (mliters.includes(unit)){
                    options= [{key:pack_unit_key_reserved,text:purchase_pack,value:pack_unit_key_reserved},
                              {key:unit,text:unit,value:unit},
                              {key:'cl',text:'cl',value:'cl'},
                              {key:'l',text:'l',value:'l'}]
                 }else if (liters.includes(unit)){
                    options= [{key:pack_unit_key_reserved,text:purchase_pack,value:pack_unit_key_reserved},
                              {key:unit,text:unit,value:unit},
                              {key:'cl',text:'cl',value:'cl'},
                              {key:'ml',text:'ml',value:'ml'}]
                 }
                 else{
                    options= [{key:pack_unit_key_reserved,text:purchase_pack,value:pack_unit_key_reserved},
                              {key:unit,text:unit,value:unit}]
                 }     

            }else{
                options= [{key:pack_unit_key_reserved,text: purchase_pack + ' ' + emptyUnit,value:pack_unit_key_reserved},
                          {key:emptyUnit,text:emptyUnit,value:emptyUnit}]
            }
        }
        return options;
    }


    computeSellingNoVAT(recipe){
        return (Number(recipe.selling_price)/Number(recipe.quantity_parts)) / (1 + Number(recipe.selling_vat))
    }


    addOrdersRows(orders){
        const {currency/*,submitted,editCellRecipe,editCellIndexRecipe,newQuantity*/} = this.state;
        return orders.map((recipe,index) => {
                console.log(recipe)
                return (
                    <Table.Row key={index} >
                        <Table.Cell  width={5}>
                            <Icon name='food' color='blue'/>
                            <a href="#" 
                               onClick={(event) => this.seeRecipe(event,recipe.id)}
                               style={{'color': 'black'}}>
                               {recipe.name.length >= 65 && 
                                  recipe.name.substring(0,65) + '..'}
                                    {recipe.name.length < 65 && 
                                    recipe.name}
                            </a>
                        </Table.Cell>
                        <Table.Cell width={3} textAlign='right' /* onClick={(event) => this.editQuantityHandlerInput(event,index,recipe,'recipe')} */ >
{/*                              {(!editCellRecipe || (editCellRecipe && index != editCellIndexRecipe) ) &&
                                <a href='#'><FormattedNumber value={this.getRecipeUnit(recipe)}/>{'  '}{ recipe.yield_units}</a>}
                            {editCellRecipe && index == editCellIndexRecipe && 
                            <OutsideClickHandler onOutsideClick={(event) => {this.setState({ editCellRecipe: false})}}>  */}
                                <Form>
                                    <Form.Group widths='equal'>
                                        <Form.Input key={index} 
                                                        type="text"
                                                        id="quantity"
                                                        //value={newQuantity}
                                                        //onChange={this.onNewQuantity}
                                                        value ={this.onInputQuantityValue(recipe.id,'recipe')}
                                                        onChange={(event) => this.onInputQuantityChange(event,recipe.id)}
                                                        //onBlur={(event) => this.updateQuantityOnBlur(event,recipe,'recipe')} 
                                                        //onKeyPress={(event) => this.updateQuantityOnEnter(event,recipe,'recipe')} 
                                                        autoFocus
                                                        style={{height:'2.5em',minWidth:'5em',maxWidth:'8em',padding:'0em 0.5em 0em 0.5em'}}
                                                />  
                                            {/* {recipe.yield_units} */}
                                            <Form.Dropdown key={index} 
                                                        //button //basic
                                                        options={this.getRelevantOptions(recipe.yield_units,'recipe',"")}
                                                        onChange={(event,data)=>this.onTypeQuantityChange(event,data,recipe.id)}
                                                        value ={this.onTypeQuantityValue(recipe.id,'recipe')}
                                                        //style={{minWidth:'5em',maxWidth:'8em'}} 
                                                        style={styles.ButtonGreenNoMarginOrder}
                                                />
                                    </Form.Group>
                                </Form>
{/*                           </OutsideClickHandler>
                           } */}
                        </Table.Cell>
                        <Table.Cell width={3} textAlign='right'><FormattedNumber value={recipe.yield_count} /> {recipe.yield_units}</Table.Cell>
                        <Table.Cell width={3} textAlign='right'>
                            {typeYield(recipe.yield_units) == 'quantity' &&
                            <span>
                              <FormattedNumber value={recipe.cost_unit} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>
                              {' / '}{recipe.yield_units}</span>
                            }
                            {typeYield(recipe.yield_units) != 'quantity' &&
                            <span>
                              <FormattedNumber value={recipe.cost_total} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>
                              {' / '}<FormattedMessage id="newestimate.quantity.recipe.singular.text"
                                                           defaultMessage='recipe'/></span>
                            }
                        </Table.Cell>
                        <Table.Cell width={2} textAlign='right'>
                            {typeYield(recipe.yield_units) == 'quantity' &&
                            <span><FormattedNumber value={this.computeSellingNoVAT(recipe)} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>
                                    {' / '}{recipe.yield_units}</span>
                             }
                            {typeYield(recipe.yield_units) != 'quantity' &&
                            <span>
                            <FormattedNumber value={(this.computeSellingNoVAT(recipe)/Number(recipe.yield_count))} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>
                                     {' / '}{recipe.yield_units}</span>
                            } 
                        </Table.Cell>
                        <Table.Cell width={3} textAlign='right'>
                                    <Button basic circular className="ui small icon button" 
                                             onClick={(event)=>this.removeFromCartList(event,recipe)}>
                                            <i className="trash alternate outline icon"></i>
                                     </Button>
                            </Table.Cell>
                     </Table.Row>
                );
        });
    }
    
    addRecipesRows(recipes){
        return recipes.map((recipe,index) => {
                return (
                    <Table.Row key={index} style={{'backgroundColor':'#fcfcfc'}}
                                    /* onClick={(event)=>this.addToCartList(event,recipe)} */>
                        <Table.Cell width={5}>
                            <Icon name='food' color='blue'/>
                            <a //href="#" 
                               //onClick={(event) => this.seeRecipe(event,recipe.id)}
                               style={{'color': 'black',fontWeight:'bolder'}}>
                                  {recipe.name.length >= 65 && 
                                    recipe.name.substring(0,65) + '..'}
                                    {recipe.name.length < 65 && 
                                    recipe.name}
                                   {/* {recipe.name.substr(0, 60)} */}
                            </a>
                        </Table.Cell>
{/*                         <Table.Cell width={3} textAlign='center'>{recipe.category && recipe.category.name}</Table.Cell>
                        <Table.Cell width={3} textAlign='right'><FormattedNumber value={recipe.yield_count} /> {recipe.yield_units}</Table.Cell> */}
                        <Table.Cell width={2} textAlign='right'>
                                   <Button color='blue' circular className="ui small icon button" 
                                             onClick={(event)=>this.addToCartList(event,recipe)}>
                                            <i className="add icon"></i>
                                   </Button>
                        </Table.Cell>
                     </Table.Row>
                );
        });
    }

    isUnSubscribedUser(){
        const { subscription } = this.props;
        if (subscription && !isEmpty(subscription) && (subscription.active == false) && (subscription.bypass == false)){
            return true
        }
        return false
    }

    upgradeSubscription = () => {
        history.push({pathname: '/profilepremium', state: {toggled: false}})
    }

    toggleItemsMenu(e,type){
        this.setState({sidebarToggled: !this.state.sidebarToggled});
        //this.setState({toggled: false});
        this.setState({filtering: false});
        this.setState({nameSelected: ""});
        this.setState({categorySelected: ""});
    } 


    resetOrder(event){
        this.setState({ emptyOrders: true });
        this.setState({ orderList: []});  
        this.setState({ units: []}); 
        this.setState({ ingredQuantities: []});   
    }

/*     openPlanning = (e) => {
        e.preventDefault();
        history.push('/productions',{planning:true});
    } */

    hideModal() {
        this.setState({ activeModalPremium: false });
        this.setState({ activeModalNewClient: false });
    }

    render() { 
        const { categoriesRecipe,alert,dispatch,loading_r,loading_cr,intl,loading_o,customer,client } = this.props;
        const { newItems,filtering,nameSelected,categorySelected,emptyOrders,orderList,availableRecipesList,
            isMobile,sidebarToggled,currentDate,dueDate,estimateName,status,note,currency,client_address,client_city,client_company,client_country,client_email,
            client_name,client_note,client_phone,client_state,client_zipcode,client_selected,submitted } = this.state;
        let isLimited = this.isUnSubscribedUser()
        let items = [];
        let membership = null;
        let user = null;

        if (customer){
             membership = customer.membership;
             user = customer.user;
        }

		let className = "floating-menu";

        if (filtering){
                items = newItems
        }else{
                items = availableRecipesList
        }

        //Filter and notify restrictions if user unsubscribed
        if (isLimited && items){
                items = items.slice(0,MAX_NUMBER_RECIPES_FREE_SUBSCRIBER);
        }

       //Filter and notify restrictions if user unsubscribed
        if (this.isUnSubscribedUser() && items){
            items = items.slice(0,MAX_NUMBER_RECIPES_FREE_SUBSCRIBER);
        }

        return ( 
                <div>
                    <Sidebar as={Segment}
                             animation={'overlay'}
                             direction={'right'}
                             visible={sidebarToggled}
                             vertical
                             width= {isMobile?'wide':'very wide'}
                             icon='labeled'
                             style={{'backgroundColor':'#fcfcfc'/*,'overflow-y': 'hidden','overflow-x': 'auto;','overflow': '-moz-scrollbars-vertical;'*/}}
                             >
                            <Grid textAlign='center' /*style={{'overflow-y': 'hidden','overflow-x': 'auto;','overflow': '-moz-scrollbars-vertical;'}}*/>
                                <Grid.Row columns={1}>
                                    <Grid.Column /*mobile={16} tablet={12} computer={8}*/ fluid>
                                      <div style={{display: 'inline-block;',position:'absolute'}}>
                                                <Icon name='close' size='large' color='grey'  onClick={(e) => this.toggleItemsMenu(e)}/>
                                      </div>  
                                      <Form>
                                      <Form.Field align='left' style={{'margin':'2em 0em 1em 1em'}}>
                                         <Header as='h2' style={{'marginLeft': '1em'}}>
                                            <img src={img_pan} style={{width:'50px'}}/>
                                            <Header.Content>
                                            <FormattedMessage id="newestimate.form.input.recipe.title"
                                                                  defaultMessage='Add recipes'/>
                                            </Header.Content>
                                        </Header>
                                       </Form.Field>
                                       <Form.Field align='left' style={{'margin':'0em 0.5em 1em 0.5em'}}>
                                             <FormattedMessage id="newestimate.form.input.search.placeholder"
                                                            defaultMessage="Enter a name ...">
                                                {placeholder => 
                                                        <Input
                                                            fluid
                                                            onChange={this.filterNameList}
                                                            color='teal'
                                                            size='large'
                                                            icon='filter'
                                                            placeholder={placeholder}
                                                            value={nameSelected}
                                                            disabled = {isLimited}
                                                            style={{'backgroundColor':'#f5f5f5'}}
                                                            //onFocus={(e)=>this.onFocusElsewhere(e)}
                                                        />
                                                } 
                                                </FormattedMessage> 
                                        </Form.Field>
                                       <Form.Field align='left' style={{'margin':'0em 0.5em 1em 0.5em'}}>
                                                <span>
                                                    <p style={styles.FontLato13}>
                                                        <FormattedMessage id="newestimate.form.filter.category.title"
                                                                        defaultMessage='Filter by category' />
                                                    </p>
                                                    <FormattedMessage id="newestimate.form.filter.category.placeholder"
                                                                        defaultMessage='Select a category'>
                                                        {placeholder =>   
                                                            <Dropdown selection
                                                            options= {categoriesRecipe && categoriesRecipe.options} 
                                                            value= {categorySelected && categorySelected.id}
                                                            placeholder={placeholder} 
                                                            onChange={this.filterCategoryList}
                                                            clearable
                                                            fluid
                                                            disabled = {isLimited}
                                                            //style={{minWidth:'18em',maxWidth:'26em'}}
                                                            />}   
                                                    </FormattedMessage>
                                                </span>
                                        </Form.Field>
                                        </Form>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={1}>
                                   { (loading_r || loading_cr) &&
                                        <Dimmer active inverted>
                                            <Loader inverted content='Loading' />
                                        </Dimmer> }
                                    <Grid.Column /*mobile={16} tablet={16} computer={16}*/ fluid >
                                        <Table  //selectable 
                                                unstackable 
                                                //compact 
                                                //definition
                                                //striped 
                                                singleLine 
                                                style={styles.FontLato13Border}
                                                //style={{'overflow-y': 'hidden','overflow-x': 'auto;','overflow': '-moz-scrollbars-none;','-ms-overflow-style':'none;'}}
                                                >
                                                <Table.Body>
                                                {availableRecipesList.length > 0 && items &&
                                                    this.addRecipesRows(items)
                                                }
                                                {availableRecipesList.length == 0 &&
                                                <Table.Row textAlign='left' style={{'backgroundColor':'#fcfcfc','font-family': 'Lato','font-size': '14px'}}>
                                                    <Table.Cell>
                                                        <FormattedMessage id="newestimate.list.recipe.empty.message"
                                                                            defaultMessage='No recipe created yet' />
                                                    </Table.Cell>
                                                    <Table.Cell width={1} textAlign='right'>
                                                        <Button color='teal' circular  className="ui small icon button" 
                                                                onClick={this.createRecipe}>
                                                                <i className="add icon"></i>
                                                        </Button>
                                                    </Table.Cell>
                                                </Table.Row>
                                                }
                                            </Table.Body>
                                            {isLimited && items &&
                                                <Table.Footer /*fullWidth*/ width={4}>
                                                        <Table.HeaderCell colSpan='2'>
                                                        <Message fluid icon size='big' style={styles.FontLato12Bold} color='blue'>
                                                                <Image src={img_performance} size ='tiny' rounded/>
                                                                <br/>
                                                                <Message.Content style={{marginLeft:'1em'}}>
                                                                    <Message.Header color='black'>
                                                                        <FormattedMessage id="newestimate.list.premium.title.message.upgradeplan"
                                                                                            defaultMessage='Recipes/Ingredients limit exceeded'/> 
                                                                    </Message.Header>
                                                                    <br/>
                                                                    <div style={styles.FontLato13}>
                                                                        <FormattedMessage id="newestimate.list.premium.message.upgradeplan.line1"
                                                                                        defaultMessage='Your have'/>{' '}{availableRecipesList.length}{' '}
                                                                        <FormattedMessage id="newestimate.list.premium.message.upgradeplan.line2"
                                                                                        defaultMessage='recipes but limited to'/>{' '}{MAX_NUMBER_RECIPES_FREE_SUBSCRIBER}{'.'} 
                                                                    </div>
                                                                    <br/><br/>
                                                                    <Button basic color='blue' style={styles.FontLato12Bold} onClick={(e) => this.upgradeSubscription()}>
                                                                        <FormattedMessage id="newestimate.list.premium.button.chooseplan"
                                                                                    defaultMessage='Choose premium plan'/>
                                                                    </Button>                                      
                                                                </Message.Content>
                                                            </Message>
                                                        </Table.HeaderCell>
                                            </Table.Footer>}
                                    </Table>
                              </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Sidebar>
                     <SideLeftUncoverProductions>
                        <Grid stackable  style={styles.FontLato13Border}>
                            <Grid.Row columns={1}>
                              <Breadcrumb  style={{background:'#fcfcfc'}}>
                                <Breadcrumb.Section link as={Link} to={'/dashboard/'}><FormattedMessage id="newestimate.link.dashboard"
                                                              defaultMessage='Dashboard'/></Breadcrumb.Section>
                                <Breadcrumb.Divider icon='right chevron'/>
                                <Breadcrumb.Section link as={Link} to={'/productions/'}><FormattedMessage id="newestimate.link.productions"
                                                              defaultMessage='Productions'/></Breadcrumb.Section>                             
                                <Breadcrumb.Divider icon='right chevron'/>
                                <Breadcrumb.Section link as={Link} to={'/estimates/'}><FormattedMessage id="newestimate.link.estimates"
                                                              defaultMessage='My estimates'/></Breadcrumb.Section>                             
                                <Breadcrumb.Divider icon='right chevron'/>
                                <Breadcrumb.Section active><FormattedMessage id="newestimate.link.order"
                                                              defaultMessage='Estimate'/></Breadcrumb.Section>
                             </Breadcrumb>
                            </Grid.Row>
                            <Grid.Row columns={1}>
                                <Grid.Column width={8}>
                                    <Header as='h2'>
                                        <img src={img_planning} style={{width:'45px'}}/>
                                        <Header.Content>
                                            <FormattedMessage id="newestimate.page.title"
                                                              defaultMessage='New Estimate'/>
                                            <Header.Subheader><FormattedMessage id="newestimate.header.caption.title"
                                                                    defaultMessage='Customize your estimate' /></Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                    </Grid.Column >
                            </Grid.Row> 
                            {alert.message && toaster_dispatch(dispatch,alert,intl)}
                            <Grid.Row columns={3}>
                                <Table  stackable
                                        //singleLine 
                                        //style={styles.FontLato13Border}
                                        style={{'background-color':'#fcfcfc', 'border-style':'none',paddingLeft:'2em'}}>
                                 {/*  <Label  basic style={styles.FontLato14Bold} size='large'  color='blue'> 
                                                    <FormattedMessage id="newestimate.list.date.title"
                                                                     defaultMessage='Estimate infos' /> 
                                </Label>*/}
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell width={3} textAlign='left' >
                                                <Form /*  style={{paddingTop:'0em'}}  */ >
                                                <label><FormattedMessage id="newestimate.form.input.creation.date.title"
                                                                         defaultMessage='Creation date'/></label>
                                                  <DatePicker  showIcon
                                                               selected={currentDate}
                                                                onChange={(date)=>this.setCurrentDate(date)}
                                                                 //inline
                                                                 //showTimeSelect
                                                                 dateFormat="P"
                                                                 locale={intl.locale}
                                                                 //timeCaption={caption}
                                                                 showWeekNumbers
                                                    /> 
                                                <label style={{paddingTop:'1em'}} ><FormattedMessage id="newestimate.form.input.due.date.title"
                                                                         defaultMessage='Due date'/></label>
                                                    <DatePicker  showIcon
                                                                 selected={dueDate}
                                                                 onChange={(date)=>this.setDueDate(date)}
                                                                 //inline
                                                                 //showTimeSelect
                                                                 dateFormat="P"
                                                                 locale={intl.locale}
                                                                 //timeCaption={caption}
                                                                 showWeekNumbers
                                                     />
                                                 <label style={{paddingTop:'0.5em'}} ><FormattedMessage id="newestimate.form.input.status.title"
                                                                         defaultMessage='Status'/></label>
                                                 <FormattedMessage id="newestimate.form.input.status.placeholder"
                                                                   defaultMessage='Status'>
                                                    {placeholder =>   
                                                            <Form.Select 
                                                                    options={optionsStatus}
                                                                    placeholder={placeholder} 
                                                                    value={status}
                                                                    onChange={this.onStatusChange}
                                                                    style={{'background-color':'#ebf2f5','color': 'black'}}
                                                                    //style={{minWidth:'10em',maxWidth:'20em'}} 

                                                    />}
                                                </FormattedMessage>  
                                                </Form>
                                            </Table.Cell>
                                            <Table.Cell width={3} textAlign='left' >
                                              <Form  /* style={{paddingTop:'0em'}}  */ >
                                                 <label><FormattedMessage id="newestimate.form.input.name.title"
                                                                         defaultMessage='Name of estimate'/></label>
                                                  <FormattedMessage id="newestimate.form.input.name.placeholder"
                                                                   defaultMessage='XXXXXX_12345'>
                                                    {placeholder =>     
                                                        <Form.Input
                                                            type="text"
                                                            placeholder={placeholder}
                                                            id="name"
                                                            value={estimateName}
                                                            onChange={this.onNameChange}
                                                            //style={{minWidth:'10em',maxWidth:'30em'}}
                                                           // onFocus={(e)=>this.onFocusElsewhere(e)} 
                                                           // error={submitted && !this.assertNameField()}
                                                            />}
                                                  </FormattedMessage>
                                              <Form.Group style={{paddingTop:'0.2em'}}> 
                                                <Form.Field>
                                                <label><FormattedMessage id="newestimate.form.input.client"
                                                                            defaultMessage='Client, customer ...'/>
                                                </label>
                                                <Button as='div' labelPosition='left' >
                                                <FormattedMessage id="newestimate.form.input.client.placeholder"
                                                                    defaultMessage='Select'>
                                                        {placeholder =>   
                                                                <Form.Select search 
                                                                        options={client && client.options}
                                                                        value = {client_selected} 
                                                                        placeholder={placeholder} 
                                                                        onChange={this.onClientChange}
                                                                        clearable
                                                                        //onFocus={(e)=>this.onFocusElsewhere(e)}
                                                                />}
                                                </FormattedMessage>
                                                <button style={{border:'none',backgroundColor:'white',background:'none',outline:'none;'}}
                                                        onClick={(event) => this.addClientClickHandler(event)}> 
                                                   <Icon name='user add' size='large' color='blue' style={{marginLeft:'1.1em'}} /></button>
                                                <button style={{border:'none',backgroundColor:'white',background:'none',outline:'none;'}}
                                                        onClick={(event) => this.editClientsListHandler(event)}> 
                                                   <Icon name='users' size='large' color='grey' style={{marginLeft:'0.4em'}} /></button>
                                                </Button>    
                                                </Form.Field> 
                                                <Form.Field>
                                                <Modal open={this.state.activeModalNewClient === true}
                                                                    dimmer='default' 
                                                                    size='small'
                                                                    style={styles.Modal} 
                                                                    onClose={this.hideModal}>
                                                                    <Header icon='user add' content={<FormattedMessage id="newestimate.modal.create.title"
                                                                                                                                defaultMessage='Add client, customer, lead ...'/>} />
                                                                        <Modal.Content>
                                                                                        <Form style={{paddingLeft:'0em'}}>
                                                                                                <Form.Field width='six'> 
                                                                                                    <label style={styles.LabelGrey}><FormattedMessage id="newestimate.modal.form.input.name"
                                                                                                                            defaultMessage='Name'/></label>
                                                                                                    <FormattedMessage id="newestimate.modal.form.input.name.placeholder"
                                                                                                                    defaultMessage='name'>
                                                                                                        {placeholder =>                                                                                
                                                                                                                    <Form.Input type="text" 
                                                                                                                        placeholder={placeholder}
                                                                                                                        id="name" 
                                                                                                                        icon='user add'
                                                                                                                        iconPosition='left'
                                                                                                                        value={client_name}
                                                                                                                        onChange={this.OnNameChange}
                                                                                                                        error= {submitted && !this.handleNameValidation()}
                                                                                                                        />}
                                                                                                    </FormattedMessage>
                                                                                                </Form.Field>
                                                                                                <Form.Group widths='equal'>
                                                                                                    <Form.Field >
                                                                                                        <label style={styles.LabelGrey}><FormattedMessage id="newestimate.modal.form.input.company"
                                                                                                                                defaultMessage='Company'/></label>
                                                                                                    <FormattedMessage id="newestimate.modal.form.input.contact.placeholder"
                                                                                                                        defaultMessage='Company'>
                                                                                                            {placeholder =>                                                                                       
                                                                                                                    <Form.Input type="text" 
                                                                                                                        fluid
                                                                                                                        placeholder={placeholder}
                                                                                                                        id="company" 
                                                                                                                        icon='user'
                                                                                                                        iconPosition='left'
                                                                                                                        value={client_company}
                                                                                                                        onChange={this.OnCompanyChange}/>}
                                                                                                        </FormattedMessage>
                                                                                                    </Form.Field>
                                                                                                    <Form.Field>
                                                                                                        <label style={styles.LabelGrey}><FormattedMessage id="newestimate.modal.form.input.email"
                                                                                                                                defaultMessage='Email'/></label>
                                                                                                    <FormattedMessage id="newestimate.modal.form.input.email.placeholder"
                                                                                                                        defaultMessage='Email'>
                                                                                                            {placeholder =>                                                                                       
                                                                                                                    <Form.Input  
                                                                                                                        fluid
                                                                                                                        placeholder={placeholder}
                                                                                                                        icon='mail'
                                                                                                                        iconPosition='left'
                                                                                                                        type="email"
                                                                                                                        id="email" 
                                                                                                                        value={client_email}
                                                                                                                        onChange={this.OnEmailChange}
                                                                                                                        error={submitted && !this.handleEmailValidation(client_email)}
                                                                                                                        />}
                                                                                                        </FormattedMessage>
{/*                                                                                                         <label style={{color:'teal', }}>
                                                                                                                <FormattedMessage id="newestimate.modal.form.input.email.info.message"
                                                                                                                                defaultMessage='Fill if you need to send directly your orders to your supplier'/>
                                                                                                        </label>  */}
                                                {/*                                                         {submitted && !this.handleEmailValidation(email)  &&
                                                                                                            <label style={{color:'red', }}><i>
                                                                                                                <FormattedMessage id="suppliers.modal.form.input.email.error.message"
                                                                                                                                    defaultMessage='email is used to send your orders'/>
                                                                                                            </i></label> 
                                                                                                        } */}
                                                                                                    </Form.Field>
                                                                                                </Form.Group>
                                                                                                <br/>
                                                                                                <Form.Field>
                                                                                                    <label style={styles.LabelGrey}><FormattedMessage id="newestimate.modal.form.input.address"
                                                                                                                            defaultMessage='Billing Address'/></label>
                                                                                                <FormattedMessage id="newestimate.modal.form.input.address.placeholder"
                                                                                                                    defaultMessage='address'>
                                                                                                        {placeholder =>          
                                                                                                                <Form.Input type="text" 
                                                                                                                    placeholder={placeholder}
                                                                                                                    id="address" 
                                                                                                                    icon='address card'
                                                                                                                    iconPosition='left'
                                                                                                                    value={client_address}
                                                                                                                    onChange={this.OnAdressChange}/>}
                                                                                                    </FormattedMessage>
                                                                                                </Form.Field>
                                                                                                <Form.Group widths='equal'>
                                                                                                    <Form.Field>
                                                                                                        <label style={styles.LabelGrey}><FormattedMessage id="newestimate.modal.form.input.city"
                                                                                                                                defaultMessage='City'/></label>
                                                                                                    <FormattedMessage id="newestimate.modal.form.input.city.placeholder"
                                                                                                                        defaultMessage='city'>
                                                                                                            {placeholder =>          
                                                                                                                    <Form.Input type="text" 
                                                                                                                        fluid
                                                                                                                        placeholder={placeholder}
                                                                                                                        id="city" 
                                                                                                                        icon='map'
                                                                                                                        iconPosition='left'
                                                                                                                        value={client_city}
                                                                                                                        onChange={this.OnCityChange}/>}
                                                                                                        </FormattedMessage>
                                                                                                    </Form.Field>
                                                                                                    <Form.Field>
                                                                                                        <label style={styles.LabelGrey}><FormattedMessage id="newestimate.modal.form.input.zipcode"
                                                                                                                                defaultMessage='Zipcode'/></label>
                                                                                                    <FormattedMessage id="newestimate.modal.form.input.zipcode.placeholder"
                                                                                                                        defaultMessage='zipcode'>
                                                                                                            {placeholder =>          
                                                                                                                    <Form.Input type="text" 
                                                                                                                        fluid
                                                                                                                        placeholder={placeholder}
                                                                                                                        id="zipcode" 
                                                                                                                        icon='map'
                                                                                                                        iconPosition='left'
                                                                                                                        value={client_zipcode}
                                                                                                                        onChange={this.OnZipcodeChange}
                                                                                                                        //error={submitted && !this.handleZipCodeValidation(zipcode)}
                                                                                                                        />}
                                                                                                        </FormattedMessage>
                                                                                                    </Form.Field>
                                                                                                    <Form.Field>
                                                                                                        <label style={styles.LabelGrey}><FormattedMessage id="newestimate.modal.form.input.state"
                                                                                                                                defaultMessage='State'/></label>
                                                                                                    <FormattedMessage id="newestimate.modal.form.input.state.placeholder"
                                                                                                                        defaultMessage='state'>
                                                                                                            {placeholder =>          
                                                                                                                    <Form.Input type="text" 
                                                                                                                        fluid
                                                                                                                        placeholder={placeholder}
                                                                                                                        id="state" 
                                                                                                                        icon='map'
                                                                                                                        iconPosition='left'
                                                                                                                        value={client_state}
                                                                                                                        onChange={this.OnStateChange}/>}
                                                                                                        </FormattedMessage>
                                                                                                    </Form.Field>
                                                                                                </Form.Group>
                                                                                                <Form.Group widths='equal'>
                                                                                                <Form.Field >
                                                                                                    <label style={styles.LabelGrey}><FormattedMessage id="newestimate.modal.form.input.country"
                                                                                                                            defaultMessage='Country'/></label>
                                                                                                <FormattedMessage id="newestimate.modal.form.input.country.placeholder"
                                                                                                                    defaultMessage='country'>
                                                                                                        {placeholder =>          
                                                                                                                <Form.Input type="text" 
                                                                                                                    placeholder={placeholder}
                                                                                                                    id="country" 
                                                                                                                    icon='world'
                                                                                                                    iconPosition='left'
                                                                                                                    value={client_country}
                                                                                                                    onChange={this.OnCountryChange}/>}
                                                                                                    </FormattedMessage>
                                                                                                </Form.Field>
                                                                                                <Form.Field >
                                                                                                <label style={styles.LabelGrey}><FormattedMessage id="newestimate.modal.form.input.phone"
                                                                                                                            defaultMessage='Phone'/></label>
                                                                                                <PhoneInput
                                                                                                        defaultCountry={intl.locale =='fr'?'FR':'US'}
                                                                                                        international
                                                                                                        //placeholder='' 
                                                                                                        value={client_phone}
                                                                                                        onChange={this.OnPhoneChange}
                                                                                                    />
                                                                                                 {submitted && !this.handlePhoneValidation(client_phone) &&
                                                                                                    <label style={{color:'red',paddingLeft:'2em'}}><i>
                                                                                                        <FormattedMessage id="newestimate.modal.form.input.phone.error.message"
                                                                                                                            defaultMessage='Invalid phone number'/> 
                                                                                                        </i></label>
                                                                                                    }
                                                                                                </Form.Field>
                                                                                                </Form.Group>
                                                                                            <Form.Group widths='equal'>
                                                                                                <Form.Field >
                                                                                                    <label style={styles.LabelGrey}><FormattedMessage id="newestimate.modal.form.input.note"
                                                                                                                            defaultMessage='Additional infos'/></label>
                                                                                                <FormattedMessage id="newestimate.modal.form.input.note.placeholder"
                                                                                                                    defaultMessage='website, position,comment ...'>
                                                                                                        {placeholder =>          
                                                                                                                <Form.TextArea type="text" 
                                                                                                                    placeholder={placeholder}
                                                                                                                    id="note" 
                                                                                                                    icon='text'
                                                                                                                    iconPosition='left'
                                                                                                                    value={client_note}
                                                                                                                    rows='1'
                                                                                                                    maxlength="300"
                                                                                                                    onChange={this.OnClientNoteChange}/>}
                                                                                                    </FormattedMessage>
                                                                                                </Form.Field>
                                                                                                </Form.Group>
                                                                                                <br/>
                                                                                        </Form>
                                                                                </Modal.Content>
                                                                                <Modal.Actions>
                                                                                    <br/>
                                                                                    <Button basic color='grey' onClick={this.hideModal}>
                                                                                        <FormattedMessage id="newestimate.modal.update.button.cancel"
                                                                                                        defaultMessage='Cancel'/>
                                                                                    </Button>
                                                                                    <Button color='blue' 
                                                                                        labelPosition='right' 
                                                                                        content={<FormattedMessage id="newestimate.modal.create.button.create"
                                                                                                                    defaultMessage='Create'/>}
                                                                                        icon='checkmark' 
                                                                                        onClick={(event) =>this.addClient(event)}
                                                                                        />
                                                                                    <br/>
                                                                                </Modal.Actions>   
                                                                            </Modal>  
                                                </Form.Field>
                                                </Form.Group> 
                                                </Form>
                                            </Table.Cell>
                                            <Table.Cell width={3}></Table.Cell>
                                            <Table.Cell width={5}  textAlign='center' style={{ paddingTop:'2em',paddingRight:'3em' }} >
                                                <Segment style={{'background-color':'#ebf2f5',minHeight: 80, textAlign:'right' }} >
                                                     <Dimmer.Dimmable>
                                                    {/*  {loading &&
                                                        <Dimmer //active={adding||updating_menu||deleting} 
                                                                inline active
                                                                style={{'background-color':'#ebf2f5'}} 
                                                                inverted>
                                                            <Loader content='Loading'/>
                                                        </Dimmer>
                                                       {/*  {estimate &&  */}
                                                            <Header as='h4' color='blue'>
                                                                <FormattedMessage id="newestimate.page.margin.title"
                                                                        defaultMessage='Gross Margin'/>&nbsp;&nbsp;&nbsp;&nbsp;{'  '}
                                                            <FormattedNumber value={0} 
                                                                           style="currency" currencyDisplay="symbol" currency={currency}/>
                                                            <p style={{'color':'#b5b1b1','font-size':'14px'}}>
                                                                <i>
                                                                <FormattedNumber value={0} style="percent" maximumFractionDigits="2"/>
                                                                </i>
                                                            </p>
                                                            <span style={{fontSize:'14px', color:'#00b3b3'}}>
                                                                <FormattedMessage id="newestimate.page.margin.foodcost"
                                                                                defaultMessage='Food Cost'/>&nbsp;&nbsp;&nbsp;&nbsp;{'  '}
                                                                 <FormattedNumber value={Number(0)} 
                                                                                 style="currency" currencyDisplay="symbol" currency={currency}/>
                                                            </span>
                                                            <p style={{'color':'#b5b1b1','font-size':'14px'}}>
                                                                <i>
                                                                <FormattedNumber value={0} style="percent" maximumFractionDigits="2"/>
                                                                </i>
                                                            </p>
                                                            <span style={{fontSize:'14px', color:'black'}}>
                                                                <FormattedMessage id="newestimate.page.margin.price"
                                                                                defaultMessage='Sales'/>&nbsp;&nbsp;&nbsp;&nbsp;{'  '}
                                                                <FormattedNumber value={0} style="currency" 
                                                                     maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>
                                                            </span>
                                                            <br/>
                                                            <span style={{fontSize:'13px', color:'grey'}}>
                                                                <FormattedMessage id="newestimate.page.margin.vat"
                                                                                defaultMessage='vat'/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'  '}
                                                                <FormattedNumber value={0} style="currency" 
                                                                     maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>
                                                            </span>
                                                            <br/>
                                                            <span style={{fontSize:'15px', color:'black'}}>
                                                                <FormattedMessage id="newestimate.page.margin.price.vat"
                                                                                defaultMessage='Sales vat incl.'/>&nbsp;&nbsp;&nbsp;&nbsp;{'  '}
                                                                <FormattedNumber value={0} style="currency" 
                                                                     maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>
                                                            </span>
                                                        </Header>
                                                    </Dimmer.Dimmable>
                                                </Segment>
                                            </Table.Cell>
                                            <Table.Cell width={8}></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </Grid.Row> 
                            <Grid.Row columns={1} style={{ paddingLeft:'2em',paddingTop:'0em' }}>
                               <Grid.Column width={6}> 
                                 <Form>
                                   <label style={{paddingTop:'0em', color:'black'}}>
                                       <FormattedMessage id="newestimate.list.note.title"
                                                         defaultMessage='Additional note' />
                                   </label>
                                   <FormattedMessage id="newestimate.list.note.placeholder"
                                                  defaultMessage='quotation for X serving(s)'>
                                     {placeholder =>   
                                            <TextArea type="text" 
                                                      id="note" 
                                                      icon='text'
                                                      iconPosition='left'
                                                      value={note}
                                                      rows='2'
                                                      maxlength="600"
                                                      onChange={(e)=>this.onNoteChange(e)} 
                                                      placeholder={placeholder}
                                            />}
                                     </FormattedMessage>
                                  </Form>
                               </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1}/>
                            <Grid.Row columns={1}/>
                            <Grid.Row columns={3}>
                                <Grid.Column  mobile={8} tablet={4} computer={4}/>
                                <Grid.Column mobile={5} tablet={0} computer={6}/>
                                <Grid.Column mobile={3} tablet={16} computer={4}> 
                                  <div className={className}>
{/*                                   <Popup trigger=
                                            { <Button //color='blue'
                                                      style={styles.ButtonGreyAddNoMarginOrder}
                                                      //size='big' 
                                                      onClick={(e) => {this.openPlanning(e)}}>
                                                          <Icon name='calendar alternate outline' />{' '}
                                                          <FormattedMessage id="estimate.floatingmenu.planning.icon.title"
                                                                            defaultMessage='Planning'/>
                                               </Button>}
                                             content={<FormattedMessage id="estimate.floatingmenu.planning.title"
                                                                        defaultMessage='Open planning'/>}
                                            position='top center'
                                            inverted
                                    /> */}
                                  <Popup trigger=
                                            { <Button //circular 
                                                      //color='blue'
                                                      style={styles.ButtonBlueAddNoMarginOrder}
                                                      //size='big' 
                                                      //icon='book' 
                                                      onClick={(e)=>this.toggleItemsMenu(e,'recipe')}>
                                                          <Icon name='add circle' />{' '}
                                                          <FormattedMessage id="newestimate.floatingmenu.recipes.icon.title"
                                                                            defaultMessage='Recipes'/>
                                               </Button>}
                                             content={<FormattedMessage id="newestimate.floatingmenu.recipes.title"
                                                                        defaultMessage='Add recipes'/>}
                                            position='top center'
                                            inverted
                                    />
                                  </div>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1} style={{'overflow-x': 'auto'}}>
                                <Grid.Column  /*width={14}*/  mobile={18} tablet={16} computer={14}> 
                                    <Table //selectable 
                                           unstackable 
                                           compact 
                                           singleLine 
                                           //color={'grey'}
                                           style={styles.FontLato13Border}>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell width={5}>
                                                    <FormattedMessage id="newestimate.list.selected.column.element.recipe"
                                                                       defaultMessage='Item(s)' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={3} textAlign='center'>
                                                    {/*  <FormattedMessage id="order.list.selected.column.units"
                                                                          defaultMessage='Unit(s)' /> */}
                                                        <FormattedMessage id="newestimate.list.selected.column.units"
                                                                          defaultMessage='Amount' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={3} textAlign='right'>
                                                        <FormattedMessage id="newestimate.list.selected.column.yield"
                                                                          defaultMessage='Yield' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={3} textAlign='right'>
                                                       <FormattedMessage id="newestimate.list.selected.column.unitprice"
                                                                          defaultMessage='Food unit cost' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={2} textAlign='right'>
                                                       <FormattedMessage id="newestimate.list.selected.column.selling"
                                                                          defaultMessage='Selling' /></Table.HeaderCell>
                                                <Table.HeaderCell width={3} textAlign='center'></Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                        {!emptyOrders && orderList &&
                                                this.addOrdersRows(orderList)
                                        }                                        
                                        {emptyOrders && 
                                         <Table.Row>
                                            <Table.Cell>
                                                <FormattedMessage id="newestimate.list.selected.column.empty.message"
                                                                       defaultMessage='No item added yet' />
                                            </Table.Cell>
                                          </Table.Row>
                                        }
                                        </Table.Body>
                                        {!emptyOrders && 
                                          <Table.Footer fullWidth>
                                            <Table.Row>
                                                    <Table.HeaderCell colSpan='6'>
                                                        <Button compact color='teal' 
                                                                floated='right'
                                                                size='small'
                                                                loading={loading_o}
                                                                style={styles.ButtonGreenNoMargin}
                                                                //disabled={!this.handleValidation() 
                                                                 //   || !this.assertNumericFields() || !this.handleIngredientsValidation()}
                                                                onClick={(e)=>this.createEstimate(e)}>
                                                                <FormattedMessage id="newestimate.list.selected.button.create"
                                                                                    defaultMessage='Create new estimate' />
                                                            </Button>
                                                    </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Footer>}
                                        <Modal open={this.state.activeModalPremium}
                                                    dimmer='true' 
                                                    style={styles.Modal} 
                                                    onClose={this.hideModal}
                                                    closeIcon>
                                                <Modal.Header>
                                                    <FormattedMessage id="newestimate.planning.header.title"
                                                                    defaultMessage='Create planning task'/>
                                                </Modal.Header>
                                                <Modal.Content>
                                                    <Message fluid icon size='big' style={styles.FontLato12Bold} color='blue'>
                                                    <Image src={img_performance} size ='small' rounded/>
                                                    <br/>
                                                    <Message.Content style={{marginLeft:'1em'}}>
                                                        <Message.Header color='black'>
                                                            <FormattedMessage id="newestimate.premium.message.upgradeplan"
                                                                                defaultMessage='PREMIUM Feature'/>
                                                        </Message.Header>
                                                        <br/>
                                                        <FormattedMessage id="newestimate.premium.title.message.upgradeplan"
                                                                        defaultMessage='Your current plan: '/>{' '}{membership && membership.name}   
                                                        <br/><br/>
                                                        <Button basic color='blue' style={styles.FontLato12Bold} onClick={(e) => this.upgradeSubscription()}>
                                                            <FormattedMessage id="newestimate.premium.button.chooseplan"
                                                                        defaultMessage='Choose premium plan'/>
                                                        </Button>                                      
                                                    </Message.Content>
                                                </Message>
                                                </Modal.Content>
                                                <Modal.Actions>
                                                    <Button basic color='grey' onClick= {(e) => this.hideModal()}>
                                                    {/* <Icon name='exit' /> */}
                                                    <FormattedMessage id="newestimate.premium.button.cancel"
                                                                    defaultMessage='Cancel'/>
                                                    </Button>
                                                    <Button color='blue' onClick= {(e) => this.upgradeSubscription()}>
                                                    <FormattedMessage id="newestimate.premium.button.chooseplan"
                                                                        defaultMessage='Choose premium plan'/>
                                                    </Button>
                                                </Modal.Actions>
                                        </Modal>
                                    </Table>
                                    <br/> <br/>
                                </Grid.Column>         
                            </Grid.Row>  
                        </Grid>
                    </SideLeftUncoverProductions>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { recipes,categoriesRecipe,client,alert,dispatch } = state;
    const { loading_r,loading_o} = state.recipes;
    const { loading_cr } = state.categoriesRecipe;
    const { subscription,customer } = state.payment;

    return {
        recipes,
        categoriesRecipe,
        client,
        alert,
        dispatch,
        loading_r,
        loading_cr,
        loading_o,
        subscription,
        customer
    };
}

const connectedNewEstimate = injectIntl(connect(mapStateToProps)(NewEstimate));
export { connectedNewEstimate as NewEstimate };
 
