import React, { Component } from 'react'
import { Sidebar, Segment, Responsive, List, Menu, Container, Icon } from 'semantic-ui-react'
import { Link,withRouter } from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
//import {ADMIN} from '../_helpers';

const containerStyle = {
  height: 'auto',
  width: '1400px',
  padding: '1em 0em',
  border:'none',
  'font-family': 'Lato', 
  'font-size': '12px',
  'color': 'grey',
  //'border-style':'solid',
  //'border-width': '0.5px',
 'background-color': '#f9f9f9',
 // backgroundImage: `url(${backgroundImg})`,
 // backgroundSize: 'cover'
};



class SideLeftProfile extends Component {
  constructor(props) {
    super(props);
    //let userIn = JSON.parse(localStorage.getItem('userIn'));
    this.state = {
        visible: false,
        //role:  userIn.user.role
   };   
  }

  isActive(match1){
    //return this.props.location.pathname.indexOf(match1)!=-1
    return this.props.location.pathname === match1
  }

  
  render() {
    /* const {role} =  this.state; */
    return (
      <div>
        <Responsive minWidth={Responsive.onlyComputer.minWidth}>
            <Sidebar.Pushable as={Container} style={containerStyle}>
             <Sidebar as={List} selection animation='uncover' width='thin' visible={true} icon='labeled' vertical='true' size='large'>
                <br/>
                <List.Item disabled><FormattedMessage id="profile.computer.sidemenu.menu.top.title"
                                                       defaultMessage='Profile settings'/></List.Item>
                <List.Item name='Personal Information' as={Link} to='/profile'>
                  <Icon name='address card' color={this.isActive('/profile')?'blue':'black'}/>
                  <List.Content>
                    <List.Header style={this.isActive('/profile')?{'color': '#0d77b5'}:{'color': 'black'}}>
                               <FormattedMessage id="profile.computer.sidemenu.menu.elem1.title"
                                                 defaultMessage='Account'/>
                    </List.Header>
                  </List.Content>
                </List.Item>
                <List.Item name='Settings' as={Link} to='/settings'>
                  <Icon name='settings' color={this.isActive('/settings')?'blue':'black'} />
                  <List.Content>
                    <List.Header style={this.isActive('/settings')?{'color': '#0d77b5'}:{'color': 'black'}}>
                               <FormattedMessage id="profile.computer.sidemenu.menu.elem5.title"
                                                 defaultMessage='Settings'/>
                    </List.Header>
                  </List.Content>
                </List.Item>
                <List.Item name='Organisation' as={Link} to='/organisation' /* disabled = {role<ADMIN} */ >
                  {/* {role>=ADMIN && */}
                    <Icon name='building' color={this.isActive('/organisation')?'blue':'black'} />{/* } */}
{/*                    {role<ADMIN &&
                    <Icon name='building' color={'grey'} />} */}
                  <List.Content>
                  {/* {role>=ADMIN && */}
                    <List.Header style={this.isActive('/organisation')?{'color': '#0d77b5'}:{'color': 'black'}}>
                               <FormattedMessage id="profile.computer.sidemenu.menu.elem6.title"
                                                 defaultMessage='Organization(s)'/>
                    </List.Header>{/* } */}
{/*                     {role<ADMIN &&
                    <List.Header style={{'color': 'grey'}}>
                               <FormattedMessage id="profile.computer.sidemenu.menu.elem6.title"
                                                 defaultMessage='Organisation'/>
                    </List.Header>} */}
                  </List.Content>
                </List.Item>
                <List.Item name='Users' as={Link} to='/invitation' /* disabled = {role<ADMIN} */>
                  {/* {role>=ADMIN && */}
                    <Icon name='user plus' color={this.isActive('/invitation')?'blue':'black'} />{/* } */}
{/*                    {role<ADMIN &&
                    <Icon name='users' color={'grey'} />} */}
                  <List.Content>
                    {/* {role>=ADMIN && */}
                      <List.Header style={this.isActive('/invitation')?{'color': '#0d77b5'}:{'color': 'black'}}>
                                <FormattedMessage id="profile.computer.sidemenu.menu.elem7.title"
                                                  defaultMessage='User(s)'/>
                      </List.Header>{/* } */}
{/*                     {role<ADMIN &&
                      <List.Header style={{'color': 'grey'}}>
                                <FormattedMessage id="profile.computer.sidemenu.menu.elem7.title"
                                                  defaultMessage='Users'/>
                      </List.Header>} */}
                  </List.Content>
                </List.Item>
                <List.Item name='clients' as={Link} to='/clients'>
                  <Icon name='users' color={this.isActive('/clients')?'blue':'black'} />
                  <List.Content>
                    <List.Header style={this.isActive('/clients')?{'color': '#0d77b5'}:{'color': 'black'}}>
                        <FormattedMessage id="profile.computer.sidemenu.menu.elem9.title"
                                          defaultMessage='Clients - Leads'/>
                    </List.Header>
                  </List.Content>
                </List.Item>              
{/*                 <List.Item name='POS' as={Link} to='/pos'>
                  <Icon name='tv' color={this.isActive('/pos')?'blue':'black'} />
                  <List.Content>
                    <List.Header style={this.isActive('/pos')?{'color': '#0d77b5'}:{'color': 'black'}}>
                        <FormattedMessage id="profile.computer.sidemenu.menu.elem8.title"
                                          defaultMessage='Point of Sale'/>
                    </List.Header>
                  </List.Content>
                </List.Item> */}
                <List.Item name='Subscriptions' as={Link} to='/subscriptions'>
                  <Icon name='credit card outline' color={this.isActive('/subscriptions')?'blue':'black'} />
                  <List.Content>
                    <List.Header style={this.isActive('/subscriptions')?{'color': '#0d77b5'}:{'color': 'black'}}>
                        <FormattedMessage id="profile.computer.sidemenu.menu.elem2.title"
                                          defaultMessage='Subscription'/>
                    </List.Header>
                  </List.Content>
                </List.Item>
                <List.Item name='Invoices' as={Link} to='/invoices'>
                  <Icon name='edit outline' color={this.isActive('/invoices')?'blue':'black'} />
                  <List.Content>
                    <List.Header style={this.isActive('/invoices')?{'color': '#0d77b5'}:{'color': 'black'}}>
                        <FormattedMessage id="profile.computer.sidemenu.menu.elem3.title"
                                          defaultMessage='Invoices'/>
                    </List.Header>
                  </List.Content>
                </List.Item>
                <List.Item name='offers' as={Link} to={{pathname: '/profilepremium', state: {toggled: true}}}>
                  <Icon name='gift' color={this.isActive('/profilepremium')?'blue':'black'} />
                  <List.Content>
                    <List.Header style={this.isActive('/profilepremium')?{'color': '#0d77b5'}:{'color': 'black'}}>
                        <FormattedMessage id="profile.computer.sidemenu.menu.elem4.title"
                                          defaultMessage='Premium plans'/>
                    </List.Header>
                  </List.Content>
                </List.Item>
              </Sidebar> 
              <Sidebar.Pusher style={{ 'min-height':'100vh', width: '100%', padding: '2em 2em',border:'none','background-color':'#fcfcfc'}}>
                  {this.props.children} 
              </Sidebar.Pusher>
            </Sidebar.Pushable>
        </Responsive>
        <Responsive minWidth={Responsive.onlyMobile.maxWidth} maxWidth={Responsive.onlyTablet.maxWidth}>
            <Menu secondary>
                <Menu.Item  onClick={() => this.setState({ visible: !this.state.visible })} >
                    <Icon name="sidebar"/><FormattedMessage id="profile.mobile.sidemenu.menu.top.title"
                                                            defaultMessage='Settings'/>
                </Menu.Item>          
            </Menu>
            <Sidebar.Pushable as={Segment} >
                 <Sidebar 
                    width='thin'
                    as={Menu}    
                    animation="push" 
                    direction="top" 
                    visible={this.state.visible} 
                    icon="labeled" 
                    horizontal
                    selection
                    size='tiny'>
                  {/* <Container> */}
                    <Menu.Item name='Personal Information' as={Link} to='/profile' >
                      <Icon name='address card' color={this.isActive('/profile')?'blue':'black'} />
                      <FormattedMessage id="profile.mobile.sidemenu.menu.elem1.title"
                                          defaultMessage='Account'/>
                    </Menu.Item>
                    <Menu.Item name='Settings' as={Link} to='/settings' >
                      <Icon name='settings' color={this.isActive('/settings')?'blue':'black'} />
                      <FormattedMessage id="profile.mobile.sidemenu.menu.elem6.title"
                                          defaultMessage='Settings'/>
                    </Menu.Item>
                    <Menu.Item name='Organisation' as={Link} to='/organisation' >
                      <Icon name='building' color={this.isActive('/organisation')?'blue':'black'} />
                      <FormattedMessage id="profile.mobile.sidemenu.menu.elem7.title"
                                          defaultMessage='Organization(s)'/>
                    </Menu.Item>
                    <Menu.Item name='Users' as={Link} to='/invitation' >
                      <Icon name='users' color={this.isActive('/invitation')?'blue':'black'} />
                      <FormattedMessage id="profile.mobile.sidemenu.menu.elem8.title"
                                          defaultMessage='User(s)'/>
                    </Menu.Item>
                    <Menu.Item name='Subscriptions' as={Link} to='/subscriptions' >
                      <Icon name='credit card outline' color={this.isActive('/subscriptions')?'blue':'black'} />
                      <FormattedMessage id="profile.mobile.sidemenu.menu.elem3.title"
                                          defaultMessage='Subscription'/>
                    </Menu.Item>
                    <Menu.Item name='Invoices' as={Link} to='/invoices' >
                      <Icon name='file alternate outline'color={this.isActive('/invoices')?'blue':'black'} />
                      <FormattedMessage id="profile.mobile.sidemenu.menu.elem4.title"
                                          defaultMessage='Invoices'/>
                    </Menu.Item>
                    <Menu.Item name='offers' as={Link} to={{pathname: '/profilepremium', state: {toggled: true}}} >
                      <Icon name='gift' color={this.isActive('/profilepremium')?'blue':'black'} />
                        <FormattedMessage id="profile.mobile.sidemenu.menu.elem5.title"
                                          defaultMessage='Premium'/>
                    </Menu.Item>
                  {/* </Container>  */} 
                 </Sidebar>
                <Sidebar.Pusher dimmed={this.state.visible} style={{ minHeight: '100vh',overflow: 'auto', padding: '2em 0em 2em 2em' }}>
                    {/* <Segment style={{ minHeight: 500, padding: '1em 0em' }}> */}
                      {this.props.children}
                    {/* </Segment>     */} 
                </Sidebar.Pusher>
            </Sidebar.Pushable>
         </Responsive>
        <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
            <Menu secondary>
                <Menu.Item  onClick={() => this.setState({ visible: !this.state.visible })} >
                    <Icon name="sidebar"/><FormattedMessage id="profile.mobile.sidemenu.menu.top.title"
                                                            defaultMessage='Settings'/>
                </Menu.Item>          
            </Menu>
            <Sidebar.Pushable as={Segment} >
                 <Sidebar 
                    width='thin'
                    as={Menu}    
                    animation="push" 
                    direction="top" 
                    visible={this.state.visible} 
                    icon="labeled" 
                    horizontal
                    selection
                    size='tiny'>
                  {/* <Container> */}
                    <Menu.Item name='Personal Information' as={Link} to='/profile' >
                      <Icon name='address card' color={this.isActive('/profile')?'blue':'black'} />
                      <FormattedMessage id="profile.mobile.sidemenu.menu.elem1.title"
                                          defaultMessage='Account'/>
                    </Menu.Item>
                    <Menu.Item name='Settings' as={Link} to='/settings' >
                      <Icon name='settings' color={this.isActive('/settings')?'blue':'black'} />
                      <FormattedMessage id="profile.mobile.sidemenu.menu.elem6.title"
                                          defaultMessage='Settings'/>
                    </Menu.Item>
                    <Menu.Item name='Organisation' as={Link} to='/organisation' >
                      <Icon name='building' color={this.isActive('/organisation')?'blue':'black'} />
                      <FormattedMessage id="profile.mobile.sidemenu.menu.elem7.title"
                                          defaultMessage='Organization(s)'/>
                    </Menu.Item>
                    <Menu.Item name='Users' as={Link} to='/invitation' >
                      <Icon name='users' color={this.isActive('/invitation')?'blue':'black'} />
                      <FormattedMessage id="profile.mobile.sidemenu.menu.elem8.title"
                                          defaultMessage='User(s)'/>
                    </Menu.Item>
                    <Menu.Item name='Subscriptions' as={Link} to='/subscriptions' >
                      <Icon name='credit card outline' color={this.isActive('/subscriptions')?'blue':'black'} />
                      <FormattedMessage id="profile.mobile.sidemenu.menu.elem3.title"
                                          defaultMessage='Subscription'/>
                    </Menu.Item>
                    <Menu.Item name='Invoices' as={Link} to='/invoices' >
                      <Icon name='file alternate outline' color={this.isActive('/invoices')?'blue':'black'} />
                      <FormattedMessage id="profile.mobile.sidemenu.menu.elem4.title"
                                          defaultMessage='Invoices'/>
                    </Menu.Item>
                    <Menu.Item name='offers' as={Link} to={{pathname: '/profilepremium', state: {toggled: true}}} >
                      <Icon name='gift' color={this.isActive('/profilepremium')?'blue':'black'} />
                        <FormattedMessage id="profile.mobile.sidemenu.menu.elem5.title"
                                          defaultMessage='Premium'/>
                    </Menu.Item>
                  {/* </Container>  */} 
                 </Sidebar>
                <Sidebar.Pusher dimmed={this.state.visible} style={{ minHeight: '100vh',overflow: 'auto', padding: '2em 0em 2em 0em' }}>
                    {/* <Segment style={{ minHeight: 500, padding: '1em 0em' }}> */}
                      {this.props.children}
                    {/* </Segment>     */} 
                </Sidebar.Pusher>
            </Sidebar.Pushable>
         </Responsive>
      </div>
    )
  }
}

export default withRouter(SideLeftProfile)
