import React, { Component } from 'react';
import { Table, Input,Dropdown, Grid, Menu,Pagination,Icon,Header,Loader,Dimmer,Modal,Button,Breadcrumb,Label,Popup } from 'semantic-ui-react';
import { recipesActions,alertActions,paymentActions } from '../_actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SideLeftUncoverProductions from './SidebarProductions';
import {toaster_dispatch,history,typeYield} from '../_helpers';
import {FormattedMessage,FormattedDate,FormattedNumber,injectIntl} from 'react-intl';
import {styles} from './Styles';
import img_invoicing from '../Assets/invoicing.png'
import './FloatingMenu.css';
import {FloatingMenuItem, FloatingMenuItemOther} from './FloatingMenu';

import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';
//import { TZDate } from '@toast-ui/calendar';
import DatePicker from "react-datepicker"
import { registerLocale, setDefaultLocale } from  'react-datepicker';
import fr from 'date-fns/locale/fr';
import en from 'date-fns/locale/en-US';


var HOUR_TIME=60*60*1000;

const optionsPage = [
    //{ key: '5', text: '5', value: 5 },
    { key: '10', text: '10', value: 10 },
    { key: '20', text: '20', value: 20 },
    { key: '30', text: '30', value: 30 },
    { key: '50', text: '50', value: 50 },
  ]

//['draft','sent','production ongoing','refused','ended','paid','not recoverable']
const optionsStatus = [
                { key: 1, text: <FormattedMessage id="estimates.options.draft" defaultMessage='Draft'/>, value: 'draft' },
                 { key: 2, text: <FormattedMessage id="estimates.options.sent"  defaultMessage='Sent'/>, value: 'sent' },
                 { key: 3, text: <FormattedMessage id="estimates.options.accepted"  defaultMessage='Accepted'/>, value: 'accepted' },
                 { key: 4, text: <FormattedMessage id="estimates.options.onproducing" defaultMessage='Production ongoing'/>, value: 'production' },
                { key: 5, text: <FormattedMessage id="estimates.options.refused"  defaultMessage='Refused'/>, value: 'refused' },
                { key: 6, text: <FormattedMessage id="estimates.options.ended" defaultMessage='Ended'/>, value: 'ended' }    ] 


  
class Estimates extends Component {
    constructor(props) {
        super(props);
        let userIn = JSON.parse(localStorage.getItem('userIn'));
        const {intl} = props;
        this.state = {
                      prevEstimates : props.estimates,
                      currency : userIn.user.currency ,
                      toggled : false,
                      isMobile: false,
                      //orderTypeSelected: 'draft',
                      filtering: false,
                      page: 1,
                      itemsPerPage: 10,
                      activeModalEstimateDelete: false,
                      allowMultipleSelection:true,
                      selectionList:[],
                      actionModal: false,
                      filterOldTasks: false,
                      estimateStatusSelected:""
                     }
        props.dispatch(recipesActions.getEstimates(true));
        props.dispatch(paymentActions.getSubscriptionState());
        props.dispatch(paymentActions.getCustomerMembership());

        this.filterNameList=this.filterNameList.bind(this);
        this.removeFilters=this.removeFilters.bind(this);
        this.filterEstimateStatusList=this.filterEstimateStatusList.bind(this);
        this.setPageNum = this.setPageNum.bind(this);
        this.onElemPerPageChange = this.onElemPerPageChange.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.selectActionList=this.selectActionList.bind(this);
        this.cancelAction=this.cancelAction.bind(this);

        registerLocale('en', en)
        registerLocale('fr', fr)
        if(intl.locale == 'fr'){
            setDefaultLocale('fr',fr)
        }else{
            setDefaultLocale('en',en)
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    static getDerivedStateFromProps(props, state) {
        if((props.estimates && props.estimates!==state.prevEstimates)) {

/*             let updatedList = props.estimates.filter((item) => {
                if (item){
                    if (!(item.state)) item.state = 'ongoing'
                    return (item.state.toLowerCase() == 'ongoing')
                }
                return null; 
            }); */

            return{newItems: props.estimates,
                   filtering: true,
                   prevEstimates: props.estimates}
        }
        return null;
    }
    
    resize() {
        this.setState({isMobile: window.innerWidth <= 760});
    }

    setPageNum = (event, { activePage }) => {
        this.setState({ page: activePage });
        this.resetSelectionList();
        this.setState({isCheckedAll: false});
    };

    onElemPerPageChange = (e,data) => {
        e.preventDefault();
        this.setState({ itemsPerPage: data.value });
    }

    removeFilters(event){
        event.preventDefault();
        this.setState({filtering: false});
        this.setState({nameSelected: ""});
        this.setState({estimateStatusSelected: ""});
        this.setState({ selectionList:[]});
/* 
        let updatedList = this.props.estimates.filter((item) => {
            if (item){
               // if (!item.state) item.state = ''
                return (item.state.toLowerCase() == this.state.estimateStatusSelected.toLowerCase())
            }
            return null;
        });  */
        this.setState({newItems:  this.props.estimates});
        this.setState({ selectedStartDate: null, selectedEndDate: null})
    }

    filterNameList(event){
        event.preventDefault();
        this.setState({ selectionList:[]});

        if (event.target.value == ""){
            this.removeFilters(event);
            return;
        }
        const {estimates} = this.props;
        if (estimates){
            let updatedList = this.props.estimates.filter((item) => {
                return (item.ref_name).normalize('NFKD').replace(/[^\w]/g, '').toLowerCase()
                        .search(event.target.value.normalize('NFKD').replace(/[^\w]/g, '').toLowerCase()) !== -1;
              });
      
/*                let updatedListOrderType = updatedList.filter((item) => {
                  if (item){
                      if (!item.state) item.state = 'ongoing'
                      return (item.state.toLowerCase() == this.state.orderTypeSelected.toLowerCase())
                  }
                  return null;
              }); */
      
              this.setState({newItems: updatedList});
              this.setState({filtering: true});
              this.setState({nameSelected: event.target.value});
              this.setState({ selectedStartDate: null, selectedEndDate: null})
              this.setState({estimateStatusSelected: ""});
        }
    }

    filterEstimateStatusList(event,data){
        event.preventDefault();
        this.setState({ selectionList:[]});
        const {estimates} = this.props;

        if (data.value == ""){
            this.removeFilters(event);
            return;
        }

        if (estimates){
            let updatedList = this.props.estimates.filter((item) => {
                if (item){
                    return (item.state.toLowerCase() == data.value.toLowerCase())
                }
                return null;
            });
            this.setState({newItems: updatedList});
            this.setState({filtering: true});
            this.setState({estimateStatusSelected: data.value});
            this.setState({nameSelected: ""});
            this.setState({ selectedStartDate: null, selectedEndDate: null})
        }

    }

    setDateRange (update){
        const {estimates} = this.props;
        this.setState({ selectionList:[]});
        this.setState({ selectedStartDate: update[0], selectedEndDate: update[1]})

        //console.log(update)
        if (estimates && update[0]!=null && update[1]!=null){
            let startDate = new Date(update[0])
            let endDate = new Date(update[1])
            endDate.setDate(endDate.getDate() + 1);
            let updatedList = this.props.estimates.filter((item) => {
                    return new Date(item.due) >= startDate && new Date(item.due) <= endDate
                  });
                
            this.setState({newItems: updatedList});
            this.setState({filtering: true});
            this.setState({nameSelected: ""});
            this.setState({estimateStatusSelected: ""});
            
        }else{
            this.setState({filtering: false});
            this.setState({nameSelected: ""});
            this.setState({estimateStatusSelected: ""});
        }

        return [update[0],update[1]]
    }

    seeEstimate (event,index) {
        event.preventDefault();
        history.push('/estimatedetails/'+index);
    }
    
    createOrder(event){
        history.push('/orders');
    }

    createNewProduction(){
        history.push('/production/',{date:new Date()});
    }

    createNewEstimate(){
        history.push('/newestimate/');
    }

    deleteEstimateClickHandler (event,index,estimate) {
        event.preventDefault();
        this.setState({ activeModalEstimateDelete: index});
        this.setState({ delete_order: estimate});
    };

    deleteEstimate(event,id){
        event.preventDefault();
        this.props.dispatch(recipesActions.deleteEstimate(id));  
    }


/*     produceEstimateClickHandler (event,index,estimate) {
        event.preventDefault();
        //this.setState({ activeModalEstimateDelete: index});
        this.setState({ produce_estimate: estimate});
        this.createDirectOrder(event,estimate)
    }; */


    hideModal() {
        this.setState({ activeModalEstimateDelete: false });
        this.setState({ actionModal: false })
        this.setState({actionSelected: ""})
    }

    actionSelectDelete(event) {
        event.preventDefault();
        const { selectionList } = this.state;
        this.props.dispatch(recipesActions.deleteSelectEstimate(selectionList,'/estimates'))
        this.setState({ selectionList:[]});
    }

    addSelectList(event,index){
        const { selectionList } = this.state;
        if (event.target.checked){
            this.setState({ selectionList:[...selectionList,index]});
        }else{
            this.setState({ selectionList:selectionList.filter((item)=> item!=index)});
            if (selectionList.length <= 1){
                this.setState({actionSelected: ""});
            }
            this.setState({isCheckedAll: false});
        }
    }

    selectAllSelectList(event,items){
        let allList = []
        if (event.target.checked){
            items.map((order,index) => {
                allList.push(order.id);
            });
        }else{
            this.setState({actionSelected: ""});
        }
        this.setState({ isCheckedAll: event.target.checked});
        this.setState({ selectionList:allList});
        
    }

    isChecked(index){
        const { selectionList } = this.state;
        return selectionList.includes(index)
    }

    isCheckedAll(){
        return this.state.isCheckedAll
    }

    selectActionList(event,data){
        event.preventDefault();
        const { selectionList } = this.state;

        this.setState({actionSelected: data.value});

        if (selectionList.length > 0 && data.value === 'delete'){
            this.setState({actionModal: true});
        }
    }

    resetSelectionList(){
        this.setState({ selectionList:[]});
        this.setState({actionSelected: ""});
    }

    cancelAction(event){
        event.preventDefault();
        this.setState({ selectionList:[]});
        this.setState({isCheckedAll: false});
        this.setState({actionSelected: ""});
    }

    createDirectOrder = (e,estimate) => {
        e.preventDefault();

        const { dispatch,customer } = this.props;
        let user = null;
        let membership = null;
            
        if (customer){
            membership = customer.membership;
            user = customer.user;
        }

        if (membership && user &&
            ((membership.membership_type=="Entreprise") || (user.bypass==true))) 
        {
            dispatch(alertActions.clear());
            this.setState({ submitted: true });
    
     /*        this.cleanI18nDecimals();
    
            if (!this.handleAllRecipesValidation()){
                const title = <FormattedMessage id="production.quantity.createorder.check.error.title"
                                                     defaultMessage='Quantity update error'/>
                const content =  <FormattedMessage id="production.quantity.createorder.check.error.message"
                                                      defaultMessage='Quantity non numeric or invalid'/>
                dispatch(alertActions.error({title:'production.quantity.createorder.check.error.title',
                                                 content:'order.quantity.createorder.check.error.message'}));   
    
                return;
            }
    
            if (!this.handleAllIngredientsValidation()){
                const title = <FormattedMessage id="production.quantity.createorder.check.error.title"
                                                defaultMessage='Quantity update error'/>
                const content =  <FormattedMessage id="production.quantity.createorder.check.error.message"
                                                   defaultMessage='Quantity non numeric or invalid'/>
                dispatch(alertActions.error({title:'production.quantity.createorder.check.error.title',
                                             content:'order.quantity.createorder.check.error.message'}));  
    
                return;
            } */
            let aggRecipes = [];
            //console.log(estimate)
/*             this.setState({ units: [...this.state.units,{id: recipe.id, 
                                                         value: "1", 
                                                         quantity_type:typeYield(recipe.yield_units),
                                                         is_nb_recipes: false,
                                                         unit: recipe.yield_units.toLowerCase() }]}); */


            if (estimate.items && estimate.items.length > 0){
                    for (let l of estimate.items){
                        if (l.item){
                            aggRecipes.push({id:l.item.id,
                                             value: l.unit,
                                             is_nb_recipes:l.is_recipes_unit,
                                             quantity_type:typeYield(l.item.yield_units),
                                             unit: l.item.yield_units.toLowerCase()
                                            })
                        }
                    }
            }
            //console.log(aggRecipes)
            let properties = {
                    ref_name: estimate.ref_name,
                    recipesUnits:aggRecipes,
                    //ingredientsUnits: this.state.ingredQuantities,
                    prod_date: estimate.due_date,
                    //note: this.state.note,
                    is_prod: true,
            }
            this.props.dispatch(recipesActions.saveOrder(properties,false,true));  

        }else{
            this.setState({ activeModalPremium: true});
        }  
    
    } 

    addOrdersRows(estimates){
        const {currency,allowMultipleSelection} = this.state;
        
        return estimates.map((estimate,index) => {
                return (
                    <Table.Row  selectable key={index}>
                         <Table.Cell width={3} textAlign='left' >
                         {allowMultipleSelection && <Input type='checkbox' id={index}
                                   onChange={(e) => this.addSelectList(e,estimate.id)}
                                   checked={this.isChecked(estimate.id)}
                                   style={{'transform': 'scale(1.0)'}}/>}
                         <span /* style={{marginLeft:'0.3em'}} */>
                        <a href={"/estimatedetails/"+estimate.id} 
                                  onClick={(event) => this.seeEstimate(event,estimate.id)} style={{marginLeft:'0.7em'}}>
                                                     <FormattedDate value={new Date(estimate.due)}
                                                                                   year='numeric'
                                                                                   month='long'
                                                                                   day='2-digit'/> </a>
                        </span>
                        </Table.Cell> 
                        <Table.Cell width={2} textAlign='left'>{estimate.ref_number}</Table.Cell>                  
                        <Table.Cell width={3} 
                                    textAlign='left' 
                                    style={{cursor:'pointer'}}>
                                <a href={"/estimatedetails/"+estimate.id} 
                                  onClick={(event) => this.seeEstimate(event,estimate.id)}>
                                  {estimate.ref_name.length >= 45 && 
                                  estimate.ref_name.substring(0,45) + '..'}
                                {estimate.ref_name.length < 45 && 
                                    estimate.ref_name}
                                &nbsp; &nbsp;{((new Date() - new Date(estimate.created)) < HOUR_TIME) &&
                                <Label color='teal'><FormattedMessage id="estimates.estimate.status.new"
                                                                      defaultMessage='New'/></Label>}</a>
                        </Table.Cell>
                        <Table.Cell width={2} textAlign='left'>
                           {estimate.state == 'refused' &&
                            <div style={{'color':'#60a0d1'}}>
                                <Icon name='thumbs down outline' color='blue'/>
                                <FormattedMessage id="estimates.estimate.status.refused"
                                                  defaultMessage='Refused'/>
                            </div>
                           }
                           {estimate.state == 'accepted' &&
                            <div style={{'color':'#60a0d1'}}>
                                <Icon name='thumbs up outline' color='blue'/>
                                <FormattedMessage id="estimates.estimate.status.accepted"
                                                  defaultMessage='Accepted'/>
                            </div>
                           }
                           {estimate.state  == 'sent' &&
                            <div style={{'color':'#60a0d1'}}>
                              <Icon name='send' color='blue'/>
                              <FormattedMessage id="estimates.estimate.status.sent"
                                              defaultMessage='Sent'/>
                            </div>
                           }
                            {estimate.state  == 'draft' &&
                            <div style={{'color':'#60a0d1'}}>
                                <Icon name='inbox' color='blue'/>
                                <FormattedMessage id="estimates.estimate.status.draft"
                                                defaultMessage='Draft'/>
                           </div>
                           }
                           {estimate.state == 'production' &&
                            <div style={{'color':'#60a0d1'}}>
                                <Icon name='factory' color='blue'/>
                                <FormattedMessage id="estimates.estimate.status.production"
                                                  defaultMessage='Production'/>
                            </div>}
                           {estimate.state == 'ended' &&
                            <div style={{'color':'#60a0d1'}}>
                                <Icon name='check circle' color='blue'/>
                                <FormattedMessage id="estimates.estimate.status.ended"
                                                  defaultMessage='Ended'/>
                            </div>
                           }
                        </Table.Cell>
                        {estimate && 
                        <Table.Cell width={2} textAlign='center'>{estimate.client__name}</Table.Cell>}
                       {/*  <Table.Cell width={2} textAlign='center'>{order && (order.use_ingredients==true) && order.nb_ingredients}</Table.Cell> */}
                        <Table.Cell width={2} textAlign='right'>
                             {estimate &&<FormattedNumber value={Number(estimate.selling_value)} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>}
                        </Table.Cell>
                        <Table.Cell width={3} textAlign='right'>
                           <Popup trigger=
                                  {<Icon color='blue' id={index} name='folder open outline' style={{cursor:'pointer'}} size='large'
                                             onClick={(event) => this.seeEstimate(event,estimate.id)}/>
                                      }
                                    content={<FormattedMessage id="estimates.popup.view.text"
                                                                     defaultMessage='Detailed view'/>}
                                    inverted/>&nbsp;&nbsp;
                             <Popup trigger=
                                  {<Icon color='grey' id={index} name='trash alternate outline' style={{cursor:'pointer'}} size='large'
                                                      onClick={(event) => this.deleteEstimateClickHandler(event,index,estimate)}/>
                                      }
                                    content={<FormattedMessage id="estimates.popup.delete.text"
                                                                     defaultMessage='Delete'/>}
                                    inverted/>
{/*                             <Button circular basic
                                                    color='teal'
                                                     size='small' 
                                                     onClick={(event) => this.produceEstimateClickHandler(event,index,estimate)}
                                                     //onClick={(e)=>this.createDirectOrder(e)}
                                                    ><FormattedMessage id="estimates.popup.produce.text"
                                                                            defaultMessage='Produce'/></Button> */}
{/*                                <span style={{marginLeft:'8em'}}><Icon color='grey' name='trash alternate outline' style={{cursor:'pointer'}} size='large'
                                                        onClick={(event) => this.deleteEstimateClickHandler(event,index,estimate)}/></span> */}
                        </Table.Cell>
                        <Modal id={index}
                                   open={this.state.activeModalEstimateDelete === index}
                                   dimmer='default' 
                                   size='tiny' 
                                   onClose={this.hideModal}
                                   style={styles.Modal}
                                   closeIcon>
                                <Header icon='trash alternate outline' content={<FormattedMessage id="estimates.modal.order.deletion.title"
                                                                                                    defaultMessage='Deletion confirmation'/>} />
                                <Modal.Content>
                                    <p><FormattedMessage id="estimates.modal.order.deletion.message"
                                                         defaultMessage='Really sure to delete estimate {estimate} ?'
                                                         values={{estimate: estimate.ref_name}} /></p>
                                </Modal.Content>
                                <Modal.Actions>
                                       <Button style={styles.ButtonGrey}
                                               onClick={this.hideModal}>
                                            <FormattedMessage id="estimates.modal.order.deletion.button.no"
                                                              defaultMessage='No'/>
                                        </Button>
                                        <Button style={styles.ButtonRed}
                                                labelPosition='right' 
                                                content={<FormattedMessage id="estimates.modal.order.deletion.button.yes"
                                                                          defaultMessage='Yes'/> }
                                                icon='checkmark' 
                                                onClick={(event) =>this.deleteEstimate(event,estimate.id)}/>
                         </Modal.Actions>
                        </Modal> 
                     </Table.Row>
                );
        });
    }

    toggleMenu() {
		this.setState({toggled: !this.state.toggled});
    }

    onClickEvent(res) {
        //console.log('MouseEvent : ', res.nativeEvent);
        //console.log('Event Info : ', res.event);
        history.push('/productiondetails/'+res.event.id);
    }

    onClickDateEvent(res) {
        //console.log('MouseEvent : ', res.nativeEvent);
        console.log('Event Info : ', res);
        history.push('/production/',{date:res.start});
    }


    onCheckboxChange = (e, data) => {
        this.setState({ filterOldTasks: data.checked});       
    }


    render() { 
        const { alert,dispatch, estimates, intl,loading} = this.props;
        const { newItems,filtering,nameSelected,isMobile,page,itemsPerPage,
            allowMultipleSelection,isCheckedAll,selectionList,actionSelected,
            selectedStartDate,selectedEndDate,estimateStatusSelected } = this.state;

        let buttons = [];
		let className = "floating-menu";
		let icon = <Icon name='add'/>;
		
		if (this.state.toggled) {
			className += " open";
            icon = <Icon name='remove'/>;

            buttons.push(<FloatingMenuItemOther label= {<FormattedMessage id="estimates.floatingmenu.item2.title" defaultMessage= "Create an order"/>}  
                                                 icon={<Icon name='cart arrow down'/>} 
                                                 action={this.createOrder} 
                                                 mobile={isMobile}
                                                 key="r"/>);
            buttons.push(<FloatingMenuItemOther label= {<FormattedMessage id="estimates.floatingmenu.item3.title" defaultMessage= "Create a production"/>} 
                                                 icon={<Icon name='calendar alternate'/>} 
                                                 action={this.createNewProduction} 
                                                 mobile={isMobile}
                                                 key="s"/>); 
            buttons.push(<FloatingMenuItem label= {<FormattedMessage id="estimates.floatingmenu.item1.title" defaultMessage= "Create estimate"/>} 
                                           icon={<Icon name='file alternate outline'/>} 
                                           action={this.createNewEstimate} 
                                           mobile={isMobile}
                                           key="o"/>); }
        buttons.push(<FloatingMenuItem label="" icon={icon} action={this.toggleMenu.bind(this)} key="m"/>); 

        let totalPages = 1;
        let items = [];
        let length = 0;
        let begin = 0;
        let end  = 0;

        if (!filtering &&  estimates){
            length = estimates.length;
            totalPages = Math.ceil( length / itemsPerPage);
            begin = (page - 1) * itemsPerPage;
            end = (page - 1) * itemsPerPage + itemsPerPage;
            if (end > length) end=length;
            items = estimates.slice(begin,end);
        }
        if (filtering && newItems ){
            length = newItems.length;
            totalPages = Math.ceil(length / itemsPerPage);
            begin = (page - 1) * itemsPerPage;
            end = (page - 1) * itemsPerPage + itemsPerPage;
            if (end > length) end=length;
            items = newItems.slice(begin,end);
        }
        
        return ( 
                     <SideLeftUncoverProductions>
                        <Grid stackable id='grid'  style={styles.FontLato13Border}>
                            <Grid.Row columns={1}>
                             <Breadcrumb  style={{background:'#fcfcfc'}}>
                                <Breadcrumb.Section link as={Link} to={'/dashboard/'}><FormattedMessage id="estimates.link.dashboard"
                                                              defaultMessage='Dashboard'/></Breadcrumb.Section>
                                <Breadcrumb.Divider icon='right chevron'/>
                                 <Breadcrumb.Section link as={Link} to={'/productions/'}>
                                            <FormattedMessage id="estimates.link.productions"
                                                              defaultMessage='Productions'/></Breadcrumb.Section>
                                <Breadcrumb.Divider icon='right chevron'/>
                                 <Breadcrumb.Section>
                                              <FormattedMessage id="estimates.link.estimates"
                                                                defaultMessage='Estimates'/></Breadcrumb.Section>                                  
                             </Breadcrumb>
                            </Grid.Row>
                            <Grid.Row columns={1}>
                            <Grid.Column  width={8}>
                                    <Header as='h2'>
                                        <img src={img_invoicing} style={{width:'45px'}}/>
                                        <Header.Content>
                                            <FormattedMessage id="estimates.page.title"
                                                              defaultMessage='Estimates'/>
                                            <Header.Subheader><FormattedMessage id="estimates.header.caption.title"
                                                                    defaultMessage='Create estimates and produce' /></Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                </Grid.Column>
                            </Grid.Row>
{/*                             {alert.message && 
                                <Grid.Row><div className={`alert ${alert.type}`}>{alert.message}</div></Grid.Row>
                            } */}
                            {alert.message && toaster_dispatch(dispatch,alert,intl)}
                            <Grid.Row columns={1}/>
                            <Grid.Row columns={3}>
                                    <Grid.Column  mobile={8} tablet={4} computer={4}> 
                                        <p style={styles.FontLato13}><FormattedMessage id="estimates.filter.ref.title"
                                                            defaultMessage='Search by reference'/></p>
                                        <FormattedMessage id="estimates.filter.byref.placeholder"
                                                            defaultMessage='Type a reference'>
                                            {placeholder =>                                                       
                                                <Input type="text"
                                                      icon fluid={!isMobile}
                                                      placeholder={placeholder}
                                                      value={nameSelected}
                                                      onChange={this.filterNameList}
                                                      style={{minWidth:'20em',maxWidth:'30em', minHeight:'3em'}}>
                                                <Icon name='search'/><input style={{borderRadius: '100px'}} /></Input>}
                                        </FormattedMessage>
                                    </Grid.Column>
                                    {/* <Grid.Column mobile={5} tablet={3} computer={1}/>  */}
                                    <Grid.Column mobile={5} tablet={3} computer={3}>  
                                      <p style={styles.FontLato13}>
                                            <FormattedMessage id="estimates.filter.state.title"
                                                            defaultMessage='State'/></p>
                                        <FormattedMessage id="estimates.filter.state.placeholder"
                                                            defaultMessage='All'>
                                            {placeholder =>      
                                            <Dropdown selection search
                                                        placeholder={placeholder} 
                                                        value={estimateStatusSelected}
                                                        options={optionsStatus}
                                                        clearable
                                                        onChange={this.filterEstimateStatusList}
                                                        style={{'font-size': '13px'}}/>}  
                                        </FormattedMessage>                            
                                    </Grid.Column>
                                    <Grid.Column mobile={5} tablet={3} computer={3}>   
                                     <div width={4} style={{paddingTop:'0.5em', fontSize:'17px'/* ,'color': '#66b2b2' */}}>
                                                            <p style={{paddingTop:'0em', fontSize:'13px'}} ><Icon name='calendar alternate outline'/>
                                                                    {' '}<FormattedMessage id="estimates.form.production.title"
                                                                                            defaultMessage='Due date start/end'/>
                                                            </p>
                                                            <FormattedMessage id="estimates.form.dlc.placeholder"
                                                                                    defaultMessage='select a range'>
                                                                        {placeholder =>    
                                                                            <DatePicker
                                                                                    selectsRange={true}
                                                                                    startDate={selectedStartDate}
                                                                                    endDate={selectedEndDate}
                                                                                    onChange={(update) => {
                                                                                        this.setDateRange(update);
                                                                                    }}
                                                                                    isClearable={true}
                                                                                    placeholderText = {placeholder} 
                                                                                    locale={intl.locale}
                                                                                    dateFormat="P"/>
                                                                        }
                                                            </FormattedMessage>
                                        </div>
                                </Grid.Column> 
                                <Grid.Column mobile={14} tablet={12} computer={4}>  
                                        <div className={className}>
                                            {buttons}
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                             <Grid.Row columns={1} style={{'overflow-x': 'auto'}}>
                                <Grid.Column mobile={18} tablet={16} computer={14}> 
                                  {selectionList.length > 0 && 
                                     <FormattedMessage id="estimates.action.main.placeholder"
                                                       defaultMessage='{number} selected'
                                                       values={{number:selectionList.length}}>
                                        {placeholder =>  
                                                <Dropdown selection floating labeled
                                                        options= {[{ key: 'none', text: ' ', value: 'none' },
                                                                  { key: 'delete', icon: {name:'trash alternate outline',color:'red'},
                                                                                  text: <FormattedMessage id="estimates.action.delete.text"
                                                                                            defaultMessage='Delete'/>, value: 'delete' },]}
                                                        value= {actionSelected}
                                                        text={placeholder}
                                                        className='icon'
                                                        onChange={this.selectActionList}
                                                        style={{'font-size':'13px','letter-spacing': '0.025em',
                                                                'background-color':'#66b2b2',color:'white'}}/>}
                                    </FormattedMessage>}
                                    {selectionList.length > 0 &&
                                            <Button compact 
                                                className="ui icon button"
                                                //color='grey' 
                                                style={{marginLeft:'0.3em' ,'letter-spacing': '0.025em','height': '36px',
                                                       'background-color': '#bfbfbf',color:'white'}}
                                                onClick={this.cancelAction}>
                                               <FormattedMessage id="estimates.action.button.cancel.text"
                                                                   defaultMessage='Cancel'/>
                                        </Button>}
                                    {(loading) &&
                                        <Dimmer active inverted>
                                            <Loader inverted content='Loading' />
                                        </Dimmer> }
                                    <Table unstackable 
                                           //compact
                                           singleline 
                                           //color={'blue'}
                                           style={styles.FontLato13Border}>
                                        <Table.Header>
                                            <Table.Row>
                                            <Table.HeaderCell width={3} textAlign='left'>{' '}
                                            {allowMultipleSelection &&
                                                   <Input type='checkbox'
                                                          onChange={(e) => this.selectAllSelectList(e,items)}
                                                          style={{'transform': 'scale(1.0)'}}
                                                          checked={isCheckedAll}/>}
                                                    <span style={{marginLeft:'0.7em'}}>
                                                    <FormattedMessage id="estimates.list.requested.table.column2"
                                                                     defaultMessage='Due date' /></span>
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={2} textAlign='left'>{' '}
                                                   <FormattedMessage id="estimates.list.requested.table.column6"
                                                                     defaultMessage='Reference' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={3} textAlign='left'>{' '}
                                                   <FormattedMessage id="estimates.list.requested.table.column1"
                                                                     defaultMessage='Name of estimate' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={2} textAlign='left'>
                                                    <FormattedMessage id="estimates.list.requested.table.column5"
                                                                     defaultMessage='State' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={2} textAlign='center'>
                                                    <FormattedMessage id="estimates.list.requested.table.column3"
                                                                     defaultMessage='Recipient' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={2} textAlign='right'>
                                                    <FormattedMessage id="estimates.list.requested.table.column4"
                                                                     defaultMessage='Selling' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={3} textAlign='right'/>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                        {items && (items.length == 0) &&
                                            <Table.Row style={{'background-color':'#f5f5f5'}}>
                                                <Table.Cell> <FormattedMessage id="estimates.list.requested.table.row.noitems"
                                                                            defaultMessage='No estimate available'/></Table.Cell>
                                                <Table.Cell colspan='6'></Table.Cell>
                                            </Table.Row>}
                                        {items && items.length > 0 &&
                                            this.addOrdersRows(items)
                                        }
                                        </Table.Body>
                                    </Table>
                                </Grid.Column>         
                            </Grid.Row>
                            <Grid.Row columns={3} >
                                <Grid.Column textAlign='left'>
                                  <span><Menu compact secondary centered style={styles.FontLato12}>
                                         <Dropdown defaultValue={10} 
                                                  options={optionsPage} 
                                                  item
                                                  inline
                                                  fluid
                                                  onChange={this.onElemPerPageChange}
                                                  />
                                  </Menu>&ensp;{begin+1} <FormattedMessage id="estimates.pagination.to"
                                                              defaultMessage='to'/> {end} <FormattedMessage id="estimates.pagination.over"
                                                              defaultMessage='of'/> {length}</span>
                                </Grid.Column>
                                <Grid.Column textAlign='center'>
                                    <Pagination color='blue'   
                                        secondary
                                        activePage={page}
                                        totalPages={totalPages}
                                        siblingRange={4}
                                        boundaryRange ={0}
                                        ellipsisItem={{ content: <Icon name='ellipsis horizontal' size='small'/>, icon: true }}
                                        firstItem={null}
                                        lastItem={null}
                                        onPageChange={this.setPageNum}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row/>
                            <Grid.Row/>
                            <Grid.Row/>
                        </Grid>
                        <Modal open={this.state.actionModal === true}
                               dimmer='default' 
                               size='tiny' 
                               style={styles.Modal} 
                               onClose={this.hideModal}
                               closeIcon>
                                <Header icon='trash alternate outline' content={<FormattedMessage id="estimates.modal.select.deletion.title"
                                                                                defaultMessage='Deletion confirmation'/>}  />
                                <Modal.Content>
                                    <p><FormattedMessage id="estimates.modal.select.deletion"
                                                         defaultMessage='Really sure to delete definetely the selected estimate(s)?'
                                        /></p>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button style={styles.ButtonGrey} //basic color='grey' 
                                        onClick={this.hideModal}>
                                        <FormattedMessage id="estimates.modal.select.deletion.button.no"
                                                              defaultMessage='Cancel'/>
                                    </Button>
                                    <Button style={styles.ButtonRed} //basic color='teal' 
                                        labelPosition='right' 
                                        content={<FormattedMessage id="estimates.modal.select.deletion.button.yes"
                                                                    defaultMessage='Confirm'/> }
                                        icon='checkmark'
                                        onClick={(event) => this.actionSelectDelete(event)}/>
                                    </Modal.Actions>
                        </Modal>
                     </SideLeftUncoverProductions>
        )
    }
}

function mapStateToProps(state) {
    const { alert,dispatch } = state;
    const { estimates,loading } = state.recipes;
    const { customer } = state.payment;
    return {
        loading,
        estimates,
        customer,
        alert,
        dispatch,
    };
}

const connectedEstimates = injectIntl(connect(mapStateToProps)(Estimates));
export { connectedEstimates as Estimates };
 
