
import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import { recipes } from './recipes.reducer';
import { ingredients } from './ingredients.reducer';
import { categoriesRecipe } from './categoriesRecipe.reducer';
import { categoriesIngredient } from './categoriesIngredient.reducer';
import { nutrition } from './nutrition.reducer';
import { payment } from './payment.reducer';
import { blog } from './blog.reducer';
import { supplier } from './suppliers.reducer';
import { locale } from './locale.reducer';
import { storageAreas } from './storageAreas.reducer';
import { client } from './client.reducer';
import { sales } from './sales.reducer';
//import { sorting } from './sorting.reducer';

const rootReducer = combineReducers({
  authentication,
  registration,
  users,
  recipes,
  ingredients,
  categoriesRecipe,
  categoriesIngredient,
  supplier,
  nutrition,
  payment,
  blog,
  locale,
  alert,
  storageAreas,
  sales,
  client,
  //sorting
});

export default rootReducer;