export const recipeConstants = {
    GETALL_REQUEST: 'RECIPES_GETALL_REQUEST',
    GETALL_SUCCESS: 'RECIPES_GETALL_SUCCESS',
    GETALL_FAILURE: 'RECIPES_GETALL_FAILURE',

    GET_REQUEST: 'RECIPES_GET_REQUEST',
    GET_SUCCESS: 'RECIPES_GET_SUCCESS',
    GET_FAILURE: 'RECIPES_GET_FAILURE',

    ADD_REQUEST: 'RECIPES_ADD_REQUEST',
    ADD_SUCCESS: 'RECIPES_ADD_SUCCESS',
    ADD_FAILURE: 'RECIPES_ADD_FAILURE',

    UPDATE_REQUEST: 'RECIPES_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'RECIPES_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'RECIPES_UPDATE_FAILURE',

    UPDATE_INSTRUCTIONS_REQUEST: 'RECIPES_UPDATE_INSTRUCTIONS_REQUEST',
    UPDATE_INSTRUCTIONS_SUCCESS: 'RECIPES_UPDATE_INSTRUCTIONS_SUCCESS',
    UPDATE_INSTRUCTIONS_FAILURE: 'RECIPES_UPDATE_INSTRUCTIONS_FAILURE',

    UPDATE_IMAGE_REQUEST: 'RECIPES_UPDATE_IMAGE_REQUEST',
    UPDATE_IMAGE_SUCCESS: 'RECIPES_UPDATE_IMAGE_SUCCESS',
    UPDATE_IMAGE_FAILURE: 'RECIPES_UPDATE_IMAGE_FAILURE',

    GET_IMAGE_REQUEST: 'RECIPES_GET_IMAGE_REQUEST',
    GET_IMAGE_SUCCESS: 'RECIPES_GET_IMAGE_SUCCESS',
    GET_IMAGE_FAILURE: 'RECIPES_GET_IMAGE_FAILURE',

    DELETE_IMAGE_REQUEST: 'RECIPES_DELETE_IMAGE_REQUEST',
    DELETE_IMAGE_SUCCESS: 'RECIPES_DELETE_IMAGE_SUCCESS',
    DELETE_IMAGE_FAILURE: 'RECIPES_DELETE_IMAGE_FAILURE',

    DELETE_REQUEST: 'RECIPES_DELETE_REQUEST',
    DELETE_SUCCESS: 'RECIPES_DELETE_SUCCESS',
    DELETE_WARN: 'RECIPES_DELETE_WARN',
    DELETE_FAILURE: 'RECIPES_DELETE_FAILURE',    

    DELETE_INGREDIENT_REQUEST: 'RECIPES_DELETE_INGREDIENT_REQUEST',
    DELETE_INGREDIENT_SUCCESS: 'RECIPES_DELETE_INGREDIENT_SUCCESS',
    DELETE_INGREDIENT_FAILURE: 'RECIPES_DELETE_INGREDIENT_FAILURE',   
    
    DELETE_SUBRECIPE_REQUEST: 'RECIPES_DELETE_SUBRECIPE_REQUEST',
    DELETE_SUBRECIPE_SUCCESS: 'RECIPES_DELETE_SUBRECIPE_SUCCESS',
    DELETE_SUBRECIPE_FAILURE: 'RECIPES_DELETE_SUBRECIPE_FAILURE',
    
    COUNT_REQUEST: 'RECIPES_COUNT_REQUEST',
    COUNT_SUCCESS: 'RECIPES_COUNT_SUCCESS',
    COUNT_FAILURE: 'RECIPES_COUNT_FAILURE',

    STATS_REQUEST: 'RECIPES_STATS_REQUEST',
    STATS_SUCCESS: 'RECIPES_STATS_SUCCESS',
    STATS_FAILURE: 'RECIPES_STATS_FAILURE',

    GET_PDF_REQUEST: 'RECIPES_GET_SUMMARY_PDF_REQUEST',
    GET_PDF_SUCCESS: 'RECIPES_GET_SUMMARY_PDF_SUCCESS',
    GET_PDF_FAILURE: 'RECIPES_GET_SUMMARY_PDF_FAILURE',

    GET_ORDER_PDF_REQUEST: 'RECIPES_GET_ORDER_PDF_REQUEST',
    GET_ORDER_PDF_SUCCESS: 'RECIPES_GET_ORDER_PDF_SUCCESS',
    GET_ORDER_PDF_FAILURE: 'RECIPES_GET_ORDER_PDF_FAILURE',

    GET_ORDER_CSV_REQUEST: 'RECIPES_GET_ORDER_CSV_REQUEST',
    GET_ORDER_CSV_SUCCESS: 'RECIPES_GET_ORDER_CSV_SUCCESS',
    GET_ORDER_CSV_FAILURE: 'RECIPES_GET_ORDER_CSV_FAILURE',

    SAVE_ORDER_REQUEST: 'RECIPES_SAVE_ORDER_REQUEST',
    SAVE_ORDER_SUCCESS: 'RECIPES_SAVE_ORDER_SUCCESS',
    SAVE_ORDER_FAILURE: 'RECIPES_SAVE_ORDER_FAILURE',

    DELETE_ORDER_REQUEST : 'RECIPES_DELETE_ORDER_REQUEST',
    DELETE_ORDER_SUCCESS : 'RECIPES_DELETE_ORDER_SUCCESS',
    DELETE_ORDER_FAILURE : 'RECIPES_DELETE_ORDER_FAILURE',

    UPDATE_ORDER_REQUEST : 'RECIPES_UPDATE_ORDER_REQUEST',
    UPDATE_ORDER_SUCCESS : 'RECIPES_UPDATE_ORDER_SUCCESS',
    UPDATE_ORDER_FAILURE : 'RECIPES_UPDATE_ORDER_FAILURE',

    UPDATE_QUANTITY_ORDER_REQUEST : 'RECIPES_UPDATE_QUANTITY_ORDER_REQUEST',
    UPDATE_QUANTITY_ORDER_SUCCESS : 'RECIPES_UPDATE_QUANTITY_ORDER_SUCCESS',
    UPDATE_QUANTITY_ORDER_FAILURE : 'RECIPES_UPDATE_QUANTITY_ORDER_FAILURE',

    DELETE_INGREDIENT_ORDER_REQUEST : 'RECIPES_DELETE_INGREDIENT_ORDER_REQUEST',
    DELETE_INGREDIENT_ORDER_SUCCESS : 'RECIPES_DELETE_INGREDIENT_ORDER_SUCCESS',
    DELETE_INGREDIENT_ORDER_FAILURE : 'RECIPES_DELETE_INGREDIENT_ORDER_FAILURE',

    ADD_INGREDIENT_ORDER_REQUEST: 'RECIPES_ADD_INGREDIENT_ORDER_REQUEST',
    ADD_INGREDIENT_ORDER_SUCCESS: 'RECIPES_ADD_INGREDIENT_ORDER_SUCCESS',
    ADD_INGREDIENT_ORDER_FAILURE: 'RECIPES_ADD_INGREDIENT_ORDER_FAILURE',

    GET_ORDERS_REQUEST: 'RECIPES_GET_ORDERS_REQUEST',
    GET_ORDERS_SUCCESS: 'RECIPES_GET_ORDERS_SUCCESS',
    GET_ORDERS_FAILURE: 'RECIPES_GET_ORDERS_FAILURE',

    GET_ORDER_REQUEST: 'RECIPES_GET_ORDER_REQUEST',
    GET_ORDER_SUCCESS: 'RECIPES_GET_ORDER_SUCCESS',
    GET_ORDER_FAILURE: 'RECIPES_GET_ORDER_FAILURE',

    DEDUCE_ORDER_REQUEST: 'RECIPES_DEDUCE_ORDER_REQUEST',
    DEDUCE_ORDER_SUCCESS: 'RECIPES_DEDUCE_ORDER_SUCCESS',
    DEDUCE_ORDER_FAILURE: 'RECIPES_DEDUCE_ORDER_FAILURE',

    ADDSTOCK_ORDER_REQUEST: 'RECIPES_ADD_ORDER_REQUEST',
    ADDSTOCK_ORDER_SUCCESS: 'RECIPES_ADD_ORDER_SUCCESS',
    ADDSTOCK_ORDER_FAILURE: 'RECIPES_ADD_ORDER_FAILURE',

    DESTOCK_ORDER_REQUEST: 'RECIPES_DESTOCK_ORDER_REQUEST',
    DESTOCK_ORDER_SUCCESS: 'RECIPES_DESTOCK_ORDER_SUCCESS',
    DESTOCK_ORDER_FAILURE: 'RECIPES_DESTOCK_ORDER_FAILURE',

    STOCK_RECIPES_ORDER_REQUEST: 'RECIPES_STOCK_RECIPES_ORDER_REQUEST',
    STOCK_RECIPES_ORDER_SUCCESS: 'RECIPES_STOCK_RECIPES_ORDER_SUCCESS',
    STOCK_RECIPES_ORDER_FAILURE: 'RECIPES_STOCK_RECIPES_ORDER_FAILURE',

    CREATE_SUPPLIERS_ORDER_REQUEST: 'RECIPES_CREATE_SUPPLIERS_ORDER_REQUEST',
    CREATE_SUPPLIERS_ORDER_SUCCESS: 'RECIPES_CREATE_SUPPLIERS_ORDER_SUCCESS',
    CREATE_SUPPLIERS_ORDER_FAILURE: 'RECIPES_CREATE_SUPPLIERS_ORDER_FAILURE',

    COUNT_ORDERS_REQUEST: 'RECIPES_COUNT_ORDERS_REQUEST',
    COUNT_ORDERS_SUCCESS: 'RECIPES_COUNT_ORDERS_SUCCESS',
    COUNT_ORDERS_FAILURE: 'RECIPES_COUNT_ORDERS_FAILURE',

    EXPORT_CSV_REQUEST: 'RECIPES_EXPORT_CSV_REQUEST',
    EXPORT_CSV_SUCCESS: 'RECIPES_EXPORT_CSV_SUCCESS',
    EXPORT_CSV_FAILURE: 'RECIPES_EXPORT_CSV_FAILURE',

    EXPORT_XLSX_REQUEST: 'RECIPES_EXPORT_XLSX_REQUEST',
    EXPORT_XLSX_SUCCESS: 'RECIPES_EXPORT_XLSX_SUCCESS',
    EXPORT_XLSX_FAILURE: 'RECIPES_EXPORT_XLSX_FAILURE',

    WEIGHT_VARIATION_REQUEST: 'RECIPES_WEIGHT_VARIATION_REQUEST',
    WEIGHT_VARIATION_SUCCESS: 'RECIPES_WEIGHT_VARIATION_SUCCESS',
    WEIGHT_VARIATION_FAILURE: 'RECIPES_WEIGHT_VARIATION_FAILURE',

    LABEL_TAG_REQUEST: 'RECIPES_LABEL_TAG_REQUEST',
    LABEL_TAG_SUCCESS: 'RECIPES_LABEL_TAG_SUCCESS',
    LABEL_TAG_FAILURE: 'RECIPES_LABEL_TAG_FAILURE',

    GETALL_MENU_REQUEST: 'RECIPES_GETALL_MENU_REQUEST',
    GETALL_MENU_SUCCESS: 'RECIPES_GETALL_MENU_SUCCESS',
    GETALL_MENU_FAILURE: 'RECIPES_GETALL_MENU_FAILURE',

    GET_MENU_REQUEST: 'RECIPES_GET_MENU_REQUEST',
    GET_MENU_SUCCESS: 'RECIPES_GET_MENU_SUCCESS',
    GET_MENU_FAILURE: 'RECIPES_GET_MENU_FAILURE',

    ADD_MENU_REQUEST: 'RECIPES_ADD_MENU_REQUEST',
    ADD_MENU_SUCCESS: 'RECIPES_ADD_MENU_SUCCESS',
    ADD_MENU_FAILURE: 'RECIPES_ADD_MENU_FAILURE',

    UPDATE_MENU_REQUEST: 'RECIPES_UPDATE_MENU_REQUEST',
    UPDATE_MENU_SUCCESS: 'RECIPES_UPDATE_MENU_SUCCESS',
    UPDATE_MENU_FAILURE: 'RECIPES_UPDATE_MENU_FAILURE',

    DELETE_MENU_REQUEST: 'RECIPES_DELETE_MENU_REQUEST',
    DELETE_MENU_SUCCESS: 'RECIPES_DELETE_MENU_SUCCESS',
    DELETE_MENU_FAILURE: 'RECIPES_DELETE_MENU_FAILURE',  
    
    ADD_RECIPE_MENU_REQUEST: 'RECIPES_ADD_RECIPE_MENU_REQUEST',
    ADD_RECIPE_MENU_SUCCESS: 'RECIPES_ADD_RECIPE_MENU_SUCCESS',
    ADD_RECIPE_MENU_FAILURE: 'RECIPES_ADD_RECIPE_MENU_FAILURE',

    UPDATE_RECIPE_MENU_REQUEST: 'RECIPES_UPDATE_RECIPE_MENU_REQUEST',
    UPDATE_RECIPE_MENU_SUCCESS: 'RECIPES_UPDATE_RECIPE_MENU_SUCCESS',
    UPDATE_RECIPE_MENU_FAILURE: 'RECIPES_UPDATE_RECIPE_MENU_FAILURE',

    DELETE_RECIPE_MENU_REQUEST: 'RECIPES_DELETE_RECIPE_MENU_REQUEST',
    DELETE_RECIPE_MENU_SUCCESS: 'RECIPES_DELETE_RECIPE_MENU_SUCCESS',
    DELETE_RECIPE_MENU_FAILURE: 'RECIPES_DELETE_RECIPE_MENU_FAILURE',

    ADD_INGREDIENT_MENU_REQUEST: 'RECIPES_ADD_INGREDIENT_MENU_REQUEST',
    ADD_INGREDIENT_MENU_SUCCESS: 'RECIPES_ADD_INGREDIENT_MENU_SUCCESS',
    ADD_INGREDIENT_MENU_FAILURE: 'RECIPES_ADD_INGREDIENT_MENU_FAILURE',

    UPDATE_INGREDIENT_MENU_REQUEST: 'RECIPES_UPDATE_INGREDIENT_MENU_REQUEST',
    UPDATE_INGREDIENT_MENU_SUCCESS: 'RECIPES_UPDATE_INGREDIENT_MENU_SUCCESS',
    UPDATE_INGREDIENT_MENU_FAILURE: 'RECIPES_UPDATE_INGREDIENT_MENU_FAILURE',

    DELETE_INGREDIENT_MENU_REQUEST: 'RECIPES_DELETE_INGREDIENT_MENU_REQUEST',
    DELETE_INGREDIENT_MENU_SUCCESS: 'RECIPES_DELETE_INGREDIENT_MENU_SUCCESS',
    DELETE_INGREDIENT_MENU_FAILURE: 'RECIPES_DELETE_INGREDIENT_MENU_FAILURE',

    DOWNLOAD_MENU_REQUEST: 'RECIPES_DOWNLOAD_MENU_REQUEST_REQUEST',
    DOWNLOAD_MENU_SUCCESS: 'RECIPES_DOWNLOAD_MENU_SUCCESS_REQUEST',
    DOWNLOAD_MENU_FAILURE: 'RECIPES_DOWNLOAD_MENU_FAILURE_REQUEST',

    COUNT_MENUS_REQUEST: 'RECIPES_COUNT_MENUS_REQUEST',
    COUNT_MENUS_SUCCESS: 'RECIPES_COUNT_MENUS_SUCCESS',
    COUNT_MENUS_FAILURE: 'RECIPES_COUNT_MENUS_FAILURE',

    DOWNLOAD_ALLERGENS_MATRIX_SELECT_REQUEST: 'RECIPES_DOWNLOAD_ALLERGENS_MATRIX_SELECT_REQUEST',
    DOWNLOAD_ALLERGENS_MATRIX_SELECT_SUCCESS: 'RECIPES_DOWNLOAD_ALLERGENS_MATRIX_SELECT_SUCCESS',
    DOWNLOAD_ALLERGENS_MATRIX_SELECT_FAILURE: 'RECIPES_DOWNLOAD_ALLERGENS_MATRIX_SELECT_FAILURE',

    GETALL_FOR_ORDER_REQUEST: 'RECIPES_GETALL_FOR_ORDER_REQUEST',
    GETALL_FOR_ORDER_SUCCESS: 'RECIPES_GETALL_FOR_ORDER_SUCCESS',
    GETALL_FOR_ORDER_FAILURE: 'RECIPES_GETALL_FOR_ORDER_FAILURE',

    UPDATE_STOCK_REQUEST: 'RECIPES_UPDATE_STOCK_REQUEST',
    UPDATE_STOCK_SUCCESS: 'RECIPES_UPDATE_STOCK_SUCCESS',
    UPDATE_STOCK_FAILURE: 'RECIPES_UPDATE_STOCK_FAILURE',

    RESET_STOCK_REQUEST: 'RECIPES_RESET_STOCK_REQUEST',
    RESET_STOCK_SUCCESS: 'RECIPES_RESET_STOCK_SUCCESS',
    RESET_STOCK_FAILURE: 'RECIPES_RESET_STOCK_FAILURE',

    INVENTORY_REQUEST: 'RECIPES_INVENTORY_REQUEST',
    INVENTORY_SUCCESS: 'RECIPES_INVENTORY_SUCCESS',
    INVENTORY_FAILURE: 'RECIPES_INVENTORY_FAILURE',

    CATEGORY_ALL_REQUEST: 'RECIPES_CATEGORY_ALL_REQUEST',
    CATEGORY_ALL_SUCCESS: 'RECIPES_CATEGORY_ALL_SUCCESS',
    CATEGORY_ALL_FAILURE: 'RECIPES_CATEGORY_ALL_AILURE',

    UPDATE_VALIDITY_REQUEST: 'RECIPES_UPDATE_VALIDITY_REQUEST',
    UPDATE_VALIDITY_SUCCESS: 'RECIPES_UPDATE_VALIDITY_SUCCESS',
    UPDATE_VALIDITY_FAILURE: 'RECIPES_UPDATE_VALIDITY_FAILURE',

    GET_ESTIMATE_REQUEST: 'RECIPES_GET_ESTIMATE_REQUEST',
    GET_ESTIMATE_SUCCESS: 'RECIPES_GET_ESTIMATE_SUCCESS',
    GET_ESTIMATE_FAILURE: 'RECIPES_GET_ESTIMATE_FAILURE',

    GET_ESTIMATES_REQUEST: 'RECIPES_GET_ESTIMATES_REQUEST',
    GET_ESTIMATES_SUCCESS: 'RECIPES_GET_ESTIMATES_SUCCESS',
    GET_ESTIMATES_FAILURE: 'RECIPES_GET_ESTIMATES_FAILURE',

    SAVE_ESTIMATE_REQUEST: 'RECIPES_SAVE_ESTIMATE_REQUEST',
    SAVE_ESTIMATE_SUCCESS: 'RECIPES_SAVE_ESTIMATE_SUCCESS',
    SAVE_ESTIMATE_FAILURE: 'RECIPES_SAVE_ESTIMATE_FAILURE',

    DELETE_ESTIMATE_REQUEST : 'RECIPES_DELETE_ESTIMATE_REQUEST',
    DELETE_ESTIMATE_SUCCESS : 'RECIPES_DELETE_ESTIMATE_SUCCESS',
    DELETE_ESTIMATE_FAILURE : 'RECIPES_DELETE_ESTIMATE_FAILURE',

    SEND_POS_REQUEST: 'RECIPES_SEND_POS_REQUEST',
    SEND_POS_SUCCESS: 'RECIPES_SEND_POS_SUCCESS',
    SEND_POS_FAILURE: 'RECIPES_SEND_POS_FAILURE',

    UPDATE_ESTIMATE_REQUEST: 'RECIPES_UPDATE_ESTIMATE_REQUEST',
    UPDATE_ESTIMATE_SUCCESS: 'RECIPES_UPDATE_ESTIMATE_SUCCESS',
    UPDATE_ESTIMATE_FAILURE: 'RECIPES_UPDATE_ESTIMATE_FAILURE',

    UPDATE_QUANTITY_ESTIMATE_REQUEST : 'RECIPES_UPDATE_QUANTITY_ESTIMATE_REQUEST',
    UPDATE_QUANTITY_ESTIMATE_SUCCESS : 'RECIPES_UPDATE_QUANTITY_ESTIMATE_SUCCESS',
    UPDATE_QUANTITY_ESTIMATE_FAILURE : 'RECIPES_UPDATE_QUANTITY_ESTIMATE_FAILURE',

    DELETE_RECIPE_ESTIMATE_REQUEST : 'RECIPES_DELETE_RECIPE_ESTIMATE_REQUEST',
    DELETE_RECIPE_ESTIMATE_SUCCESS : 'RECIPES_DELETE_RECIPE_ESTIMATE_SUCCESS',
    DELETE_RECIPE_ESTIMATE_FAILURE : 'RECIPES_DELETE_RECIPE_ESTIMATE_FAILURE',

    ADD_RECIPE_ESTIMATE_REQUEST: 'RECIPES_ADD_RECIPE_ESTIMATE_REQUEST',
    ADD_RECIPE_ESTIMATE_SUCCESS: 'RECIPES_ADD_RECIPE_ESTIMATE_SUCCESS',
    ADD_RECIPE_ESTIMATE_FAILURE: 'RECIPES_ADD_RECIPE_ESTIMATE_FAILURE',

    UPDATE_ESTIMATE_INFOS_REQUEST: 'RECIPES_UPDATE_ESTIMATE_INFOS_REQUEST',
    UPDATE_ESTIMATE_INFOS_SUCCESS: 'RECIPES_UPDATE_ESTIMATE_INFOS_SUCCESS',
    UPDATE_ESTIMATE_INFOS_FAILURE: 'RECIPES_UPDATE_ESTIMATE_INFOS_FAILURE',

    UPDATE_PROD_STATE_ORDER_REQUEST: 'RECIPES_UPDATE_PROD_STATE_ORDER_REQUEST',
    UPDATE_PROD_STATE_ORDER_SUCCESS: 'RECIPES_UPDATE_PROD_STATE_ORDER_SUCCESS',
    UPDATE_PROD_STATE_ORDER_FAILURE: 'RECIPES_UPDATE_PROD_STATE_ORDER_FAILURE',

    COUNT_ESTIMATES_REQUEST: 'RECIPES_COUNT_ESTIMATES_REQUEST',
    COUNT_ESTIMATES_SUCCESS: 'RECIPES_COUNT_ESTIMATES_SUCCESS',
    COUNT_ESTIMATES_FAILURE: 'RECIPES_COUNT_ESTIMATES_FAILURE',

};