import React, { Component } from 'react';
import './App.css';
import { Router, Route, Redirect, Switch} from 'react-router-dom';
import {HomepageLayout} from '../Components/HomepageLayout';
import {LoginLayout} from '../Components/LoginLayout';
import {Recipes} from '../Components/Recipes';
import {NewRecipeSearch} from '../Components/NewRecipeSearch';
import {EditRecipe} from '../Components/EditRecipe';
import {CopyRecipe} from '../Components/CopyRecipe';
import {DeleteRecipe} from '../Components/DeleteRecipe';
import {RecipeDetails} from '../Components/RecipeDetails';
import {NewIngredient} from '../Components/NewIngredient';
import {EditIngredient} from '../Components/EditIngredient';
import {Ingredients} from '../Components/Ingredients';
import {RegisterLayout} from '../Components/RegisterLayout';
import {RegisterConfirm} from '../Components/RegisterConfirm';
import {PwdReset} from '../Components/PwdReset';
import {PwdChange} from '../Components/PwdChange';
import {PwdConfirm} from '../Components/PwdConfirm';
import {ConfirmDone} from '../Components/ConfirmDone';
import {ContactPage} from '../Components/ContactPage';
import {history} from '../_helpers/history';
import {connect} from 'react-redux';
import { alertActions } from '../_actions';
import { PrivateRoute, checkTokenExpirationValid/*, PrivateRouteOutside*/} from '../_helpers';
import { IngredientDetails } from '../Components/IngredientDetails';
import { CategoriesRecipe } from '../Components/CategoriesRecipe';
import { CategoriesIngredient } from '../Components/CategoriesIngredient';
import {PrepareInstructions} from '../Components/PrepareInstructions';
import { DeleteIngredient } from '../Components/DeleteIngredient';
import { HistoryIngredient } from '../Components/HistoryIngredient';
import { DownloadRecipe } from '../Components/DownloadRecipe';
import {NutritionFacts} from '../Components/NutritionFacts';
import {Dashboard} from '../Components/Dashboard';
import { Orders } from '../Components/Orders';
import { OrderResult } from '../Components/OrderResult';
import { PricingLayout } from '../Components/PricingLayout';

import {StorePlanCheckout} from '../Components/StorePlanCheckout';
import { Subscriptions } from '../Components/Subscriptions';
import { Profile } from '../Components/Profile';
import { Invoices } from '../Components/Invoices';
import { InvoiceDetails } from '../Components/InvoicesDetails';
import { injectIntl,defineMessages} from 'react-intl'
import CookieConsent from "react-cookie-consent";
import {FormattedMessage} from 'react-intl';
import { NutritionRecipe } from '../Components/NutritionRecipe';
import { PremiumLayout } from '../Components/PremiumLayout';
import {Error404Page} from '../Components/Error404Page';
import img_logo from '../Assets/ratatool-logo.png'

import {Helmet} from "react-helmet";
import { Settings } from '../Components/Settings';
import {Blog} from '../Components/Blog';
import {Overview} from '../Components/Overview';
import { OrdersList } from '../Components/OrdersList';
import { OrdersListDetails } from '../Components/OrdersListDetails';
import { ExportListRecipes } from '../Components/ExportListRecipes';
import { ExportListIngredients } from '../Components/ExportListIngredients';
import { BlogPage } from '../Components/BlogPage';
import { Suppliers } from '../Components/Suppliers';

import { SCACheck } from '../Components/SCACheck';
import { CguCgv } from '../Components/CguCgv';
import { PrivacyPolicy } from '../Components/PrivacyPolicy';
import { SendVoucher } from '../Components/SendVoucher';
import { ValidateVoucher } from '../Components/ValidateVoucher';
import { ActivateVoucher } from '../Components/ActivateVoucher';
import { serverConstants } from '../_constants';

//import ReactGA from 'react-ga';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module'
import { Inventory } from '../Components/Inventory';
import { ResetStock } from '../Components/ResetStock';
import { InventoryPDF } from '../Components/InventoryPDF';

import {NewMultipleIngredients} from '../Components/NewMultipleIngredients';
import {ImportIngredients} from '../Components/ImportIngredients';
import {UpdateIngredients} from '../Components/UpdateIngredients';

import {Menus} from '../Components/Menus';
import {EditMenu} from '../Components/EditMenu';
import { DeleteMenu } from '../Components/DeleteMenu';
import { DownloadMenu } from '../Components/DownloadMenu';
import { AllergensRecipe } from '../Components/Allergens';
import { Organisation } from '../Components/Organisation';
import { Invitation } from '../Components/Invitation';
import { StockAreas } from '../Components/StockAreas';
import { Production } from '../Components/Production';
import { Productions } from '../Components/Productions';
import { ProductionDetails } from '../Components/ProductionDetails';
//import { Sales } from '../Components/Sales';
//import { POS } from '../Components/POS';
import { Estimates } from '../Components/Estimates';
import { NewEstimate } from '../Components/NewEstimate';
import { EstimateDetails } from '../Components/EstimateDetails';
import { Clients } from '../Components/Clients';
import { CopyIngredient } from '../Components/CopyIngredient';
// import { StorePlanOutside } from '../Components/StorePlanOutside';

const frontpages = ['/','/overview', '/blog','/pricing','/contact','/termsconditions','/privacypolicy']

//GOOGLE ANALYTICS ID
//ReactGA.initialize('UA-144573518-1');
ReactGA.initialize('G-12HN731BDQ');

//GOOGLE TAG MANAGER
const tagManagerArgs = {
    gtmId: 'GTM-K45KTBR',
    auth: 'Wcd7N6sR2JciBQyKW4Rg0A',
    preview: 'env-1',
    dataLayerName: 'PageDataLayer',
}

TagManager.initialize(tagManagerArgs)



const html_tags = defineMessages({
  html_title: {
    id: 'homepage.html.title.tag',
    defaultMessage: "Ratatool - Bring productivity and profitability to your restaurant",
  },
  meta_name_description: {
    id: 'homepage.meta.name.description.tag',
    defaultMessage: "Restaurant management and recipe cost software for food costs, estimate margin and ratios, manage your recipe forms, orders, stocks and nutrition facts labels and lists",
  },
  meta_name_keywords: {
    id: 'homepage.meta.name.keywords.tag',
    defaultMessage: "restaurant, caterer, baker, snack, cuisine, technical recipe forms, stock, inventory, recipe costs, recipe margin, profitability, growth, waste, nutrition, calories, posts, blog, food",
  },
  meta_og_title: {
    id: 'homepage.meta.property.og_title.tag',
    defaultMessage: "Bring productivity and profitability to your restaurant",
  },
  meta_og_description: {
    id: 'homepage.meta.property.og_description.tag',
    defaultMessage: "Restaurant management and recipe costing tool to calculate food costs, estimate margin and ratios, manage your professional recipe forms, orders, stocks and nutrition facts labels and lists",
  },
  meta_og_locale: {
    id: 'homepage.meta.property.og_locale.tag',
    defaultMessage: "en-US",
  },
});



class App extends Component {

  constructor(props) {
    super(props);
    const { dispatch, intl } = this.props;

    let pageReloaded = false;
    let hide_msg = false;

    window.intercomSettings = {
      app_id: "t3tsst9x"
    };

    if (window.performance) {
      if (performance.navigation.type == 1) {
        pageReloaded = true
      } 
    }

    history.listen((location, action) => {
        window.scrollTo(0, 0)
        // clear alert on location change
        //ReactGA.set({ page: location.pathname })
        //ReactGA.pageview(location.pathname)
        ReactGA.send({ hitType: "pageview", page: location.pathname, title: location.pathname});

        if (!checkTokenExpirationValid()){
          
          /* dispatch(alertActions.warn(<FormattedMessage id="app.action.login.message.session.expired"
                                                     defaultMessage="Session expired: Please log again"/>)); */
          let title_text = <FormattedMessage id="app.actions.notification.title"
                                             defaultMessage="Session Expired"/>
          let content_text = <FormattedMessage id="app.actions.notification.content"
                                               defaultMessage="Please log again"/>

          dispatch(alertActions.warn({title:'app.actions.notification.title',
                                      content:'user.actions.notification.content',
                                      stringMode: false}));
        }

        window.Tawk_API.onLoad = function(){
          window.Tawk_API.hideWidget();
        };

        if (frontpages.includes(location.pathname)){
          hide_msg = false
        }else if (location.pathname.includes('/article/')){
          hide_msg = false
        }else{
          hide_msg = true
        }

        if (hide_msg){
          window.Tawk_API.hideWidget();
        }else{
          window.Tawk_API.showWidget();
        }

        //window.Intercom('update', {"hide_default_launcher": hide_msg });
        //localStorage.setItem('hide_msg', hide_msg);

/*         let user = JSON.parse(localStorage.getItem('userIn'));
        if (user && user.token){
           this.props.dispatch(userActions.verify(user.token));
        } */
    });

    //console.log(intl)
    localStorage.setItem('locale', intl.locale);
    //ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: window.location.pathname + window.location.search});
    //console.log('APP:' + window.location.pathname + window.location.search)

    if (pageReloaded){
      //let current_hide_msg_status = localStorage.getItem('hide_msg');
      //window.Intercom('update', {"hide_default_launcher": current_hide_msg_status=='true'});

      if (frontpages.includes(window.location.pathname)){
        hide_msg = false
      }else if (window.location.pathname.includes('/article/')){
        hide_msg = false
      }else{
        hide_msg = true
      }

      window.Tawk_API.onLoad = function(){
        if (hide_msg){
          window.Tawk_API.hideWidget();
        }else{
          window.Tawk_API.showWidget();
        }
      };
    }
  }

  render() { 
    const { intl} = this.props;
    let absolute_url = intl.locale == 'fr'? serverConstants.FRONTEND_URL_FR: serverConstants.FRONTEND_URL_EN;
    return ( 
      <div>
        <Helmet htmlAttributes={{ lang : intl.locale }}>

{/*           { intl.locale == 'fr' &&
            <link rel="canonical" href="https://fr.ratatool.com" />}
          { intl.locale == 'en' &&
            <link rel="canonical" href="https://www.ratatool.com" />}
          <link rel="alternate" hreflang="fr" href="https://fr.ratatool.com" />
          <link rel="alternate" hreflang="en" href="https://www.ratatool.com" />
          <link rel="alternate" hreflang="x-default" href="https://www.ratatool.com" /> */}
          {/*  <meta name="language" content="fr-FR" /> */}

          {/* <title>Ratatool - Apportez la productivité et la rentabilité à votre restaurant</title> */}
          <title>{intl.formatMessage(html_tags.html_title)}</title>
{/*       <meta name="description" content="Calculez votre coût matière avec le mercuriale d'ingrédients de vos recettes, estimez vos marges, réduisez vos pertes, éditez vos fiches techniques recettes, vos bons de commandes et vos étiquettes nutritionnelles INCO."/>
          <meta name="keywords"  content="restaurant, traîteur, boulanger,  cuisine, gestion opérationnelle, fiche techniques, coût recette, coût de revient, ratio, rentabilité, marges, pertes, calcul, nutrition, calories, meilleures pratiques, articles, blog" /> */}
          <meta name="description" content={intl.formatMessage(html_tags.meta_name_description)}/>
          <meta name="keywords" content={intl.formatMessage(html_tags.meta_name_keywords)}/> 

          {/* <meta property="og:locale" content="fr_FR" /> */}
          <meta property="og:locale" content={intl.formatMessage(html_tags.meta_og_locale)}/>
          <meta property="og:type" content="ratatool.com" />
{/*       <meta property="og:title" content="Ratatool - Apportez la productivité et la rentabilité à votre restaurant" />
          <meta property="og:description" content="Calculez votre coût matière avec le mercuriale d'ingrédients de vos recettes, estimez vos marges, réduisez vos pertes, éditez vos fiches techniques recettes et vos étiquettes nutritionnelles." />*/}
          <meta property="og:title" content={intl.formatMessage(html_tags.meta_og_title)}/>
          <meta property="og:description" content={intl.formatMessage(html_tags.meta_og_description)}/>
          <meta property="og:url" content={absolute_url} />
          <meta property="og:image" content={absolute_url+img_logo}/>
          <meta property="og:site_name" content="Ratatool" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@RatatoolApp" />
          {/* <meta name="twitter:creator" content="@rendementlocati" /> */}
          <meta name="twitter:image" content={absolute_url+img_logo}/>
          <meta name="twitter:description" content={intl.formatMessage(html_tags.meta_og_description)}/>


{/*           <script>{`
            (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/t3tsst9x';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
          `}</script> */}

        </Helmet>
        <CookieConsent style={{ background: "#f7f7f7" }} 
          buttonStyle={{ color:'white', background:'black' ,'text-align':'center', marginRight: "100px" }}
          buttonText={<FormattedMessage id="app.consent.button.text"
                                          defaultMessage="Accept"/>}>
          <span align="center" style={{ fontSize: "14px", color:'black', 'text-align':'center' }}>
                  <FormattedMessage id="app.consent.banner.message"
                                    defaultMessage="This website uses cookies to enhance your user experience."/>
          </span>
        </CookieConsent>
         <Router /*onUpdate={() => window.scrollTo(0, 0)}*/ history={history} >

               <Switch>
                <Route exact path="/" component={HomepageLayout} />
                {/* <Route  path="/news" component={News} />  */}
                {/* <Route  path="/about" component={About} /> */}
                <Route path="/login" component={LoginLayout} />
                <Route path="/register" component={RegisterLayout} />
                <Route path="/registerInvite/email=:invitee" component={RegisterLayout} />
                <Route path="/confirm-email/:token" component={RegisterConfirm} />
                <Route path="/pwdreset" component={PwdReset} />
                <Route path="/password-reset/confirm/:uid/:token" component={PwdConfirm} />
                <Route path="/confirmdone" component={ConfirmDone} />
                <Route path="/pwdchange" component={PwdChange} />
                <Route path="/pricing" component={PricingLayout} />
                <Route path="/contact" component={ContactPage} />
                <Route  path="/overview" component={Overview} />
                <Route path="/blog" component={Blog} />
                <Route path="/termsconditions" component={CguCgv} />
                <Route path="/privacypolicy" component={PrivacyPolicy} />
                <Route path="/sendvoucher" component={SendVoucher} />
                <Route path="/validatevoucher" component={ValidateVoucher} />
                <Route path="/activatevoucher" component={ActivateVoucher} />
                <Route  path="/recipe-sofware-restaurant"  render={() => <Overview business="restaurant"/>} />
                <Route  path="/restaurant-management-software"  render={() => <Overview business="management"/>} />
                <Route  path="/recipe-sofware-bakery" render={() => <Overview business="bakery"/>} />
                <Route  path="/recipe-sofware-hotel"  render={() => <Overview business="hotel"/>} />
                <Route  path="/recipe-sofware-caterer"  render={() => <Overview business="caterer"/>} />
                <Route  path="/recipe-sofware-kitchen"  render={() => <Overview business="kitchen"/>} />

{/*                 <Route exact 
                       path="/article/calcul-du-cout-recette" 
                       component={() => <Redirect to={{ pathname: '/article/calcul-du-cout-revient-recette' }} />} />
                 <Route exact 
                       path="/article/reduire-cout-restauration" 
                       component={() => <Redirect to={{ pathname: '/article/9-facons-de-reduire-le-gaspillage-alimentaire-restaurant' }} />} /> */}
                       
                <Route path="/article/:slug" component={BlogPage} />

                <PrivateRoute  path="/recipes" component={Recipes}/>
                <PrivateRoute  path="/dashboard" component={Dashboard}/>
                <PrivateRoute  path="/orders" component={Orders}/>
                <PrivateRoute  path="/orderresult" component={OrderResult}/>
                <PrivateRoute  path="/newrecipe" component={NewRecipeSearch}/>
                <PrivateRoute  path="/deleterecipe/:recipeId" component={DeleteRecipe}/>
                <PrivateRoute  path="/editrecipe/:recipeId" component={EditRecipe}/>
                <PrivateRoute  path="/copyrecipe/:recipeId" component={CopyRecipe}/>
                <PrivateRoute  path="/recipedetails/:recipeId" component={RecipeDetails}/>
                <PrivateRoute  path="/recipenutrition/:recipeId" component={NutritionRecipe}/>
                <PrivateRoute  path="/downloadrecipe/:recipeId" component={DownloadRecipe}/>
                <PrivateRoute  path="/instructionsrecipe/:recipeId" component={PrepareInstructions}/>
                <PrivateRoute  path="/exportrecipes" component={ExportListRecipes}/>
                <PrivateRoute  path="/menus" component={Menus}/>
                <PrivateRoute  path="/editmenu/:menuId" component={EditMenu}/>
                <PrivateRoute  path="/printmenu/:menuId" component={DownloadMenu}/>
                <PrivateRoute  path="/deletemenu/:menuId" component={DeleteMenu}/>
                <PrivateRoute  path="/ingredients" component={Ingredients}/>
                <PrivateRoute  path="/exportingredients" component={ExportListIngredients}/>
                <PrivateRoute  path="/newingredient" component={NewIngredient}/>
                <PrivateRoute  path="/newingredients" component={NewMultipleIngredients}/>
                <PrivateRoute  path="/importingredients" component={ImportIngredients}/>
                <PrivateRoute  path="/updateingredients" component={UpdateIngredients}/>
                <PrivateRoute  path="/deleteingredient/:ingredientId" component={DeleteIngredient}/>
                <PrivateRoute  path="/editingredient/:ingredientId" component={EditIngredient}/>
                <PrivateRoute  path="/copyingredient/:ingredientId" component={CopyIngredient}/>
                <PrivateRoute  path="/historyingredient/:ingredientId" component={HistoryIngredient}/>
                <PrivateRoute  path="/ingredientdetails/:ingredientId" component={IngredientDetails}/>
                <PrivateRoute  path="/ingredientnutritionfacts/:ingredientId" component={NutritionFacts}/>
                <PrivateRoute  path="/categoriesrecipe" component={CategoriesRecipe}/>
                <PrivateRoute  path="/categoriesingredient" component={CategoriesIngredient}/>
                <PrivateRoute  path="/subscriptions" component={Subscriptions}/>
                <PrivateRoute  path="/invoices" component={Invoices}/>
                <PrivateRoute  path="/invoicedetails/" component={InvoiceDetails}/>
                <PrivateRoute  path="/profilepremium" component={PremiumLayout} />
                <PrivateRoute  path="/orderslist" component={OrdersList}/>
                <PrivateRoute  path="/orderdetails/:orderId" component={OrdersListDetails}/>
                <PrivateRoute  path="/productions" component={Productions}/>
                <PrivateRoute  path="/production" component={Production}/>
                <PrivateRoute  path="/productiondetails/:orderId" component={ProductionDetails}/>
                <PrivateRoute  path="/ingredientssuppliers" component={Suppliers}/>
                <PrivateRoute  path="/inventory" component={Inventory}/>
                <PrivateRoute  path="/resetinventory" component={ResetStock}/>
                <PrivateRoute  path="/exportinventory" component={InventoryPDF}/>
                <PrivateRoute  path="/allergensrecipe" component={AllergensRecipe}/>
                <PrivateRoute  path="/stockarea" component={StockAreas}/>
                <PrivateRoute  path="/estimates" component={Estimates}/>
                <PrivateRoute  path="/newestimate" component={NewEstimate}/>
                <PrivateRoute  path="/estimatedetails/:estimateId" component={EstimateDetails}/>
                <PrivateRoute  path="/clients" component={Clients}/>
{/*                 <PrivateRoute  path="/sales" component={Sales}/>
                <PrivateRoute  path="/pos" component={POS}/> */}
                
                {/* <PrivateRoute  path="/storeplan/:type/:name/:mode/:price" component={StorePlanCheckout}/> */}
                <PrivateRoute  path="/storeplan" component={StorePlanCheckout}/>
                {/* <PrivateRouteOutside  path="/storeplanoutside" component={StorePlanOutside}/> */}
                <PrivateRoute  path="/profile" component={Profile}/>
                <PrivateRoute  path="/settings" component={Settings}/>
                <PrivateRoute  path="/organisation" component={Organisation}/>
                <PrivateRoute  path="/invitation" component={Invitation}/>
                <PrivateRoute  path="/scacheck" component={SCACheck}/>
                {/* <Route  path="/seo-incremental-loading/" component={IncrementalLoading}/>    */}     
                <Route component={Error404Page} />
            </Switch>
          </Router>
        </div>
     )
  }
}
 
function mapStateToProps(state) {
  const { loggedIn } = state.authentication;
  return {
    loggedIn,
  };
}

const connectedApp = injectIntl(connect(mapStateToProps)(App));
export { connectedApp as App }; 


