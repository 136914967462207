import { clientConstants } from '../_constants';
import { clientService } from '../_api';
import { alertActions } from '.';
import { history,isEmpty } from '../_helpers';
import React from 'react';
import {FormattedMessage} from 'react-intl';


export const clientActions = {
    getAll,
    add,
    get,
    update,
    delete: _delete,
    count,
};

function getAll() {
    return dispatch => {
        dispatch(request());

        clientService.getAll()
            .then(
                client => dispatch(success(client)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: clientConstants.GETALL_REQUEST } }
    function success(client) { return { type: clientConstants.GETALL_SUCCESS, client } }
    function failure(error) { return { type: clientConstants.GETALL_FAILURE, error } }
}

function get(id) {
    return dispatch => {
        dispatch(request(id));

        clientService.get(id)
            .then(
                client => {
                    dispatch(success(client));
                    //history.push('/ingredientdetails');
                },
                error => dispatch(failure(error))
            );
    };

    function request(id) { return { type: clientConstants.GET_REQUEST ,id} }
    function success(client) { return { type: clientConstants.GET_SUCCESS, client } }
    function failure(error) { return { type: clientConstants.GET_FAILURE, error } }
}

function add(client,link) {
    return dispatch => {
        dispatch(request());

        clientService.add(client)
            .then(
                client =>{
                    dispatch(success(client));
                    let title_text = <FormattedMessage id="clients.actions.client.add.success.title"
                                                       defaultMessage="Client Action"/>
                    let content_text = <FormattedMessage id="clients.actions.client.add.success.content"
                                                         defaultMessage="Client Added Successfully"/>
                                
                    dispatch(alertActions.success({title: "clients.actions.client.add.success.title",
                                                   content:"clients.actions.client.add.success.content"}));
                    if (link){
                        history.push('/');
                        history.push(link);
                    }
                },
                error =>{
                    dispatch(failure(error))
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                 content:error.message,
                                                 stringMode: true }));
                    history.push('/');
                    //history.push('/clients');
                } 
            );
    };

    function request() { return { type: clientConstants.ADD_REQUEST } }
    function success(client) { return { type: clientConstants.ADD_SUCCESS, client } }
    function failure(error) { return { type: clientConstants.ADD_FAILURE, error } }
}

function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        clientService.delete(id)
            .then(
                client => { 
                    dispatch(success(id,client));
                    let title_text = <FormattedMessage id="clients.actions.client.delete.success.title"
                                                       defaultMessage="Client Action"/>
                    let content_text = <FormattedMessage id="clients.actions.client.delete.success.content"
                                                         defaultMessage="Client Deleted Successfully"/>
                                
                    dispatch(alertActions.success({title: "clients.actions.client.delete.success.title",
                                                   content:"clients.actions.client.delete.success.content"}));
                },
                error => {
                    dispatch(failure(error))
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                content:error.message,
                                                stringMode: true }));
                   history.push('/');
                   //history.push('/clients');
                }
            );
    };

    function request() { return { type: clientConstants.DELETE_REQUEST } }
    function success(id,client) { return { type: clientConstants.DELETE_SUCCESS,id:id, client } }
    function failure(error) { return { type: clientConstants.DELETE_FAILURE, error } }
}

function count() {
    return dispatch => {
        dispatch(request());

        clientService.count()
            .then(
                supplier => dispatch(success(supplier)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: clientConstants.COUNT_REQUEST } }
    function success(client) { return { type: clientConstants.COUNT_SUCCESS, client } }
    function failure(error) { return { type: clientConstants.COUNT_FAILURE, error } }
}

function update(id,category) {
    return dispatch => {
        dispatch(request());

        clientService.update(id,category)
            .then(
                supplier =>{
                    dispatch(success(id,supplier));
                    let title_text = <FormattedMessage id="clients.actions.client.update.success.title"
                                                       defaultMessage="Client Action"/>
                    let content_text = <FormattedMessage id="clients.actions.client.update.success.content"
                                                         defaultMessage="Client Updated Successfully"/>
                                
                    dispatch(alertActions.success({title: "clients.actions.client.update.success.title",
                                                   content:"clients.actions.client.update.success.content"}));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error({title: isEmpty(error.title)?error.statusText:error.title,
                                                 content:error.message,
                                                 stringMode: true }));
                    history.push('/');
                    //history.push('/clients');
                }
            );
    };

    function request() { return { type: clientConstants.UPDATE_REQUEST } }
    function success(id,client) { return { type:clientConstants.UPDATE_SUCCESS, id:id, client } }
    function failure(error) { return { type: clientConstants.UPDATE_FAILURE, error } }
}


