import React, { Component,useState } from 'react';
import { Table, Input,Dropdown, Grid, Menu,Pagination,Icon,Header,Loader,Dimmer,Popup,Modal,Button,Breadcrumb,Label,Form} from 'semantic-ui-react';
import { recipesActions } from '../_actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SideLeftUncoverOrders from './SidebarOrders';
import {toaster_dispatch,history} from '../_helpers';
import {FormattedMessage,FormattedDate,FormattedNumber,FormattedTime,injectIntl} from 'react-intl';
import {styles} from './Styles';
import img_order from '../Assets/receipt.png'
import './FloatingMenu.css';
import {FloatingMenuItem, FloatingMenuItemOther} from './FloatingMenu';
import DatePicker from "react-datepicker"
import { registerLocale, setDefaultLocale } from  'react-datepicker';
import fr from 'date-fns/locale/fr';
import en from 'date-fns/locale/en-US';


var HOUR_TIME=60*60*1000;

const optionsPage = [
    //{ key: '5', text: '5', value: 5 },
    { key: '10', text: '10', value: 10 },
    { key: '20', text: '20', value: 20 },
    { key: '30', text: '30', value: 30 },
    { key: '50', text: '50', value: 50 },
  ]

const options = [
    { key: 1, text: <FormattedMessage id="orderslist.options.ongoing"
                                      defaultMessage='Ongoing orders'/>, value: 'ongoing' },
    { key: 2, text: <FormattedMessage id="orderslist.options.sent"
                                      defaultMessage='Sent orders'/>, value: 'sent' },
    { key: 3, text: <FormattedMessage id="orderslist.options.received"
                                      defaultMessage='Received orders'/>, value: 'received' },
  ]

  
class OrdersList extends Component {
    constructor(props) {
        super(props);
        const {intl} = props;
        let userIn = JSON.parse(localStorage.getItem('userIn'));
        this.state = {
                      prevOrders : props.orders,
                      currency : userIn.user.currency ,
                      toggled: false ,
                      isMobile: false,
                      orderTypeSelected: 'ongoing',
                      filtering: false,
                      page: 1,
                      itemsPerPage: 10,
                      activeModalOrderDelete: false,
                      allowMultipleSelection:true,
                      selectionList:[],
                      actionModal: false,
                     }
        props.dispatch(recipesActions.getOrders(true));

        this.filterNameList=this.filterNameList.bind(this);
        this.filterOrderTypeList=this.filterOrderTypeList.bind(this);

        this.removeFilters=this.removeFilters.bind(this);
        this.setPageNum = this.setPageNum.bind(this);
        this.onElemPerPageChange = this.onElemPerPageChange.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.selectActionList=this.selectActionList.bind(this);
        this.cancelAction=this.cancelAction.bind(this);

        registerLocale('en', en)
        registerLocale('fr', fr)
        if(intl.locale == 'fr'){
            setDefaultLocale('fr',fr)
        }else{
            setDefaultLocale('en',en)
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();

    }

    static getDerivedStateFromProps(props, state) {
        if((props.orders && props.orders!==state.prevOrders)) {

            let updatedList = props.orders.filter((item) => {
                if (item){
                    if (!(item.state)) item.state = 'ongoing'
                    return (item.state.toLowerCase() == 'ongoing')
                }
                return null;
            });
            //console.log(updatedList)

            return{newItems: updatedList,
                   filtering: true,
                   prevOrders: props.orders}
        }
        return null;
    }
    
    resize() {
        this.setState({isMobile: window.innerWidth <= 760});
    }

    setPageNum = (event, { activePage }) => {
        this.setState({ page: activePage });
        this.resetSelectionList();
        this.setState({isCheckedAll: false});
    };

    onElemPerPageChange = (e,data) => {
        e.preventDefault();
        this.setState({ itemsPerPage: data.value });
    }

    removeFilters(event){
        event.preventDefault();
        this.setState({filtering: true});
        //this.setState({filtering: false});
        this.setState({nameSelected: ""});
        this.setState({ selectionList:[]});

         let updatedList = this.props.orders.filter((item) => {
            if (item){
                if (!item.state) item.state = 'ongoing'
                return (item.state.toLowerCase() == this.state.orderTypeSelected.toLowerCase())
            }
            return null;
        });
        this.setState({newItems: updatedList});
        this.setState({ selectedReceivedStartDate: null, selectedReceivedEndDate: null})
        this.setState({ selectedCreationStartDate: null, selectedCreationEndDate:null})
        
    }

    filterNameList(event){
        event.preventDefault();
        this.setState({ selectionList:[]});

        if (event.target.value == ""){
            this.removeFilters(event);
            return;
        }

        const {orders} = this.props;
        
        if (orders){
            let updatedList = this.props.orders.filter((item) => {
                return (item.ref_name).normalize('NFKD').replace(/[^\w]/g, '').toLowerCase()
                        .search(event.target.value.normalize('NFKD').replace(/[^\w]/g, '').toLowerCase()) !== -1;
              });
      
      
               let updatedListOrderType = updatedList.filter((item) => {
                  if (item){
                      if (!item.state) item.state = 'ongoing'
                      return (item.state.toLowerCase() == this.state.orderTypeSelected.toLowerCase())
                  }
                  return null;
              });
      
              this.setState({newItems: updatedListOrderType});
              //this.setState({newItems: updatedList});
              this.setState({filtering: true});
              this.setState({nameSelected: event.target.value});
              this.setState({ selectedReceivedStartDate: null, selectedReceivedEndDate: null})
              this.setState({ selectedCreationStartDate: null, selectedCreationEndDate:null})
        }
    }

    filterOrderTypeList(event,data){
        event.preventDefault();
        this.setState({ selectionList:[]});
        const {orders} = this.props;

        if (orders){
            let updatedList = this.props.orders.filter((item) => {
                if (item){
                    if (!item.state) item.state = 'ongoing'
                    return (item.state.toLowerCase() == data.value.toLowerCase())
                }
                return null;
            });
            //console.log(updatedList)
            this.setState({newItems: updatedList});
            this.setState({filtering: true});
            this.setState({orderTypeSelected: data.value});
            this.setState({nameSelected: ""});
            this.setState({ selectedReceivedStartDate: null, selectedReceivedEndDate: null})
            this.setState({ selectedCreationStartDate: null, selectedCreationEndDate:null})
        }

    }

    setDateRange (update,type){
        const {orders} = this.props;
        this.setState({ selectionList:[]});
        if (type == 'created'){
            this.setState({ selectedCreationStartDate: update[0], selectedCreationEndDate: update[1]})
            this.setState({ selectedReceivedStartDate: null, selectedReceivedEndDate: null})
        }else{
            this.setState({ selectedCreationStartDate: null, selectedCreationEndDate:null})
            this.setState({ selectedReceivedStartDate: update[0], selectedReceivedEndDate: update[1]})
        }
        //console.log(update)
        if (orders && update[0]!=null && update[1]!=null){
            let startDate = new Date(update[0])
            let endDate = new Date(update[1])
            endDate.setDate(endDate.getDate() + 1);
            let updatedList = []
            if (type == 'created'){
                updatedList = this.props.orders.filter((item) => {
                    return new Date(item.created) >= startDate && new Date(item.created) <= endDate
                  });
            }else{
                updatedList = this.props.orders.filter((item) => {
                    return new Date(item.received) >= startDate && new Date(item.received) <= endDate
                  });
            }
                
            this.setState({newItems: updatedList});
            this.setState({filtering: true});
            this.setState({nameSelected: ""});
            
        }else{
            this.setState({filtering: false});
            this.setState({nameSelected: ""});
        }

        return [update[0],update[1]]
    }

    seeOrder (event,index) {
        event.preventDefault();
        history.push('/orderdetails/'+index);
    }
    
    createOrder(event){
        event.preventDefault();
        history.push('/orders');
    }

    createRecipe(){
        history.push('/newrecipe');
    }

    createNewOrder(){
        history.push('/orders');
    }

    createSupplier(){
        history.push('/ingredientssuppliers');
    }

    orderTypeSelected = (e,data) => {
        e.preventDefault();
        this.setState({ orderTypeSelected: data.value });
      }

    deleteOrderClickHandler (event,index,order) {
        event.preventDefault();
        this.setState({ activeModalOrderDelete: index});
        this.setState({ delete_order: order});
    };

    deleteOrder(event,id){
        event.preventDefault();
        this.props.dispatch(recipesActions.deleteOrder(id));  
    }

    hideModal() {
        this.setState({ activeModalOrderDelete: false });
        this.setState({ actionModal: false })
        this.setState({actionSelected: ""})
    }

    actionSelectDelete(event) {
        event.preventDefault();
        const { selectionList } = this.state;
        this.props.dispatch(recipesActions.deleteSelectOrder(selectionList,false))
        this.setState({ selectionList:[]});
    }

    addSelectList(event,index){
        const { selectionList } = this.state;
        if (event.target.checked){
            this.setState({ selectionList:[...selectionList,index]});
        }else{
            this.setState({ selectionList:selectionList.filter((item)=> item!=index)});
            if (selectionList.length <= 1){
                this.setState({actionSelected: ""});
            }
            this.setState({isCheckedAll: false});
        }
    }

    selectAllSelectList(event,items){
        let allList = []
        if (event.target.checked){
            items.map((order,index) => {
                allList.push(order.id);
            });
        }else{
            this.setState({actionSelected: ""});
        }
        this.setState({ isCheckedAll: event.target.checked});
        this.setState({ selectionList:allList});
        
    }

    isChecked(index){
        const { selectionList } = this.state;
        return selectionList.includes(index)
    }

    isCheckedAll(){
        return this.state.isCheckedAll
    }

    selectActionList(event,data){
        event.preventDefault();
        const { selectionList } = this.state;

        this.setState({actionSelected: data.value});

        if (selectionList.length > 0 && data.value === 'delete'){
            this.setState({actionModal: true});
        }
    }

    resetSelectionList(){
        this.setState({ selectionList:[]});
        this.setState({actionSelected: ""});
    }

    cancelAction(event){
        event.preventDefault();
        this.setState({ selectionList:[]});
        this.setState({isCheckedAll: false});
        this.setState({actionSelected: ""});
    }

    addOrdersRows(orders){
        //console.log(orders)
        const {currency,orderTypeSelected,allowMultipleSelection} = this.state;
        
        return orders.map((order,index) => {
                return (
                    <Table.Row  selectable key={index}>
                        <Table.Cell width={5} 
                                    textAlign='left' 
                                    style={{cursor:'pointer'}} 
                                    //onClick={(event) => this.seeOrder(event,order.id)}
                                    >
                                {allowMultipleSelection && <Input type='checkbox' id={index}
                                   onChange={(e) => this.addSelectList(e,order.id)}
                                   checked={this.isChecked(order.id)}
                                   style={{'transform': 'scale(1.0)'}}
                                  />}
                                 <span style={{marginLeft:'0.7em'}}>
                               {/*  <Icon name='list alternate outline' color='grey'/> */}
                                <a href={"/orderdetails/"+order.id} 
                                  onClick={(event) => this.seeOrder(event,order.id)}>
                                  {order.ref_name.length >= 45 && 
                                  order.ref_name.substring(0,45) + '..'}
                                {order.ref_name.length < 45 && 
                                    order.ref_name}
                                {/* {order.ref_name} */}
                                &nbsp; &nbsp;{((new Date() - new Date(order.created)) < HOUR_TIME) &&
                                <Label color='teal'><FormattedMessage id="orderslist.order.status.new"
                                                                      defaultMessage='New'/></Label>}</a></span>
                        </Table.Cell>
                        <Table.Cell width={3} textAlign='left'>{order && order.created && 
                                                        <span>
                                                         <FormattedDate value={new Date(order.created)}
                                                                                   year='numeric'
                                                                                   month='short'
                                                                                   day='2-digit'/>{' - '}
                                                         <FormattedTime value={new Date(order.created)}
                                                                                   hour='numeric'
                                                                                   minute='numeric'/></span>}</Table.Cell>
                        <Table.Cell width={2} textAlign='center'>
                           {orderTypeSelected == 'ongoing' &&
                            <div style={{'color':'#60a0d1'}}>
                                <Icon name='envelope open outline' color='blue'/>
                                <FormattedMessage id="orderslist.order.status.ongoing"
                                                  defaultMessage='Ongoing'/>
                            </div>
                           }
                           {orderTypeSelected == 'sent' &&
                            <div style={{'color':'#60a0d1'}}>
                              <Icon name='send' color='blue'/>
                              <FormattedMessage id="orderslist.order.status.sent"
                                              defaultMessage='Sent'/>
                            </div>
                           }
                            {orderTypeSelected == 'received' &&
                            <div style={{'color':'#60a0d1'}}>
                                <Icon name='inbox' color='blue'/>
                                <FormattedMessage id="orderslist.order.status.received"
                                                defaultMessage='Received'/>
                           </div>
                           }
                        </Table.Cell>
                        <Table.Cell width={3} textAlign='center'>{order && order.received && 
                                                        <span>
                                                         <FormattedDate value={new Date(order.received)}
                                                                                   year='numeric'
                                                                                   month='long'
                                                                                   day='2-digit'/>{' - '}
                                                         <FormattedTime value={new Date(order.received)}
                                                                                   hour='numeric'
                                                                                   minute='numeric'/></span>}</Table.Cell>
                        <Table.Cell width={2} textAlign='center'>{order && (order.use_ingredients==true) && order.nb_ingredients}</Table.Cell>
                        <Table.Cell width={2} textAlign='right'>
                             {order && (order.use_ingredients==true) &&
                             <FormattedNumber value={order.value} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>}
                        </Table.Cell>
                        <Table.Cell width={2} textAlign='right'>
                                  <Popup trigger={<Icon color='grey' name='trash alternate outline' style={{cursor:'pointer'}} size='large'
                                                        onClick={(event) => this.deleteOrderClickHandler(event,index,order)}/>
                                                 }
                                        content={<FormattedMessage id="orderslist.popup.delete.text"
                                                                   defaultMessage='Delete'/>}
                                        inverted
                                        size='small' 
                                        />   
                        </Table.Cell>
                        <Modal id={index}
                                   open={this.state.activeModalOrderDelete === index}
                                   dimmer='default' 
                                   size='tiny' 
                                   onClose={this.hideModal}
                                   style={styles.Modal}
                                   closeIcon>
                                <Header icon='trash alternate outline' content={<FormattedMessage id="orderslist.modal.order.deletion.title"
                                                                                                    defaultMessage='Deletion confirmation'/>} />
                                <Modal.Content>
                                    <p><FormattedMessage id="orderslist.modal.order.deletion.message"
                                                         defaultMessage='Really sure to delete the order {order} ?'
                                                         values={{order: order.ref_name}} /></p>
                                </Modal.Content>
                                <Modal.Actions>
                                       <Button style={styles.ButtonGrey}//basic color='grey' 
                                               onClick={this.hideModal}>
                                            <FormattedMessage id="orderslist.modal.order.deletion.button.no"
                                                              defaultMessage='No'/>
                                        </Button>
                                        <Button style={styles.ButtonRed}//basic color='teal' 
                                                labelPosition='right' 
                                                content={<FormattedMessage id="orderslist.modal.order.deletion.button.yes"
                                                                          defaultMessage='Yes'/> }
                                                icon='checkmark' 
                                                onClick={(event) =>this.deleteOrder(event,order.id)}/>
                         </Modal.Actions>
                        </Modal> 
                     </Table.Row>
                );
        });
    }

    toggleMenu() {
		this.setState({toggled: !this.state.toggled});
    }


    render() { 
        const { alert,dispatch, orders, intl,loading} = this.props;
        const { newItems,filtering,orderTypeSelected,nameSelected,isMobile,page,itemsPerPage,
            allowMultipleSelection,isCheckedAll,selectionList,actionSelected,
            selectedCreationStartDate,selectedCreationEndDate,selectedReceivedStartDate,selectedReceivedEndDate } = this.state;

        let buttons = [];
		let className = "floating-menu";
		let icon = <Icon name='add'/>;
		
		if (this.state.toggled) {
			className += " open";
            icon = <Icon name='remove'/>;

            buttons.push(<FloatingMenuItemOther label= {<FormattedMessage id="orderslist.floatingmenu.item3.title" defaultMessage= "Create a supplier"/>} 
                                                icon={<Icon name='shipping fast'/>} 
                                                action={this.createSupplier} 
                                                mobile={isMobile}
                                                key="s"/>); 
            buttons.push(<FloatingMenuItemOther label= {<FormattedMessage id="orderslist.floatingmenu.item2.title" defaultMessage= "Create a recipe"/>}  
                                                 icon={<Icon name='food'/>} 
                                                 action={this.createRecipe} 
                                                 mobile={isMobile}
                                                 key="r"/>);
            buttons.push(<FloatingMenuItem label= {<FormattedMessage id="orderslist.floatingmenu.item1.title" defaultMessage= "Create an order"/>} 
                                           icon={<Icon name='cart'/>} 
                                           action={this.createNewOrder} 
                                           mobile={isMobile}
                                           key="o"/>);
            
		}
        buttons.push(<FloatingMenuItem label="" icon={icon} action={this.toggleMenu.bind(this)} key="m"/>); 

        let totalPages = 1;
        let items = [];
        let length = 0;
        let begin = 0;
        let end  = 0;

        if (!filtering &&  orders){
            length = orders.length;
            totalPages = Math.ceil( length / itemsPerPage);
            begin = (page - 1) * itemsPerPage;
            end = (page - 1) * itemsPerPage + itemsPerPage;
            if (end > length) end=length;
            items = orders.slice(begin,end);
        }
        if (filtering && newItems ){
            length = newItems.length;
            totalPages = Math.ceil(length / itemsPerPage);
            begin = (page - 1) * itemsPerPage;
            end = (page - 1) * itemsPerPage + itemsPerPage;
            if (end > length) end=length;
            items = newItems.slice(begin,end);
        }
        
        //console.log(orderTypeSelected)

        return ( 
                     <SideLeftUncoverOrders>
                        <Grid stackable id='grid'  style={styles.FontLato13Border}>
                            <Grid.Row columns={1}>
                             <Breadcrumb  style={{background:'#fcfcfc'}}>
                                <Breadcrumb.Section link as={Link} to={'/dashboard/'}><FormattedMessage id="orderslist.link.dashboard"
                                                              defaultMessage='Dashboard'/></Breadcrumb.Section>
                                <Breadcrumb.Divider icon='right chevron'/>
                                <Breadcrumb.Section active><FormattedMessage id="orderslist.link.orders"
                                                              defaultMessage='Orders'/></Breadcrumb.Section>                             
                             </Breadcrumb>
                            </Grid.Row>
                            <Grid.Row columns={1}>
                            <Grid.Column  width={8}>
                                    <Header as='h2'>
                                        <img src={img_order} style={{width:'50px'}}/>
                                        <Header.Content>
                                            <FormattedMessage id="orderslist.page.title"
                                                              defaultMessage='Orders'/>
                                            <Header.Subheader><FormattedMessage id="orderslist.header.caption.title"
                                                                    defaultMessage='Manage your orders' /></Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                </Grid.Column>
                            </Grid.Row>
{/*                             {alert.message && 
                                <Grid.Row><div className={`alert ${alert.type}`}>{alert.message}</div></Grid.Row>
                            } */}
                            {alert.message && toaster_dispatch(dispatch,alert,intl)}
                            {/* <Grid.Row columns={1}/> */}
                            <Grid.Row columns={1} >
                                <Grid.Column width={4} >
                                    <Dropdown selection
                                              placeholder={'Vos commandes'} 
                                              value={orderTypeSelected}
                                              //icon='edit' 
                                              options={options}
                                              fluid
                                              onChange={this.filterOrderTypeList}
                                              style={{'background-color':'#e8f3fa','font-size': '18px','font-weight': '600','color': 'black'}}/>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1}> 
                            {/* <Grid.Row columns={1}/> */}
{/*                             <Grid.Row>
                                <Grid.Column>
                                    <br/><Label basic style={styles.FontLato13Bold} size='big' color='blue'>
                                            <FormattedMessage id="orderslist.list.requested.title"
                                                              defaultMessage='Order list available' />
                                    </Label>
                                </Grid.Column>
                            </Grid.Row> */}
                            </Grid.Row>
{/*                            <Grid.Row columns={1}/>
                           <Grid.Row columns={1}/>
                           <Grid.Row columns={1}/> */}
                            <Grid.Row columns={3}>
                                <Grid.Column  mobile={8} tablet={4} computer={4}> 
                                    <p style={styles.FontLato13}><FormattedMessage id="orderslist.filter.ref.title"
                                                         defaultMessage='Search by reference'/></p>
                                    <FormattedMessage id="orderslist.filter.byref.placeholder"
                                                        defaultMessage='Type a reference'>
                                        {placeholder =>                                                       
                                            <Input //size='large'
                                                type="text"
                                                icon fluid={!isMobile}
                                                placeholder={placeholder}
                                                value={nameSelected}
                                                onChange={this.filterNameList}
                                                style={{minWidth:'20em',maxWidth:'30em', minHeight:'3em'}}>
                                            <Icon name='search'/><input style={{borderRadius: '100px'}} /></Input>}
                                    </FormattedMessage>
                                </Grid.Column> 
                                <Grid.Column mobile={5} tablet={3} computer={1}/> 
                                <Grid.Column mobile={5} tablet={3} computer={3}>        
                                            <div width={4} style={{paddingTop:'0.3em', fontSize:'17px'/* ,'color': '#66b2b2' */}}>
                                                            <p style={{paddingTop:'0em', fontSize:'13px'}} ><Icon name='calendar alternate outline'/>
                                                                    {' '}<FormattedMessage id="orderslist.form.creation.title"
                                                                                            defaultMessage='Creation date start/end'/>
                                                            </p>
                                                            <FormattedMessage id="orderslist.form.dlc.placeholder"
                                                                                    defaultMessage='select a range'>
                                                                        {placeholder =>    
                                                                            <DatePicker
                                                                                    selectsRange={true}
                                                                                    startDate={selectedCreationStartDate}
                                                                                    endDate={selectedCreationEndDate}
                                                                                    onChange={(update) => {
                                                                                        this.setDateRange(update,'created');
                                                                                    }}
                                                                                    isClearable={true}
                                                                                    placeholderText = {placeholder} 
                                                                                    locale={intl.locale}
                                                                                    dateFormat="P" />
                                                                        }
                                                            </FormattedMessage>
                                            </div>
                                </Grid.Column>
                                <Grid.Column mobile={5} tablet={3} computer={3}>   
                                     {orderTypeSelected !== 'received' &&<div width={4} style={{paddingTop:'0.3em', fontSize:'17px',  'pointer-events': 'none', opacity: '0.7'/* ,'color': '#66b2b2' */}}>
                                                            <p style={{paddingTop:'0em', fontSize:'13px'}} ><Icon name='calendar alternate outline'/>
                                                                    {' '}<FormattedMessage id="orderslist.form.received.title"
                                                                                            defaultMessage='Received date start/end'/>
                                                            </p>
                                                            <FormattedMessage id="orderslist.form.dlc.placeholder"
                                                                                    defaultMessage='select a range'>
                                                                        {placeholder =>    
                                                                            <DatePicker
                                                                                    selectsRange={true}
                                                                                    startDate={selectedReceivedStartDate}
                                                                                    endDate={selectedReceivedEndDate}
                                                                                    onChange={(update) => {
                                                                                        this.setDateRange(update,'received');
                                                                                    }}
                                                                                    isClearable={true}
                                                                                    placeholderText = {placeholder} 
                                                                                    locale={intl.locale}
                                                                                    dateFormat="P"
                                                                                    disabled = {true}/>
                                                                        }
                                                            </FormattedMessage>
                                            </div>}
                                        {orderTypeSelected == 'received' &&<div width={4} style={{paddingTop:'0.3em', fontSize:'17px'/* ,'color': '#66b2b2' */}}>
                                                            <p style={{paddingTop:'0em', fontSize:'13px'}} ><Icon name='calendar alternate outline'/>
                                                                    {' '}<FormattedMessage id="orderslist.form.received.title"
                                                                                            defaultMessage='Received date start/end'/>
                                                            </p>
                                                            <FormattedMessage id="orderslist.form.dlc.placeholder"
                                                                                    defaultMessage='select a range'>
                                                                        {placeholder =>    
                                                                            <DatePicker
                                                                                    selectsRange={true}
                                                                                    startDate={selectedReceivedStartDate}
                                                                                    endDate={selectedReceivedEndDate}
                                                                                    onChange={(update) => {
                                                                                        this.setDateRange(update,'received');
                                                                                    }}
                                                                                    isClearable={true}
                                                                                    placeholderText = {placeholder} 
                                                                                    locale={intl.locale}
                                                                                    dateFormat="P"/>
                                                                        }
                                                            </FormattedMessage>
                                        </div>}
                                </Grid.Column>
                               {/*  <Grid.Column mobile={5} tablet={3} computer={3}/> */}
                                <Grid.Column mobile={3} tablet={16} computer={3}> 
                                    <div className={className}>
                                        {buttons}
                                    </div>
                                </Grid.Column>
                            </Grid.Row>   
                            <Grid.Row columns={1} style={{'overflow-x': 'auto'}}>
                                <Grid.Column mobile={18} tablet={16} computer={14}> 
{/*                                     {(!orders || orders.length <= 0) &&
                                        <p style={styles.LabelGrey}>
                                                    <FormattedMessage id="orderslist.segment.message.no.data"
                                                                    defaultMessage='No order created'/>
                                        </p>} */}
                                  {selectionList.length > 0 && 
                                     <FormattedMessage id="orderslist.action.main.placeholder"
                                                       defaultMessage='{number} selected'
                                                       values={{number:selectionList.length}}>
                                        {placeholder =>  
                                                <Dropdown selection floating labeled
                                                        options= {[{ key: 'none', text: ' ', value: 'none' },
                                                                  { key: 'delete', icon: {name:'trash alternate outline',color:'red'},
                                                                                  text: <FormattedMessage id="orderslist.action.delete.text"
                                                                                            defaultMessage='Delete'/>, value: 'delete' },]}
                                                        value= {actionSelected}
                                                        text={placeholder}
                                                        className='icon'
                                                        onChange={this.selectActionList}
                                                        style={{'font-size':'13px','letter-spacing': '0.025em',
                                                                'background-color':'#66b2b2',color:'white'}}/>}
                                    </FormattedMessage>}
                                    {selectionList.length > 0 &&
                                            <Button compact 
                                                className="ui icon button"
                                                //color='grey' 
                                                style={{marginLeft:'0.3em' ,'letter-spacing': '0.025em','height': '36px',
                                                       'background-color': '#bfbfbf',color:'white'}}
                                                onClick={this.cancelAction}>
                                               <FormattedMessage id="orderslist.action.button.cancel.text"
                                                                   defaultMessage='Cancel'/>
                                        </Button>}
                                    {(loading) &&
                                        <Dimmer active inverted>
                                            <Loader inverted content='Loading' />
                                        </Dimmer> }
                                    <Table //definition
                                           unstackable 
                                           //compact
                                           singleline 
                                           //color={'blue'}
                                           style={styles.FontLato13Border}>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell width={5} textAlign='left'>{' '}
                                                {allowMultipleSelection && <Input type='checkbox'
                                                            onChange={(e) => this.selectAllSelectList(e,items)}
                                                            style={{'transform': 'scale(1.0)'}}
                                                            checked={isCheckedAll}/>}
                                                   <span style={{marginLeft:'0.7em'}}><FormattedMessage id="orderslist.list.requested.table.column1"
                                                                     defaultMessage='Order Ref' /></span> 
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={3} textAlign='left'>
                                                    <FormattedMessage id="orderslist.list.requested.table.column2"
                                                                     defaultMessage='Created' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={2} textAlign='center'>
                                                    <FormattedMessage id="orderslist.list.requested.table.column5"
                                                                     defaultMessage='State' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={3} textAlign='center'>
                                                    <FormattedMessage id="orderslist.list.requested.table.column6"
                                                                     defaultMessage='Received' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={2} textAlign='center'>
                                                    <FormattedMessage id="orderslist.list.requested.table.column3"
                                                                     defaultMessage='Number of items' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={2} textAlign='center'>
                                                    <FormattedMessage id="orderslist.list.requested.table.column4"
                                                                     defaultMessage='Total Cost' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={2} textAlign='left'/>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                        {items && (items.length == 0) &&
                                            <Table.Row style={{'background-color':'#f5f5f5'}}>
                                                <Table.Cell> <FormattedMessage id="orderslist.list.requested.table.row.noitems"
                                                                            defaultMessage='No orders'/></Table.Cell>
                                                <Table.Cell colspan='5'></Table.Cell>
                                            </Table.Row>}
                                        {items && items.length > 0 &&
                                            this.addOrdersRows(items)
                                        }
                                        </Table.Body>
{/*                                         <Table.Footer fullWidth>
                                                <Table.HeaderCell colSpan='6'>
                                                <Button basic floated='right' 
                                                        icon
                                                        labelPosition='left'  
                                                        size='medium'
                                                        color='teal'
                                                        onClick={this.createOrder}>
                                                    <Icon name='add' /><FormattedMessage id="orderslist.table.footer.button.add"
                                                                          defaultMessage='Order'/>
                                                </Button>
                                                </Table.HeaderCell>
                                        </Table.Footer> */}
                                    </Table>
                                </Grid.Column>         
                            </Grid.Row>
                            <Grid.Row columns={3} >
                                <Grid.Column textAlign='left'>
                                  <span><Menu compact secondary centered style={styles.FontLato12}>
                                         <Dropdown defaultValue={10} 
                                                  options={optionsPage} 
                                                  //simple 
                                                  item
                                                  inline
                                                  //icon='list'
                                                  //button 
                                                  fluid
                                                  //search
                                                  //header='Page'
                                                  //text='ingredients'
                                                  onChange={this.onElemPerPageChange}
                                                  />
                                  </Menu>&ensp;{begin+1} <FormattedMessage id="orderslist.pagination.to"
                                                              defaultMessage='to'/> {end} <FormattedMessage id="orderslist.pagination.over"
                                                              defaultMessage='of'/> {length}</span>
                                </Grid.Column>
                                <Grid.Column textAlign='center'>
                                    <Pagination color='blue'   
                                        secondary
                                        activePage={page}
                                        totalPages={totalPages}
                                        siblingRange={4}
                                        boundaryRange ={0}
                                        //defaultActivePage={1}
                                        //ellipsisItem={null}
                                        ellipsisItem={{ content: <Icon name='ellipsis horizontal' size='small'/>, icon: true }}
                                        firstItem={null}
                                        lastItem={null}
                                        onPageChange={this.setPageNum}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Modal open={this.state.actionModal === true}
                               dimmer='default' 
                               size='tiny' 
                               style={styles.Modal} 
                               onClose={this.hideModal}
                               closeIcon>
                                <Header icon='trash alternate outline' content={<FormattedMessage id="orderslist.modal.select.deletion.title"
                                                                                defaultMessage='Deletion confirmation'/>}  />
                                <Modal.Content>
                                    <p><FormattedMessage id="orderslist.modal.select.deletion"
                                                         defaultMessage='Really sure to delete definetely the selected order(s)?'
                                        /></p>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button style={styles.ButtonGrey} //basic color='grey' 
                                        onClick={this.hideModal}>
                                        <FormattedMessage id="orderslist.modal.select.deletion.button.no"
                                                              defaultMessage='Cancel'/>
                                    </Button>
                                    <Button style={styles.ButtonRed} //basic color='teal' 
                                        labelPosition='right' 
                                        content={<FormattedMessage id="orderslist.modal.select.deletion.button.yes"
                                                                    defaultMessage='Confirm'/> }
                                        icon='checkmark'
                                        onClick={(event) => this.actionSelectDelete(event)}/>
                                    </Modal.Actions>
                        </Modal>
                     </SideLeftUncoverOrders>
        )
    }
}

function mapStateToProps(state) {
    const { alert,dispatch } = state;
    const { orders } = state.recipes;
    const { loading } = state.recipes;
    return {
        loading,
        orders,
        alert,
        dispatch,
    };
}

const connectedOrdersList = injectIntl(connect(mapStateToProps)(OrdersList));
export { connectedOrdersList as OrdersList };
 
