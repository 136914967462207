import React, { Component } from 'react';
import { Table, Image, Button, Grid, Label,Message,Icon,Modal,Header,Loader,Dimmer,Input,Popup,Dropdown,Breadcrumb,Form,Sidebar,Segment,TextArea,Checkbox} from 'semantic-ui-react';
import { recipesActions,categoryRecipeActions,paymentActions,alertActions } from '../_actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SideLeftUncoverProductions from './SidebarProductions';
import {toaster_dispatch,toaster_info,optimizeDecimalPart,history,computeVolumeUnitFactor,computeWeightUnitFactor
    ,typeYield,isFloat,isNumeric,trim,isPack,GUEST,MAX_NUMBER_INGREDIENTS_FREE_SUBSCRIBER,isEmpty,MAX_NUMBER_RECIPES_FREE_SUBSCRIBER,grams,mgrams,cliters,mliters} from '../_helpers';
import {FormattedMessage,FormattedNumber,FormattedDate,FormattedTime,injectIntl} from 'react-intl';
import OutsideClickHandler from 'react-outside-click-handler';
import img_performance from '../Assets/performance.png'
import {styles} from './Styles';
import img_planning from '../Assets/planning.png'
import img_bag from '../Assets/shopping-bag.png';

import "react-datepicker/dist/react-datepicker.css"
import DatePicker from "react-datepicker"
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';
import en from 'date-fns/locale/en-US';


const DUPLICATE_ADD_RECIPE_MESSAGE_TITLE = <FormattedMessage id="productiondetails.toaster.recipe.add.duplicate.message.title"
                                                  defaultMessage='Adding ingredient/recipe ...'/>
const DUPLICATE_ADD_RECIPE_MESSAGE_CONTENT = <FormattedMessage id="productiondetails.toaster.recipe.add.duplicate.message.content"
                                                  defaultMessage='Already added to your order'/>


class ProductionDetails extends Component {
    constructor(props) {
        super(props);
        const {intl} = props;
        let userIn = JSON.parse(localStorage.getItem('userIn'));
        this.state = { prevRecipe : props.recipes,
                       recipesData: [],
                       ingredientsList:[],
                       currency : userIn.user.currency,
                       activeModalPremium: false,
                       activeModalDelete: false,
                       activeModalRecipeDelete: null,
                       activeModalRecipeExport: null,
                       activeModalRename: false,
                       activeModalNote: false,
                       activeModalDate: false,
                       activeModalReduceStockAvailable: false,
                       activeModalDestock: false,
                       activeModalDestockRecipes: false,
                       activeModalStockRecipes: false,
                       activeModalExpiration : false,
                       newQuantity:0,
                       editCell: false,
                       active_role: userIn.user.role,
                       filtering: false,
                       supplierSelected: null,
                       orderName: "",
                       orderNote: "",
                       withEmailSupplier: false,
                       isWithPrices: false,
                       withCostBreakdown: true,
                       withPieChart: false,
                       withMargin: true,
                       withPrepInstructions: false,
                       withBreakpage: false,
                       withSubRecipes: false,
                       withSuppliers: true,
                       batch_number: "",
                       expiration_date : ""
                     }
        props.dispatch(paymentActions.getCustomerMembership());
        props.dispatch(recipesActions.getOrder(props.match.params.orderId));
        props.dispatch(recipesActions.getOrdersRecipes(0,'infinite', 0));
        props.dispatch(categoryRecipeActions.getAll()); 
        props.dispatch(paymentActions.getSubscriptionState());

        this.createSuppliersOrders=this.createSuppliersOrders.bind(this);
        this.reduceStockQuantity=this.reduceStockQuantity.bind(this);
        this.destockQuantity=this.destockQuantity.bind(this);
        this.destockQuantityRecipes=this.destockQuantityRecipes.bind(this);
        this.stockQuantityRecipes=this.stockQuantityRecipes.bind(this);
        this.deleteOrder=this.deleteOrder.bind(this);
        this.deleteRecipeOrder=this.deleteRecipeOrder.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.filterNameList=this.filterNameList.bind(this);
        this.filterCategoryList=this.filterCategoryList.bind(this);

        registerLocale('en', en)
        registerLocale('fr', fr)
        if(intl.locale == 'fr'){
            setDefaultLocale('fr',fr)
        }else{
            setDefaultLocale('en',en)
        }
    }

    static getDerivedStateFromProps(props, state) {
        if((props.recipes!==state.prevRecipe)&&(props.recipes.order)){
            let order = props.recipes.order;
            let aggRecipes = [];
            let aggIngredients = [];
 
             if (order && order.use_ingredients == true){
                for (let i of order.ingredients){
                    let supplier = null
                    if (i.extra_supplier_used){
                        if (i.extra_supplier){
                            supplier = {id:i.extra_supplier.supplier.id, 
                                        name: i.extra_supplier.supplier.name }
                        }
                       
                    }else{
                        if (i.ingredient.supplier){
                            supplier = {id:i.ingredient.supplier.id, 
                                        name: i.ingredient.supplier.name }
                        }else if (i.ingredient.supplier__name){
                            supplier = {id:i.ingredient.supplier__id, 
                                        name: i.ingredient.supplier__name }
                        }
                    }
                    i.supplier = supplier
                    aggIngredients.push({id: i.ingredient.id, data:i, count:i.unit})
                }

                if (order.items && order.items.length > 0){
                    for (let l of order.items){
                        if (l.item){
                            aggRecipes.push({item:l.item,
                                             unit:l.unit, 
                                             is_nb_recipes:l.is_recipes_unit,
                                             date_expire:l.date_expire!=null?l.date_expire:null,
                                             batch_number:l.batch_number
                                            })
                        }
                    }
                }
                let numberSuppliers = 0;
                const suppliers_lists = aggIngredients.reduce((sublist, item) => {

                    let use_supplier = null
                    if (item.data.supplier && item.data.supplier.name && item.data.supplier.name!=""){
                        use_supplier = item.data.supplier.name
                    }
    
                    if (use_supplier && use_supplier!="" && !sublist[use_supplier]) {
                        sublist[use_supplier] = [];
                    }else if (!sublist["undefined"]){
                        sublist["undefined"] = [];
                    }
    
                    if (use_supplier && use_supplier!="") {
                        sublist[use_supplier].push(item);
                    }else {
                        sublist["undefined"].push(item)
                    }
                    return sublist;
                  }, {})
    
                let new_suppliers_lists = []
                for (let [supplier_name, ingredients_list] of Object.entries(suppliers_lists)) {
                    new_suppliers_lists.push({supplier_name,ingredients_list});
                    new_suppliers_lists.sort(function (a, b) {
                        return a.supplier_name.toLowerCase().localeCompare(b.supplier_name.toLowerCase()); 
                    }) 
                    if (ingredients_list.length > 0) numberSuppliers +=1;
    
                }
                return { prevRecipe : props.recipes,
                         recipesData: aggRecipes,
                         ingredientsList: aggIngredients,
                         suppliers_lists:new_suppliers_lists,
                         numberSuppliers:numberSuppliers,
                         orderName: order.ref_name,
                         orderNote: order.note,
                         orderDate: new Date(order.prod_date)
                        }
            }
            else if (order && order.items && order.items.length > 0){
                for (let l of order.items){
                        if (l.item){
                            aggRecipes.push({item:l.item, unit:l.unit})
                            for (let i of l.item.ingredients){
                                if (i.ingredient){
                                  let factor = 1;
                                  if (i.quantity_type == 'weight'){
                                        factor = computeWeightUnitFactor('g',i.quantity_unit);
                                        i.quantity_value *=  factor
                                        i.quantity_unit = 'g'
                                        i.quantity =  i.quantity_value + i.quantity_unit
                                   }
                                   else if (i.quantity_type == 'volume'){
                                        factor = computeVolumeUnitFactor('l',i.quantity_unit);
                                        i.quantity_value *=  factor
                                        i.quantity_unit = 'l'
                                        i.quantity =  i.quantity_value + i.quantity_unit
                                  }
                                  let count = Number(l.unit).toFixed(3)
                                  var index = aggIngredients.findIndex(x => x.id == i.ingredient.id)
                                  if (index === -1) {
                                      i.supplier = null
                                      aggIngredients.push({id: i.ingredient.id, data:i, count:count})
                                  }else {
                                      //Update when ingredient already exists
                                      let old_i = aggIngredients[index].data;
                                      let old_count = aggIngredients[index].count;
                                      let new_i = i;
                                      if (i.quantity_type == old_i.quantity_type)
                                      {
                                         new_i.quantity_value = old_i.quantity_value*old_count + i.quantity_value*count;
                                         new_i.quantity_value = new_i.quantity_value.toString();
                                         new_i.quantity = new_i.quantity_value + old_i.quantity_unit;
                                         new_i.item_cost = old_i.item_cost*old_count + i.item_cost*count; 
                                         new_i.supplier = null
                                         aggIngredients[index] = {id: i.ingredient.id, data:new_i, count:1}
                                      } 
                                  }
                                }else{
                                  //TO CHECK WHY ONE TIME
                                  console.log('XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX')
                                }
                            }
                            for (let r of l.item.subrecipes){
                                if (r.subrecipe){
                                    let yield_count = r.subrecipe.yield_count
                                    let yield_units = r.subrecipe.yield_units
                                    let quantity_value = r.quantity_value
                                    let factor = 1;
                                    let percent = 1;

                                    let recipe_type = typeYield(yield_units);
                                    let recipe_factor = 1;
    
                                    if (recipe_type == 'weight'){
                                        recipe_factor = computeWeightUnitFactor('g', yield_units);
                                        recipe_factor *= Number(yield_count);
                                    }
                                    else if (recipe_type == 'volume'){
                                        recipe_factor = computeVolumeUnitFactor('l', yield_units);
                                        recipe_factor *= Number(yield_count);
                                    }else{
                                        recipe_factor = Number(yield_count);
                                    }
    
                                    if (recipe_factor < 0) recipe_factor = 1;

                                    for (let i of r.subrecipe.ingredients){
                                        if (i.ingredient){
                                            if (i.quantity_type == 'weight'){
                                                factor = computeWeightUnitFactor('g',i.quantity_unit);
                                                i.quantity_value *=  factor
                                                i.quantity_unit = 'g'
                                                i.quantity =  i.quantity_value + i.quantity_unit
                                            }
                                            else if (i.quantity_type == 'volume'){
                                                factor = computeVolumeUnitFactor('l',i.quantity_unit);
                                                i.quantity_value *=  factor
                                                i.quantity_unit = 'l'
                                                i.quantity =  i.quantity_value + i.quantity_unit
                                            }

                                            /* if (factor){
                                                factor *= Number(quantity_value)
                                                factor /= Number(yield_count)
                                            } */
                                            //percent = Number(quantity_value)/Number(yield_count)
                                            percent = Number(quantity_value)/recipe_factor
                                            let count = Number(l.unit*percent).toFixed(3)

                                            var index = aggIngredients.findIndex(x => x.id == i.ingredient.id)
                                            if (index === -1) {
                                                i.supplier = null
                                                aggIngredients.push({id: i.ingredient.id, data:i, count:count})
                                            }else {
                                                //Update when ingredient already exists
                                                let old_i = aggIngredients[index].data;
                                                let old_count = aggIngredients[index].count;
                                                let new_i = i;
                                                if (i.quantity_type == old_i.quantity_type)
                                                {   
                                                   new_i.quantity_value = old_i.quantity_value*old_count + i.quantity_value*count;
                                                   new_i.quantity_value = new_i.quantity_value.toString();
                                                   new_i.quantity = new_i.quantity_value + old_i.quantity_unit;
                                                   new_i.item_cost = old_i.item_cost*old_count + i.item_cost*count;
                                                   new_i.supplier = null
                                                   aggIngredients[index] = {id: i.ingredient.id, data:new_i, count:1}
                                                } 
                                            }
                                        }
                                    }
                                    for (let sub of r.subrecipe.subrecipes){
                                        if (sub.subrecipe){
                                            let yield_count = sub.subrecipe.yield_count
                                            let yield_units = sub.subrecipe.yield_units
                                            let quantity_value = sub.quantity_value
                                            let factor= 1;
                                            let percent2=1;

                                            if (recipe_type == 'weight'){
                                                recipe_factor = computeWeightUnitFactor('g', yield_units);
                                                recipe_factor *= Number(yield_count);
                                            }
                                            else if (recipe_type == 'volume'){
                                                recipe_factor = computeVolumeUnitFactor('l', yield_units);
                                                recipe_factor *= Number(yield_count);
                                            }else{
                                                recipe_factor = Number(yield_count);
                                            }
    
                                            if (recipe_factor < 0) recipe_factor = 1;

                                            for (let i of sub.subrecipe.ingredients){
                                                if (i.ingredient){
                                                    if (i.quantity_type == 'weight'){
                                                        factor = computeWeightUnitFactor('g',i.quantity_unit);
                                                        i.quantity_value *=  factor
                                                        i.quantity_unit = 'g'
                                                        i.quantity =  i.quantity_value + i.quantity_unit
                                                    }
                                                    else if (i.quantity_type == 'volume'){
                                                        factor = computeVolumeUnitFactor('l',i.quantity_unit);
                                                        i.quantity_value *=  factor
                                                        i.quantity_unit = 'l'
                                                        i.quantity =  i.quantity_value + i.quantity_unit
                                                    }
/*                                                     if (factor){
                                                        factor *= Number(quantity_value)
                                                        factor /= Number(yield_count)
                                                    } */
                                                    percent2 = Number(quantity_value)/recipe_factor
                                                    //percent2 = Number(quantity_value)/Number(yield_count)
                                                    let count = Number(l.unit*percent2*percent).toFixed(2)
                                                    var index = aggIngredients.findIndex(x => x.id == i.ingredient.id)
                                                    if (index === -1) {
                                                        i.supplier = null
                                                        aggIngredients.push({id: i.ingredient.id, data:i, count:count})
                                                    }else {
                                                        //Update when ingredient already exists
                                                        let old_i = aggIngredients[index].data;
                                                        let old_count = aggIngredients[index].count;
                                                        let new_i = i;
                                                        if (i.quantity_type == old_i.quantity_type)
                                                        {
                                                           new_i.quantity_value = old_i.quantity_value*old_count + i.quantity_value*count;
                                                           new_i.quantity_value = new_i.quantity_value.toString();
                                                           new_i.quantity = new_i.quantity_value + old_i.quantity_unit;
                                                           new_i.item_cost = old_i.item_cost*old_count + i.item_cost*count;
                                                           new_i.supplier = null
                                                           aggIngredients[index] = {id: i.ingredient.id, data:new_i, count:1}
                                                        } 
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                }  
                return { prevRecipe : props.recipes,
                        recipesData: aggRecipes,
                        ingredientsList: aggIngredients,
                        orderName: order.ref_name,
                        orderNote: order.note,
                        orderDate: new Date(order.prod_date) }
            }
        }

        if((props.recipes!==state.prevRecipe)&&(props.recipes.items)){
            return { prevRecipe : props.recipes,
                     availableRecipesList: props.recipes.items };
        }
        return null;
    }


    computeQuantityWithIngredientUnit(item, onlyValue=false){
        let unitPart = "";
        let valuePart = 0;

        if (item.data.quantity_value) valuePart = item.data.quantity_value
        if (item.data.quantity_unit) unitPart = item.data.quantity_unit

        if (item.data.quantity_type == 'volume'){

            if (item.data.quantity_unit != item.data.ingredient.unit){
                let ifactor = computeVolumeUnitFactor(item.data.ingredient.unit,item.data.quantity_unit);
                valuePart = item.data.quantity_value * ifactor
                unitPart = item.data.ingredient.unit
            }
            // Apply number of items
            valuePart*= Number(item.count);

/*             for (let unit of unitVolume ){
              if (unitPart === unit){
                if ((unitPart === 'ml')||(unitPart === 'milliliter')||(unitPart === 'milliliters')){
                    if (valuePart >= Number(1000)){
                        unitPart = 'L';
                        valuePart /= Number(1000);
                    }
                }
                else if ((unitPart === 'cl')||(unitPart === 'centiliter')||(unitPart === 'centiliters')){
                    if (valuePart >= Number(100)){
                        unitPart = 'L';
                        valuePart /= Number(100);
                    }
                }
                break;
              }
            } */
          }else if (item.data.quantity_type == 'weight'){

             if (item.data.quantity_unit != item.data.ingredient.unit){
                let ifactor = computeWeightUnitFactor(item.data.ingredient.unit,item.data.quantity_unit);
                 valuePart = item.data.quantity_value * ifactor
                 unitPart = item.data.ingredient.unit
             }

            // Apply number of items
            valuePart*= Number(item.count);

/*             for (let unit of unitWeight ){
              if (unitPart === unit){
                if ((unitPart === 'g')||(unitPart === 'gram')||(unitPart === 'grams')){
                    if (valuePart >= Number(1000)){
                        unitPart = 'kg';
                        valuePart /= Number(1000);
                    }
                }
                else if (unitPart === 'mg'){
                    if (valuePart >= Number(1000)){
                        unitPart = 'g';
                        valuePart /= Number(1000);
                    }
                }
                break;
              } 
           } */
         }else if (item.data.quantity_type == 'quantity'){
            // Apply number of items
            valuePart*= Number(item.count);
         }

        if (onlyValue){
            return optimizeDecimalPart(valuePart,3);
        }

        if (item.data.quantity_type == 'quantity'){
            let is_pack = isPack(item.data.quantity_unit)
            if (is_pack){

                let unitPack = ""
                let is_pack = isPack(item.data.quantity_unit)
                let type = typeYield(item.data.ingredient.unit)
                let is_volume_ingredient = (type == 'volume')
                let is_weight_ingredient = (type == 'weight')

                if (is_weight_ingredient) unitPack = item.data.ingredient.purchase_weight
                else if (is_volume_ingredient) unitPack = item.data.ingredient.purchase_volume
                else  unitPack = item.data.ingredient.purchase_quantity

                if (item.data.extra_supplier_used  == true && item.data.extra_supplier){
                        if (is_pack){
                            if (is_weight_ingredient) unitPack = item.data.extra_supplier.purchase_weight
                            else if (is_volume_ingredient) unitPack = item.data.extra_supplier.purchase_volume
                            else unitPack = item.data.extra_supplier.purchase_quantity
                        }
                }
                if (item.data.extra_supplier_used  == true && !item.data.extra_supplier){
                    if (item.data.ingredient.default_supplier == false){
                        for (let s of item.data.ingredient.extra_suppliers){
                            if (s.default_supplier == true){
                                if (is_pack){
                                    if (is_weight_ingredient) unitPack = s.purchase_weight
                                    else if (is_volume_ingredient) unitPack = s.purchase_volume
                                    else unitPack = s.purchase_quantity
                                }
                                break
                            }
                        }
                    }
                }
                return optimizeDecimalPart(valuePart,3) + ' x ' + unitPack;

            }else{
                return optimizeDecimalPart(valuePart,3) + ' ' + unitPart;
            }
        }

        let valuePartI18n = <FormattedNumber value={valuePart.toFixed(3)}/>

        return <FormattedMessage id="productiondetails.compute.quantity.result"
                                 defaultMessage='{valuePart} {unitPart}' 
                                 values={{valuePart: valuePartI18n,
                                          unitPart:  unitPart}}/>
        //return valuePart.toFixed(2) + ' ' + unitPart;
    }


    computeTotalCost(ingredientsList){
        let cost = 0;
        ingredientsList.map((item,index) => {
            cost+=(item.data.item_cost*item.count);
            return item;
        });
        return cost.toFixed(2);
    }
    
    computeSupplier(item){
  /*       let default_supplier = item.data.ingredient.supplier && item.data.ingredient.supplier.name
        if (item.data.ingredient.default_supplier == false){
            for (let s of item.data.ingredient.extra_suppliers){
                if (s.default_supplier == true){
                    default_supplier = s.supplier.name
                    break
                }
            }
        } */
        let default_supplier = item.data.ingredient.supplier && item.data.ingredient.supplier.name
        if (item.data.extra_supplier_used == true && item.data.extra_supplier){
            default_supplier = item.data.extra_supplier.supplier && item.data.extra_supplier.supplier.name
        }
        if (item.data.extra_supplier_used  == true && !item.data.extra_supplier){
            if (item.data.ingredient.default_supplier == false){
                for (let s of item.data.ingredient.extra_suppliers){
                    if (s.default_supplier == true){
                        default_supplier = s.supplier.name
                        break
                    }
                }
            }
        }
        return  default_supplier;
    }

    computeExpiration(item){
              let default_expiration = item.data.ingredient.date_expire
              if (item.data.extra_supplier_used == true && item.data.extra_supplier){
                default_expiration = item.data.extra_supplier.date_expire
              }
              if (item.data.extra_supplier_used  == true && !item.data.extra_supplier){
                  if (item.data.ingredient.default_supplier == false){
                      for (let s of item.data.ingredient.extra_suppliers){
                          if (s.default_supplier == true){
                              default_expiration = s.supplier.date_expire
                              break
                          }
                      }
                  }
              }
              return  default_expiration;
    }

    computeBatch(item){
        let default_batch = item.data.ingredient.batch_number
        if (item.data.extra_supplier_used == true && item.data.extra_supplier){
            default_batch = item.data.extra_supplier.batch_number
        }
        if (item.data.extra_supplier_used  == true && !item.data.extra_supplier){
            if (item.data.ingredient.default_supplier == false){
                for (let s of item.data.ingredient.extra_suppliers){
                    if (s.default_supplier == true){
                        default_batch = s.supplier.batch_number
                        break
                    }
                }
            }
        }
        return  default_batch;
}
    computePurchaseUnit(item){
        const{currency} = this.state;
        let is_pack = isPack(item.data.quantity_unit)
        let is_quantity = item.data.quantity_type == 'quantity'
        let type = typeYield(item.data.ingredient.unit)
        let is_volume_ingredient = (type == 'volume')
        let is_weight_ingredient = (type == 'weight')
        let is_quantity_ingredient = (type == 'quantity')
 /*        let default_price_per_unit = item.data.ingredient.price_per_unit
        if (item.data.ingredient.default_supplier == false){
                for (let s of item.data.ingredient.extra_suppliers){
                    if (s.default_supplier == true){
                        default_price_per_unit = s.price_per_unit
                        break
                    }
                }
        }  */

        let purchase_pack = ""
        if (is_weight_ingredient) purchase_pack = item.data.ingredient.purchase_weight
        else if (is_volume_ingredient) purchase_pack = item.data.ingredient.purchase_volume
        else  purchase_pack = item.data.ingredient.purchase_quantity

        let default_price_per_unit = item.data.ingredient.price_per_unit
        if (is_pack){
            default_price_per_unit = item.data.ingredient.price
        }
        if (item.data.extra_supplier_used  == true && item.data.extra_supplier){
                default_price_per_unit = item.data.extra_supplier.price_per_unit
                if (is_pack){
                    default_price_per_unit = item.data.extra_supplier.price
                    if (is_weight_ingredient) purchase_pack = item.data.extra_supplier.purchase_weight
                    else if (is_volume_ingredient) purchase_pack = item.data.extra_supplier.purchase_volume
                    else purchase_pack = item.data.extra_supplier.purchase_quantity
                }
        }
        if (item.data.extra_supplier_used  == true && !item.data.extra_supplier){
            if (item.data.ingredient.default_supplier == false){
                for (let s of item.data.ingredient.extra_suppliers){
                    if (s.default_supplier == true){
                        default_price_per_unit = s.price_per_unit
                        if (is_pack){
                            default_price_per_unit = s.price
                            if (is_weight_ingredient) purchase_pack = s.purchase_weight
                            else if (is_volume_ingredient) purchase_pack = s.purchase_volume
                            else purchase_pack = s.purchase_quantity
                        }
                        break
                    }
                }
            }
        }

        let price_per_unit = <FormattedNumber value={default_price_per_unit} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>

        if (is_quantity){
            if (is_pack){
                    if (is_quantity_ingredient) {
                        return <FormattedMessage id="productiondetails.quantity.pack.result.quantity"
                                                defaultMessage='{valuePart} / {unitPart} units' 
                                                values={{valuePart: price_per_unit,
                                                        unitPart:  purchase_pack}}/>
                    }else{
                        return <FormattedMessage id="productiondetails.quantity.pack.result"
                                            defaultMessage='{valuePart} / {unitPart}' 
                                            values={{valuePart: price_per_unit,
                                                    unitPart:  purchase_pack}}/>
                    }
            }else{

                return <FormattedMessage id="productiondetails.quantity.unit.result"
                                            defaultMessage='{valuePart} / unit' 
                                            values={{valuePart: price_per_unit}}/>
            }
        }else{
            let new_unit = item.data.ingredient.unit
            if (grams.includes(item.data.ingredient.unit))
            {
                price_per_unit = <FormattedNumber value={Number(default_price_per_unit) * 1000} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>
                new_unit = 'Kg'
            }else if (mgrams.includes(item.data.ingredient.unit))
            {
                price_per_unit = <FormattedNumber value={Number(default_price_per_unit) * 1e6} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>
                new_unit = 'Kg'
            }
            else if (cliters.includes(item.data.ingredient.unit))
            {
                price_per_unit = <FormattedNumber value={Number(default_price_per_unit) * 100} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>
                new_unit = 'L'
            }
            else if (mliters.includes(item.data.ingredient.unit))
            {
                price_per_unit = <FormattedNumber value={Number(default_price_per_unit) * 1000} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>
                new_unit = 'L'
            }
            return <FormattedMessage id="productiondetails.notquantity.unit.result"
                                            defaultMessage='{valuePart} / {unitPart}' 
                                            values={{valuePart: price_per_unit,
                                                      unitPart:  new_unit}}/>
        }
    }


    computePurchaseUnitOnly(item){
        let is_pack = isPack(item.data.quantity_unit)
        let is_quantity = item.data.quantity_type == 'quantity'
        let type = typeYield(item.data.ingredient.unit)
        let is_volume_ingredient = (type == 'volume')
        let is_weight_ingredient = (type == 'weight')
        //let is_quantity_ingredient = (type == 'quantity')

        let purchase_pack = ""
        if (is_weight_ingredient) purchase_pack = item.data.ingredient.purchase_weight
        else if (is_volume_ingredient) purchase_pack = item.data.ingredient.purchase_volume
        else purchase_pack = item.data.ingredient.purchase_quantity

        if (item.data.extra_supplier_used  == true && item.data.extra_supplier){
                if (is_pack){
                    if (is_weight_ingredient) purchase_pack = item.data.extra_supplier.purchase_weight
                    else if (is_volume_ingredient) purchase_pack = item.data.extra_supplier.purchase_volume
                    else purchase_pack = item.data.extra_supplier.purchase_quantity
                }
        }
        if (item.data.extra_supplier_used  == true && !item.data.extra_supplier){
            if (item.data.ingredient.default_supplier == false){
                for (let s of item.data.ingredient.extra_suppliers){
                    if (s.default_supplier == true){
                        if (is_pack){
                            if (is_weight_ingredient) purchase_pack = s.purchase_weight
                            else if (is_volume_ingredient) purchase_pack = s.purchase_volume
                            else purchase_pack = s.purchase_quantity
                        }
                        break
                    }
                }
            }
        }

        if (is_quantity){
            if (is_pack){
/*                 if (is_quantity_ingredient) {
                    return <FormattedMessage id="orderslistdetails.unitpart.pack.result.quantity"
                                            defaultMessage='{unitPart} units' 
                                            values={{unitPart:  purchase_pack}}/>
                }else{ */

                    return <FormattedMessage id="productiondetails.unitpart.pack.result"
                                             defaultMessage='x {unitPart}' 
                                             values={{unitPart:  purchase_pack}}/>
                //}

            }/* else{

                return <FormattedMessage id="orderslistdetails.unitpart.quantity.result"
                                            defaultMessage='unit'/>
            } */
        }else{
                return <FormattedMessage id="productiondetails.unitpart.notquantity.result"
                                            defaultMessage='{unitPart}' 
                                            values={{unitPart:  item.data.ingredient.unit}}/>
        }
    }

    generateDocBackend(event, isPdf){
        event.preventDefault();
        const { ingredientsList,isWithPrices } = this.state;
        const {customer,order,intl} = this.props;
        let user = null;
        let membership = null;
            
        if (customer){
            membership = customer.membership;
            user = customer.user;
        }
    
        if (membership && user &&
            ((membership.membership_type=="Entreprise") || (membership.membership_type=="Professional")|| (user.bypass==true))) 
        {
            const tzid = Intl.DateTimeFormat().resolvedOptions().timeZone;
            //var offset = new Date().getTimezoneOffset();
            //console.log(tzid);
            //console.log(offset);
            if (ingredientsList){
                let properties = {
                    id: order.id,
                    items: ingredientsList,
                    name: order.ref_name,
                    withCosts: isWithPrices,
                    timezone: tzid
                }
                if (isPdf){
                    this.props.dispatch(recipesActions.renderOrder(properties)); 
                }else{
                    this.props.dispatch(recipesActions.renderOrderCsv(order.id, order.ref_name,isWithPrices)); 
                }
                toaster_info(intl.formatMessage({ id: 'downloadrecipe.toaster.message.title' }),
                             intl.formatMessage({ id: 'downloadrecipe.toaster.message.content' }))   
                
            } 
        }else{
           this.setState({ activeModalPremium: true});
        }  
    }

    deleteClickHandler (event) {
        event.preventDefault();
        this.setState({ activeModalDelete: true});
    }; 


    deleteRecipeClickHandler (event,index,item) {
        event.preventDefault();
        this.setState({ activeModalRecipeDelete: index});
        this.setState({ item_order: item});
    };

    exportRecipeClickHandler (event,index,item) {
        event.preventDefault();
        this.setState({ activeModalRecipeExport: index});
        this.setState({ item_order: item});
    };

    deleteOrder(event){
        event.preventDefault();
        const {order} = this.props;
        this.props.dispatch(recipesActions.deleteOrder(order.id,true));  
    }

    deleteRecipeOrder(event){
        event.preventDefault();
        const {order} = this.props;
        const { item_order } = this.state;
        this.props.dispatch(recipesActions.deleteRecipeOrder(order.id,
                                                            {order_recipe_id:item_order.item.id}));  
    }

    renameClickHandler (event) {
        event.preventDefault();
        const { order } = this.props; 
        this.setState({ activeModalRename: true});
        this.setState({ nameSelected: order.ref_name });
    };

    noteClickHandler (event) {
        event.preventDefault();
        const { order } = this.props; 
        this.setState({ activeModalNote: true});
        this.setState({ note: order.note });
    };

    dateClickHandler (event) {
        event.preventDefault();
        //const { order } = this.props; 
        this.setState({ activeModalDate: true});
        //this.setState({ note: order.note });
    };

    expirationClickHandler (event,index,item) {
        event.preventDefault();
        this.setState({ activeModalExpiration: index});
        this.setState({ item_order: item});
        this.setState({batch_number:item.batch_number });
        this.setState({expiration_date:item.date_expire!=null?new Date(item.date_expire):""});
    };

    onRenameOrder = (e) => {
        e.preventDefault();
        this.setState({ nameSelected: e.target.value });
    }

    onNoteUpdate = (e) => {
        e.preventDefault();
        this.setState({ note: e.target.value });
    }

    setOrderDate = (date) => {
        this.setState({ orderDate: date });
    }

    renameOrder (event,index) {
        event.preventDefault();
        const { order } = this.props; 
        let nameSelected = this.state.nameSelected;
        
        if (!isEmpty(nameSelected)){
            nameSelected = nameSelected.slice(0,50);
            this.props.dispatch(recipesActions.updateOrder(order.id,{ref_name:nameSelected},true)); 
            this.setState({ activeModalRename: false});
        }
    }

    updateOrder (event,index) {
        event.preventDefault();
        const { order } = this.props; 
        let note = this.state.note;
        
        if (!isEmpty(note)){
            note = note.slice(0,650);
        }
        this.props.dispatch(recipesActions.updateOrder(order.id,{ref_name:this.state.orderName,note:note},false,true)); 
        this.setState({ activeModalNote: false});
    }

    updateOrderDate (event,index) {
        event.preventDefault();
        const { order } = this.props; 
        let date = this.state.orderDate;
        
        if (date){
            this.props.dispatch(recipesActions.updateOrder(order.id,{ref_name:this.state.orderName,prod_date:date},false,true)); 
            this.setState({ activeModalDate: false});
        }
    }

    setExpirationDate (date) {
        this.setState({ expiration_date: date});
    }

    onBatchNumberChange = (e) => {
        e.preventDefault();
        this.setState({batch_number: e.target.value });
    }

    updateRecipeTraceability (event,element) {
        event.preventDefault();
        const { order } = this.props; 
        const { expiration_date,batch_number } = this.state; 

        this.setState({ submitted: true });

        if (!this.assertBatchField()){
            return;
        }

       let date_expire = (expiration_date && expiration_date != null ) ? expiration_date:null
        
        if (element){
            this.props.dispatch(recipesActions.updateOrderRecipeTraceability(order.id,
                                                                            {order_recipe_id:element.item.id,expiration_date:date_expire,batch_number:batch_number})); 
            this.setState({ activeModalExpiration: false});
        }
    }

    seeRecipe (event,index) {
        event.preventDefault();
        history.push('/recipedetails/'+index);
    }


    createSuppliersOrders(event){
        event.preventDefault();
        const { order } = this.props;
        if (order && order.ingredients){
            this.props.dispatch(recipesActions.createSuppliersOrders(order.id)); 
        }

    }

    reduceStockClickHandler (event) {
        event.preventDefault();
        this.setState({ activeModalReduceStockAvailable: true});
    };
    
    destockClickHandler (event) {
        event.preventDefault();
        this.setState({ activeModalDestock: true});
    }; 

    destockRecipesClickHandler (event) {
        event.preventDefault();
        
        const {customer} = this.props;
        let user = null;
        let membership = null;
    
        if (customer){
          membership = customer.membership;
          user = customer.user;
        }

        if (membership && user &&
            ((membership.membership_type=="Entreprise") || (user.bypass==true)))
        {
            this.setState({ activeModalDestockRecipes: true});

        }else{
            this.setState({ activeModalPremium: true});
        }
    }; 

    stockRecipesClickHandler (event) {
        event.preventDefault();
        
        const {customer} = this.props;
        let user = null;
        let membership = null;
    
        if (customer){
          membership = customer.membership;
          user = customer.user;
        }

        if (membership && user &&
            ((membership.membership_type=="Entreprise") || (user.bypass==true)))
        {
            this.setState({ activeModalStockRecipes: true});

        }else{
            this.setState({ activeModalPremium: true});
        }
    }; 

    reduceStockQuantity(event){
        event.preventDefault();
        const { order } = this.props;
        if (order && order.ingredients){
            this.props.dispatch(recipesActions.deduceStockOrder(order.id)); 
            this.setState({ activeModalReduceStockAvailable: false});
        }

    }

    destockQuantity(event){
        event.preventDefault();
        const { order } = this.props;
        if (order && order.ingredients){
            this.props.dispatch(recipesActions.destockOrder(order.id)); 
            this.setState({ activeModalDestock: false});
        }

    }

    destockQuantityRecipes(event){
        event.preventDefault();
        const { order } = this.props;
        if (order && order.items){
            this.props.dispatch(recipesActions.destockOrderRecipes(order.id)); 
            this.setState({ activeModalDestockRecipes: false});
        }

    }

    stockQuantityRecipes(event){
        event.preventDefault();
        const { order } = this.props;
        if (order && order.items){
            this.props.dispatch(recipesActions.stockOrderRecipes(order.id)); 
            this.setState({ activeModalStockRecipes: false});
        }

    }


    editQuantityHandlerInput (event,index,i) {
        event.preventDefault();
        let quantity = Number(i.unit)
        this.setState({ newQuantity: quantity});
        this.setState({ editCell: true});
        this.setState({ editCellIndex: index});
    }; 

    onNewQuantity = (e,item) => {
        //e.preventDefault();
        this.setState({ newQuantity: e.target.value});
    }

    assertQuantityField(quantity){
        if ((isFloat(quantity) || isNumeric(quantity))
          && (quantity != "")
          && Math.trunc(quantity).toString().length<10)
          return true;
        return false;
    }

    assertBatchField(){
        if ((trim(this.state.batch_number).length<40))
          return true;
        return false;
      }

    updateQuantityOnBlur (event,order_id,breakdown) {
        event.preventDefault();
        const {newQuantity,active_role} = this.state;
        const {dispatch} = this.props;
        let quantity = Number(trim(newQuantity.toString()).replace(/,/g, '.')).toFixed(3);
        let quantity_origin = breakdown.unit;
        quantity_origin = Number(quantity_origin).toFixed(3) 

        const title = <FormattedMessage id="productiondetails.quantity.check.error.title"
                                        defaultMessage='Quantity Update Error'/>

        if (this.assertQuantityField(quantity) & quantity!=quantity_origin){

            if (active_role!= GUEST){
                dispatch(recipesActions.updateOrderRecipeQuantity(order_id,{order_recipe_id:breakdown.item.id,
                                                                           quantity_value:quantity,
                                                                           is_nb_recipes:breakdown.is_nb_recipes}));
                breakdown.unit = quantity
                this.setState({ editCell: false });
                //this.setState({ newUnit: '' });
            }else{
                const content =  <FormattedMessage id="productiondetails.quantity.privilege.error.message"
                                                   defaultMessage='You do not have Right Privilege'/>
                dispatch(alertActions.error({title:'productiondetails.quantity.privilege.error.message',
                                             content:'productiondetails.quantity.check.error.title'}));  
                this.setState({ editCell: false });
            }
        }else{
            if (Math.trunc(quantity).toString().length>=9){

                const content =  <FormattedMessage id="productiondetails.quantity.check.error.message"
                                                  defaultMessage='Quantity field to big (must be less than 10 digits)'/>
                 dispatch(alertActions.error({title:'productiondetails.quantity.check.error.title',
                                             content:'productiondetails.quantity.check.error.message'}));   
             }
            this.setState({ editCell: false });
        }
    }

    updateQuantityOnEnter (event,order_id,breakdown) {
        const {newQuantity,active_role} = this.state;
        const {dispatch} = this.props;

        if (event.key === 'Enter') {
                let quantity = Number(trim(newQuantity.toString()).replace(/,/g, '.')).toFixed(3);
                let quantity_origin = breakdown.unit;
                quantity_origin = Number(quantity_origin).toFixed(3)

                const title = <FormattedMessage id="productiondetails.quantity.check.error.title"
                                                defaultMessage='Quantity Update Error'/>

                if (this.assertQuantityField(quantity) && quantity!=quantity_origin){

                    if (active_role!= GUEST){
                        dispatch(recipesActions.updateOrderRecipeQuantity(order_id,{order_recipe_id:breakdown.item.id,
                                                                                    quantity_value:quantity,
                                                                                    is_nb_recipes:breakdown.is_nb_recipes}));
                        breakdown.unit = quantity
                        this.setState({ editCell: false });
                    }else{
                        const content =  <FormattedMessage id="productiondetails.quantity.privilege.error.message"
                                                           defaultMessage='You do not have Right Privilege'/>
                        dispatch(alertActions.error({title:'productiondetails.quantity.privilege.error.message',
                                                     content:'productiondetails.quantity.check.error.title'}));  
                        this.setState({ editCell: false });
                    }
                }else{
                    if (Math.trunc(quantity).toString().length>=9){
                       const content =  <FormattedMessage id="productiondetails.quantity.check.error.message"
                                                         defaultMessage='Quantity field to big (must be less than 10 digits)'/>
                        dispatch(alertActions.error({title:'productiondetails.quantity.check.error.title',
                                                    content:'productiondetails.quantity.check.error.message'}));   
                    }
                    this.setState({ editCell: false });
                }
        }
    }

    onInputQuantityValue = (id,type) => {
        let toReturn = '1' 
        let found
        if ( type =='recipe'){
            found = this.state.units.find(unit => unit.id == id)
            if (found){
                toReturn = found.value
            } 
        }else{
            found = this.state.ingredQuantities.find(unit => unit.id == id)
            if (found){
                toReturn = found.value
            } 
        }
        return toReturn
    }


    addOrderRows(recipesData){
        const {order,intl} = this.props;
        const {currency,editCell,editCellIndex,newQuantity,isWithPrices,orderDate,submitted} = this.state;
        recipesData  =  recipesData.sort(function (a, b) {
            return a.item.name.toLowerCase().localeCompare(b.item.name.toLowerCase());
        }) 
        //Temporary
        //To manage older orders using units instead of quantities 
        var dateUpdate = new Date(2020,8,19,12,5,0,0);
        var dateOrder= new Date(order.created);
        let boolUseUnits = false
        if (dateOrder < dateUpdate){
            boolUseUnits = true
        }
        return recipesData.map((i,index) => {
                return ( 
                    <Table.Row key={index} >
   {/*                      { !boolUseUnits &&  i.is_nb_recipes &&
                            <Table.Cell width={4} textAlign='left'>
                                <FormattedNumber value={i.unit} />{' '}<FormattedMessage id="productiondetails.quantity.recipe.text"
                                                                                         defaultMessage='recipe(s)'/>                
                            </Table.Cell>} */}
                            { !boolUseUnits &&  i.is_nb_recipes &&
                                <Table.Cell width={4} textAlign='left' onClick={(event) => this.editQuantityHandlerInput(event,index,i)}>
                                    {(!editCell || (editCell && index != editCellIndex) ) &&
                                            <a href='#'> <FormattedNumber value={Number(i.unit)} maximumFractionDigits="3" />{' '} <FormattedMessage id="productiondetails.quantity.recipe.text"
                                                                                        defaultMessage='recipe(s)'/> {/* {this.computeQuantityWithIngredientUnit(i.item)} */}</a>}
                                    {editCell && index == editCellIndex &&
                                    <OutsideClickHandler onOutsideClick={(event) => {this.setState({ editCell: false})}}>
                                            <div>     
                                                    <Input type="text"
                                                            id="quantity"
                                                            value={newQuantity}
                                                            onChange={this.onNewQuantity}
                                                            onBlur={(event) => this.updateQuantityOnBlur(event,order.id,i)} 
                                                            onKeyPress={(event) => this.updateQuantityOnEnter(event,order.id,i)} 
                                                            autoFocus
                                                            style={{height:'2.6em',minWidth:'6em',maxWidth:'6em', padding:'0em 0.3em 0em 0.3em'}}
                                                            label={{ basic: false, content: <FormattedMessage id="productiondetails.quantity.recipe.text"
                                                                                                              defaultMessage='recipe(s)'/>   }}
                                                            labelPosition='right'
                                                            //error={submitted && (!this.assertQuantityField())}
                                                    />
                                               {/*  {' '} <FormattedMessage id="productiondetails.quantity.recipe.text"
                                                                                         defaultMessage='recipe(s)'/>    */}
                                            </div>
                                        </OutsideClickHandler>}  
                             </Table.Cell>}
{/*                          { !boolUseUnits &&  !i.is_nb_recipes &&
                            <Table.Cell width={4} textAlign='left'>
                                <FormattedNumber value={i.unit} />{' '}{i.item.yield_units}
                            </Table.Cell>} */}
                            { !boolUseUnits &&  !i.is_nb_recipes &&
                                <Table.Cell width={4} textAlign='left' onClick={(event) => this.editQuantityHandlerInput(event,index,i)}>
                                {(!editCell || (editCell && index != editCellIndex) ) &&
                                        <a href='#'>  <FormattedNumber value={Number(i.unit)} maximumFractionDigits="3"/>{' '}{i.item.yield_units}{/* {this.computeQuantityWithIngredientUnit(i.item)} */}</a>}
                                {editCell && index == editCellIndex &&
                                <OutsideClickHandler onOutsideClick={(event) => {this.setState({ editCell: false})}}>
                                        <div>     
                                                <Input type="text"
                                                        id="quantity"
                                                        value={newQuantity}
                                                        onChange={this.onNewQuantity}
                                                        onBlur={(event) => this.updateQuantityOnBlur(event,order.id,i)} 
                                                         onKeyPress={(event) => this.updateQuantityOnEnter(event,order.id,i)} 
                                                        autoFocus
                                                        style={{height:'2.6em',minWidth:'6em',maxWidth:'6em', padding:'0em 0.3em 0em 0.3em'}}
                                                        label={{ basic: false, content:i.item.yield_units  }}
                                                            labelPosition='right'
                                                        //error={submitted && (!this.assertQuantityField())}
                                                />
                                           {/*  {' '}{i.item.yield_units} */}
                                        </div>
                                    </OutsideClickHandler>}  
                         </Table.Cell>}
                        { boolUseUnits &&
                        <Table.Cell width={4} textAlign='left'><FormattedNumber value={i.unit} /></Table.Cell>}
                        <Table.Cell width={5} textAlign='left'><Icon name='food' color='blue'/><a href="#" 
                               onClick={(event) => this.seeRecipe(event,i.item.id)}
                               >{i.item.name}</a>
                        </Table.Cell>
                        <Table.Cell width={3} textAlign='left'><FormattedNumber value={i.item.yield_count} />{' '}{i.item.yield_units}</Table.Cell>
                        {isWithPrices &&
                        <Table.Cell  width={3} textAlign='left'>
                        {typeYield(i.item.yield_units) == 'quantity' &&
                           <span> 
                               <FormattedNumber value={i.item.cost_unit} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>
                               {' / '}{i.item.yield_units}</span>}
                        {typeYield(i.item.yield_units) != 'quantity' &&
                            <span><FormattedNumber value={i.item.cost_total/Number(i.item.yield_count)} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>
                            {' / '}{i.item.yield_units}{/* <FormattedMessage id="productiondetails.quantity.recipe.singular.text"
                                                           defaultMessage='recipe'/> */}</span>}
                        </Table.Cell>}
                        <Table.Cell width={4} textAlign='center'>
                                        {i && !i.date_expire && i.batch_number=='' &&
                                         <Popup trigger={<Icon color='blue' name='pencil alternate' style={{cursor:'pointer'}} size='large'
                                                                onClick={(event)=>this.expirationClickHandler(event,index,i)}/>
                                                        }
                                                content={<FormattedMessage id="productiondetails.popup.expiration.text"
                                                                        defaultMessage='Expiration date, batch'/>}
                                                inverted
                                                size='tiny'/>   
                                        }
                                        {i && (i.date_expire || i.batch_number!='') &&
                                         <div>
                                         {i.date_expire &&
                                          <a style={{cursor:'pointer'}} onClick={(event)=>this.expirationClickHandler(event,index,i)}>
                                             <FormattedDate value={new Date(i.date_expire)}
                                                            year='numeric'
                                                            month='short'
                                                            day='2-digit'/><br/><span style={{'color':'#b5b1b1'}}><i>{i.batch_number}</i> </span>
                                          </a>}
                                         {!i.date_expire &&
                                          <a style={{cursor:'pointer'}} onClick={(event)=>this.expirationClickHandler(event,index,i)}>
                                            -- <br/><span style={{'color':'#b5b1b1'}}><i>{i.batch_number}</i> </span>
                                          </a>}
                                         </div> 
                                         }

                        </Table.Cell>
                        <Table.Cell width={1} textAlign='right'>
                                        <Popup trigger={<Icon color='blue' name='newspaper outline' style={{cursor:'pointer'}} size='large'
                                                                onClick={(event) => this.exportRecipeClickHandler(event,index,i)}/>
                                                        }
                                                content={<FormattedMessage id="productiondetails.popup.export.text"
                                                                        defaultMessage='Export recipe template'/>}
                                                inverted
                                                size='small'/>
                                </Table.Cell>
                         <Table.Cell width={1} textAlign='right'>
                                        <Popup trigger={<Icon color='grey' name='trash alternate outline' style={{cursor:'pointer'}} size='large'
                                                                onClick={(event) => this.deleteRecipeClickHandler(event,index,i)}/>
                                                        }
                                                content={<FormattedMessage id="productiondetails.popup.delete.text"
                                                                        defaultMessage='Delete'/>}
                                                inverted
                                                size='small'/>
                        </Table.Cell>
                        <Modal id={index}
                               open={this.state.activeModalExpiration  === index}
                               dimmer='default' 
                               size='tiny'
                               style={styles.Modal} 
                               onClose={this.hideModal}>
                            <Header content={<FormattedMessage id="productiondetails.order.modal.traceability.title"
                                                                    defaultMessage='Traceability'/>} />
                            <Modal.Content>
                            <Form style={{paddingLeft:'0em'}}>
                              <Form.Group style={{paddingTop:'0.2em', paddingLeft:'1em'}} widths='equal'> 
                                   <Form.Field>
                                                        <label><Icon name='calendar alternate outline'/>
                                                                {' '}<FormattedMessage id="productiondetails.form.dlc.title"
                                                                                    defaultMessage='Use by'/>
                                                        </label>
                                                        <FormattedMessage id="productiondetails.form.dlc.placeholder"
                                                                                defaultMessage='---'>
                                                                {placeholder =>     
                                                                        <DatePicker selected={this.state.expiration_date} 
                                                                                    onChange={(date) => this.setExpirationDate(date)}
                                                                                    placeholderText = {placeholder} 
                                                                                    locale={intl.locale}
                                                                                    //onFocus={(e)=>this.onFocusElsewhere(e)}
                                                                                    dateFormat="P"/>}
                                                        </FormattedMessage>
                                    </Form.Field>
                                      <Form.Field>
                                    <label><FormattedMessage id="productiondetails.form.batchnumber.code.title"
                                                                        defaultMessage='Batch number'/>
                                    </label>
                                    <FormattedMessage id="productiondetails.form.batchnumber.placeholder"
                                                                defaultMessage='XXX_1234'>
                                                    {placeholder =>     
                                                        <Form.Input
                                                            type="text"
                                                            placeholder={placeholder}
                                                            id="batchcode"
                                                            value={this.state.batch_number}
                                                            onChange={this.onBatchNumberChange}
                                                            error={submitted && !this.assertBatchField()}
                                                            //onFocus={(e)=>this.onFocusElsewhere(e)}
                                                            />}
                                    </FormattedMessage>
                                    {submitted && !this.assertBatchField()  &&
                                    <label style={{color:'red', }}><i>
                                        <FormattedMessage id="productiondetails.form.batchnumber.error.message"
                                                            defaultMessage='batch number too long'/>
                                    </i></label> }
                                    </Form.Field>             
                               </Form.Group>
                               <br/> <br/> <br/> <br/> <br/> <br/><br/>
                            </Form>
                             </Modal.Content>
                              <Modal.Actions>
                                    <Button color='grey' onClick={this.hideModal}>
                                          <FormattedMessage id="productiondetails.order.modal.date.button.no"
                                                            defaultMessage='Cancel'/>
                                    </Button>
                                    <Button style={styles.ButtonGreen}
                                            labelPosition='right' 
                                            content={<FormattedMessage id="productiondetails.order.modal.date.button.set"
                                                                       defaultMessage='Set'/>}
                                            icon='checkmark' 
                                            onClick={(event) =>this.updateRecipeTraceability(event,i)}/>
                              </Modal.Actions>
                        </Modal>
                        <Modal id={index}
                                        open={this.state.activeModalRecipeExport === index}
                                        dimmer='default' 
                                        size='tiny' 
                                        onClose={this.hideModal}
                                        style={styles.Modal}
                                        closeIcon>
                                        <Header icon='newspaper outline' as='h5' content={<FormattedMessage id="productiondetails.modal.ingredient.export.title"
                                                                                                    defaultMessage='Production document -  {name}'
                                                                                                    values={{name:i.item.name}}/>} />
                                        <Modal.Content>
                                        <label style={styles.FontLato14}>
                                                <FormattedMessage id="productiondetails.modal.form.options.title"
                                                                defaultMessage='Include in my document'/>
                                        </label>
                                        {/* <br/> */}
                                        <Segment color='teal'>
                                        <Form.Group style={styles.FontLato12} grouped >
                                        <Form.Field style={{marginLeft:'1em'}}> 
                                                <FormattedMessage id="productiondetails.modal.form.options.option.cost"
                                                                defaultMessage='Cost summary'>
                                                    {label => 
                                                        <Checkbox label={label}
                                                                id='cost'
                                                                checked = {this.state.withCostBreakdown}
                                                                onChange={this.onCheckboxModalChange}/>}
                                                </FormattedMessage>
                                            </Form.Field>
                                            <Form.Field style={{marginLeft:'1em'}}>
                                                <FormattedMessage id="productiondetails.modal.form.options.option.margin"
                                                                defaultMessage='Margin evaluation'>
                                                    {label => 
                                                        <Checkbox label={label}
                                                                id='margin'
                                                                checked = {this.state.withMargin}
                                                                onChange={this.onCheckboxModalChange} />}
                                                </FormattedMessage>
                                            </Form.Field>
                                            <Form.Field style={{marginLeft:'1em'}}>
                                                <FormattedMessage id="productiondetails.modal.form.options.option.supplier"
                                                                defaultMessage='Suppliers'>
                                                    {label => 
                                                        <Checkbox label={label}
                                                                id='supplier'                                       
                                                                checked = {this.state.withSuppliers}
                                                                onChange={this.onCheckboxModalChange} />}
                                                </FormattedMessage>
                                            </Form.Field>
                                            <Form.Field style={{marginLeft:'1em'}}>
                                                <FormattedMessage id="productiondetails.modal.form.options.option.chart"
                                                                defaultMessage='Pie chart'>
                                                    {label => 
                                                        <Checkbox label={label}
                                                                id='chart'
                                                                checked = {this.state.withPieChart}
                                                                onChange={this.onCheckboxModalChange}/>}
                                                </FormattedMessage>
                                            </Form.Field>
                                            <Form.Field style={{marginLeft:'1em'}}>
                                                <FormattedMessage id="productiondetails.modal.form.options.option.instructions"
                                                                defaultMessage='Preparation instructions'>
                                                    {label => 
                                                        <Checkbox label={label}
                                                                id='instruct'
                                                                checked = {this.state.withPrepInstructions}
                                                                onChange={this.onCheckboxModalChange}/>}
                                                </FormattedMessage>
                                                <br/> 
                                                <span style={{marginLeft:'3em'}}>           
                                                    <FormattedMessage id="productiondetails.modal.form.options.option.breakpage"
                                                                    defaultMessage='Start on a new page'>
                                                        {label => 
                                                            <Checkbox label={label}
                                                                    id='break'
                                                                    checked = {this.state.withBreakpage}
                                                                    onChange={this.onCheckboxModalChange} />}
                                                    </FormattedMessage>
                                                </span>
                                            </Form.Field>
                                            <Form.Field style={{marginLeft:'1em'}}>
                                                <FormattedMessage id="productiondetails.modal.form.options.option.subrecipes"
                                                                defaultMessage='Sub-recipes'>
                                                    {label => 
                                                        <Checkbox label={label}
                                                                id='sub'
                                                                checked = {this.state.withSubRecipes}
                                                                onChange={this.onCheckboxModalChange} />}
                                                </FormattedMessage>
                                            </Form.Field>
                                        </Form.Group>
                                        </Segment>
                                        </Modal.Content>
                                        <Modal.Actions>
                                            <Button style={styles.ButtonGrey}
                                                    onClick={this.hideModal}>
                                                    <FormattedMessage id="productiondetails.modal.ingredient.export.button.no"
                                                                    defaultMessage='Cancel'/>
                                                </Button>
                                                <Button style={styles.ButtonGreen}
                                                        labelPosition='right' 
                                                        content={<FormattedMessage id="productiondetails.modal.ingredient.export.button.yes"
                                                                                defaultMessage='Download'/> }
                                                        icon='checkmark' 
                                                        onClick={(event) =>this.downloadRecipeBackend(event,i)}/>
                                        </Modal.Actions>
                        </Modal>
                        <Modal id={index}
                                        open={this.state.activeModalRecipeDelete === index}
                                        dimmer='default' 
                                        size='tiny' 
                                        onClose={this.hideModal}
                                        style={styles.Modal}
                                        closeIcon>
                                        <Header icon='trash alternate outline' content={<FormattedMessage id="productiondetails.modal.ingredient.deletion.title"
                                                                                                            defaultMessage='Deletion confirmation'/>} />
                                        <Modal.Content>
                                            <p><FormattedMessage id="productiondetails.modal.ingredient.deletion.message"
                                                                defaultMessage='Really sure to delete {ingredient} from {order} ?'
                                                                values={{order: order.ref_name,
                                                                        ingredient : i.item.name}} /></p>
                                        </Modal.Content>
                                        <Modal.Actions>
                                            <Button style={styles.ButtonGrey}
                                                    onClick={this.hideModal}>
                                                    <FormattedMessage id="productiondetails.modal.ingredient.deletion.button.no"
                                                                    defaultMessage='No'/>
                                                </Button>
                                                <Button style={styles.ButtonRed}
                                                        labelPosition='right' 
                                                        content={<FormattedMessage id="productiondetails.modal.ingredient.deletion.button.yes"
                                                                                defaultMessage='Yes'/> }
                                                        icon='checkmark' 
                                                        onClick={(event) =>this.deleteRecipeOrder(event,order.id)}/>
                                        </Modal.Actions>
                        </Modal>
                     </Table.Row>
                );
        });
    }


    addListRowsPerSupplier(){
        const{currency,suppliers_lists,numberSuppliers,isWithPrices/*,newUnit*/} = this.state;
        const{order} = this.props;
        let new_supplier = false;
        let supplier_title = "";
        let nb_ingredients = 0;
        let computeGblIndex = 0; 

        return suppliers_lists.map((elem,indexSupplier) => { 
            new_supplier = true
            let ingredientsList  =  elem.ingredients_list.sort(function (a, b) {
                return a.data.ingredient.name.toLowerCase().localeCompare(b.data.ingredient.name.toLowerCase()); 
            }) 

            if (indexSupplier>0){
                computeGblIndex = nb_ingredients;
            }

            return ingredientsList.map((item,indexSupplierElem) => {
                 if (indexSupplierElem > 0){
                    computeGblIndex += 1 ;
                 }
                let index = computeGblIndex 
                let expiration_date =this.computeExpiration(item)
                let batch_number =this.computeBatch(item)

                if (new_supplier == true && numberSuppliers > 1){
                    nb_ingredients +=  ingredientsList.length;
                    new_supplier = false 
                    if (elem.supplier_name == 'undefined'){
                        supplier_title = <FormattedMessage id="productiondetails.list.ingredient.supplier.divider.notdefined"
                                                     defaultMessage='No supplier'/>
                    }else{
                        supplier_title = elem.supplier_name

                    }      
                    return ([ 
                            <Table.Row style={{'background-color':'#f5f5f5'}}>
                                                <Table.Cell style={{color:'#00b3b3'}}> {supplier_title}</Table.Cell>
                                                {order  && order.state =='received' && <Table.Cell colspan='3'/>}
                                                {order  && order.state !='received' &&<Table.Cell colspan='4'/>}
                                                {isWithPrices &&
                                                <Table.Cell textAlign='center' style={{color:'#00b3b3'}}> 
                                                    <FormattedMessage id="productiondetails.list.ingredient.supplier.subtotal.text"
                                                                      defaultMessage='Total :'/>{'  '}
                                                    <FormattedNumber value={this.computeTotalCost(ingredientsList)} 
                                                                     style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>
                                                </Table.Cell>}
                                                <Table.Cell/> 
                              </Table.Row>,
                              <Table.Row key={index} >
                              <Table.Cell width={4} textAlign='left' style={{'color': 'black'}} >
                                {item.data.ingredient.name.length >= 45 && 
                                  item.data.ingredient.name.substring(0,45) + '..'}
                                {item.data.ingredient.name.length < 45 && 
                                    item.data.ingredient.name}
                                {/* {item.data.ingredient.name} */}
                              </Table.Cell>
                              <Table.Cell width={3} textAlign='center'>{this.computeSupplier(item)}
                                        {(expiration_date || batch_number!='') &&
                                         <div style={{'font-size':'11px'}}>
                                            {expiration_date && batch_number!='' &&
                                                <span style={{cursor:'pointer','color':'#b5b1b1'}}>
                                                    <FormattedDate value={new Date(expiration_date)}
                                                                    year='numeric'
                                                                    month='short'
                                                                    day='2-digit'/>{' | '}<span style={{'color':'#b5b1b1'}}><i>{batch_number}</i> </span>
                                                </span>}
                                            {batch_number=='' && expiration_date &&
                                            <span style={{cursor:'pointer','color':'#b5b1b1'}}>
                                                <span style={{'color':'#b5b1b1'}}><FormattedDate value={new Date(expiration_date)}
                                                                    year='numeric'
                                                                    month='short'
                                                                    day='2-digit'/> </span>
                                            </span>}
                                            {!expiration_date && batch_number!='' &&
                                            <span style={{cursor:'pointer','color':'#b5b1b1'}}>
                                                <span style={{'color':'#b5b1b1'}}><i>{batch_number}</i> </span>
                                            </span>}
                                         </div> }
                              </Table.Cell>

                              {isWithPrices && <Table.Cell width={2} textAlign='right'>{this.computePurchaseUnit(item)}</Table.Cell>}
                              <Table.Cell width={3} textAlign='right'>
                                  {this.computeQuantityWithIngredientUnit(item)}
                              </Table.Cell>
                              <Table.Cell width={3} textAlign='right'>{optimizeDecimalPart(Number(item.data.ingredient.stock_quantity))} {item.data.ingredient.stock_unit}</Table.Cell>
                              {isWithPrices &&
                              <Table.Cell width={2} textAlign='center'>
                                  <FormattedNumber value={(item.data.item_cost*item.count).toFixed(2)} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/> 
                                  </Table.Cell>}
                              <Table.Cell width={1} textAlign='right'/>
                              {!isWithPrices &&
                                   <Table.Cell width={1} textAlign='right'/>}
                          </Table.Row>]);
                }else{  
                    return (
                            <Table.Row key={index} >
                                <Table.Cell width={4} textAlign='left' style={{'color': 'black'}} >
                                    {item.data.ingredient.name.length >= 45 && 
                                     item.data.ingredient.name.substring(0,45) + '..'}
                                    {item.data.ingredient.name.length < 45 && 
                                      item.data.ingredient.name}
                                    {/* {item.data.ingredient.name} */}
                                </Table.Cell>
                                <Table.Cell width={3} textAlign='center'>{this.computeSupplier(item)}
                                    {(expiration_date || batch_number!='') &&
                                         <div style={{'font-size':'11px'}}>
                                            {expiration_date && batch_number!='' &&
                                                <span style={{cursor:'pointer','color':'#b5b1b1'}}>
                                                    <FormattedDate value={new Date(expiration_date)}
                                                                    year='numeric'
                                                                    month='short'
                                                                    day='2-digit'/>{' | '}<span style={{'color':'#b5b1b1'}}><i>{batch_number}</i> </span>
                                                </span>}
                                            {batch_number=='' && expiration_date &&
                                            <span style={{cursor:'pointer','color':'#b5b1b1'}}>
                                                <span style={{'color':'#b5b1b1'}}><FormattedDate value={new Date(expiration_date)}
                                                                    year='numeric'
                                                                    month='short'
                                                                    day='2-digit'/> </span>
                                            </span>}
                                            {!expiration_date && batch_number!='' &&
                                            <span style={{cursor:'pointer','color':'#b5b1b1'}}>
                                                <span style={{'color':'#b5b1b1'}}><i>{batch_number}</i> </span>
                                            </span>}
                                         </div> }
                                </Table.Cell>
                                {isWithPrices && <Table.Cell width={2} textAlign='right'>{this.computePurchaseUnit(item)}</Table.Cell>}
                                <Table.Cell width={2} textAlign='right'>
                                    {this.computeQuantityWithIngredientUnit(item)}
                                </Table.Cell>
                                <Table.Cell width={3} textAlign='right'>{optimizeDecimalPart(Number(item.data.ingredient.stock_quantity))} {item.data.ingredient.stock_unit}</Table.Cell>
                                {isWithPrices && 
                                <Table.Cell width={2} textAlign='center'>
                                    <FormattedNumber value={(item.data.item_cost*item.count).toFixed(2)} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/> 
                                    {/*  {intl.formatMessage({id:'currency.symbol'})} */}</Table.Cell>}
                                    <Table.Cell width={1} textAlign='right'/>
                                {!isWithPrices &&
                                    <Table.Cell width={1} textAlign='right'/>}
                            </Table.Row>
                        );
                    }
            });
        });
    }

    upgradeSubscription = () => {
        history.push({pathname: '/profilepremium', state: {toggled: false}})
    }

    hideModal() {
        this.setState({ activeModalPremium: false });
        this.setState({ activeModalDelete: false });
        this.setState({ activeModalRecipeDelete: false });
        this.setState({ activeModalRecipeExport: false });
        this.setState({ activeModalRename: false });
        this.setState({ activeModalNote: false });
        this.setState({ activeModalDate: false });
        this.setState({ activeModalReduceStockAvailable: false });
        this.setState({ activeModalDestock: false });
        this.setState({ activeModalDestockRecipes: false });
        this.setState({ activeModalStockRecipes: false });
        this.setState({ activeModalExpiration: false });
    }

    toggleItemsMenu(e){
        this.setState({sidebarToggled: !this.state.sidebarToggled});
        this.setState({filtering: false});
        this.setState({nameSelected: ""});
        this.setState({note: ""});   
    } 

    addRecipesRows(recipes){
        return recipes.map((recipe,index) => {
                return (
                    <Table.Row key={index} style={{'backgroundColor':'#fcfcfc'}}
                                    /* onClick={(event)=>this.addToCartList(event,recipe)} */>
                        <Table.Cell width={5}>
                            <Icon name='food' color='blue'/>
                            <a //href="#" 
                               //onClick={(event) => this.seeRecipe(event,recipe.id)}
                               style={{'color': 'black',fontWeight:'bolder'}}>
                                  {recipe.name.length >= 65 && 
                                    recipe.name.substring(0,65) + '..'}
                                    {recipe.name.length < 65 && 
                                    recipe.name}
                                   {/* {recipe.name.substr(0, 60)} */}
                            </a>
                        </Table.Cell>
{/*                         <Table.Cell width={3} textAlign='center'>{recipe.category && recipe.category.name}</Table.Cell>
                        <Table.Cell width={3} textAlign='right'><FormattedNumber value={recipe.yield_count} /> {recipe.yield_units}</Table.Cell> */}
                        <Table.Cell width={1} textAlign='left'>
                                   <Button color='blue' circular className="ui small icon button" 
                                             onClick={(event)=>this.addToCartList(event,recipe)}>
                                            <i className="add icon"></i>
                                   </Button>
                        </Table.Cell>
                     </Table.Row>
                );
        });
    }

    addToCartList (event,recipe) {
        event.preventDefault();
        const {intl,dispatch,order} = this.props;
        var found = this.state.recipesData.find(x => x.item.id == recipe.id)
       
        if (!found){
             dispatch(recipesActions.addOrderRecipe(order.id,
                                                    {order_recipe_id: recipe.id,
                                                     quantity_value: '1',
                                                     is_nb_recipes: false}));
        }else{
            toaster_info(intl.formatMessage({ id: 'productiondetails.toaster.recipe.add.duplicate.message.title' }),
                         intl.formatMessage({ id: 'productiondetails.toaster.recipe.add.duplicate.message.content' }))
        }    
    }

    filterNameList(event){
        event.preventDefault();
        const {availableRecipesList,} = this.state;
        this.setState({nameSelected: event.target.value});
        this.setState({supplierSelected: null});

        let updatedList = availableRecipesList.filter((item) => {
                return (item.name).normalize('NFKD').replace(/[^\w]/g, '').toLowerCase()
                            .search(event.target.value.normalize('NFKD').replace(/[^\w]/g, '').toLowerCase()) !== -1;
              });

        this.setState({newItems: updatedList});
        this.setState({filtering: true}); 

    }

    filterCategoryList(event,data){
        event.preventDefault();
        const {categoriesRecipe} = this.props;
        const {availableRecipesList} = this.state;

        this.setState({filtering: false});
        this.setState({categorySelected: ""});
        this.setState({nameSelected: ""});

        if (data.value!="")
        {
            let category_id = data.value;
            let elem = categoriesRecipe.options.find(category => category.value == category_id)

            if (elem){
                let updatedList = availableRecipesList.filter((item) => {
                    if (item.category && item.category.id){
                        return item.category.id == elem.value
                    }
                    return null;
                });
                this.setState({newItems: updatedList});
                this.setState({filtering: true});
                this.setState({categorySelected: {id:category_id,
                                                  name:elem.text}});
            }
        }
    }

    onCheckEmailSupplier = (e,data) => {
        e.preventDefault();
        this.setState({ withEmailSupplier: data.checked });
    }

    isUnSubscribedUser(){
        const { subscription } = this.props;
        if (subscription && !isEmpty(subscription) && (subscription.active == false) && (subscription.bypass == false)){
            return true
        }
        return false
    }

    openPlanning = (e) => {
        e.preventDefault();
        history.push('/productions',{planning:true});
    }

    onCheckboxChange = (e, data) => {
        this.setState({ isWithPrices: data.checked});
    }

    downloadRecipeBackend (e,element) {
        const {withCostBreakdown,
                withPieChart,withMargin, withPrepInstructions,withSubRecipes,withBreakpage,withSuppliers} = this.state;
        const {customer,intl,order} = this.props;
        let user = null;
        let membership = null;
            
        if (customer){
            membership = customer.membership;
            user = customer.user;
        }

        if (membership && user &&
            ((membership.membership_type=="Entreprise") || (membership.membership_type=="Professional")|| (user.bypass==true))) 
        {
            if (element){
                let properties = {
                    name: element.item.name,
                    id: element.item.id,
                    pie: (withPieChart?1:0),
                    cost:(withCostBreakdown?1:0),
                    text: (withPrepInstructions?1:0),
                    margin: (withMargin?1:0),
                    subrecipes : (withSubRecipes?1:0),
                    breakpage : (withBreakpage?1:0),
                    units:element.unit.replace(/,/g, '.'),
                    portions : element.is_nb_recipes?0:1,
                    supplier : (withSuppliers?1:0),
                }
                this.setState({ activeModalRecipeExport: false });
                this.props.dispatch(recipesActions.renderPdf(properties));  
                toaster_info(intl.formatMessage({ id: 'downloadrecipe.toaster.message.title' }),
                             intl.formatMessage({ id: 'downloadrecipe.toaster.message.content' }))
                
            }
        }
        else {
            this.setState({ activeModalPremium: true});
        }
    }

    onCheckboxModalChange = (e, data) => {
        if (data.id == 'cost')
            this.setState({ withCostBreakdown: data.checked});
        else if (data.id  == 'chart')
            this.setState({ withPieChart: data.checked});
        else if (data.id  == 'margin')
            this.setState({ withMargin: data.checked});
        else if (data.id  == 'instruct'){
            this.setState({ withPrepInstructions: data.checked});
            //this.setState({ withBreakpage: data.checked});
        }
        else if (data.id  == 'sub')
            this.setState({ withSubRecipes: data.checked});
        else if (data.id  == 'break')
            this.setState({ withBreakpage: data.checked});
        else if (data.id  == 'order')
            this.setState({ withOrder: data.checked});
        else if (data.id  == 'supplier')
            this.setState({ withSuppliers: data.checked});
    }

    render() { 
        const { alert,dispatch, loading, updating,adding,customer,intl,order,categoriesRecipe} = this.props;
        const {recipesData,ingredientsList,currency,suppliers_lists,numberSuppliers,availableRecipesList,
            sidebarToggled,newItems,filtering,isMobile,nameSelected,categorySelected,orderName,orderNote,orderDate,isWithPrices } = this.state;
        let membership = null;
        let user = null;
        let isLimited = this.isUnSubscribedUser()
        let items = [];
        let className = "floating-menu";

        if (customer){
             membership = customer.membership;
             user = customer.user;
        }

        if (filtering){
            items = newItems
        }else{
            items = availableRecipesList
        }
        //Filter and notify restrictions if user unsubscribed
        if (isLimited && items){
            items = items.slice(0,MAX_NUMBER_INGREDIENTS_FREE_SUBSCRIBER);
        }

        return ( 
            <div>
                <Sidebar as={Segment}
                                animation={'overlay'}
                                direction={'right'}
                                visible={sidebarToggled}
                                vertical
                                width= {isMobile?'wide':'very wide'}
                                icon='labeled'
                                style={{'backgroundColor':'#fcfcfc'/*,'overflow-y': 'hidden','overflow-x': 'auto;','overflow': '-moz-scrollbars-vertical;'*/}}
                                >
                            <Grid textAlign='center' /*style={{'overflow-y': 'hidden','overflow-x': 'auto;','overflow': '-moz-scrollbars-vertical;'}}*/>
                                <Grid.Row columns={1}>
                                    <Grid.Column /*mobile={16} tablet={12} computer={8}*/ fluid>
                                        <div style={{display: 'inline-block;',position:'absolute'}}>
                                                <Icon name='close' size='large' color='grey'  onClick={(e) => this.toggleItemsMenu(e)}/>
                                        </div>  
                                        <Form>
                                        <Form.Field align='left' style={{'margin':'2em 0em 1em 1em'}}>
                                            <Header as='h2' style={{'marginLeft': '1em'}}>
                                                <img src={img_bag} style={{width:'45px'}}/>
                                            <Header.Content>
                                                <FormattedMessage id="productiondetails.form.input.recipe.title"
                                                                    defaultMessage='Add recipes'/>
                                            </Header.Content>
                                        </Header>
                                        </Form.Field>
                                        <Form.Field align='left' style={{'margin':'0em 0.5em 1em 0.5em'}}>
                                                <FormattedMessage id="productiondetails.form.input.search.placeholder"
                                                            defaultMessage="Enter a name ...">
                                                {placeholder => 
                                                        <Input
                                                            fluid
                                                            onChange={this.filterNameList}
                                                            color='teal'
                                                            size='large'
                                                            icon='filter'
                                                            placeholder={placeholder}
                                                            value={nameSelected}
                                                            disabled = {isLimited}
                                                            style={{'backgroundColor':'#f5f5f5'}}
                                                            //onFocus={(e)=>this.onFocusElsewhere(e)}
                                                        /> } 
                                                </FormattedMessage> 
                                        </Form.Field>
                                        <Form.Field align='left' style={{'margin':'0em 0.5em 1em 0.5em'}}>
                                                <span>
                                                    <p style={styles.FontLato13}>
                                                        <FormattedMessage id="productiondetails.form.filter.category.title"
                                                                        defaultMessage='Filter by category' />
                                                    </p>
                                                    <FormattedMessage id="productiondetails.form.filter.category.placeholder"
                                                                        defaultMessage='Select a category'>
                                                        {placeholder =>   
                                                            <Dropdown selection
                                                            options= {categoriesRecipe && categoriesRecipe.options} 
                                                            value= {categorySelected && categorySelected.id}
                                                            placeholder={placeholder} 
                                                            onChange={this.filterCategoryList}
                                                            clearable
                                                            fluid
                                                            disabled = {isLimited}
                                                            //style={{minWidth:'18em',maxWidth:'26em'}}
                                                            />}   
                                                    </FormattedMessage>
                                                </span>
                                        </Form.Field>
                                      </Form>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={1}>
                                   { (adding) &&
                                        <Dimmer active inverted>
                                            <Loader inverted content='Loading' />
                                        </Dimmer> }
                                    <Grid.Column /*mobile={16} tablet={16} computer={16}*/ fluid >
                                        <Table  unstackable 
                                                singleLine 
                                                style={styles.FontLato13Border}
                                                //style={{'overflow-y': 'hidden','overflow-x': 'auto;','overflow': '-moz-scrollbars-none;','-ms-overflow-style':'none;'}}
                                                >
                                                <Table.Body>
                                                {availableRecipesList && availableRecipesList.length > 0 && items &&
                                                    this.addRecipesRows(items)
                                                }
                                                {availableRecipesList && availableRecipesList.length == 0 && 
                                                <Table.Row textAlign='left' style={{'backgroundColor':'#fcfcfc','font-family': 'Lato','font-size': '14px'}}>
                                                    <Table.Cell>
                                                        <FormattedMessage id="productiondetails.list.recipe.empty.message"
                                                                            defaultMessage='No recipe created yet' />
                                                    </Table.Cell>
                                                    <Table.Cell width={1} textAlign='right'>
                                                        <Button color='teal' circular  className="ui small icon button" 
                                                                onClick={this.createRecipe}>
                                                                <i className="add icon"></i>
                                                        </Button>
                                                    </Table.Cell>
                                                </Table.Row>
                                                }
                                            </Table.Body>
                                            {isLimited && items &&
                                                <Table.Footer /*fullWidth*/ width={4}>
                                                        <Table.HeaderCell colSpan='2'>
                                                        <Message fluid icon size='big' style={styles.FontLato12Bold} color='blue'>
                                                                <Image src={img_performance} size ='tiny' rounded/>
                                                                <br/>
                                                                <Message.Content style={{marginLeft:'1em'}}>
                                                                    <Message.Header color='black'>
                                                                        <FormattedMessage id="productiondetails.list.premium.title.message.upgradeplan"
                                                                                            defaultMessage='Recipes limit exceeded'/> 
                                                                    </Message.Header>
                                                                    <br/>
                                                                    <div style={styles.FontLato13}>
                                                                        <FormattedMessage id="productiondetails.list.premium.message.upgradeplan.line1"
                                                                                        defaultMessage='Your have'/>{' '}{availableRecipesList.length}{' '}
                                                                        <FormattedMessage id="productiondetails.list.premium.message.upgradeplan.line3"
                                                                                        defaultMessage='recipes but limited to'/>{' '}{MAX_NUMBER_RECIPES_FREE_SUBSCRIBER}{'.'} 
                                                                    </div>
                                                                    <br/><br/>
                                                                    <Button basic color='blue' style={styles.FontLato12Bold} onClick={(e) => this.upgradeSubscription()}>
                                                                        <FormattedMessage id="productiondetails.list.premium.button.chooseplan"
                                                                                    defaultMessage='Choose premium plan'/>
                                                                    </Button>                                      
                                                                </Message.Content>
                                                            </Message>
                                                        </Table.HeaderCell>
                                            </Table.Footer>}
                                    </Table>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Sidebar>
                     <SideLeftUncoverProductions>
                        <Grid stackable id='grid'  style={styles.FontLato13Border}>
                            <Grid.Row columns={1}>
                             <Breadcrumb  style={{background:'#fcfcfc'}}>
                                <Breadcrumb.Section link as={Link} to={'/dashboard/'}><FormattedMessage id="productiondetails.link.dashboard"
                                                              defaultMessage='Dashboard'/></Breadcrumb.Section>
                                <Breadcrumb.Divider icon='right chevron'/>
                                <Breadcrumb.Section link as={Link} to={'/productions/'}><FormattedMessage id="productiondetails.link.orders"
                                                              defaultMessage='Productions'/></Breadcrumb.Section> 
                                <Breadcrumb.Divider icon='right chevron'/>
                                <Breadcrumb.Section active onClick={(e) => {this.openPlanning(e)}} ><FormattedMessage id="productiondetails.link.planning"
                                                              defaultMessage='Planning'/></Breadcrumb.Section>  
                                <Breadcrumb.Divider icon='right chevron'/>
                                <Breadcrumb.Section active>{order && order.ref_name}</Breadcrumb.Section>                                
                             </Breadcrumb>
                            </Grid.Row>
                            <Grid.Row columns={1}>
                                <Grid.Column width={14}>
                                        <Header as='h2' fluid>
                                            <img src={img_planning} style={{width:'45px'}}/>
                                            <Header.Content>
                                                <FormattedMessage id="productiondetails.page.title"
                                                                defaultMessage='Production scheduled'/>{' '}&nbsp;
                                                    {order && order.prod_state == 'notstarted' &&
                                                                  <Label size='large' color='orange'>
                                                                    <FormattedMessage id="productiondetails.action.notstarted.text"
                                                                                      defaultMessage='Not started'/></Label>}
                                                     {order && order.prod_state == 'started' &&
                                                                  <Label size='large' color='teal'>
                                                                     <FormattedMessage id="productiondetails.action.started.text"
                                                                                       defaultMessage='Started'/></Label>}
                                                     {order && order.prod_state == 'finished' &&
                                                                  <Label size='large' color='grey'>
                                                                 <FormattedMessage id="productiondetails.action.finished.text"
                                                                                   defaultMessage='Finished'/></Label>}
                                                <br/>               
                                                <Header.Subheader style={{'font-size': '16px','color': '#66b2b2', display: 'flex'}}>
                                                        <FormattedMessage id="productiondetails.page.order.caption.name"
                                                                           defaultMessage='Ref:'/>&nbsp;&nbsp;{orderName}&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <Popup trigger= {  <Icon color='grey'
                                                                                name='pencil alternate'
                                                                                //size='small' 
                                                                                disabled = {loading||updating}
                                                                                style={{cursor:'pointer'}}
                                                                                onClick={(event)=>this.renameClickHandler(event)}
                                                                            />}
                                                                    content={<FormattedMessage id="productiondetails.order.caption.icon.title"
                                                                    defaultMessage='Rename'/>}
                                                                    position='bottom center'
                                                                    inverted
                                                            />
                                                            <Modal open={this.state.activeModalRename}
                                                                dimmer='default' 
                                                                size='tiny'
                                                                style={styles.Modal} 
                                                                onClose={this.hideModal}>
                                                                <Header icon='pencil alternate' content={<FormattedMessage id="productiondetails.order.modal.rename.title"
                                                                                                                           defaultMessage='Rename order'/>} />
                                                                <Modal.Content>
                                                                    <Input
                                                                        type="text"
                                                                        placeholder="Renaming"
                                                                        id="renameCategory"
                                                                        value={this.state.nameSelected}
                                                                        onChange={this.onRenameOrder}
                                                                        style={{minWidth:'20em',maxWidth:'40em'}}
                                                                        />
                                                                </Modal.Content>
                                                                <Modal.Actions>
                                                                    <Button color='grey' onClick={this.hideModal}>
                                                                        <FormattedMessage id="productiondetails.order.modal.rename.button.no"
                                                                                        defaultMessage='Cancel'/>
                                                                    </Button>
                                                                    <Button color='teal' 
                                                                        labelPosition='right' 
                                                                        content={<FormattedMessage id="productiondetails.order.modal.rename.button.yes"
                                                                                                    defaultMessage='Rename'/>}
                                                                        icon='checkmark' 
                                                                        onClick={(event) =>this.renameOrder(event)}/>
                                                                    </Modal.Actions>
                                                            </Modal> 
                                                </Header.Subheader>
                                            </Header.Content>
                                         </Header>
                                </Grid.Column>
                            </Grid.Row>
{/*                             {alert.message && 
                                <Grid.Row><div className={`alert ${alert.type}`}>{alert.message}</div></Grid.Row>
                            } */}
                            {alert.message && toaster_dispatch(dispatch,alert,intl)}
                            <Grid.Row>
                                <Grid.Column>
                                  <span>
                                            <Header as='h4' color='grey'><FormattedMessage id="productiondetails.list.info.date"
                                                              defaultMessage='Production date' />
                                            {order && order.created && 
                                                <span style={{marginLeft:'1em'}} >{' '}
                                                         <FormattedDate value={new Date(order.prod_date)}
                                                                                   year='numeric'
                                                                                   month='long'
                                                                                   day='2-digit'/>{' - '}
                                                        <FormattedTime value={new Date(order.prod_date)}
                                                                                   hour='numeric'
                                                                                   minute='numeric'/> {' '}{' '}
                                                <Popup trigger= {  <Icon color='grey'
                                                                         name='clock outline'
                                                                         //size='large' 
                                                                         disabled = {loading||updating}
                                                                         style={{cursor:'pointer'}}
                                                                         onClick={(event)=>this.dateClickHandler(event)}
                                                                    />}
                                                                    content={<FormattedMessage id="productiondetails.order.caption.date.title"
                                                                                               defaultMessage='Change'/>}
                                                                    position='bottom center'
                                                                    inverted
                                                            />  
                                                 <Modal open={this.state.activeModalDate}
                                                                dimmer='default' 
                                                                size='tiny'
                                                                style={styles.Modal} 
                                                                onClose={this.hideModal}>
                                                                <Header content={<FormattedMessage id="productiondetails.order.modal.date.title"
                                                                                                    defaultMessage='Date of production'/>} />
                                                                <Modal.Content>
                                                                <FormattedMessage id="productiondetails.order.modal.datepicker.time.caption"
                                                                                  defaultMessage='Time'>
                                                                   {caption =>  
                                                                     <DatePicker  showIcon
                                                                            selected={orderDate}
                                                                            onChange={(date)=>this.setOrderDate(date)}
                                                                            inline
                                                                            showTimeSelect
                                                                            dateFormat="Pp"
                                                                            locale={intl.locale}
                                                                            timeCaption={caption}
                                                                            showWeekNumbers
                                                                        />}</FormattedMessage>
                                                                </Modal.Content>
                                                                <Modal.Actions>
                                                                    <Button color='grey' onClick={this.hideModal}>
                                                                        <FormattedMessage id="productiondetails.order.modal.date.button.no"
                                                                                        defaultMessage='Cancel'/>
                                                                    </Button>
                                                                    <Button color='teal' 
                                                                        labelPosition='right' 
                                                                        content={<FormattedMessage id="productiondetails.order.modal.date.button.yes"
                                                                                                    defaultMessage='Change'/>}
                                                                        icon='checkmark' 
                                                                        onClick={(event) =>this.updateOrderDate(event)}/>
                                                                    </Modal.Actions>
                                                            </Modal>                                                       
                                                </span>}</Header>
                                 </span>
                                 <br/>{/*  <br/> */}
                                 {order &&
                                 <span><Segment raised size='small' style={{  'max-width':'520px','max-height':'15px;',cursor:'pointer'}}
                                                                onClick={(event)=>this.noteClickHandler(event)}>
                                                        {orderNote.length == 0 && 
                                                                <FormattedMessage id="productiondetails.page.order.note.add"
                                                                                defaultMessage='Add a production note'/> 
                                                        }
                                                       
                                                        { orderNote.split('\n').map( line => 
                                                             <div  key={ Math.random() * 10}  dir="auto"  style={{textAlign: 'start'}}> {line} </div> 
                                                        )} 
                                                         <br/>
                                         </Segment>
                                         <Modal open={this.state.activeModalNote}
                                                                dimmer='default' 
                                                                size='tiny'
                                                                style={styles.Modal} 
                                                                onClose={this.hideModal}>
                                                                <Header content={<FormattedMessage id="productiondetails.order.modal.note.title"
                                                                                                                           defaultMessage='Update note'/>} />
                                                                <Modal.Content>
                                                                    <TextArea
                                                                        type="text"
                                                                        placeholder="Note"
                                                                        id="note"
                                                                        rows='4'
                                                                        maxlength="650"
                                                                        value={this.state.note}
                                                                        onChange={this.onNoteUpdate}
                                                                        style={{ minHeight: 160, minWidth:450,resize:'none'}}/>
                                                                </Modal.Content>
                                                                <Modal.Actions>
                                                                    <Button color='grey' onClick={this.hideModal}>
                                                                        <FormattedMessage id="productiondetails.order.modal.note.button.no"
                                                                                        defaultMessage='Cancel'/>
                                                                    </Button>
                                                                    <Button color='teal'
                                                                        labelPosition='right' 
                                                                        content={<FormattedMessage id="productiondetails.order.modal.note.button.yes"
                                                                                                    defaultMessage='Update'/>}
                                                                        icon='checkmark' 
                                                                        onClick={(event) =>this.updateOrder(event)}/>
                                                                    </Modal.Actions>
                                        </Modal> 
                                 </span>}
                                </Grid.Column>
                            </Grid.Row>
                            <br/>
                           <Grid.Row columns={1}/>
                            <Grid.Row columns={4}>
                                <Grid.Column mobile={0} tablet={0} computer={1}/>
                                <Grid.Column mobile={0} tablet={0} computer={4}/>
                                <Grid.Column mobile={12} tablet={16} computer={6}> 
                                 <div className={className}>
                                 <FormattedMessage id="productiondetails.floatingmenu.file.hideprices.option"
                                                     defaultMessage='Show costs'>
                                        { label => 
                                            <Checkbox label={label}
                                                      style={{margin:'0em 1em 0em 0em','font-size': '14px', fontWeight:'700'}} 
                                                      onChange={this.onCheckboxChange}
                                                      checked={isWithPrices}/>
                                        }
                                 </FormattedMessage>
                                 <Popup trigger=
                                            { <Button //color='blue'
                                                      style={styles.ButtonGreyAddNoMarginOrder}
                                                      //size='big' 
                                                      disabled = {loading||updating}
                                                      onClick={(e) => {this.openPlanning(e)}}>
                                                          <Icon name='calendar alternate outline' />{' '}
                                                          <FormattedMessage id="productiondetails.floatingmenu.planning.icon.title"
                                                                            defaultMessage='Planning'/>
                                               </Button>}
                                             content={<FormattedMessage id="productiondetails.floatingmenu.planning.title"
                                                                        defaultMessage='Open planning'/>}
                                            position='top center'
                                            inverted
                                    />
                                    <Popup trigger=
                                            { <Button  //color='blue'
                                                      style={styles.ButtonBlueAddNoMarginOrder}
                                                      //size='big' 
                                                      disabled = {loading||updating}
                                                      onClick={(e)=>this.toggleItemsMenu(e,'recipe')}>
                                                          <Icon name='add circle' />{' '}
                                                          <FormattedMessage id="productiondetails.floatingmenu.recipes.icon.title"
                                                                            defaultMessage='Recipes'/>
                                               </Button>}
                                             content={<FormattedMessage id="productiondetails.floatingmenu.recipes.title"
                                                                        defaultMessage='Add recipes'/>}
                                            position='top center'
                                            inverted
                                    />
                                 </div>
                                </Grid.Column>
                                <Grid.Column mobile={8} tablet={4} computer={2}/>
                            </Grid.Row>
                            {order && (!order.ingredients_added) && order.items && (order.items.length>0) && 
                            <Grid.Row columns={3} style={{'overflow-x': 'auto'}}>
                               <Grid.Column  mobile={18} tablet={16} computer={1}/> 
                                <Grid.Column  mobile={18} tablet={16} computer={10}> 
                                 {loading &&
                                        <Dimmer active inverted>
                                            <Loader inverted content='Loading' />
                                        </Dimmer> }
                                    <Table selectable 
                                           unstackable 
                                           compact 
                                           color={'blue'}
                                           style={styles.FontLato13Border}>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell width={4} textAlign='left'>
                                                    <FormattedMessage id="productiondetails.list.requested.table.column1"
                                                                     defaultMessage='Recipe quantity' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={5} textAlign='left'>
                                                    <FormattedMessage id="productiondetails.list.requested.table.column2"
                                                                     defaultMessage='Recipe Name' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={3} textAlign='left'>
                                                    <FormattedMessage id="productiondetails.list.requested.table.column3"
                                                                     defaultMessage='Recipe yield' />
                                                </Table.HeaderCell>
                                                {isWithPrices &&
                                                <Table.HeaderCell width={3} textAlign='left'>
                                                    <FormattedMessage id="productiondetails.list.requested.table.column4"
                                                                     defaultMessage='Unit cost' />
                                                </Table.HeaderCell>}
                                                <Table.HeaderCell width={3} textAlign='center'>
                                                    <span style={{marginLeft:'0.7em'}}><FormattedMessage id="productiondetails.list.requested.table.column6"
                                                                          defaultMessage='Expiration date'/></span>
                                                         <p style={{'color':'#b5b1b1','font-size':'12px',marginLeft:'2em'}}><i><FormattedMessage id="productiondetails.list.requested.table.column6b"
                                                                          defaultMessage='Batch number'/></i></p>
                                                    {/* <FormattedMessage id="productiondetails.list.requested.table.column6"
                                                                     defaultMessage='Expiration date, batch' /> */}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={1} textAlign='right'>
                                                    <FormattedMessage id="productiondetails.list.requested.table.column5"
                                                                     defaultMessage='Recipe form' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={1} textAlign='right'/>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                        {recipesData &&
                                            this.addOrderRows(recipesData)
                                        }                              
                                        </Table.Body>
                                    </Table>
                                    <br/>
                                </Grid.Column>
                                <Grid.Column  mobile={18} tablet={16} computer={2}/>          
                              </Grid.Row>} 
                            <Grid.Row columns={3}/>
                            <Grid.Row>
                                <Grid.Column  /*width={14}*/  mobile={18} tablet={16} computer={1}/> 
                                <Grid.Column  /*width={14}*/  mobile={18} tablet={16} computer={10}> 
                                 <Dimmer.Dimmable> 
                                        <Dimmer active={loading||updating||adding} blurring inverted>
                                           <Loader inverted content='Loading' />
                                        </Dimmer>
                                    <Table id='table'
                                           //selectable 
                                           unstackable 
                                           compact 
                                           //striped 
                                           singleLine 
                                           //color={'blue'}
                                           style={styles.FontLato13Border}>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell width={4} textAlign='left'>
                                                    <FormattedMessage id="productiondetails.shopping.list.requested.table.column1"
                                                                        defaultMessage='Ingredient' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={3} textAlign='center'>
                                                    <FormattedMessage id="productiondetails.shopping.list.requested.table.column5"
                                                                        defaultMessage='Supplier' />
                                                     <p style={{'color':'#b5b1b1','font-size':'12px',marginLeft:'2em'}}><i><FormattedMessage id="productiondetails.shopping.list.requested.table.column5b"
                                                                          defaultMessage='Expiration date | batch number'/></i></p>
                                                </Table.HeaderCell>
                                                {isWithPrices &&
                                                  <Table.HeaderCell width={2} textAlign='right'>
                                                    <FormattedMessage id="productiondetails.shopping.list.requested.table.column2"
                                                                     defaultMessage='Unit cost' />
                                                </Table.HeaderCell>}
                                                <Table.HeaderCell width={3} textAlign='right'>
                                                    <FormattedMessage id="productiondetails.shopping.list.requested.table.column3"
                                                                     defaultMessage='Amount' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={3} textAlign='right'>
                                                    <FormattedMessage id="productiondetails.shopping.list.requested.table.column6"
                                                                        defaultMessage='Stock Quantity' />
                                                </Table.HeaderCell>
                                                {isWithPrices &&
                                                <Table.HeaderCell width={2} textAlign='center'>
                                                    <FormattedMessage id="productiondetails.shopping.list.requested.table.column4"
                                                                        defaultMessage='Cost' />
                                                </Table.HeaderCell>}
                                               <Table.HeaderCell width={1} textAlign='right'/>
                                               {!isWithPrices &&
                                               <Table.HeaderCell width={1} textAlign='right'/>}
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                        {suppliers_lists &&
                                            this.addListRowsPerSupplier()
                                        }
                                        </Table.Body>
                                        {isWithPrices &&
                                        <Table.Footer>
                                            <Table.Row>
                                                <Table.HeaderCell width={3} textAlign='left'>
                                                    <Label basic style={styles.FontLato14Bold} size='large' color='grey'>
                                                        <FormattedMessage id="productiondetails.shopping.list.requested.table.text.total"
                                                                          defaultMessage='TOTAL' />
                                                    </Label>
                                                </Table.HeaderCell>
                                                <Table.HeaderCell/>
                                                <Table.HeaderCell width={3} textAlign='center'>
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={3} textAlign='center'>
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={3} textAlign='right'/>            
                                                <Table.HeaderCell width={3} textAlign='center'>
                                                    <Label basic style={styles.FontLato14Bold} size='large' color='blue'>
                                                    {ingredientsList &&
                                                                <FormattedNumber value={this.computeTotalCost(ingredientsList)} 
                                                                      style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>
                                                                }
                                                    </Label>
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={1} textAlign='right'/>
                                            </Table.Row>
                                        </Table.Footer>}
                                        {order &&
                                                <Modal open={this.state.activeModalDelete}
                                                    dimmer='true' 
                                                    size='tiny' 
                                                    onClose={this.hideModal}
                                                    style={styles.Modal}
                                                    closeIcon>
                                                    <Header icon='trash alternate outline' content={<FormattedMessage id="productiondetails.modal.deletion.title"
                                                                                                    defaultMessage='Deletion confirmation'/>} />
                                                    <Modal.Content>
                                                        <p><FormattedMessage id="productiondetails.modal.deletion.message"
                                                                            defaultMessage='Really sure to delete {name} ?'
                                                                            values={{name: order.ref_name}} /></p>
                                                    </Modal.Content>
                                                    <Modal.Actions>
                                                        <Button style={styles.ButtonGrey}//basic color='grey' 
                                                                onClick={this.hideModal}>
                                                                <FormattedMessage id="productiondetails.modal.deletion.button.no"
                                                                                defaultMessage='No'/>
                                                        </Button>
                                                        <Button style={styles.ButtonRed}//basic color='teal' 
                                                            labelPosition='right' 
                                                            content={<FormattedMessage id="productiondetails.modal.deletion.button.yes"
                                                                                        defaultMessage='Yes'/> }
                                                            icon='checkmark' 
                                                            onClick={(event) =>this.deleteOrder(event,order.id)}/>
                                                    </Modal.Actions>
                                                </Modal> }
                                                <Modal open={this.state.activeModalPremium}
                                                    dimmer='true' 
                                                    style={styles.Modal} 
                                                    onClose={this.hideModal}
                                                    closeIcon>
                                                <Modal.Header>
                                                    <FormattedMessage id="productiondetails.upgrade.header.title"
                                                                    defaultMessage='Upgrade necessary'/>
                                                </Modal.Header>
                                                <Modal.Content>
                                                    <Message fluid icon size='big' style={styles.FontLato12Bold} color='blue'>
                                                    <Image src={img_performance} size ='small' rounded/>
                                                    <br/>
                                                    <Message.Content style={{marginLeft:'1em'}}>
                                                        <Message.Header color='black'>
                                                            <FormattedMessage id="productiondetails.premium.message.upgradeplan"
                                                                                defaultMessage='PREMIUM Feature'/>
                                                        </Message.Header>
                                                        <br/>
                                                        <FormattedMessage id="productiondetails.premium.title.message.upgradeplan"
                                                                        defaultMessage='Your current plan: '/>{' '}{membership && membership.name}   
                                                        <br/><br/>
                                                        <Button basic color='blue' style={styles.FontLato12Bold} onClick={(e) => this.upgradeSubscription()}>
                                                            <FormattedMessage id="productiondetails.premium.button.chooseplan"
                                                                        defaultMessage='Choose premium plan'/>
                                                        </Button>                                      
                                                    </Message.Content>
                                                </Message>
                                                </Modal.Content>
                                                <Modal.Actions>
                                                    <Button basic color='grey' onClick= {(e) => this.hideModal()}>
                                                    {/* <Icon name='exit' /> */}
                                                    <FormattedMessage id="productiondetails.premium.button.cancel"
                                                                    defaultMessage='Cancel'/>
                                                    </Button>
                                                    <Button color='blue' onClick= {(e) => this.upgradeSubscription()}>
                                                    <FormattedMessage id="productiondetails.premium.button.chooseplan"
                                                                        defaultMessage='Choose premium plan'/>
                                                    </Button>
                                                </Modal.Actions>
                                            </Modal>
                                    </Table>
                                    </Dimmer.Dimmable>
                                </Grid.Column>
                                <Grid.Column  /*width={14}*/  mobile={18} tablet={16} computer={2}/> 
                            </Grid.Row>
{/*                             <Grid.Row columns={1}/>*/}
                            <Grid.Row columns={1}/> 
                             <Grid.Row columns={1}  style={{paddingLeft:'3em'}}>
                               {!loading && <Grid.Column computer={14} tablet={16} 
                                           // style={{'text-align':'center','justify-content': 'center',margin: '0 auto;',display: 'flex;'}} 
                                            only='computer tablet'>
                                              {order && (!order.state || order.state=='ongoing') && (order.use_ingredients == true) &&
                                              <Dropdown button 
                                                        className= 'icon'
                                                        icon = 'ellipsis vertical'
                                                        floating
                                                        labeled
                                                        text={<FormattedMessage id="productiondetails.shopping.list.requested.table.button.actions.stock"
                                                                                defaultMessage='Inventory Actions' />}
                                                        basic
                                                        style={styles.FontLato14BoldPadded}>
                                                    <Dropdown.Menu>
                                                       <Dropdown.Item
                                                              icon = 'warehouse'
                                                              disable = {true}
                                                              onClick={(event) => this.stockRecipesClickHandler(event)}
                                                              text = {<FormattedMessage id="productiondetails.shopping.list.requested.table.button.stock.recipes"
                                                                                        defaultMessage='Stock your production recipes' />}
                                                        >
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                              icon = 'boxes'
                                                              onClick={(event) => this.destockClickHandler(event)}
                                                              text = {<FormattedMessage id="productiondetails.shopping.list.requested.table.button.destock"
                                                                                    defaultMessage='Destock ingredients only' />}
                                                        >
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                              icon = 'boxes'
                                                              onClick={(event) => this.destockRecipesClickHandler(event)}
                                                              text = {<FormattedMessage id="productiondetails.shopping.list.requested.table.button.destock.recipes"
                                                                                    defaultMessage='Destock recipes only' />}
                                                        >
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                                icon ='box'
                                                                onClick={(event) => this.reduceStockClickHandler(event)}
                                                                text={<FormattedMessage id="productiondetails.shopping.list.requested.table.button.deduce"
                                                                                        defaultMessage='Reduce ingredients already existing in stock' />}
                                                        >
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                              </Dropdown>}
                                             {order && (!order.state || order.state=='ongoing') && (order.use_ingredients == true) && /* numberSuppliers>1 && */
                                               <Button basic
                                                            icon 
                                                            labelPosition='left' 
                                                            color='white' 
                                                            size='medium'
                                                            onClick={(event) => this.createSuppliersOrders(event)}
                                                            style={styles.FontLato14BoldPadded}><Icon name='shipping fast'/> 
                                                             <FormattedMessage id="productiondetails.shopping.list.requested.table.button.convertSuppliers"
                                                                               defaultMessage='Create {number} supplier(s) order(s)'
                                                                               values={{number: numberSuppliers}} />
                                             </Button>}
                                                <Dropdown button 
                                                        className= 'icon'
                                                        icon = 'ellipsis vertical'
                                                        floating
                                                        labeled
                                                        text={<FormattedMessage id="productiondetails.shopping.list.requested.table.button.print"
                                                                                defaultMessage='Export' />}
                                                        basic
                                                        style={styles.FontLato14BoldPadded}>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item
                                                                icon ='file pdf'
                                                                onClick={(event)=>this.generateDocBackend(event,true)}
                                                                text={<FormattedMessage id="productiondetails.shopping.list.requested.table.button.exportpdf"
                                                                                        defaultMessage='PDF File' />}
                                                        >
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                              icon = 'file excel'
                                                              onClick={(event)=>this.generateDocBackend(event,false)}
                                                              text = {<FormattedMessage id="productiondetails.shopping.list.requested.table.button.exportcsv"
                                                                                    defaultMessage='CSV File' />}
                                                        >
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <Button basic 
                                                                icon 
                                                                color='white' 
                                                                labelPosition='left'
                                                                size='medium'
                                                                style={styles.FontLato14BoldPadded}
                                                                onClick={(event) => this.deleteClickHandler(event)}>
                                                                <Icon name='trash alternate outline icon'/>
                                                                <FormattedMessage id="productiondetails.list.items.button.delete"
                                                                             defaultMessage='Delete' />
                                                </Button>
                                               <br/>
                                    </Grid.Column>} 
                                {!loading && order && (!order.state || order.state=='ongoing') && (order.use_ingredients == true) &&
                                   <Grid.Column  mobile={18} only='mobile' style={{'text-align':'center','justify-content': 'center',margin: '0 auto;',display: 'flex;'}} >
                                              <Dropdown button 
                                                        className= 'icon'
                                                        icon = 'ellipsis vertical'
                                                        floating
                                                        labeled
                                                        text='Actions Stock'
                                                        basic
                                                        style={styles.FontLato14BoldPadded}>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item
                                                              icon = 'boxes'
                                                              disable = {true}
                                                              onClick={(event) => this.stockRecipesClickHandler(event)}
                                                              text = {<FormattedMessage id="productiondetails.shopping.list.requested.table.button.stock.recipes"
                                                                                        defaultMessage='Stock your production recipes' />}
                                                        >
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                              icon = 'boxes'
                                                              onClick={(event) => this.destockClickHandler(event)}
                                                              text = {<FormattedMessage id="productiondetails.shopping.list.requested.table.button.destock"
                                                                                    defaultMessage='Destock ingredients only' />}
                                                        >
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                              icon = 'boxes'
                                                              disable = {true}
                                                              onClick={(event) => this.destockRecipesClickHandler(event)}
                                                              text = {<FormattedMessage id="productiondetails.shopping.list.requested.table.button.destock.recipes"
                                                                                        defaultMessage='Destock recipes only' />}
                                                        >
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                                icon ='box'
                                                                onClick={(event) => this.reduceStockClickHandler(event)}
                                                                text={<FormattedMessage id="productiondetails.shopping.list.requested.table.button.deduce"
                                                                                        defaultMessage='Reduce ingredients already existing in stock' />}
                                                        >
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                              </Dropdown>
                              </Grid.Column>}
                               {!loading && order && (!order.state || order.state=='ongoing') && (order.use_ingredients == true) && /* numberSuppliers > 1 && */
                              <Grid.Column  mobile={18} only='mobile' style={{'text-align':'center','justify-content': 'center',margin: '0 auto;',display: 'flex;'}} >
                                              <Button basic
                                                            icon 
                                                            labelPosition='left' 
                                                            color='white' 
                                                            size='medium'
                                                            onClick={(event) => this.createSuppliersOrders(event)}
                                                            style={styles.FontLato14BoldPadded}><Icon name='shipping fast'/> 
                                                             <FormattedMessage id="productiondetails.shopping.list.requested.table.button.convertSuppliers"
                                                                               defaultMessage='Create {number} supplier(s) order(s)'
                                                                               values={{number: numberSuppliers}} />
                                             </Button>
                              </Grid.Column>}
                              {!loading && <Grid.Column  mobile={18} only='mobile' style={{'text-align':'center','justify-content': 'center',margin: '0 auto;',display: 'flex;'}} >
                                                <Dropdown button 
                                                        className= 'icon'
                                                        icon = 'ellipsis vertical'
                                                        floating
                                                        labeled
                                                        text={<FormattedMessage id="productiondetails.shopping.list.requested.table.button.print"
                                                                                defaultMessage='Export' />}
                                                        basic
                                                        style={styles.FontLato14BoldPadded}>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item
                                                                icon ='file pdf'
                                                                onClick={(event)=>this.generateDocBackend(event,true)}
                                                                text={<FormattedMessage id="productiondetails.shopping.list.requested.table.button.exportpdf"
                                                                                        defaultMessage='PDF File' />}
                                                        >
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                              icon = 'file excel'
                                                              onClick={(event)=>this.generateDocBackend(event,false)}
                                                              text = {<FormattedMessage id="productiondetails.shopping.list.requested.table.button.exportcsv"
                                                                                    defaultMessage='CSV File' />}
                                                        >
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                              </Dropdown>
                              </Grid.Column>}
                              {order && <Modal open={this.state.activeModalReduceStockAvailable}
                                                    dimmer='true' 
                                                    size='small' 
                                                    onClose={this.hideModal}
                                                    style={styles.Modal}
                                                    closeIcon>
                                                    <Header icon='box' color='blue' content={<FormattedMessage id="productiondetails.modal.reducestock.title"
                                                                                                    defaultMessage='Your production quantities are going to be reduced from inventory existing quantities'/>} />
                                                    <Modal.Content>
                                                        <p style={{paddingLeft:'0.5em', color:'black',  'font-size': '16px', 'font-family': 'Lato' }}><FormattedMessage id="productiondetails.modal.reducestock.message"
                                                                            defaultMessage='Really sure to decrease stock good available quantities from {name} ?'
                                                                            values={{name: order.ref_name}} /></p>
                                                    </Modal.Content>
                                                    <Modal.Actions>
                                                        <Button style={styles.ButtonGrey}//basic color='grey' 
                                                                onClick={this.hideModal}>
                                                                <FormattedMessage id="productiondetails.modal.reducestock.button.no"
                                                                                defaultMessage='Cancel'/>
                                                        </Button>
                                                        <Button style={styles.ButtonBlue}//basic color='teal'
                                                                labelPosition='right' 
                                                                loading = {updating}
                                                                content={<FormattedMessage id="productiondetails.modal.reducestock.button.yes"
                                                                                          defaultMessage='Proceed'/> }
                                                            icon='checkmark' 
                                                            onClick={this.reduceStockQuantity}/>
                                                    </Modal.Actions>
                                                </Modal> }
                                {order && <Modal open={this.state.activeModalDestock}
                                                    dimmer='true' 
                                                    size='small' 
                                                    onClose={this.hideModal}
                                                    style={styles.Modal}
                                                    closeIcon>
                                                    <Header icon='boxes' color='blue' content={<FormattedMessage id="productiondetails.modal.destock.title"
                                                                                                    defaultMessage='Your production ingredients quantities are going to be removed from inventory'/>} />
                                                    <Modal.Content>
                                                        <p style={{paddingLeft:'0.5em', color:'black',  'font-size': '16px', 'font-family': 'Lato' }}><FormattedMessage id="productiondetails.modal.destock.message"
                                                                            defaultMessage='Really sure to destock ingredients quantities from {name} ?'
                                                                            values={{name: order.ref_name}} /></p>
                                                    </Modal.Content>
                                                    <Modal.Actions>
                                                        <Button style={styles.ButtonGrey}//basic color='grey' 
                                                                onClick={this.hideModal}>
                                                                <FormattedMessage id="productiondetails.modal.destock.button.no"
                                                                                defaultMessage='Cancel'/>
                                                        </Button>
                                                        <Button style={styles.ButtonBlue}//basic color='teal'
                                                                labelPosition='right' 
                                                                loading = {updating}
                                                                content={<FormattedMessage id="productiondetails.modal.destock.button.yes"
                                                                                          defaultMessage='Proceed'/> }
                                                            icon='checkmark' 
                                                            onClick={this.destockQuantity}/>
                                                    </Modal.Actions>
                              </Modal> }
                              {order && <Modal open={this.state.activeModalDestockRecipes}
                                                    dimmer='true' 
                                                    size='small' 
                                                    onClose={this.hideModal}
                                                    style={styles.Modal}
                                                    closeIcon>
                                                    <Header icon='boxes' color='blue' content={<FormattedMessage id="productiondetails.modal.destock.recipes.title"
                                                                                                    defaultMessage='Your production recipes quantities are going to be removed from inventory'/>} />
                                                    <Modal.Content>
                                                        <p style={{paddingLeft:'0.5em', color:'black',  'font-size': '16px', 'font-family': 'Lato' }}><FormattedMessage id="productiondetails.modal.destock.recipes.message"
                                                                            defaultMessage='Really sure to destock recipes quantities from {name} ?'
                                                                            values={{name: order.ref_name}} /></p>
                                                    </Modal.Content>
                                                    <Modal.Actions>
                                                        <Button style={styles.ButtonGrey}//basic color='grey' 
                                                                onClick={this.hideModal}>
                                                                <FormattedMessage id="productiondetails.modal.destock.button.no"
                                                                                defaultMessage='Cancel'/>
                                                        </Button>
                                                        <Button style={styles.ButtonBlue}//basic color='teal'
                                                                labelPosition='right' 
                                                                loading = {updating}
                                                                content={<FormattedMessage id="productiondetails.modal.destock.button.yes"
                                                                                          defaultMessage='Proceed'/> }
                                                            icon='checkmark' 
                                                            onClick={this.destockQuantityRecipes}/>
                                                    </Modal.Actions>
                              </Modal> }
                              {order && <Modal open={this.state.activeModalStockRecipes}
                                                    dimmer='true' 
                                                    size='small' 
                                                    onClose={this.hideModal}
                                                    style={styles.Modal}
                                                    closeIcon>
                                                    <Header icon='warehouse' color='blue' content={<FormattedMessage id="productiondetails.modal.stock.recipes.title"
                                                                                                    defaultMessage='Your production recipes quantities are going to be added to your inventory'/>} />
                                                    <Modal.Content>
                                                        <p style={{paddingLeft:'0.5em', color:'black',  'font-size': '16px', 'font-family': 'Lato' }}><FormattedMessage id="productiondetails.modal.stock.recipes.message"
                                                                            defaultMessage='Really sure to stock recipes quantities from {name} ?'
                                                                            values={{name: order.ref_name}} /></p>
                                                    </Modal.Content>
                                                    <Modal.Actions>
                                                        <Button style={styles.ButtonGrey}//basic color='grey' 
                                                                onClick={this.hideModal}>
                                                                <FormattedMessage id="productiondetails.modal.destock.button.no"
                                                                                defaultMessage='Cancel'/>
                                                        </Button>
                                                        <Button style={styles.ButtonBlue}//basic color='teal'
                                                                labelPosition='right' 
                                                                loading = {updating}
                                                                content={<FormattedMessage id="productiondetails.modal.destock.button.yes"
                                                                                          defaultMessage='Proceed'/> }
                                                            icon='checkmark' 
                                                            onClick={this.stockQuantityRecipes}/>
                                                    </Modal.Actions>
                              </Modal> }
                              {!loading && <Grid.Column  mobile={18} only='mobile' style={{'text-align':'center','justify-content': 'center',margin: '0 auto;',display: 'flex;'}} >
                                                <Button basic 
                                                                icon 
                                                                color='white' 
                                                                labelPosition='left'
                                                                size='medium'
                                                                style={styles.FontLato14BoldPadded}
                                                                onClick={(event) => this.deleteClickHandler(event)}>
                                                                <Icon name='trash alternate outline icon'/>
                                                                <FormattedMessage id="productiondetails.list.items.button.delete"
                                                                             defaultMessage='Delete' />
                                                </Button>
                                                <br/> 
                                </Grid.Column> }                                               
                            </Grid.Row>
                            <Grid.Row columns={1}/> 
                        </Grid>
                     </SideLeftUncoverProductions>
                </div>
        )
    }
}

function mapStateToProps(state) {
    const { alert,dispatch,recipes,ingredients,supplier,categoriesRecipe } = state;
    const { loading,updating,adding,order,loading_r } = state.recipes;
    const { subscription,customer } = state.payment;

    return {
        loading,
        loading_r,
        adding,
        updating,
        recipes,
        categoriesRecipe,
        ingredients,
        supplier,
        order,
        customer,
        alert,
        dispatch,
        subscription,
    };
}

const connectedProductionDetails = injectIntl(connect(mapStateToProps)(ProductionDetails));
export { connectedProductionDetails as ProductionDetails };
 
