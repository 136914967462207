import React, { Component } from 'react';
import { Table,Grid,Button,Header,Modal,Form,Popup,Dimmer,Loader,Icon} from 'semantic-ui-react'
import { connect } from 'react-redux';
//import { Link } from 'react-router-dom';
import SidebarProfile from './SidebarProfile';
import { clientActions } from '../_actions';
import {toaster_dispatch,trim,isNumeric} from '../_helpers';
import {FormattedMessage,injectIntl} from 'react-intl';
import {styles} from './Styles';
import img_suppliers from '../Assets/cargo-truck.png'
import PhoneInput from 'react-phone-number-input'
import { isValidPhoneNumber,isPossiblePhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

class Clients extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            company_name: "",
            phone: "",
            address: "",
            zipcode: "",
            city: "",
            country: "",
            state: "",
            email:"",
            note:"",
            activeModalDelete: null,
            activeModalEdit: null,
            activeModalCreate: null,
            submitted: false,
        }
        this.hideModal = this.hideModal.bind(this);
        props.dispatch(clientActions.getAll());
    }

    OnNameChange = (e) => {
         this.setState({ name: e.target.value });
    }
    OnCompanyNameChange = (e) => {
         this.setState({ company_name: e.target.value });
    }

    OnEmailChange = (e) => {
         this.setState({ email: e.target.value });
    }

    OnPhoneChange = (number) => {
        if (number == undefined){
            number = ''
        }

        this.setState({ phone: number });
    }
    OnAdressChange = (e) => {
        this.setState({ address: e.target.value });
    }

    OnCityChange = (e) => {
        this.setState({ city: e.target.value });
    }

    OnCountryChange = (e) => {
       this.setState({ country: e.target.value });
    }

    OnZipcodeChange = (e) => {
       this.setState({ zipcode: e.target.value });
    } 

   OnStateChange = (e) => {
       this.setState({ state: e.target.value });
   }

   OnNoteChange = (e) => {
    this.setState({ note: e.target.value });
   }



    addClient = (e) => {
        e.preventDefault();
        const { name,company_name,address,email,zipcode,city,
            country,state,phone,note} = this.state;
        
        this.setState({ submitted: true });


        if (name && name !='' && this.handleValidation()){
            const client_infos = {
                         name:trim(name),
                         company_name: trim(company_name),
                         phone:phone,
                         address: address,
                         zipcode: zipcode,
                         city: city,
                         country: country,
                         state: state,
                         email:email,
                         note:note,
            }
            this.props.dispatch(clientActions.add(client_infos,'/clients/'));
            this.setState({ submitted: false });
        }
    }
    
    editClient (event,index) {
        event.preventDefault();
        const { name,company_name,address,email,zipcode,city,
            country,state,phone,note} = this.state;

        this.setState({ submitted: true });

        if (name && name !='' && this.handleValidation()) {
            const client_infos = {
                         name:trim(name),
                         company_name: trim(company_name),
                         phone:phone,
                         address: address,
                         zipcode: zipcode,
                         city: city,
                         country: country,
                         state: state,
                         email:email,
                         note:note,
            }
            this.props.dispatch(clientActions.update(index,client_infos));
            this.setState({ submitted: false });
        }
    }

    deleteClient (event,index) {
        event.preventDefault();
        // -- Implementation with modals for deletion
        this.props.dispatch(clientActions.delete(index))
        
        // -- Implementation with new page for deletion
        //history.push('/deleterecipe/'+index)
    }

    deleteClickHandler (event,index) {
        event.preventDefault();
        this.setState({ activeModalDelete: index});
    };

    editClickHandler (event,index,client) {
        event.preventDefault();
        this.setState({ activeModalEdit: index});
        this.setState({ name: client.name });
        this.setState({ company_name : client.company_name });
        this.setState({ contact: client.contact });
        this.setState({ phone: client.phone });
        this.setState({ address: client.address });
        this.setState({ zipcode: client.zipcode });
        this.setState({ state: client.state });
        this.setState({ email: client.email });
        this.setState({ city: client.city });
        this.setState({ country: client.country });
        this.setState({ note: client.note });
    };

    hideModal() {
        this.setState({ activeModalDelete: null });
        this.setState({ activeModalEdit: null });
        this.setState({ activeModalCreate: null });
        this.setState({ submitted: false });
        this.setState({ name: '' });
        this.setState({ company_name: '' });
        this.setState({ adress: '' });
        this.setState({ zipcode: '' });
        this.setState({ state: '' });
        this.setState({ email: ''});
        this.setState({ phone: ''});
        this.setState({ city: '' });
        this.setState({ country: '' });
        this.setState({ note: '' });
    }

    toaster(dispatch,alert){
        const {intl} = this.props;
        this.hideModal();
        toaster_dispatch(dispatch,alert,intl);
    }


    handleNameValidation(){
        const { name } = this.state;
        if(name == ""){
            return false
        }
        return true
    }

    handleValidation(){
        const { email,zipcode,phone } = this.state;

        if (!this.handleNameValidation()){ 
            return false;
        }
/*         if (!isNumeric(zipcode)){
            return false;
        } */
        if (!this.handleEmailValidation(email)){ 
            return false;
        }

        if (!this.handlePhoneValidation(phone)){ 
            return false;
        }

        return true;
    }

    handleZipCodeValidation(zipcode){
/*         if(zipcode !== ""){
            if (!isNumeric(zipcode)){
                return false;
            }
        } */
        return true
    }

    handlePhoneValidation(number){
        
        if(number && number !== ""){
            return isPossiblePhoneNumber(number) //&& isValidPhoneNumber(number)
        }

        return true;
    }

    handleEmailValidation(email){
/*         if(!email){
            return false;
        } */

        if(email !== ""){
            let lastAtPos = email.lastIndexOf('@');
            let lastDotPos = email.lastIndexOf('.');
      
            if (!(lastAtPos < lastDotPos 
                && lastAtPos > 0 
                && email.indexOf('@@') == -1 
                && lastDotPos > 2 
                && (email.length - lastDotPos) > 2)) {
              return false;
            }
        }
        return true;
    }
    
    createClient(event){
        event.preventDefault();
        this.setState({activeModalCreate: true});
    } 

    addClientRows(clients){
        const { name,email,company_name,address,city,country,state,zipcode,phone,note,submitted} = this.state;
        const { intl } = this.props;
        return clients.items.map((client,index) => {
                return (
                    <Table.Row key={index} >
                        <Table.Cell textAlign='left'>
                            <a href="#" 
                               onClick={(event)=>this.editClickHandler(event,index,client)}>{client.name}
                            </a>
                        </Table.Cell>
                        <Table.Cell textAlign='left'>{client.company_name}</Table.Cell>
                        <Table.Cell textAlign='left'>{client.email}</Table.Cell>
                        <Table.Cell textAlign='right'>{client.phone}</Table.Cell>
                        <Table.Cell textAlign='right'>
                            <Icon name="edit outline" size='large' color='blue' style={{cursor:'pointer'}}
                                       onClick={(event)=>this.editClickHandler(event,index,client)}/>&nbsp;
                            <Icon name="trash alternate outline" size='large' color='grey' style={{cursor:'pointer'}}
                                        onClick={(event) => this.deleteClickHandler(event,index)}/>
                            <Modal id={index}
                                   open={this.state.activeModalEdit === index}
                                   dimmer='default' 
                                   size='small'
                                   style={styles.Modal} 
                                   onClose={this.hideModal}>
                                <Header icon='users' content={<FormattedMessage id="clients.modal.rename.title"
                                                                                defaultMessage='Edit client'/>} />
                                <Modal.Content>
                                          <Form style={{paddingLeft:'0em'}}>
                                                <Form.Field width='six'> 
                                                    <label style={styles.LabelGrey}><FormattedMessage id="clients.modal.form.input.name"
                                                                              defaultMessage='Name'/></label>
                                                    <FormattedMessage id="clients.modal.form.input.name.placeholder"
                                                                    defaultMessage='name'>
                                                        {placeholder =>                                                                                
                                                                    <Form.Input type="text" 
                                                                        placeholder={placeholder}
                                                                        id="name" 
                                                                        icon='user add'
                                                                        iconPosition='left'
                                                                        value={name}
                                                                        onChange={this.OnNameChange}
                                                                        error= {submitted && !this.handleNameValidation()}
                                                                        />}
                                                    </FormattedMessage>
                                                </Form.Field>
                                                <Form.Group widths='equal'>
                                                    <Form.Field >
                                                        <label style={styles.LabelGrey}><FormattedMessage id="clients.modal.form.input.companyname"
                                                                                defaultMessage='Company'/></label>
                                                    <FormattedMessage id="clients.modal.form.input.companyname.placeholder"
                                                                        defaultMessage='Company'>
                                                            {placeholder =>                                                                                       
                                                                    <Form.Input type="text" 
                                                                        fluid
                                                                        placeholder={placeholder}
                                                                        id="company_type"
                                                                        icon='building'
                                                                        iconPosition='left' 
                                                                        value={company_name}
                                                                        onChange={this.OnCompanyNameChange}/>}
                                                        </FormattedMessage>
                                                    </Form.Field>
                                                    <Form.Field >
                                                        <label style={styles.LabelGrey}><FormattedMessage id="clients.modal.form.input.email"
                                                                                defaultMessage='Email'/></label>
                                                    <FormattedMessage id="clients.modal.form.input.email.placeholder"
                                                                        defaultMessage='Email'>
                                                            {placeholder =>                                                                                       
                                                                    <Form.Input  
                                                                        fluid
                                                                        placeholder={placeholder}
                                                                        icon='mail'
                                                                        iconPosition='left'
                                                                        type="email"
                                                                        id="email" 
                                                                        value={email}
                                                                        onChange={this.OnEmailChange}
                                                                        error={submitted && !this.handleEmailValidation(email)}/>}
                                                        </FormattedMessage>
{/*                                                         {submitted && !this.handleEmailValidation(email)  &&
                                                            <label style={{color:'red', }}><i>
                                                                <FormattedMessage id="suppliers.modal.form.input.email.error.message"
                                                                                    defaultMessage='email is used to send your orders'/>
                                                            </i></label> 
                                                        } */}
                                                       </Form.Field>
                                                </Form.Group>
                                                <br/>
                                                <Form.Field>
                                                    <label style={styles.LabelGrey}><FormattedMessage id="clients.modal.form.input.address"
                                                                              defaultMessage='Billing Address'/></label>
                                                   <FormattedMessage id="clients.modal.form.input.address.placeholder"
                                                                    defaultMessage='address'>
                                                        {placeholder =>          
                                                                <Form.Input type="text" 
                                                                    placeholder={placeholder}
                                                                    id="address" 
                                                                    icon='address card'
                                                                    iconPosition='left'
                                                                    value={address}
                                                                    onChange={this.OnAdressChange}/>}
                                                    </FormattedMessage>
                                                </Form.Field>
                                                <Form.Group widths='equal'>
                                                    <Form.Field>
                                                        <label style={styles.LabelGrey}><FormattedMessage id="clients.modal.form.input.city"
                                                                                defaultMessage='City'/></label>
                                                    <FormattedMessage id="clients.modal.form.input.city.placeholder"
                                                                        defaultMessage='city'>
                                                            {placeholder =>          
                                                                    <Form.Input type="text" 
                                                                        fluid
                                                                        placeholder={placeholder}
                                                                        id="city" 
                                                                        icon='map'
                                                                        iconPosition='left'
                                                                        value={city}
                                                                        onChange={this.OnCityChange}/>}
                                                        </FormattedMessage>
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <label style={styles.LabelGrey}><FormattedMessage id="clients.modal.form.input.zipcode"
                                                                                defaultMessage='Zipcode'/></label>
                                                    <FormattedMessage id="clients.modal.form.input.zipcode.placeholder"
                                                                        defaultMessage='zipcode'>
                                                            {placeholder =>          
                                                                    <Form.Input type="text" 
                                                                        fluid
                                                                        placeholder={placeholder}
                                                                        id="zipcode" 
                                                                        icon='map'
                                                                        iconPosition='left'
                                                                        value={zipcode}
                                                                        onChange={this.OnZipcodeChange}
                                                                        error={submitted && !this.handleZipCodeValidation(zipcode)}/>}
                                                        </FormattedMessage>
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <label style={styles.LabelGrey}><FormattedMessage id="clients.modal.form.input.state"
                                                                                defaultMessage='State'/></label>
                                                    <FormattedMessage id="clients.modal.form.input.state.placeholder"
                                                                        defaultMessage='state'>
                                                            {placeholder =>          
                                                                    <Form.Input type="text" 
                                                                        fluid
                                                                        placeholder={placeholder}
                                                                        id="state" 
                                                                        icon='map'
                                                                        iconPosition='left'
                                                                        value={state}
                                                                        onChange={this.OnStateChange}/>}
                                                        </FormattedMessage>
                                                    </Form.Field>
                                                </Form.Group>
                                                <Form.Group widths='equal'>
                                                <Form.Field >
                                                    <label style={styles.LabelGrey}><FormattedMessage id="clients.modal.form.input.country"
                                                                              defaultMessage='Country'/></label>
                                                   <FormattedMessage id="clients.modal.form.input.country.placeholder"
                                                                    defaultMessage='country'>
                                                        {placeholder =>          
                                                                <Form.Input type="text" 
                                                                    placeholder={placeholder}
                                                                    id="country" 
                                                                    icon='world'
                                                                    iconPosition='left'
                                                                    value={country}
                                                                    onChange={this.OnCountryChange}/>}
                                                    </FormattedMessage>
                                                </Form.Field>
                                                <Form.Field >
                                                    <label style={styles.LabelGrey}><FormattedMessage id="clients.modal.form.input.phone"
                                                                              defaultMessage='Phone'/></label>
                                                    <PhoneInput
                                                        defaultCountry={intl.locale =='fr'?'FR':'US'}
                                                        international
                                                        //placeholder='' 
                                                        value={phone}
                                                        onChange={this.OnPhoneChange}
                                                    />
                                                   {submitted && !this.handlePhoneValidation(phone) &&
                                                    <label style={{color:'red',paddingLeft:'2em'}}><i>
                                                        <FormattedMessage id="clients.modal.form.input.phone.error.message"
                                                                          defaultMessage='Invalid phone number'/> 
                                                        </i></label>
                                                    }
                                                </Form.Field>
                                                </Form.Group>
                                                <Form.Group widths='equal'>
                                                <Form.Field >
                                                    <label style={styles.LabelGrey}><FormattedMessage id="clients.modal.form.input.note"
                                                                              defaultMessage='Technical note'/></label>
                                                   <FormattedMessage id="clients.modal.form.input.note.placeholder"
                                                                    defaultMessage='note (website, comment ...)'>
                                                        {placeholder =>          
                                                                <Form.TextArea type="text" 
                                                                    placeholder={placeholder}
                                                                    id="note" 
                                                                    icon='text'
                                                                    iconPosition='left'
                                                                    value={note}
                                                                    rows='1'
                                                                    maxlength="300"
                                                                    onChange={this.OnNoteChange}/>}
                                                    </FormattedMessage>
                                                </Form.Field>
                                                </Form.Group>
                                                <br/>
                                         </Form>
                                </Modal.Content>
                                <Modal.Actions>
                                    <br/>
                                    <Button basic color='grey' onClick={this.hideModal}>
                                        <FormattedMessage id="clients.modal.update.button.cancel"
                                                          defaultMessage='Cancel'/>
                                    </Button>
                                    <Button color='teal' 
                                        labelPosition='right' 
                                        content={<FormattedMessage id="clients.modal.rename.button.save"
                                                                    defaultMessage='Save'/>}
                                        icon='checkmark' 
                                        onClick={(event) =>this.editClient(event,client.id)}/>
                                      <br/>
                                 </Modal.Actions>   
                            </Modal> 
                             <Modal id={index}
                                   open={this.state.activeModalDelete === index}
                                   dimmer='default' 
                                   size='tiny' 
                                   style={styles.Modal} 
                                   onClose={this.hideModal}>
                                <Header icon='trash alternate outline' content={<FormattedMessage id="clients.modal.delete.title"
                                                                                defaultMessage='Deletion confirmation'/>} />
                                <Modal.Content>
                                    <p>
                                        <FormattedMessage id="clients.modal.delete.message"
                                                         defaultMessage='Really sure to delete "{name}" ?'
                                                         values={{name:client.name}}/>
                                    </p>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button basic color='grey' onClick={this.hideModal}>
                                        <FormattedMessage id="clients.modal.delete.button.no"
                                                          defaultMessage='No'/>
                                    </Button>
                                    <Button color='red'  
                                        labelPosition='right' 
                                        content={<FormattedMessage id="clients.modal.delete.button.yes"
                                                                    defaultMessage='Yes'/>} 
                                        icon='checkmark' 
                                        onClick={(event) =>this.deleteClient(event,client.id)}/>
                                    </Modal.Actions>
                            </Modal> 
                        </Table.Cell>
                     </Table.Row>
                );
        });
    }


    render() { 
        const { client,alert,dispatch,loading_s,intl } = this.props;
        const { name,email,company_name,address,city,country,state,zipcode,phone,note,submitted} = this.state;
        let className = "floating-menu";

        return ( 
            <div>
                <SidebarProfile>
                <Grid stackable style={styles.FontLato12}>
                        <Grid.Row columns={1}>
                                <Grid.Column width={6}>
                                    <Header as='h2'>
                                        <img src={img_suppliers} style={{width:'45px'}}/>
                                        <Header.Content>
                                            <FormattedMessage id="clients.page.title"
                                                              defaultMessage='Clients/Leads'/>
                                            <Header.Subheader><FormattedMessage id="clients.page.subtitle"
                                                              defaultMessage='Manage your customers'/></Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                </Grid.Column >
                        </Grid.Row> 
                    {/*{alert.message && 
                       <div className={`alert ${alert.type}`}>{alert.message}</div> */}
                    {alert.message && this.toaster(dispatch,alert)}
                    <Grid.Row columns={1}/>
                    <Grid.Row columns={1}/>
                    <Grid.Row columns={1}>
                                <Grid.Column mobile={18} tablet={16} computer={12}> 
                                    <div className={className}>
                                            <Popup trigger=
                                                    { <Button circular 
                                                            color='teal'
                                                            size='big' 
                                                            icon='add' 
                                                            onClick={(event)=>this.createClient(event)}
                                                            ></Button>}
                                                    content={<FormattedMessage id="clients.floatingmenu.item1.title"
                                                                                defaultMessage='Add contact/client'/>}
                                                    position='left center'
                                                    inverted
                                            />
                                    </div>
                                </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1} style={{marginTop:'0em',paddingTop:'0em'}}>
                        <Grid.Column mobile={18} tablet={16} computer={12}> 
                          <Dimmer.Dimmable>
                                        <Dimmer active={((loading_s))} blurring inverted>
                                           <Loader inverted content='Loading' />
                                        </Dimmer> 
                            <Table unstackable
                                   compact 
                                   striped 
                                   singleLine 
                                   style={styles.FontLato14Border}>
                                <Table.Header >
                                    <Table.Row>
                                        <Table.HeaderCell width={5} textAlign='left'>
                                             <FormattedMessage id="clients.table.row.name"
                                                              defaultMessage='Name' /> 
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width={3} textAlign='left'>
                                             <FormattedMessage id="clients.table.row.company_type"
                                                              defaultMessage='Company' /> 
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width={3} textAlign='left'>
                                             <FormattedMessage id="clients.table.row.company_email"
                                                              defaultMessage='Email' /> 
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width={2} textAlign='right'>
                                             <FormattedMessage id="clients.table.row.company_phone"
                                                              defaultMessage='Phone' /> 
                                        </Table.HeaderCell>

                                        <Table.HeaderCell textAlign='right'></Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                {client && client.items &&(client.items.length == 0) &&
                                    <Table.Row style={{'background-color':'#f5f5f5'}}>
                                                <Table.Cell> <FormattedMessage id="clients.table.row.noitems"
                                                                            defaultMessage='No clients available'/></Table.Cell>
                                                <Table.Cell colspan='6'></Table.Cell>
                                    </Table.Row>}
                                {client && client.items &&
                                      this.addClientRows(client)}
                                </Table.Body>
                            </Table>
                           </Dimmer.Dimmable>
                        </Grid.Column>
                    </Grid.Row>
                <Modal open={this.state.activeModalCreate === true}
                       dimmer='default' 
                       size='small'
                       style={styles.Modal} 
                       onClose={this.hideModal}>
                       <Header icon='user add' content={<FormattedMessage id="clients.modal.create.title"
                                                                                defaultMessage="Add client, customer, lead ..."/>} />
                        <Modal.Content>
                                          <Form style={{paddingLeft:'0em'}}>
                                                <Form.Field width='six'> 
                                                    <label style={styles.LabelGrey}><FormattedMessage id="clients.modal.form.input.name"
                                                                              defaultMessage='Name'/></label>
                                                    <FormattedMessage id="clients.modal.form.input.name.placeholder"
                                                                    defaultMessage='name'>
                                                        {placeholder =>                                                                                
                                                                    <Form.Input type="text" 
                                                                        placeholder={placeholder}
                                                                        id="name" 
                                                                        icon='user add'
                                                                        iconPosition='left'
                                                                        value={name}
                                                                        onChange={this.OnNameChange}
                                                                        error= {submitted && !this.handleNameValidation()}
                                                                        />}
                                                    </FormattedMessage>
                                                </Form.Field>
                                                <Form.Group widths='equal'>
                                                    <Form.Field >
                                                        <label style={styles.LabelGrey}><FormattedMessage id="clients.modal.form.input.companyname"
                                                                                defaultMessage='Company'/></label>
                                                    <FormattedMessage id="clients.modal.form.input.companyname.placeholder"
                                                                        defaultMessage='Company'>
                                                            {placeholder =>                                                                                       
                                                                    <Form.Input type="text" 
                                                                        fluid
                                                                        placeholder={placeholder}
                                                                        id="company_name"
                                                                        icon='building'
                                                                        iconPosition='left' 
                                                                        value={company_name}
                                                                        onChange={this.OnCompanyNameChange}/>}
                                                        </FormattedMessage>
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <label style={styles.LabelGrey}><FormattedMessage id="clients.modal.form.input.email"
                                                                                defaultMessage='Email'/></label>
                                                    <FormattedMessage id="clients.modal.form.input.email.placeholder"
                                                                        defaultMessage='Email'>
                                                            {placeholder =>                                                                                       
                                                                    <Form.Input  
                                                                        fluid
                                                                        placeholder={placeholder}
                                                                        icon='mail'
                                                                        iconPosition='left'
                                                                        type="email"
                                                                        id="email" 
                                                                        value={email}
                                                                        onChange={this.OnEmailChange}
                                                                        error={submitted && !this.handleEmailValidation(email)}
                                                                        />}
                                                        </FormattedMessage>
{/*                                                         {submitted && !this.handleEmailValidation(email)  &&
                                                            <label style={{color:'red', }}><i>
                                                                <FormattedMessage id="suppliers.modal.form.input.email.error.message"
                                                                                    defaultMessage='email is used to send your orders'/>
                                                            </i></label> 
                                                        } */}
                                                    </Form.Field>
                                                </Form.Group>
                                                <br/>
                                                <Form.Field>
                                                    <label style={styles.LabelGrey}><FormattedMessage id="clients.modal.form.input.address"
                                                                              defaultMessage='Billing Address'/></label>
                                                   <FormattedMessage id="clients.modal.form.input.address.placeholder"
                                                                    defaultMessage='address'>
                                                        {placeholder =>          
                                                                <Form.Input type="text" 
                                                                    placeholder={placeholder}
                                                                    id="address" 
                                                                    icon='address card'
                                                                    iconPosition='left'
                                                                    value={address}
                                                                    onChange={this.OnAdressChange}/>}
                                                    </FormattedMessage>
                                                </Form.Field>
                                                <Form.Group widths='equal'>
                                                    <Form.Field>
                                                        <label style={styles.LabelGrey}><FormattedMessage id="clients.modal.form.input.city"
                                                                                defaultMessage='City'/></label>
                                                    <FormattedMessage id="clients.modal.form.input.city.placeholder"
                                                                        defaultMessage='city'>
                                                            {placeholder =>          
                                                                    <Form.Input type="text" 
                                                                        fluid
                                                                        placeholder={placeholder}
                                                                        id="city" 
                                                                        icon='map'
                                                                        iconPosition='left'
                                                                        value={city}
                                                                        onChange={this.OnCityChange}/>}
                                                        </FormattedMessage>
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <label style={styles.LabelGrey}><FormattedMessage id="clients.modal.form.input.zipcode"
                                                                                defaultMessage='Zipcode'/></label>
                                                    <FormattedMessage id="clients.modal.form.input.zipcode.placeholder"
                                                                        defaultMessage='zipcode'>
                                                            {placeholder =>          
                                                                    <Form.Input type="text" 
                                                                        fluid
                                                                        placeholder={placeholder}
                                                                        id="zipcode" 
                                                                        icon='map'
                                                                        iconPosition='left'
                                                                        value={zipcode}
                                                                        onChange={this.OnZipcodeChange}
                                                                        error={submitted && !this.handleZipCodeValidation(zipcode)}/>}
                                                        </FormattedMessage>
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <label style={styles.LabelGrey}><FormattedMessage id="clients.modal.form.input.state"
                                                                                defaultMessage='State'/></label>
                                                    <FormattedMessage id="clients.modal.form.input.state.placeholder"
                                                                        defaultMessage='state'>
                                                            {placeholder =>          
                                                                    <Form.Input type="text" 
                                                                        fluid
                                                                        placeholder={placeholder}
                                                                        id="state" 
                                                                        icon='map'
                                                                        iconPosition='left'
                                                                        value={state}
                                                                        onChange={this.OnStateChange}/>}
                                                        </FormattedMessage>
                                                    </Form.Field>
                                                </Form.Group>
                                                <Form.Group widths='equal'>
                                                <Form.Field >
                                                    <label style={styles.LabelGrey}><FormattedMessage id="clients.modal.form.input.country"
                                                                              defaultMessage='Country'/></label>
                                                   <FormattedMessage id="clients.modal.form.input.country.placeholder"
                                                                    defaultMessage='country'>
                                                        {placeholder =>          
                                                                <Form.Input type="text" 
                                                                    placeholder={placeholder}
                                                                    id="country" 
                                                                    icon='world'
                                                                    iconPosition='left'
                                                                    value={country}
                                                                    onChange={this.OnCountryChange}/>}
                                                    </FormattedMessage>
                                                </Form.Field>
                                                <Form.Field >
                                                   <label style={styles.LabelGrey}><FormattedMessage id="clients.modal.form.input.phone"
                                                                              defaultMessage='Phone'/></label>
                                                    <PhoneInput
                                                        defaultCountry={intl.locale =='fr'?'FR':'US'}
                                                        international
                                                        //placeholder='' 
                                                        value={phone}
                                                        onChange={this.OnPhoneChange}
                                                    />
                                                   {submitted && !this.handlePhoneValidation(phone) &&
                                                    <label style={{color:'red',paddingLeft:'2em'}}><i>
                                                        <FormattedMessage id="clients.modal.form.input.phone.error.message"
                                                                            defaultMessage='Invalid phone number'/> 
                                                        </i></label>
                                                    }
                                                </Form.Field>
                                                </Form.Group>
                                                <Form.Group widths='equal'>
                                                <Form.Field >
                                                    <label style={styles.LabelGrey}><FormattedMessage id="clients.modal.form.input.note"
                                                                              defaultMessage='Technical note'/></label>
                                                   <FormattedMessage id="clients.modal.form.input.note.placeholder"
                                                                    defaultMessage='note (website, comment ...)'>
                                                        {placeholder =>          
                                                                <Form.TextArea type="text" 
                                                                    placeholder={placeholder}
                                                                    id="note" 
                                                                    icon='text'
                                                                    iconPosition='left'
                                                                    value={note}
                                                                    rows='1'
                                                                    maxlength="300"
                                                                    onChange={this.OnNoteChange}/>}
                                                    </FormattedMessage>
                                                </Form.Field>
                                                </Form.Group>
                                                <br/>
                                        </Form>
                                </Modal.Content>
                                <Modal.Actions>
                                    <br/>
                                    <Button basic color='grey' onClick={this.hideModal}>
                                        <FormattedMessage id="clients.modal.update.button.cancel"
                                                          defaultMessage='Cancel'/>
                                    </Button>
                                    <Button color='teal' 
                                        labelPosition='right' 
                                        content={<FormattedMessage id="clients.modal.create.button.create"
                                                                    defaultMessage='Create'/>}
                                        icon='checkmark' 
                                        onClick={(event) =>this.addClient(event)}
                                        />
                                      <br/>
                                 </Modal.Actions>   
                            </Modal> 
                        </Grid>
                </SidebarProfile>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { client,alert,dispatch } = state;
    const { loading_c } = state.client;
    return {
        client,
        loading_c,
        alert,
        dispatch
    };
}

const connectedClients = injectIntl(connect(mapStateToProps)(Clients));
export { connectedClients as Clients };
 