import React, { Component } from 'react';
import { ingredientsActions ,categoryIngredientActions,supplierActions,alertActions} from '../_actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Form, Header,Label,Grid,Message,Icon,Loader,Dimmer,Breadcrumb,Segment,Select} from 'semantic-ui-react'
import SideLeftUncoverIngredient from './SidebarIngredient';
import { history,toaster_dispatch,toaster_error,trim, isNumeric,isFloat,unitWeight,unitVolume,optimizeDecimalPart,get_payload,isInteger,dateWithoutTimezone,typeYield} from '../_helpers';
import {  WEIGHT_INSTRUCTION_LINE_TITLE,
          WEIGHT_INSTRUCTION_LINE_KG,
          WEIGHT_INSTRUCTION_LINE_G,
          WEIGHT_INSTRUCTION_LINE_MG,
          WEIGHT_INSTRUCTION_LINE_OZ,
          WEIGHT_INSTRUCTION_LINE_LB,
          WEIGHT_INSTRUCTION_LINE_EX,
          VOLUME_INSTRUCTION_LINE_TITLE,
          VOLUME_INSTRUCTION_LINE_L,
          VOLUME_INSTRUCTION_LINE_ML,
          VOLUME_INSTRUCTION_LINE_CL,
          VOLUME_INSTRUCTION_LINE_FL,
          VOLUME_INSTRUCTION_LINE_GAL,
          VOLUME_INSTRUCTION_LINE_EX,
          QUANTITY_INSTRUCTION_LINE_TITLE,
          QUANTITY_INSTRUCTION_LINE_NUMBER,
          QUANTITY_INSTRUCTION_LINE_EX,
          allergens,
          VAToptions
        } from '../_helpers';
import {FormattedMessage,injectIntl} from 'react-intl';
import { alertConstants } from '../_constants';
import {styles} from './Styles';
import DatePicker from "react-datepicker"
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';
import en from 'date-fns/locale/en-US';


const  WEIGHT = <FormattedMessage id="copyingredient.quantity.select.weight"
                                    defaultMessage="Weight"/>;
const  VOLUME = <FormattedMessage id="copyingredient.quantity.select.volume"
                                    defaultMessage="Volume"/>;
const  QUANTITY = <FormattedMessage id="copyingredient.quantity.select.quantity"
                                    defaultMessage="Quantity"/>;

const ERROR_MESSAGE_TITLE = <FormattedMessage id="copyingredient.toaster.message.title"
                                                  defaultMessage='ERROR Field validation'/>
         
const ERROR_MESSAGE_CONTENT = <FormattedMessage id="copyingredient.toaster.message.content"
                                                  defaultMessage='Extra Supplier can not be empty'/>


class CopyIngredient extends Component {
  constructor(props) {
    super(props);
    const {intl} = props;
    this.state = {
      prevIngredients : null,
      title: "",
      ref_internal:"",
      name: "",
      price: "",
      note: " ",
      purchase_weight: "",
      purchase_volume: "",
      purchase_quantity: "",
      //category: {name:""},
      categorySelected : null,
      supplierSelected : null,
      allergensSelected : null,
      type_quantity: "",
      type_quantity_updated: false,
      quantity: "",
      pack_units: 1,
      highlighted: false,
      submitted: false,
      ref_supplier:"",
      tax : 0.00,
      batch_number: "",
      selectedDate : ""
    }

    props.dispatch(ingredientsActions.get(props.match.params.ingredientId));
    props.dispatch(categoryIngredientActions.getAll());
    props.dispatch(supplierActions.getAll());

    registerLocale('en', en)
    registerLocale('fr', fr)
    if(intl.locale == 'fr'){
        setDefaultLocale('fr',fr)
    }else{
        setDefaultLocale('en',en)
    }
  }

  static getDerivedStateFromProps(props, state) {
    if(props.ingredients && props.ingredients.selected!==state.prevIngredientSelected){
      if (props.ingredients.selected){
               
        const selected = props.ingredients.selected;
        let result =  {
          prevIngredientSelected : selected,
          selected: selected, 
          name: selected.name,
          title: selected.name,
          note: selected.note,
          ref_internal: selected.ref_internal,
          tax: Number(selected.tax)
         };

        if (selected.category){
          Object.assign(result,{ categorySelected: {id:selected.category.id,
                                                    name:selected.category.name }});
        }

        if (selected.allergens){
          let values=[]
          for (let elem of selected.allergens){
                values.push(elem.name)
          }       
          Object.assign(result,{ allergensSelected:values});
        }

        if (selected.default_supplier == true){
          Object.assign(result,{ extra_supplier_id: null });
          Object.assign(result,{ price: optimizeDecimalPart(Number(selected.price)).toString() });
          Object.assign(result,{ purchase_weight: selected.purchase_weight });
          Object.assign(result,{ purchase_volume: selected.purchase_volume });
          Object.assign(result,{ purchase_quantity: selected.purchase_quantity });
          Object.assign(result,{ ref_supplier: selected.ref_supplier });
          Object.assign(result,{ type_quantity: 'weight' });
          Object.assign(result,{ quantity_unit: selected.unit });
          Object.assign(result,{ pack_units: selected.pack_units });
          Object.assign(result,{ selectedDate: selected.date_expire!=null?new Date(selected.date_expire):""});
          Object.assign(result,{ batch_number: selected.batch_number });
          
          if (selected.purchase_weight !== ""){
            Object.assign(result,{ type_quantity: 'weight' });
            let aQuantity = get_payload(selected.purchase_weight,true)
            if (aQuantity !== "") aQuantity = optimizeDecimalPart(Number(aQuantity).toString())
            if (selected && selected.extra_suppliers && selected.extra_suppliers.length <=0){
              let quantity_string =  aQuantity + ' ' + selected.unit
              Object.assign(result,{ quantity:  quantity_string});
            }else{
              Object.assign(result,{ quantity:  aQuantity});
            }
          }else if (selected.purchase_volume !== ""){
            Object.assign(result,{ type_quantity: 'volume' });
            let aQuantity = get_payload(selected.purchase_volume,true)
            if (aQuantity !== "") aQuantity = optimizeDecimalPart(Number(aQuantity).toString())
            if (selected && selected.extra_suppliers && selected.extra_suppliers.length <=0){
                let quantity_string =  aQuantity + ' ' + selected.unit
                Object.assign(result,{ quantity:  quantity_string});
              }else{
                Object.assign(result,{ quantity:  aQuantity});
              }
          }else{
            Object.assign(result,{ type_quantity: 'quantity' });
            Object.assign(result,{ quantity: optimizeDecimalPart(Number(selected.purchase_quantity)).toString() });
          }

          if (selected.supplier){
            Object.assign(result,{ supplierSelected:{id:selected.supplier.id,
                                                     name:selected.supplier.name}});
          }

        }else{
          //Extra supplier is selected for this ingredient
          for (let extra of selected.extra_suppliers){
                if (extra.default_supplier == true){
                  //Object.assign(result,{ extra_supplier_instance: extra });
                  Object.assign(result,{ extra_supplier_id: extra.id });
                  Object.assign(result,{ price: optimizeDecimalPart(Number(extra.price)).toString() });
                  Object.assign(result,{ purchase_weight: extra.purchase_weight });
                  Object.assign(result,{ purchase_volume: extra.purchase_volume });
                  Object.assign(result,{ purchase_quantity: extra.purchase_quantity });
                  Object.assign(result,{ ref_supplier: extra.ref_supplier });
                  Object.assign(result,{ type_quantity: 'weight' });
                  Object.assign(result,{ quantity_unit: extra.unit });
                  Object.assign(result,{ pack_units: extra.pack_units });
                  Object.assign(result,{ selectedDate: extra.date_expire!=null?new Date(extra.date_expire):""});
                  Object.assign(result,{ batch_number: extra.batch_number });
          
                  if (extra.purchase_weight !== ""){
                    Object.assign(result,{ type_quantity: 'weight' });
                    let aQuantity = get_payload(extra.purchase_weight,true)
                    if (aQuantity != "") aQuantity = optimizeDecimalPart(Number(aQuantity)).toString()
                    Object.assign(result,{ quantity: aQuantity });
                  }else if (extra.purchase_volume  !== ""){
                    Object.assign(result,{ type_quantity: 'volume' });
                    let aQuantity = get_payload(extra.purchase_volume ,true)
                    if (aQuantity != "") aQuantity = optimizeDecimalPart(Number(aQuantity)).toString()
                    Object.assign(result,{ quantity: aQuantity});
                  }else{
                    Object.assign(result,{ type_quantity: 'quantity' });
                    Object.assign(result,{ quantity:optimizeDecimalPart(Number(extra.purchase_quantity)).toString()});
                  }

                  if (extra.supplier){
                    Object.assign(result,{ supplierSelected:{id:extra.supplier.id,
                                                             name:extra.supplier.name  }});
                  }
                }
          }
        }
        return result;
      }
     }
    return null;
  }

  onNameChange = (e) => {
    e.preventDefault();
    this.setState({ name: e.target.value });
  }

  onPriceChange = (e) => {
    e.preventDefault();
    this.setState({ price: e.target.value });
  }

  onNoteChange = (e) => {
    e.preventDefault();
    this.setState({ note: e.target.value });
  }

  resetPurchaseQuantities () {
    this.setState({ purchase_weight: "" });
    this.setState({ purchase_volume: ""});
    this.setState({ purchase_quantity: ""});
  }

  onCategoryChange = (e,data) => {
    e.preventDefault();
    const {categoriesIngredient} = this.props;
    if (data.value==""){
      this.setState({ categorySelected: null });
    }else{
      let category_id = data.value;
      let elem = categoriesIngredient.options.find(category => category.value == category_id)
      this.setState({ categorySelected: { id: category_id,
                                          name:elem.text }});
    }
  }

  onAllergensChange = (e,data) => {
    e.preventDefault();
    //console.log(data)
    this.setState({ allergensSelected: data.value });
  }
  
  onBatchNumberChange = (e) => {
    e.preventDefault();
    this.setState({batch_number: e.target.value });
  }

  setExpirationDate (date){
    this.setState({ selectedDate: date})
  }

  createAllergensList = (allergens) => {
    let listAllergens = [];  
    if (allergens){
      for (let elem of allergens){
        listAllergens.push({id:0,name:elem})
      }    
    }
    return listAllergens;   
  }

  onSupplierChange = (e,data) => {
    e.preventDefault();
    const {supplier} = this.props;
      if (data.value==""){
        this.setState({ supplierSelected: null });
      }else{
        let supplier_id = data.value;
        let elem = supplier.options.find(supplier => supplier.value == supplier_id)
        this.setState({ supplierSelected: {id: supplier_id,
                                           name: elem.text } });
      }
    }
   

  onTypeQuantityChange = (e,data) => {
    e.preventDefault();
    const {quantity,selected} = this.state;
    this.setState({ type_quantity: data.value });
    this.resetPurchaseQuantities();

    let type =  data.value
    if ( type === 'volume')
      this.setState({ purchase_volume: quantity });
    else if ( type === 'weight')
      this.setState({ purchase_weight:quantity});
    else if  ( type === 'quantity')
      this.setState({ purchase_quantity:quantity });

    this.setState({ type_quantity_updated: (type!==typeYield(selected.unit))?true:false});
  }

  onUnitsPackagingChange = (e,data) => {
    e.preventDefault();
    this.setState({ pack_units: data.value });
  }


  onFullQuantityChange= (e) => {
    e.preventDefault();
   
    const {type_quantity} = this.state;
    this.setState({ quantity: e.target.value });
    this.resetPurchaseQuantities();

    if (type_quantity === 'volume')
      this.setState({ purchase_volume: e.target.value.toLowerCase() });
    else if (type_quantity === 'weight')
      this.setState({ purchase_weight: e.target.value.toLowerCase() });
    else if  (type_quantity === 'quantity')
      this.setState({ purchase_quantity: e.target.value });
  }


  onQuantityChange = (e) => {
    e.preventDefault();
    const {type_quantity,quantity_unit} = this.state;
    this.setState({ quantity: e.target.value });
    this.resetPurchaseQuantities();

    let quantity_value =  parseFloat(e.target.value.replace(/,/g, '.')).toFixed(3)
    quantity_value = optimizeDecimalPart(Number(quantity_value)).toString()
    let quantity_string =  quantity_value + ' ' + quantity_unit
    //console.log(quantity_string)
    
    if (type_quantity === 'volume'){
      this.setState({ purchase_volume: quantity_string.toLowerCase() });
    }  
    else if (type_quantity === 'weight'){
      this.setState({ purchase_weight:quantity_string.toLowerCase()});
    }
    else if  (type_quantity === 'quantity'){
      this.setState({ purchase_quantity: quantity_string });
    }
  }

  onRefInternalChange = (e) => {
    e.preventDefault();
    this.setState({ ref_internal: e.target.value });
  }

  onRefSupplierChange = (e) => {
    e.preventDefault();
    this.setState({ ref_supplier: e.target.value });
  }

  onFocusElsewhere(e) {
    e.preventDefault();
    this.setState({ highlighted: false });
  }

  onFocusQuantityField = (e,quantity) => {
    e.preventDefault();
    this.setState({ highlighted: true});
  }

  onFocusPackField = (e) => {
    e.preventDefault();
    this.setState({ highlighted: true});
  }

  onTaxChange = (e,data) => {
    e.preventDefault();
    //console.log(data.value)
    this.setState({ tax: data.value});
  }


  assertNameField(){
    if ((this.state.name !== "")
          &&(trim(this.state.name).length>0)
          &&(trim(this.state.name).length<100))
      return true;
    return false;
  }

  assertSelectedWeight(){
    if (this.state.type_quantity != "")
      return true;
    return false;
  }

  assertQuantityField(){
    if (this.state.quantity != "")
      return true;
    return false;
  }

  assertPackField(){
    let units = Number(this.state.pack_units)
    if (isInteger(units)
        && (units > 0)
        && Math.trunc(units).toString().length<7)
      return true;
    return false;
  }

  assertQuantityValueField(){
    let value = this.state.quantity.toString().replace(/,/g, '.');
    if ((isFloat(value) || isNumeric(value))
      && (value != "")
      && (Number(value) > 0)
      && Math.trunc(value).toString().length<7)
      return true;
    return false;
  }

  assertPriceField(){
    let price = this.state.price.replace(/,/g, '.');
    if ((isFloat(price) || isNumeric(price))
      && (price != "")
      && Math.trunc(price).toString().length<7)
      return true;
    return false;
  }
  
  assertQuantityPermittedIncluded(quantity){
    let isFound = false;
    let unitPart = "";
    const {type_quantity} = this.state;
    let inputs= quantity.replace(/,/g, '.');
    inputs = inputs.split(' ').filter(Boolean);

    if (inputs.length > 2)
      return false;
    
    if (inputs.length == 1){
      if (type_quantity == 'quantity'){
        if (!isNumeric(inputs)) 
              return false;
        if (inputs <=0){
            return false;
        }
              
      }else{
        //let tokens = inputs[0].split(/(\d+)/).filter(Boolean);
        //let tokens = inputs[0].split(/[-+]?[0-9]*\.?[0-9]*/).filter(Boolean);
        let tokens = inputs[0].match(/[a-z]+|[^a-z]+/gi);
        if (tokens.length != 2)
          return false;
        for (let i of tokens){
          if (!isNumeric(i)) unitPart = i;
          if (isNumeric(i) && i<=0 ) {
            return false;
          }
          //console.log(unitPart);
        }
      }
    }else{
      if (inputs.length > 2)
          return false
      if (!isNumeric(inputs[0]) && !isNumeric(inputs[1]))
          return false
      for (let i of inputs){
        if (!isNumeric(i)) unitPart = i;
        if (isNumeric(i) && i<=0 ) {
          return false;
        }   
      }
    }

    if (type_quantity == 'volume'){
      for (let unit of unitVolume ){
        if (unitPart === unit){
            isFound = true;
            break;
        }
      }
    }else if (type_quantity == 'weight'){
      for (let unit of unitWeight ){
        if (unitPart === unit){
          isFound = true;
          break;
        }
     }
    }else {
        if (inputs.length > 1)
          return false
        isFound = true;
    }
    return isFound;
  }


  assertQuantityFieldsValidity(){
    const {quantity} = this.state;
    let clean_quantity = trim(quantity).toLowerCase();
    //this.setState({ quantity: clean_quantity });
    if (!this.assertQuantityPermittedIncluded(clean_quantity)) return false;
    return true;
  }



  onSubmit = (e,newRoute) => {
    e.preventDefault();
    const {selected,name,price,note,purchase_weight,purchase_volume,
      purchase_quantity,categorySelected,supplierSelected,allergensSelected,ref_internal,ref_supplier,extra_supplier_id,tax,pack_units,
      selectedDate,batch_number} = this.state;
    const { dispatch,extra_suppliers,intl } = this.props;

    this.setState({ submitted: true });

    if (!this.assertNameField()){
      return;
    }

    if (!this.assertPriceField()){
      return;
    }

    if (!this.assertSelectedWeight()){
      return;
    }

    if (selected && selected.extra_suppliers && (selected.extra_suppliers).length>0){
      if (!this.assertQuantityValueField()){
        return;
      } 
    }

    if (!this.assertPackField()){
      return;
    }

/*     if (!this.assertQuantityField()){
      return;
    }*/

    if (selected && selected.extra_suppliers && (selected.extra_suppliers).length<=0){
      if (!this.assertQuantityFieldsValidity()){
        return
      } 
    }


    if (name && price && selected) {

        if (extra_supplier_id == null){

          const ingredient = {
            name:trim(name.replace(/\n|\r|\t/g, " ")),
            price:Number(trim(price).replace(/,/g, '.')).toFixed(3),
            note:note,
            purchase_weight:trim(purchase_weight).replace(/,/g, '.'),
            purchase_volume:trim(purchase_volume).replace(/,/g, '.'),
            purchase_quantity:trim(purchase_quantity).replace(/,/g, '.'),
            category:categorySelected,
            supplier:supplierSelected,
            allergens:this.createAllergensList(allergensSelected),
            ref_internal:ref_internal,
            ref_supplier:ref_supplier,
            tax: tax,
            pack_units:pack_units,
            date_expire: (selectedDate && selectedDate != "" ) ? dateWithoutTimezone(selectedDate):null,
            batch_number: batch_number,
            extra_suppliers:extra_suppliers,
            metrics:selected.metrics
          }
          dispatch(alertActions.clear());
          dispatch(ingredientsActions.add(ingredient,'/ingredients/'));

        }else{

          if (supplierSelected){

           const extra_suppliers_list = extra_suppliers.map((extra_supplier_instance, idx) => {
            if (extra_supplier_instance.id === extra_supplier_id) {
                extra_supplier_instance.supplier = supplierSelected
                extra_supplier_instance.price =  Number(trim(price).replace(/,/g, '.')).toFixed(3)
                extra_supplier_instance.purchase_weight = trim(purchase_weight).replace(/,/g, '.')
                extra_supplier_instance.purchase_volume = trim(purchase_volume).replace(/,/g, '.')
                extra_supplier_instance.purchase_quantity = trim(purchase_quantity).replace(/,/g, '.')
                extra_supplier_instance.ref_supplier = ref_supplier
                extra_supplier_instance.default_supplier = true
                extra_supplier_instance.pack_units = pack_units
                extra_supplier_instance.date_expire = (selectedDate && selectedDate != "" ) ? dateWithoutTimezone(selectedDate):null
                extra_supplier_instance.batch_number = batch_number
                return extra_supplier_instance;
            }else {
                return extra_supplier_instance;
            }
          });
                
          const ingredient = {
            name:trim(name.replace(/\n|\r|\t/g, " ")),
            note:note,
            category:categorySelected,
            allergens:this.createAllergensList(allergensSelected),
            ref_internal:ref_internal,
            price:Number(trim(selected.price).replace(/,/g, '.')).toFixed(3),
            purchase_weight:trim(selected.purchase_weight).replace(/,/g, '.'),
            purchase_volume:trim(selected.purchase_volume).replace(/,/g, '.'),
            purchase_quantity:trim(selected.purchase_quantity).replace(/,/g, '.'),
            supplier:selected.supplier,
            ref_supplier:selected.ref_supplier,
            extra_suppliers:extra_suppliers_list,
            tax: (tax!=null)?tax:0.00,
            pack_units:selected.pack_units,
            metrics:selected.metrics
          }
          dispatch(alertActions.clear());
          dispatch(ingredientsActions.add(ingredient,'/ingredients/'));

            }else{
                    toaster_error(intl.formatMessage({ id: 'copyingredient.toaster.message.title' }),
                                  intl.formatMessage({ id: 'copyingredient.toaster.message.content' }))   
          }
        }
    }
  }

  cancelAction = (e) => {
    e.preventDefault();
    history.goBack();
  }


  
  render() {

    const { categoriesIngredient,supplier,alert,dispatch,loading,updating,intl } = this.props;
    const {quantity,type_quantity,quantity_unit,submitted,categorySelected,supplierSelected,allergensSelected,pack_units,selectedDate,batch_number,selected,
      highlighted,type_quantity_updated} = this.state;

    const renderLabel = (option) => ({
      color: option.color,
      content: option.text,
    })

    let type_quantity_text;

    switch (type_quantity) {
      case 'weight':
        type_quantity_text = intl.formatMessage({id:'copyingredient.quantity.select.weight'})
        break;
      case 'volume':
        type_quantity_text = intl.formatMessage({id:'copyingredient.quantity.select.volume'})
        break;
      case 'quantity':
        type_quantity_text = intl.formatMessage({id:'copyingredient.quantity.select.quantity'})
        break;
      default:
        type_quantity_text = intl.formatMessage({id:'copyingredient.quantity.select.weight'})
    }

    return (
      <div>
      { loading &&
        <SideLeftUncoverIngredient>
          <Dimmer active={(loading)} blurring inverted>
                <Loader inverted content='Loading' />
          </Dimmer> 
        </SideLeftUncoverIngredient>
      }
      {!loading &&  <SideLeftUncoverIngredient id={this.props.match.params.ingredientId}>
      <Grid stackable style={styles.FontLato12}>
      <Grid.Row columns={1}>
              <Breadcrumb style={{background:'#fcfcfc'}}>
                        <Breadcrumb.Section link as={Link} to={'/dashboard/'}><FormattedMessage id="copyingredient.link.dashboard"
                                                              defaultMessage='Dashboard'/></Breadcrumb.Section>
                        <Breadcrumb.Divider />
                        <Breadcrumb.Section link as={Link} to={'/ingredients/'}><FormattedMessage id="copyingredient.link.ingredients"
                                                            defaultMessage='Ingredients'/></Breadcrumb.Section>
                        <Breadcrumb.Divider />
                        <Breadcrumb.Section link as={Link} to={'/ingredientdetails/'+this.props.match.params.ingredientId}>{this.state.title}</Breadcrumb.Section>   
                        <Breadcrumb.Divider />
                        <Breadcrumb.Section active><FormattedMessage id="copyingredient.link.copy"
                                                              defaultMessage='Copy'/></Breadcrumb.Section>               
              </Breadcrumb>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column  /*width={6}*/ mobile={12} tablet={10} computer={7}>
              <Header as='h2'>
                   <Icon name='edit' size='mini' color='blue'/>
                   <Header.Content>
                      <FormattedMessage id="copyingredient.header.title"
                                        defaultMessage=' Copy ingredient'/>
                      <Header.Subheader>{this.state.title}</Header.Subheader>
                    </Header.Content>
              </Header>
              {alert.message  &&  alert.type == alertConstants.ERROR &&
                    <Message  error size='small' > {alert.message.content}</Message>
              }
              <Form onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}>
              <Form.Group>
                <Form.Field width={12}>
                    <label style={{color:'#60a0d1',fontSize:14}}><FormattedMessage id="copyingredient.form.input.name"
                                             defaultMessage='Name * '/>
                    </label>
                    <FormattedMessage id="copyingredient.form.input.name.placeholder"
                                      defaultMessage='Enter a name'>
                         {placeholder =>     
                              <Form.Input
                                  type="text"
                                  placeholder={placeholder}
                                  id="name"
                                  value={this.state.name}
                                  onChange={this.onNameChange}
                                  //style={{minWidth:'10em',maxWidth:'30em'}}
                                  onFocus={(e)=>this.onFocusElsewhere(e)} 
                                  error={submitted && !this.assertNameField()}
                                />}
                      </FormattedMessage>
                      {submitted && !this.assertNameField()  &&
                        <label style={{color:'red', }}><i>
                              <FormattedMessage id="copyingredient.form.name.error.message"
                                                defaultMessage='name is empty'/>
                        </i></label> 
                      }
                </Form.Field>
                <Form.Field width={3}>
                    <label><FormattedMessage id="copyingredient.form.code.title"
                                             defaultMessage='Internal code'/>
                    </label>
                    <FormattedMessage id="copyingredient.form.code.placeholder"
                                      defaultMessage='reference'>
                         {placeholder =>     
                              <Form.Input
                                  type="text"
                                  placeholder={placeholder}
                                  id="code"
                                  value={this.state.ref_internal}
                                  onChange={this.onRefInternalChange}
                                  onFocus={(e)=>this.onFocusElsewhere(e)}
                                />}
                      </FormattedMessage>
               </Form.Field>
               <Form.Field width={3}>
                      <label><FormattedMessage id="copyingredient.form.tax.title"
                                              defaultMessage='Sales Tax'/>
                      </label>
                      <FormattedMessage id="copyingredient.form.tax.placeholder"
                                        defaultMessage='Tax'>
                          {placeholder =>     
                                <Select compact
                                  options={VAToptions} 
                                  type="text"
                                  placeholder={placeholder}
                                  id="VAT"
                                  value={this.state.tax}
                                  onChange={this.onTaxChange}
                                  />}
                  </FormattedMessage>
                  </Form.Field>
              </Form.Group>
              <Form.Group>
              <Form.Field width={6}>
                    <label><FormattedMessage id="copyingredient.form.category.name"
                                              defaultMessage='Category '/>
                    </label>
                    <FormattedMessage id="copyingredient.form.category.placeholder"
                                      defaultMessage='Select'>
                          {placeholder =>   
                                  <Form.Dropdown selection search   
                                          options={categoriesIngredient && categoriesIngredient.options} 
                                          value ={categorySelected && categorySelected.id}
                                          placeholder={placeholder} 
                                          onChange={this.onCategoryChange}
                                          onFocus={(e)=>this.onFocusElsewhere(e)}
                                          clearable
                                          //style={{minWidth:'10em',maxWidth:'30em'}}
                                    />}
                    </FormattedMessage>    
               </Form.Field>  
               <Form.Field width={10}>
                    <label><FormattedMessage id="copyingredient.form.allergens.name"
                                                defaultMessage='Allergens'/>
                      </label>
                      <FormattedMessage id="copyingredient.form.allergens.placeholder"
                                        defaultMessage='Add allergens'>
                            {placeholder =>   
                                    <Form.Dropdown selection     
                                            multiple
                                            search
                                            options={ [{key:'peanuts',text:intl.formatMessage(allergens.peanuts),value:'peanuts', color:'red'},
                                                       {key:'celery',text:intl.formatMessage(allergens.celery),value:'celery',color:'olive'},
                                                       {key:'crustaceans',text:intl.formatMessage(allergens.crustaceans),value:'crustaceans',color:'pink'},
                                                       {key:'egg',text:intl.formatMessage(allergens.egg),value:'egg',color:'yellow'},
                                                       {key:'fish',text:intl.formatMessage(allergens.fish),value:'fish',color:'teal'},
                                                       {key:'gluten',text:intl.formatMessage(allergens.gluten),value:'gluten',color:'brown'},
                                                       {key:'lupin',text:intl.formatMessage(allergens.lupin),value:'lupin',color:'grey'},
                                                       {key:'milk',text:intl.formatMessage(allergens.milk),value:'milk',color:'blue'},
                                                       {key:'molluscs',text:intl.formatMessage(allergens.molluscs),value:'molluscs',color:'purple'},
                                                       {key:'mustard',text:intl.formatMessage(allergens.mustard),value:'mustard',color:'brown'},
                                                       {key:'nuts',text:intl.formatMessage(allergens.nuts),value:'nuts',color:'violet'},
                                                       {key:'sesame',text:intl.formatMessage(allergens.sesame),value:'sesame',color:'yellow'},
                                                       {key:'soya',text:intl.formatMessage(allergens.soya),value:'soya',color:'green'},
                                                       {key:'sulphites',text:intl.formatMessage(allergens.sulphites),value:'sulphites',color:'orange'}] } 
                                            value = {allergensSelected} 
                                            placeholder={placeholder} 
                                            onChange={this.onAllergensChange}
                                            onFocus={(e)=>this.onFocusElsewhere(e)}
                                            renderLabel={renderLabel}
                                            //style={styles.FontLato12}
                                            //style={{minWidth:'10em',maxWidth:'30em'}}
                                      />}
                      </FormattedMessage> 
                  </Form.Field>
                  </Form.Group>
                <Segment  style={{background:'#fafafa'}}>
                <Form.Group > 
                  <Form.Field width={7}>
                    <label><FormattedMessage id="copyingredient.form.supplier.name"
                                              defaultMessage='Supplier (default one) '/>
                    </label>
                    <FormattedMessage id="copyingredient.form.supplier.placeholder"
                                      defaultMessage='Select'>
                          {placeholder =>   
                                  <Form.Dropdown selection compact search
                                          options={supplier && supplier.options} 
                                          value = {supplierSelected && supplierSelected.id} 
                                          placeholder={placeholder} 
                                          onChange={this.onSupplierChange}
                                          onFocus={(e)=>this.onFocusElsewhere(e)}
                                          clearable
                                          //style={{minWidth:'10em',maxWidth:'30em'}}
                                    />}
                    </FormattedMessage>    
                  </Form.Field> 
                  <Form.Field width={5}/* style={{'marginLeft':'0.5em'}} */>
                    <label style={{color:'#60a0d1',fontSize:14}}><FormattedMessage id="copyingredient.form.input.price"
                                             defaultMessage='Price (Purchase Cost) *'/>
                    </label>
                    <FormattedMessage id="copyingredient.form.input.price.placeholder"
                                      defaultMessage='Add a purchase price'>
                         {placeholder =>     
                              <Form.Input
                                  type="text"
                                  placeholder={placeholder}
                                  id="price"
                                  value={this.state.price}
                                  onChange={this.onPriceChange}
                                  //style={{minWidth:'10em',maxWidth:'30em'}}
                                  onFocus={(e)=>this.onFocusElsewhere(e)}
                                  error={submitted && !this.assertPriceField()}
                                />}
                      </FormattedMessage>
                      {submitted && !this.assertPriceField()  &&
                        <label style={{color:'red', }}><i>
                              <FormattedMessage id="copyingredient.form.price.error.message"
                                                defaultMessage='price is numeric'/>
                        </i></label> 
                      }
                  </Form.Field>
                  <Form.Field width={4}>
                    <label><FormattedMessage id="copyingredient.form.supplier.code.title"
                                             defaultMessage='Supplier code'/>
                    </label>
                    <FormattedMessage id="copyingredient.form.supplier.code.placeholder"
                                      defaultMessage='reference'>
                         {placeholder =>     
                              <Form.Input
                                  type="text"
                                  placeholder={placeholder}
                                  id="suppliercode"
                                  value={this.state.ref_supplier}
                                  onChange={this.onRefSupplierChange}
                                  onFocus={(e)=>this.onFocusElsewhere(e)}
                                />}
                      </FormattedMessage>
                 </Form.Field>
               </Form.Group>
                    {pack_units >= 1 && quantity && 
                        <p style={{textAlign:'right',color:'black',fontSize:'13px',paddingBottom:'0.4em'}}>
                             <FormattedMessage id="copyingredient.form.purchase.text1"
                                          defaultMessage='I buy'/>  {' '}
                                     {selected && selected.extra_suppliers && (selected.extra_suppliers).length<=0 &&
                                      <span style={{color:'#00CC99',fontSize:'14px'}}>{pack_units} x {quantity}</span>}
                                     {selected && selected.extra_suppliers && (selected.extra_suppliers).length>0 &&
                                      <span style={{color:'#00CC99',fontSize:'14px'}}>{pack_units} x {quantity}{quantity_unit}</span>}
                                    {' '}
                               <FormattedMessage id="copyingredient.form.purchase.text2"
                                          defaultMessage=' of the product. Enter price for case/pack of '/> 
                            <span style={{color:'#00CC99',fontSize:'14px'}}> {pack_units}</span>
                        </p>} 
                        <Form.Group>
                          <Form.Field width={6}>
                            <label /* style={{color:'#60a0d1',fontSize:14}} */><FormattedMessage id="copyingredient.form.select.purchase.type"
                                                      defaultMessage='Type of purchase '/>
                            </label>
                            {selected && selected.extra_suppliers && (selected.extra_suppliers).length <=0 &&
                            <div>
                            <FormattedMessage id="copyingredient.form.select.purchase.placeholder"
                                              defaultMessage='Select a type of quantity'>
                                  {placeholder =>   
                                          <Form.Select  
                                                  options={ [{key:'volume',text:<span><Icon name='flask' color='teal'/>{VOLUME}</span>,value:'volume'},
                                                              {key:'weight',text:<span><Icon name='balance' color='teal'/>{WEIGHT}</span>,value:'weight'},
                                                            {key:'quantity',text:<span><Icon name='copy' color='teal'/>{QUANTITY}</span>,value:'quantity'}] } 
                                                  value = {type_quantity} 
                                                  placeholder={placeholder} 
                                                  onChange={this.onTypeQuantityChange}
                                                  error={submitted && !this.assertSelectedWeight()}/>}
                            </FormattedMessage></div>}
                            {selected && selected.extra_suppliers && (selected.extra_suppliers).length>0 &&
                            <div style={{  'pointer-events': 'none', opacity: '0.65'}}>
                            <FormattedMessage id="copyingredient.form.select.purchase.placeholder"
                                              defaultMessage='Select a type of quantity'>
                                  {placeholder =>   
                                          <Form.Input  
                                                  value = {type_quantity_text} 
                                                  placeholder={placeholder} 
                                                  //onChange={this.onTypeQuantityChange}
                                                  error={submitted && !this.assertSelectedWeight()}/>}
                            </FormattedMessage></div> }
                            {submitted && !this.assertSelectedWeight()  &&
                                <label style={{color:'red', }}><i>
                                      <FormattedMessage id="copyingredient.form.select.purchase.error.message"
                                                        defaultMessage='purchase type to select'/>
                                </i></label> 
                              }
                        </Form.Field>
                        <Form.Field width={6}>
                          <label style={{color:'#60a0d1',fontSize:14}}><FormattedMessage id="copyingredient.form.input.quantity"
                                                  defaultMessage='Quantity *'/>
                          </label>
                          {selected && selected.extra_suppliers && (selected.extra_suppliers).length <=0 &&
                           <div>
                           <FormattedMessage id="copyingredient.form.input.quantityfull.placeholder"
                                            defaultMessage='Quantity with unit'>
                              {placeholder =>   
                                    <Form.Input
                                        type="text"
                                        placeholder={placeholder}
                                        id="quantity"
                                        value={quantity}
                                        onChange={this.onFullQuantityChange}
                                        onFocus={(e)=>this.onFocusQuantityField(e,quantity)}
                                        error={submitted && (/* !this.assertQuantityField() || */ !this.assertQuantityFieldsValidity())}/>}
                            </FormattedMessage>
                            {submitted && (/*  !this.assertQuantityValueField()   || */  !this.assertQuantityFieldsValidity() ) &&
                                  <label style={{color:'red', }}><i>
                                                   <FormattedMessage id="copyingredient.form.extrasupplier.quantityfull.error.message"
                                                                    defaultMessage='quantity format invalid (follow instructions)'/>
                                  </i></label>}
                            </div>}
                            {selected && selected.extra_suppliers && (selected.extra_suppliers).length>0 &&
                             <div>
                            <FormattedMessage id="copyingredient.form.input.quantity.placeholder"
                                            defaultMessage='Enter a number'>
                              {placeholder =>   
                                /*  <Form.Group inline>   */
                                    <Form.Input 
                                        labelPosition='right' 
                                        type="text"
                                        placeholder={placeholder}
                                        id="quantity"
                                        value={quantity}
                                        onChange={this.onQuantityChange}
                                        onFocus={(e)=>this.onFocusQuantityField(e,quantity)}
                                        error={submitted && (!this.assertQuantityValueField() /* || !this.assertQuantityFieldsValidity() */)}>
                                       <input />
                                       {type_quantity == 'quantity' &&
                                       <Label color='teal' style={styles.FontLato13}><FormattedMessage id="copyingredient.quantity.unit.text"
                                                                                          defaultMessage='unit'/></Label>}
                                       {type_quantity != 'quantity' &&
                                       <Label color='teal' style={styles.FontLato13}>{quantity_unit}</Label>}
                                      </Form.Input>
                                  /*   </Form.Group> */}
                            </FormattedMessage>
                            {submitted && ( !this.assertQuantityValueField() /*  ||  !this.assertQuantityFieldsValidity() */) &&
                                  <label style={{color:'red', }}><i>
                                                    <FormattedMessage id="copyingredient.form.extrasupplier.quantity.error.message"
                                                                          defaultMessage='enter a decimal number'/>
                                  </i></label>}
                            </div>}
                        </Form.Field>
                        <Form.Field width={4}>
                              <label><FormattedMessage id="copyingredient.form.input.packaging"
                                                      defaultMessage='Case contains'/>
                              </label>
                              <FormattedMessage id="copyingredient.form.input.packaging.units.placeholder"
                                                defaultMessage='Number units'>
                                  {placeholder =>     
                                        <Form.Input
                                            type="number"
                                            placeholder={placeholder}
                                            id="units"
                                            min="1"
                                            value={pack_units}
                                            onChange={this.onUnitsPackagingChange}
                                            //onFocus={(e)=>this.onFocusPackField(e)}
                                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                            //style={{minWidth:'10em',maxWidth:'30em'}}
                                            error={submitted && !this.assertPackField()}
                                            icon={pack_units == 1 ? <Icon name='tag' color="grey"/>: <Icon name='tags' color="teal"/>}
                                            iconPosition='left'
                                          />}
                                </FormattedMessage>
                                {submitted && !this.assertPackField() &&
                                  <label style={{color:'red', }}><i>
                                        <FormattedMessage id="copyingredient.form.pack.error.message"
                                                          defaultMessage='units number invalid'/>
                                  </i></label> 
                                }
                      </Form.Field>
                      </Form.Group>
                      {selected && selected.extra_suppliers && (selected.extra_suppliers).length<=0 && type_quantity_updated &&
                        <p style={{textAlign:'left',color:'grey',fontSize:'13px'} }>
                          <Icon name='info circle' color='grey'/>
                          <i><FormattedMessage id="copyingredient.form.type_quantity.info.message"
                                            defaultMessage='If you update the type, adapt your quantites in related recipes'/></i>
                        </p>}
                      { highlighted && selected && selected.extra_suppliers && (selected.extra_suppliers).length<=0
                          && type_quantity == 'weight' 
                          &&  <Message size='tiny' 
                                      icon='balance'>       
                                  <Icon name='balance' color='teal'/> 
                                  <Message.Content>
                                    <Message.Header>{WEIGHT_INSTRUCTION_LINE_TITLE}</Message.Header>
                                    <ul>
                                      <br/>
                                      <li>{WEIGHT_INSTRUCTION_LINE_KG}</li>
                                      <li>{WEIGHT_INSTRUCTION_LINE_G}</li>
                                      <li>{WEIGHT_INSTRUCTION_LINE_MG}</li>
                                      <li>{WEIGHT_INSTRUCTION_LINE_LB}</li>
                                      <li>{WEIGHT_INSTRUCTION_LINE_OZ}</li>
                                    </ul> 
                                    {WEIGHT_INSTRUCTION_LINE_EX}
                                  </Message.Content>                                     
                              </Message>
                        }
                      { highlighted && selected && selected.extra_suppliers && (selected.extra_suppliers).length<=0
                          && type_quantity == 'volume'   
                          &&  <Message size='tiny' 
                                    icon='flask' >       
                              <Icon name='flask' color='teal'/> 
                              <Message.Content>
                                <Message.Header>{VOLUME_INSTRUCTION_LINE_TITLE}</Message.Header>
                                <ul>
                                  <br/>
                                  <li>{VOLUME_INSTRUCTION_LINE_L}</li>
                                  <li>{VOLUME_INSTRUCTION_LINE_ML}</li>
                                  <li>{VOLUME_INSTRUCTION_LINE_CL}</li>
                                  <li>{VOLUME_INSTRUCTION_LINE_FL}</li>
                                  <li>{VOLUME_INSTRUCTION_LINE_GAL}</li>
                                </ul> 
                                {VOLUME_INSTRUCTION_LINE_EX}
                              </Message.Content>                                     
                          </Message>
                        }
                      { highlighted && selected && selected.extra_suppliers && (selected.extra_suppliers).length<=0
                        && type_quantity == 'quantity' 
                          &&  <Message size='tiny' 
                                      icon='copy'>       
                              <Icon name='copy' color='teal'/> 
                              <Message.Content>
                                <Message.Header>{QUANTITY_INSTRUCTION_LINE_TITLE}</Message.Header>
                                <ul>
                                  <br/>
                                  <li>{QUANTITY_INSTRUCTION_LINE_NUMBER}</li>
                                </ul> 
                                {QUANTITY_INSTRUCTION_LINE_EX}
                              </Message.Content>                                     
                          </Message>
                        }
                       {selected && selected.extra_suppliers && (selected.extra_suppliers).length>0 &&
                        <p style={{textAlign:'right',color:'grey',fontSize:'13px'} }>
                          <Icon name='info circle' color='grey'/>
                          <i><FormattedMessage id="copyingredient.form.extrasupplier.unit.info.message"
                                            defaultMessage='Units are fixed in case extra suppliers are defined'/></i>
                        </p>}
{/*                       {pack_units >= 1 && quantity && 
                        <p style={{textAlign:'right',color:'black',fontSize:'13px'}}>
                             <FormattedMessage id="editingredient.form.purchase.text1"
                                          defaultMessage='I buy'/>  {' '}
                                     {selected && selected.extra_suppliers && (selected.extra_suppliers).length<=0 &&
                                      <span style={{color:'#00CC99',fontSize:'14px'}}>{pack_units} x {quantity}</span>}
                                     {selected && selected.extra_suppliers && (selected.extra_suppliers).length>0 &&
                                      <span style={{color:'#00CC99',fontSize:'14px'}}>{pack_units} x {quantity}{quantity_unit}</span>}
                                    {' '}
                               <FormattedMessage id="editingredient.form.purchase.text2"
                                          defaultMessage=' of the product. Enter price for case/pack of '/> 
                            <span style={{color:'#00CC99',fontSize:'14px'}}> {pack_units}</span>
                        </p>}  */}
                     <Form.Group style={{paddingTop:'0.5em'}}> 
                          <Form.Field>
                                <label><Icon name='calendar alternate outline'/>
                                        {' '}<FormattedMessage id="copyingredient.form.dlc.title"
                                                              defaultMessage='Use by'/>
                                 </label>
                                 <FormattedMessage id="copyingredient.form.dlc.placeholder"
                                                        defaultMessage='---'>
                                          {placeholder =>     
                                                  <DatePicker selected={selectedDate} 
                                                              onChange={(date) => this.setExpirationDate(date)}
                                                              placeholderText = {placeholder} 
                                                              locale={intl.locale}
                                                              onFocus={(e)=>this.onFocusElsewhere(e)}
                                                              dateFormat="P"/>}
                                 </FormattedMessage>
                          </Form.Field>
                          <Form.Field>
                                 <label><FormattedMessage id="copyingredient.form.extrasupplier.batchnumber.code.title"
                                                                    defaultMessage='Batch number'/>
                                 </label>
                                 <FormattedMessage id="copyingredient.form.extrasupplier.supplier.batchnumber.placeholder"
                                                              defaultMessage='XXX_1234'>
                                                {placeholder =>     
                                                      <Form.Input
                                                          type="text"
                                                          placeholder={placeholder}
                                                          id="batchcode"
                                                          value={batch_number}
                                                          onChange={this.onBatchNumberChange}
                                                          onFocus={(e)=>this.onFocusElsewhere(e)}
                                                        />}
                                 </FormattedMessage>
                          </Form.Field>                  
                    </Form.Group>
                    </Segment>
                    <Form.Field>
                    <label><FormattedMessage id="copyingredient.form.note.title"
                                             defaultMessage='Note'/>
                    </label>
                    <FormattedMessage id="copyingredient.form.note.placeholder"
                                      defaultMessage='Add a note'>
                         {placeholder =>     
                              <Form.TextArea
                                  type="text"
                                  placeholder={placeholder}
                                  id="note"
                                  value={this.state.note}
                                  onChange={this.onNoteChange}
                                  onFocus={(e)=>this.onFocusElsewhere(e)} 
                                  //style={{minWidth:'10em',maxWidth:'30em'}}
                                />}
                      </FormattedMessage>
                  </Form.Field>
                <br/>
                {updating && <Button loading  style={styles.ButtonGreen} //basic color='teal'
                         /*disabled={this.state.name.length === 0 
                          || this.state.price.length === 0 
                          ||(this.state.purchase_quantity.length===0 
                              && this.state.purchase_volume.length=== 0 
                              && this.state.purchase_weight.length=== 0)}*/
                        onClick={(e) => {this.onSubmit(e,'/ingredients/')}} >
                  <FormattedMessage id="copyingredient.button.submit"
                                    defaultMessage='Create new'/>
                </Button>}
                {!updating && <Button  style={styles.ButtonGreen} //basic color='teal'
                         /*disabled={this.state.name.length === 0 
                          || this.state.price.length === 0 
                          ||(this.state.purchase_quantity.length===0 
                              && this.state.purchase_volume.length=== 0 
                              && this.state.purchase_weight.length=== 0)}*/
                              onClick={(e) => {this.onSubmit(e,'/ingredients/')}} >
                  <FormattedMessage id="copyingredient.button.submit"
                                    defaultMessage='Create new'/>
                </Button>}
                <Button style={styles.ButtonGrey} //basic color='grey' 
                  onClick={this.cancelAction}>
                  <FormattedMessage id="copyingredient.button.cancel"
                                      defaultMessage='Cancel'/>
                </Button>
                {alert.message && toaster_dispatch(dispatch,alert,intl)}
              </Form>
           </Grid.Column>
{/*            <Grid.Column width={5}>
              <br/><br/><br/><br/><br/>
              <img alt='vegetables' src={img_vegetables}/>
          </Grid.Column> */}
         </Grid.Row>
      </Grid>
      </SideLeftUncoverIngredient>}
    </div>
    );
  }
}

function mapStateToProps(state) {
    const { ingredients,categoriesIngredient,alert,dispatch,supplier } = state;
    const {loading,updating,extra_suppliers} = state.ingredients;
    return {
        ingredients,
        categoriesIngredient,
        supplier,
        loading,
        updating,
        alert,
        dispatch,
        extra_suppliers,
    };
}


const connectedCopyIngredient = injectIntl(connect(mapStateToProps)(CopyIngredient));
export { connectedCopyIngredient as CopyIngredient };
