import React, { Component } from 'react'
import {
  Container,
  Dropdown,
  Visibility,
  Icon,
  Menu,
  Segment,
  Sidebar,
  Responsive,
  Button,
  Modal,
  Header,
  Message,
  Image
} from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import { userActions } from '../_actions';
import {trim,ADMIN,extractRoleFromCode,history} from '../_helpers';
import img_logo from '../Assets/ratatool-logo.png'
import { styles } from './Styles';
import img_performance from '../Assets/performance.png'

//const avatar = (<Image src={`assets/nan.jpg`} avatar />);
const avatar = (<Icon name='circle user' size='large'/>);


  const containerStyle = {
    height: 'auto',
    width: '1400px',
    padding: '0em 0em 0em 0em',
    border:'none',
    //backgroundImage: `url(${backgroundImg})`,
    //backgroundSize: 'cover'
  };

class HeaderMenu extends Component {

    constructor(props) {
        super(props);
        let userIn = JSON.parse(localStorage.getItem('userIn'));
        this.state = {
            prevCustomer : props.customer,
            menuVisible: false,
            activeItem: 'Home',
            username : userIn.user.username ,
            email: userIn.user.email,
            current_org: userIn.user.active_org,
            active_role:  userIn.user.role,
            org_member: userIn.user.active_org?true:false, 
            activeModalChangeOrg: null,
            activeModalPremium : false,
            access: extractRoleFromCode(userIn.user.role),
       }; 
       this.hideModal = this.hideModal.bind(this);  
    }

    static getDerivedStateFromProps(props, state) {
        if(props.customer && props.customer!==state.prevCustomer) {
            return {quantity:props.customer.quantity}
        }
        return null;
    }



    hideFixedMenu = () => this.setState({ fixed: false })
    showFixedMenu = () => this.setState({ fixed: true })

    handleItemClick = (e, { name }) => {
        this.setState({ activeItem: name });
    }

/*     isActive(match1,match2){
        const {route} = this.props;
        //console.log(route.location.pathname);
        //return matchPath(route.location.pathname, path) != null
        return ((route.location.pathname.includes(match1)) 
                || (route.location.pathname.includes(match2)))
    } */

    isActive(match1){
        const {route} = this.props;
        return route.location.pathname.indexOf(match1)!=-1
    }

    isActive2Match(match1,match2){
        const {route} = this.props;
        return (route.location.pathname.indexOf(match1)!=-1)
                 || (route.location.pathname.indexOf(match2)!=-1)
    }

    onOrgClick = () => {
        history.push({pathname: '/organisation'})
    }

    onOrgChange = (e,data) => {
        e.preventDefault();
        const { orgs_options/*,customer*/} = this.props;
        let elem = data.options.find(e => e.value == data.value)

/*      if (customer && customer.membership.membership_type!="Entreprise"){
            this.setState({ activeModalPremium: true});   
        }else  */ if (elem.description == 'Premium'){
            this.setState({ activeModalPremium: true});
        }else
        {
            let org_id = data.value;
            let elem = orgs_options.find(org => org.value == org_id)
            this.setState({ activeModalChangeOrg: {id:org_id,
                                                    name:elem.text} }); 
        }
    }

    switchActiveOrganisation(event, organisation_id){
        event.preventDefault();
        const { username,email} = this.state;
        const { dispatch,route} = this.props;

        if (organisation_id && username && email) {
            const user_infos = {
                     username:trim(username),
                     email:trim(email),
                     active_org: organisation_id
             }
            dispatch(userActions.update(user_infos,route.location));
        }
    }

    hideModal() {
        this.setState({ activeModalChangeOrg: null });
        this.setState({ activeModalPremium: false });
    }

    upgradeSubscription = () => {
        history.push({pathname: '/profilepremium', state: {toggled: false}})
        this.hideModal()
    }

    
    render() {
        const { orgs_options,customer} = this.props;
        const { fixed, username,email,current_org,activeModalChangeOrg,active_role,org_member,access,quantity} = this.state;
        let membership = null;
        let sorted_orgs;
        if (customer){
            membership = customer.membership;
        }

        if (orgs_options && orgs_options.length > 0){
            sorted_orgs = orgs_options.map((elem,index) => (index >  quantity - 1)? { ...elem,  description : 'Premium' }  : { ...elem,  description : ''});
        }

        return (
          <div>
            <Responsive minWidth={1150/*Responsive.onlyComputer.minWidth*/} >
              <Visibility once={false} onBottomPassed={this.showFixedMenu} onBottomPassedReverse={this.hideFixedMenu}> 
                 {/* <Segment textAlign='center' style={{ minHeight:50, padding: '1em 0em',background:'#F8F8F8',border:'none' }} vertical> */}
                <Segment textAlign='left' attached='top' style={{ height:85, width:'100vw',border:'none' }} vertical> 
                    <Menu   borderless 
                            attached={!fixed}
                            fixed={fixed ? 'top' : null}
                            secondary={!fixed} 
                            pointing={!fixed} 
                            //size='massive'
                            style={styles.FontLato14BoldGrey}
                           >
                         <Container style={containerStyle}>
                           {/*  <Menu.Item header> */}
                                <Link to='/'><img alt='logo' src={img_logo} style={{ width: '135px', height: '38px' }} /></Link>
                           {/*  </Menu.Item> */}
                            <Menu.Item />
                            <Menu.Item as={Link} to='/dashboard' 
                                       active={this.isActive('dashboard')}
                                       onClick={this.handleItemClick}
                                       //style = {{'color': '#66b2b2'}}
                                       color='blue'
                                       >
                              <div style={{marginLeft: '0.5em'}}><Icon name='dashboard' size='large'/></div>
                              {/*  <FormattedMessage id="dashboard.computer.header.top.menu.dashboard"
                                                  defaultMessage='DASHBOARD'/> */}
                            </Menu.Item>
                            <Menu.Item  as={Link} to='/recipes' 
                                        active={this.isActive('recipe')}
                                        onClick={this.handleItemClick}
                                        //style = {{'color': '#66b2b2'}}
                                        color='blue'
                                        >
                                <Icon name='food'/>
                                <FormattedMessage id="dashboard.computer.header.top.menu.recipes"
                                                  defaultMessage='RECIPES'/>
                            </Menu.Item>
                            <Menu.Item  as={Link} to='/ingredients'
                                        active={this.isActive('ingredient')}
                                        onClick={this.handleItemClick}
                                        //style = {{'color': '#66b2b2'}}
                                        color='blue'
                                        >
                              <Icon name='shopping basket'/>
                               {/*  <img src={img_bag} alt='recette pan' style={{width:'20px'}}/> */}
                                <FormattedMessage id="dashboard.computer.header.top.menu.ingredients"
                                                  defaultMessage='INGREDIENTS'/>
                            </Menu.Item>
                             <Menu.Item  as={Link} to='/menus' 
                                        active={this.isActive('menu')}
                                        onClick={this.handleItemClick}
                                        //style = {{'color': '#66b2b2'}}
                                        color='blue'
                                        >
                                <Icon name='block layout'/>
                                <FormattedMessage id="dashboard.computer.header.top.menu.menus"
                                                  defaultMessage='MENUS'/>
                            </Menu.Item>
                            <Menu.Item  as={Link} to='/productions'
                                        active={this.isActive2Match('production','estimate')}
                                        onClick={this.handleItemClick}
                                        color='blue'>
                                <Icon name='calendar alternate outline'/>
                                <FormattedMessage id="dashboard.computer.header.top.menu.productions"
                                                  defaultMessage='PRODUCTIONS'/>
                            </Menu.Item>
                            <Menu.Item  as={Link} to='/orderslist'
                                        active={this.isActive('order')}
                                        onClick={this.handleItemClick}
                                        color='blue'>
                                <Icon name='cart arrow down'/>
                                <FormattedMessage id="dashboard.computer.header.top.menu.orders"
                                                  defaultMessage='ORDERS'/>
                            </Menu.Item>
                            <Menu.Item  as={Link} to={{pathname:'/inventory',state:{startPage:1}}}
                                        active={this.isActive2Match('inventory','stockarea')}
                                        onClick={this.handleItemClick}
                                        color='blue'>
                                <Icon name='warehouse'/>
                                <FormattedMessage id="dashboard.computer.header.top.menu.stock"
                                                  defaultMessage='INVENTORY'/>
                            </Menu.Item>
{/*                             <Menu.Item  as={Link} to='/invoicing'
                                        active={this.isActive('invoicing')}
                                        onClick={this.handleItemClick}
                                        color='blue'>
                                <Icon name='file alternate'/>
                                <FormattedMessage id="dashboard.computer.header.top.menu.invoicing"
                                                  defaultMessage='INVOICING'/>
                            </Menu.Item> */}
{/*                             <Menu.Item  as={Link} to={{pathname:'/sales'}}
                                        active={this.isActive('sales')}
                                        onClick={this.handleItemClick}
                                        color='blue'>
                                <Icon name='tv'/>
                                <FormattedMessage id="dashboard.computer.header.top.menu.sells"
                                                  defaultMessage='SALES'/>
                            </Menu.Item> */}
{/*                             <Menu.Item>
                                <Icon name='question'/>
                                <FormattedMessage id="dashboard.computer.header.top.menu.help"
                                                  defaultMessage='HELP'/>
                            </Menu.Item> */}
                            <Menu.Menu position='right' vertical >
                                {/* <Menu.Item text="true">Hello {username},</Menu.Item> */}
                                <Dropdown item //compact fluid
                                          trigger= {<span><Icon name='user circle' size='big'/>
                                                   {username}{/* {' '},{' '}{current_org && current_org.name} */}</span>}
                                          pointing="top right">
                                    <Dropdown.Menu style={{'paddingLeft':'1em','paddingRight':'1em'}} >
                                    <Dropdown.Item text= {email} /* icon='mail' */ disabled>
                                    </Dropdown.Item>
                                   {org_member && 
                                        <Dropdown.Item disabled>
                                                <FormattedMessage id="dashboard.computer.header.top.right.submenu.role"
                                                                               defaultMessage='role : '/>{' '}{access}
                                        </Dropdown.Item>}
                                   <Dropdown.Divider/>
                                        <Dropdown.Item text={<FormattedMessage id="dashboard.computer.header.top.right.submenu.profile"
                                                                               defaultMessage='Profile'/> }
                                                       as={Link} 
                                                       to='/profile'
                                                       icon='settings'/>
                                       {/*  <Dropdown.Divider /> */}
                                        <Dropdown.Item  disabled = {org_member && active_role<ADMIN} 
                                                        text={<FormattedMessage id="dashboard.computer.header.top.right.submenu.organisation"
                                                                                                              defaultMessage='Organization(s)'/>}
                                                        as={Link} 
                                                        to='/organisation'
                                                        icon='building'/>
                                        {sorted_orgs && (sorted_orgs.length >= 1) &&
                                            <Dropdown selection fluid
                                                       options= {sorted_orgs}
                                                       value={current_org && current_org.id}
                                                       onClick = {this.onOrgClick}
                                                       onChange={this.onOrgChange}
                                                       disabled = {sorted_orgs.length < 2} />
                                        }
                                        {/* <Dropdown.Divider /> */}
                                        <Dropdown.Item disabled = {org_member && active_role<ADMIN} text={<FormattedMessage id="dashboard.computer.header.top.right.submenu.users"
                                                                                                             defaultMessage='User(s)'/> }
                                                       as={Link} 
                                                       to='/invitation'
                                                       icon='users'
                                                       iconPosition='left'
                                                       />
                                       {/*  <Dropdown.Divider /> */}
                                        <Dropdown.Item  text={<FormattedMessage id="dashboard.computer.header.top.right.submenu.premium"
                                                                            defaultMessage='Premium plans'/> }
                                                       as={Link} 
                                                       //to='/profilepremium'
                                                       to={{pathname: '/profilepremium', state: {toggled: true}}}
                                                       icon='credit card'/>
                                        {/* <Dropdown.Divider /> */}
                                        <Dropdown.Item text={<FormattedMessage id="dashboard.computer.header.top.right.submenu.password"
                                                                            defaultMessage='Change Password'/> }
                                                        as={Link} 
                                                        to='/pwdchange'
                                                        icon='lock'
                                                        /> 
                                       {/*  <Dropdown.Divider /> */}
                                        <Dropdown.Item text={<FormattedMessage id="dashboard.computer.header.top.right.submenu.help"
                                                                               defaultMessage='Help'/> }
                                                   as={Link} 
                                                   to='/contact'
                                                   icon='help'/>
                                      {/*   <Dropdown.Divider /> */}
{/*                                         <Dropdown.Item text={<FormattedMessage id="dashboard.computer.header.top.right.submenu.logout"
                                                                               defaultMessage='Logout'/> }
                                                        as={Link} 
                                                        to='/login'
                                                        icon='sign-out'/>    */}  
                                       <br/>
                                       <Button basic 
                                               circular
                                               fluid  
                                               as={Link} 
                                               to='/login'><Icon name='log out'/>
                                         <FormattedMessage id="dashboard.mobile.header.top.right.submenu.logout"
                                                            defaultMessage='Logout'/>
                                       </Button>
                                       <br/><br/>                  
    {/*                                    <Dropdown.Item text="Open..." description="ctrl + o" />
                                    <Dropdown.Item text="Save as..." description="ctrl + s" />
                                        <Dropdown.Item text="Rename" description="ctrl + r" />
                                        <Dropdown.Item text="Make a copy" />
                                        <Dropdown.Item icon="folder" text="Move to folder" />
                                        <Dropdown.Item icon="trash" text="Move to trash" />
                                        <Dropdown.Divider />
                                        <Dropdown.Item text="Download As..." />
                                        <Dropdown.Item text="Publish To Web" />
                                        <Dropdown.Item text="E-mail Collaborators" /> */}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Menu.Menu>
                            <Menu.Item/>
                         </Container>
                    </Menu>
                   </Segment>
             </Visibility>
                {/* <Segment style={{padding:'0'}} vertical> */}
                {/*   <Container style={{ width: '100vh', border:'none', padding: '1em'}} >  */}
                    {this.props.children}
                 {/*  </Container> */}
               {/*</Segment>*/}
            </Responsive>

            <Responsive  maxWidth={1149/*Responsive.onlyTablet.maxWidth*/}>
                <Menu secondary attached="top" style={styles.FontLato14BoldGrey}>
                    <Menu.Item  onClick={() => this.setState({ menuVisible: !this.state.menuVisible })} >
                        <Icon name="sidebar" attached="top"/><Icon name="home" attached="top"/>
                    </Menu.Item>  
                    <Menu.Item position='right' as={Link} to='/'>
                        <img alt='logo' src={img_logo} style={{ marginRight: '1.5em',width: '150px', height: '43px'  }}/>
                    </Menu.Item>  
                    <Dropdown item trigger={avatar} pointing="top right">
                                <Dropdown.Menu style={{'paddingLeft':'1em','paddingRight':'1em'}}>
                                <Dropdown.Item text= {email} /* icon='mail' */ disabled/> 
                                {org_member && 
                                        <Dropdown.Item disabled>
                                                <FormattedMessage id="dashboard.computer.header.top.right.submenu.role"
                                                                               defaultMessage='role : '/>{' '}{access} 
                                        </Dropdown.Item>}
                                <Dropdown.Divider/>
                                    <Dropdown.Item text={<FormattedMessage id="dashboard.mobile.header.top.right.submenu.profile"
                                                                            defaultMessage='Profile'/> } 
                                                   as={Link} 
                                                   to='/profile'
                                                   icon='settings'/>
                                    {/* <Dropdown.Divider /> */}
                                    <Dropdown.Item disabled = {org_member && active_role<ADMIN} text={<FormattedMessage id="dashboard.computer.header.top.right.submenu.organisation"
                                                                                                         defaultMessage='Organization(s)'/> }
                                                       as={Link} 
                                                       to='/organisation'
                                                       icon='building'
                                                       />
                                        {sorted_orgs && (sorted_orgs.length >= 1) &&
                                           <Dropdown selection fluid
                                                       options= {sorted_orgs}
                                                       value={current_org && current_org.id}
                                                       onClick = {this.onOrgClick}
                                                       onChange={this.onOrgChange}
                                                       disabled = {sorted_orgs.length < 2}/>
                                        }
                                    {/* <Dropdown.Divider /> */}
                                    <Dropdown.Item  disabled = {org_member && active_role<ADMIN} text={<FormattedMessage id="dashboard.computer.header.top.right.submenu.users"
                                                                            defaultMessage='User(s)'/> }
                                                       as={Link} 
                                                       to='/invitation'
                                                       icon='users'/>
                                   {/*  <Dropdown.Divider /> */}
                                    <Dropdown.Item text={<FormattedMessage id="dashboard.mobile.header.top.right.submenu.premium"
                                                                            defaultMessage='Premium plans'/> }
                                                       as={Link} 
                                                       //to='/profilepremium'
                                                       to={{pathname: '/profilepremium', state: {toggled: true}}}
                                                       icon='credit card'/>
                                    {/* <Dropdown.Divider /> */}
                                    <Dropdown.Item text={<FormattedMessage id="dashboard.mobile.header.top.right.submenu.password"
                                                                            defaultMessage='Change Password'/> } 
                                                    as={Link} 
                                                    to='/pwdchange'
                                                    icon='lock'
                                                     /> 
                                    {/* <Dropdown.Divider /> */}
                                    <Dropdown.Item text={<FormattedMessage id="dashboard.mobile.header.top.right.submenu.help"
                                                                               defaultMessage='Help'/> }
                                                   as={Link} 
                                                   to='/contact'
                                                   icon='help'/>
                                    <br/> <br/>
{/*                                     <Dropdown.Item text={<FormattedMessage id="dashboard.mobile.header.top.right.submenu.logout"
                                                                               defaultMessage='Logout'/> }
                                                   as={Link} 
                                                   to='/login'
                                                   icon='sign-out'/> */}
                                    <Button basic 
                                               circular
                                               fluid  
                                               as={Link} 
                                               to='/login'><Icon name='log out'/>
                                         <FormattedMessage id="dashboard.mobile.header.top.right.submenu.logout"
                                                            defaultMessage='Logout'/>
                                    </Button>
                                    <br/> <br/>
{/*                                     <Dropdown.Item text="New" />
                                    <Dropdown.Item text="Open..." description="ctrl + o" />
                                    <Dropdown.Item text="Save as..." description="ctrl + s" />
                                    <Dropdown.Item text="Rename" description="ctrl + r" />
                                    <Dropdown.Item text="Make a copy" />
                                    <Dropdown.Item icon="folder" text="Move to folder" />
                                    <Dropdown.Item icon="trash" text="Move to trash" />
                                    <Dropdown.Divider />
                                    <Dropdown.Item text="Download As..." />
                                    <Dropdown.Item text="Publish To Web" />
                                    <Dropdown.Item text="E-mail Collaborators" /> */}
                                </Dropdown.Menu>
                    </Dropdown>
{/*                      <Menu.Item  postion="right" as={Link} to='/'>
                        <Image  size='tiny' src='/assets/ratatool-logo.png' />  
                    </Menu.Item> */}
                </Menu>
                <Sidebar.Pushable as={Segment} attached="top">
                    <Sidebar 
                        width='thin'
                        as={Menu} 
                        animation="overlay" 
                        direction="left" 
                        visible={this.state.menuVisible} 
                        icon="labeled" 
                        vertical
                        selection
                        onClick={() => this.setState({ menuVisible: !this.state.menuVisible })}
                        style={{background:'#F8F8F8'}}
                        size='tiny'>
                      {/* <Container> */}
{/*                         <Menu.Item header  as={Link} to='/'>
                            <Image size='tiny' src='/assets/ratatool-logo.png' style={{ marginRight: '1em' }} />
                        </Menu.Item> */}
                        <Menu.Item as={Link} to='/dashboard'
                                   active={this.isActive('dashboard')}
                                   onClick={this.handleItemClick}
                                   icon='dashboard'
                                   color='blue'>
                            <Icon name='dashboard' size='tiny'/>
                            <FormattedMessage id="dashboard.mobile.header.top.menu.dashboard"
                                               defaultMessage='Dashboard'/></Menu.Item>
                        <Menu.Item  as={Link} to='/recipes' 
                                        active={this.isActive('recipe')}
                                        onClick={this.handleItemClick}
                                        color='blue'>
                            <Icon name='book' size='tiny'/>
                            <FormattedMessage id="dashboard.mobile.header.top.menu.recipes"
                                             defaultMessage='Recipes'/></Menu.Item>
                         <Menu.Item  as={Link} to='/ingredients'
                                        active={this.isActive('ingredient')}
                                        onClick={this.handleItemClick}
                                        color='blue'>
                            <Icon name='shopping basket' size='tiny'/>
                            <FormattedMessage id="dashboard.mobile.header.top.menu.ingredients"
                                              defaultMessage='Ingredients'/></Menu.Item>
                         <Menu.Item  as={Link} to='/menus' 
                                        active={this.isActive('menu')}
                                        onClick={this.handleItemClick}
                                        color='blue'>
                            <Icon name='block layout' size='tiny'/>
                            <FormattedMessage id="dashboard.mobile.header.top.menu.menus"
                                              defaultMessage='Menus'/></Menu.Item>
                         <Menu.Item  as={Link} to='/productions'
                                        active={this.isActive('productions')}
                                        onClick={this.handleItemClick}
                                        color='blue'>
                            <Icon name='calendar alternate outline' size='tiny'/>
                            <FormattedMessage id="dashboard.mobile.header.top.menu.productions"
                                              defaultMessage='Productions'/></Menu.Item>
                         <Menu.Item  as={Link} to='/orderslist'
                                        active={this.isActive('order')}
                                        onClick={this.handleItemClick}
                                        color='blue'>
                            <Icon name='cart arrow down' size='tiny'/>
                            <FormattedMessage id="dashboard.mobile.header.top.menu.order"
                                              defaultMessage='Orders'/></Menu.Item>
                        <Menu.Item  as={Link} to={{pathname:'/inventory',state:{startPage:1}}}
                                        active={this.isActive('inventory')}
                                        onClick={this.handleItemClick}
                                        color='blue'>
                            <Icon name='warehouse' size='tiny'/>
                            <FormattedMessage id="dashboard.mobile.header.top.menu.goods"
                                               defaultMessage='Inventory'/></Menu.Item>
{/*                         <Dropdown item simple text='Shopping List'>
                            <Dropdown.Menu>
                                <Dropdown.Item>List Item</Dropdown.Item>
                                <Dropdown.Item>List Item</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Header>Header Item</Dropdown.Header>
                                <Dropdown.Item>
                                <i className='dropdown icon' />
                                <span className='text'>Submenu</span>
                                <Dropdown.Menu>
                                    <Dropdown.Item>List Item</Dropdown.Item>
                                    <Dropdown.Item>List Item</Dropdown.Item>
                                </Dropdown.Menu>
                                </Dropdown.Item>
                                <Dropdown.Item>List Item</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown> */}
                    {/* </Container>   */}
                    </Sidebar>
                    <Sidebar.Pusher dimmed={this.state.menuVisible}>
                        {/* <Segment basic> */}
                        {this.props.children}
                        {/* </Segment>    */}  
                    </Sidebar.Pusher>
                </Sidebar.Pushable>
            </Responsive>
            <Modal open={this.state.activeModalChangeOrg}
                   dimmer='default' 
                   size='tiny' 
                   style={styles.Modal} 
                   onClose={this.hideModal}>
                <Header icon='building' content={<FormattedMessage id="dashboard.organisation.modal.change.title"
                                                                        defaultMessage='Change active organization'/>} />
                            <Modal.Content>
                                    <p>
                                         <FormattedMessage id="dashboard.organisation.modal.change.message"
                                                         defaultMessage='Confirm to switch to organization "{name}" ?'
                                                         values={{name: activeModalChangeOrg && activeModalChangeOrg.name}}/>
                                    </p>
                            </Modal.Content>
                            <Modal.Actions>
                                    <Button style={styles.ButtonGrey} onClick={this.hideModal}>
                                        <FormattedMessage id="dashboard.organisation.modal.change.button.no"
                                                          defaultMessage='Cancel'/>
                                    </Button>
                                    <Button style={styles.ButtonBlue}  
                                        labelPosition='right' 
                                        content={<FormattedMessage id="dashboard.organisation.modal.change.button.yes"
                                                                    defaultMessage='Change'/>} 
                                        icon='checkmark' 
                                        onClick={(event) =>this.switchActiveOrganisation(event,activeModalChangeOrg.id)}/>
                            </Modal.Actions>
             </Modal>
             <Modal open={this.state.activeModalPremium}
                                    dimmer='true' 
                                    style={styles.Modal} 
                                    onClose={this.hideModal}
                                    closeIcon>
                                <Modal.Header>
                                    <FormattedMessage id="dashboard.organisations.header.title"
                                                    defaultMessage='Manage organization(s)'/>
                                </Modal.Header>
                                <Modal.Content>
                                    <Message fluid icon size='big' style={styles.FontLato12Bold} color='blue'>
                                    <Image src={img_performance} size ='small' rounded/>
                                    <br/>
                                    <Message.Content style={{marginLeft:'1em'}}>
                                        <Message.Header color='black'>
                                            <FormattedMessage id="dashboard.organisations.premium.message.upgradeplan"
                                                                defaultMessage='ENTERPRISE Feature'/>
                                        </Message.Header>
                                        <br/>
                                        <FormattedMessage id="dashboard.organisations.premium.title.message.upgradeplan"
                                                        defaultMessage='Your current plan: '/>{' '}{membership && membership.name}
                                                       {customer && customer.quantity && customer.membership.membership_type == 'Entreprise' &&
                                                            <p style={styles.LabelGreySmall}>{'  '}<FormattedMessage id="dashboard.organisations.premium.message.plan.include"
                                                                                                            defaultMessage="include"/>
                                                                {' '}{customer.quantity}{'  '}
                                                                <FormattedMessage id="dashboard.organisations.premium.message.plan.organisation"
                                                                                  defaultMessage="organization(s) (or company(s)) "/></p>}   
                                        <br/><br/>
                                        <Button basic color='blue' style={styles.FontLato14Bold} onClick={(e) => this.upgradeSubscription()}>
                                            <FormattedMessage id="dashboard.organisations.premium.button.chooseplan0"
                                                        defaultMessage='Select premium plan (and choose number of organisation(s))'/>
                                        </Button>                                      
                                    </Message.Content>
                                </Message>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button basic color='grey' onClick= {(e) => this.hideModal()}>
                                    {/* <Icon name='exit' /> */}
                                    <FormattedMessage id="dashboard.organisations.premium.button.cancel"
                                                    defaultMessage='Cancel'/>
                                    </Button>
                                    <Button color='blue' onClick= {(e) => this.upgradeSubscription()}>
                                    <FormattedMessage id="dashboard.organisations.premium.button.chooseplan"
                                                        defaultMessage='Choose premium plan'/>
                                    </Button>
                                </Modal.Actions>
                            </Modal> 
          </div>
        );
    }
}


export default HeaderMenu;


