export const clientConstants = {
    GETALL_REQUEST: 'CLIENT_GETALL_REQUEST',
    GETALL_SUCCESS: 'CLIENT_GETALL_SUCCESS',
    GETALL_FAILURE: 'CLIENT_GETALL_FAILURE',

    GET_REQUEST: 'CLIENT_GET_REQUEST',
    GET_SUCCESS: 'CLIENT_GET_SUCCESS',
    GET_FAILURE: 'CLIENT_GET_FAILURE',

    ADD_REQUEST: 'CLIENT_ADD_REQUEST',
    ADD_SUCCESS: 'CLIENT_ADD_SUCCESS',
    ADD_FAILURE: 'CLIENT_ADD_FAILURE',

    UPDATE_REQUEST: 'CLIENT_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'CLIENT_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'CLIENT_UPDATE_FAILURE',

    DELETE_REQUEST: 'CLIENT_DELETE_REQUEST',
    DELETE_SUCCESS: 'CLIENT_DELETE_SUCCESS',
    DELETE_FAILURE: 'CLIENT_DELETE_FAILURE',
    
    COUNT_REQUEST: 'CLIENT_COUNT_REQUEST',
    COUNT_SUCCESS: 'CLIENT_COUNT_SUCCESS',
    COUNT_FAILURE: 'CLIENT_COUNT_FAILURE',

};