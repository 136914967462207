import { clientConstants } from '../_constants';

export function client(state = {}, action) {
  switch (action.type) {
    case clientConstants.GETALL_REQUEST:
      return {
        loading_cl: true
      };
    case clientConstants.GETALL_SUCCESS:
      let options=[];
      let sorted_clients = action.client;
      if (sorted_clients){
        sorted_clients = sorted_clients.sort(function (a, b) {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        })
      }
      action.client.map((item,index)=>
            options[index]= {key:index,text:item.name,value:item.id});
      return {
        loading_cl: false,
        items: sorted_clients,
        options: options

      };
    case clientConstants.GETALL_FAILURE:
      return { 
        error: action.error
      };
    case clientConstants.GET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case clientConstants.GET_SUCCESS:
      return {
        ...state,
        selected: action.client
      };
    case clientConstants.GET_FAILURE:
      return { 
        error: action.error
      };
    case clientConstants.ADD_REQUEST:
      return {
        ...state,
        adding: true
      };
    case clientConstants.ADD_SUCCESS:

      let newItems = [...state.items,action.client]
      newItems  =  newItems.sort(function (a, b) {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      })
      let newOptions=[];
      newItems.map((item,index)=>
        newOptions[index]= {key:index,text:item.name,value:item.id});

      return {
          ...state,
          adding: false,
          added: true,
          items: newItems,
          options: newOptions
      };
    case clientConstants.ADD_FAILURE:
      return { 
        error: action.error
      };

    case clientConstants.DELETE_REQUEST:
      return {
        ...state,
        deleting: true
      };
    case clientConstants.DELETE_SUCCESS:
      newItems = state.items.filter(client => client.id != action.id);
      newOptions=[];
        newItems.map((item,index)=>
          newOptions[index]= {key:index,text:item.name,value:item.id});
      return {
        ...state,
        deleting: false,
        deleted: true,
        items: newItems,
        options: newOptions
      };
    case clientConstants.DELETE_FAILURE:
      return {
        error: action.error
      };
    case clientConstants.UPDATE_REQUEST:
      return {
        ...state,
        updating: true
      };
    case clientConstants.UPDATE_SUCCESS:
      newItems = state.items.map(client =>  {     
            if (client.id === action.id) {
              return action.client;
            }
            return client;
        });
      newOptions=[];
        newItems.map((item,index)=>
          newOptions[index]= {key:index,text:item.name,value:item.id});
      return {
        ...state,
        updating: false,
        updated: true,
        items: newItems,
        options: newOptions
      };
    case clientConstants.UPDATE_FAILURE:
      return {
        error: action.error
      };

    case clientConstants.COUNT_REQUEST:
      return {
        counting: true
      };
    case clientConstants.COUNT_SUCCESS:
      return {
        counting: false,
        count: action.client

      };
    case clientConstants.COUNT_FAILURE:
      return { 
        error: action.error
      };
  
    default:
      return state
  }
}