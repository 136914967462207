import React, { Component } from 'react'
import { Sidebar, Segment, Responsive, List, Menu, Container, Icon,Button } from 'semantic-ui-react'
import { Link,withRouter } from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import { connect } from 'react-redux';
import {history} from '../_helpers';
import {styles} from './Styles';

const containerStyle = {
  height: 'auto',
  width: '1400px',
  padding: '1em 0em',
  border:'none',
  'font-family': 'Lato', 
  'font-size': '12px',
  'color': 'grey',
  //'border-style':'solid',
  //'border-width': '0.5px',
 'background-color': '#f9f9f9',
 // backgroundImage: `url(${backgroundImg})`,
 // backgroundSize: 'cover'
};

class SideLeftUncoverProductions extends Component {
  state = { visible: false }
  
  isActive(match1,match2){
    return (this.props.location.pathname.indexOf(match1)!=-1)
             || (this.props.location.pathname.indexOf(match2)!=-1)
  }

  isActiveExact(match1,match2){
    return this.props.location.pathname === match1 
          || this.props.location.pathname === match2 
  }

  onSubmit = (e) => {
    e.preventDefault();
    history.push({pathname: '/profilepremium', state: {toggled: true}})
  }


  onSubmitProd = (e) => {
    e.preventDefault();
    history.push('/production',{date:new Date()});
  }

  onSubmitPlanning = (e) => {
    e.preventDefault();
    history.push('/productions',{planning:true});
  }


  render() {
    const {subscription} = this.props;
    return (
      <div >
        <Responsive minWidth={Responsive.onlyComputer.minWidth} >
            <Sidebar.Pushable  as={Container} style={containerStyle}>
             <Sidebar as={List} selection animation='uncover' width='thin' visible={true} icon='labeled' vertical='true' size='large'>
                <br/>
                <List.Item disabled>
                      <FormattedMessage id="productions.computer.sidemenu.menu.top.title"
                                         defaultMessage='Actions'/>
                </List.Item>
                <List.Item name='Recent' as={Link} to='/productions'>
                  <Icon name='calendar alternate outline' color={this.isActive('productions')?'blue': 'black'}  />
                  <List.Content>
                    <List.Header style={this.isActive('productions')?{'color': '#0d77b5'}:{'color': 'black'}}>
                      <FormattedMessage id="productions.computer.sidemenu.menu.elem2.title"
                                          defaultMessage='My productions'/>
                    </List.Header>
                  </List.Content>
                </List.Item>
               <List.Item name='New' onClick={(e) => {this.onSubmitProd(e)}}  style={{paddingLeft:'1.1em'}}>
                  <Icon name='add' color={this.isActiveExact('/production','/orderresult')?'blue': 'black'} />
                  <List.Content>
                    <List.Header style={this.isActiveExact('/production','/orderresult')?{'color': '#0d77b5'}:{'color': 'black'}}>
                      <FormattedMessage id="productions.computer.sidemenu.menu.elem1.title"
                                          defaultMessage='New task'/>
                    </List.Header>
                  </List.Content>
                </List.Item>
{/*                 <List.Item name='delimiter'>
                <List.Content>
                    <List.Header>
                   ------------------------- 
                    </List.Header>
                </List.Content>
                </List.Item> */}
                <List.Item name='Recent'  as={Link} to='/estimates'>
                  <Icon name='folder open' color={this.isActiveExact('/estimates')?'blue': 'black'} />
                  <List.Content>
                    <List.Header style={this.isActiveExact('/estimates')?{'color': '#0d77b5'}:{'color': 'black'}}>
                      <FormattedMessage id="productions.computer.sidemenu.menu.elem3.title"
                                          defaultMessage='My estimates'/>
                    </List.Header>
                  </List.Content>
                </List.Item>
                 <List.Item name='New'  as={Link} to='/newestimate' style={{paddingLeft:'1.1em'}}>
                  <Icon name='add' color={this.isActiveExact('/newestimate')?'blue': 'black'} />
                  <List.Content>
                    <List.Header style={this.isActiveExact('/newestimate')?{'color': '#0d77b5'}:{'color': 'black'}}>
                      <FormattedMessage id="productions.computer.sidemenu.menu.elem4.title"
                                          defaultMessage='New estimate'/>
                    </List.Header>
                  </List.Content>
                </List.Item>
                {subscription && 
                (!subscription.customer__membership__membership_type || subscription.customer__membership__membership_type=="Free") &&
                    <div style={{'text-align':'center', 'paddingTop' : '32em'}}>
                      <a style={{color:'grey'}}>
                            <FormattedMessage id="productions.computer.sidemenu.premium.title.message.subscribe"
                                              defaultMessage='Plan: Basic'/>
                      </a>
                      <Button style={styles.ButtonBluePremium}
                        onClick={(e) => {this.onSubmit(e)}}>
                        <FormattedMessage id="productions.button.premium"
                                          defaultMessage='Subscribe'/>
                      </Button>
                </div>}
              </Sidebar> 
              <Sidebar.Pusher style={{'min-height':'100vh', width: '100%' , padding: '2em 2em 2em 3em',border:'none','background-color':'#fcfcfc'}}>
                  {this.props.children} 
              </Sidebar.Pusher>
            </Sidebar.Pushable>
        </Responsive>
        <Responsive minWidth={Responsive.onlyMobile.maxWidth} maxWidth={Responsive.onlyTablet.maxWidth}>
            <Menu secondary>
                <Menu.Item  onClick={() => this.setState({ visible: !this.state.visible })} >
                    <Icon name="sidebar"/>
                        <FormattedMessage id="productions.mobile.sidemenu.menu.title"
                                          defaultMessage='Productions'/>
                </Menu.Item>          
            </Menu>
            <Sidebar.Pushable as={Segment} >
                 <Sidebar 
                    width='thin'
                    as={Menu}    
                    animation="push" 
                    direction="top" 
                    visible={this.state.visible} 
                    icon="labeled" 
                    horizontal
                    selection
                    size='tiny'>
                    <Menu.Item name='Recent' as={Link} to='/productions' style={this.isActiveExact('/productions')?{'color': '#0d77b5'}:{'color': 'black'}}>
                      <Icon name='calendar' color={this.isActive('/productions')?'blue': 'black'}/>
                      <FormattedMessage id="productions.mobile.sidemenu.menu.elem2.title"
                                          defaultMessage='My productions'/>
                    </Menu.Item>
                   <Menu.Item name='New' onClick={(e) => {this.onSubmitProd(e)}} style={this.isActiveExact('/production')?{'color': '#0d77b5'}:{'color': 'black'}}>
                      <Icon name='add' color={this.isActiveExact('/production','/orderresult')?'blue': 'black'}/>
                      <FormattedMessage id="productions.mobile.sidemenu.menu.elem1.title"
                                          defaultMessage='New task'/>
                    </Menu.Item>
                    <Menu.Item name='Recent' as={Link} to='/estimates' style={this.isActiveExact('/estimates')?{'color': '#0d77b5'}:{'color': 'black'}}>
                      <Icon name='folder open' color={this.isActive('/estimates')?'blue': 'black'}/>
                      <FormattedMessage id="productions.mobile.sidemenu.menu.elem3.title"
                                          defaultMessage='My estimates'/>
                    </Menu.Item>
                   <Menu.Item name='New'  as={Link} to='/newestimate' style={this.isActiveExact('/newestimate')?{'color': '#0d77b5'}:{'color': 'black'}}>
                      <Icon name='add' color={this.isActiveExact('/newestimate')?'blue': 'black'}/>
                      <FormattedMessage id="productions.mobile.sidemenu.menu.elem4.title"
                                          defaultMessage='New estimate'/>
                    </Menu.Item>
                 </Sidebar>
                <Sidebar.Pusher dimmed={this.state.visible} style={{minHeight: '100vh',overflow: 'auto', padding: '2em 0em 2em 2em' }}>
                      {this.props.children}
                </Sidebar.Pusher>
            </Sidebar.Pushable>
         </Responsive>
         <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
            <Menu secondary>
                <Menu.Item  onClick={() => this.setState({ visible: !this.state.visible })} >
                    <Icon name="sidebar"/>
                        <FormattedMessage id="productions.mobile.sidemenu.menu.title"
                                          defaultMessage='Productions'/>
                </Menu.Item>          
            </Menu>
            <Sidebar.Pushable as={Segment} >
                 <Sidebar 
                    width='thin'
                    as={Menu}    
                    animation="push" 
                    direction="top" 
                    visible={this.state.visible} 
                    icon="labeled" 
                    horizontal
                    selection
                    size='tiny'>
                   <Menu.Item name='Recent' as={Link} to='/productions' style={this.isActiveExact('/productions')?{'color': '#0d77b5'}:{'color': 'black'}}>
                      <Icon name='archive' color={this.isActive('/productions')?'blue': 'black'}/>
                      <FormattedMessage id="productions.mobile.sidemenu.menu.elem2.title"
                                          defaultMessage='My productions'/>
                   </Menu.Item>
                   <Menu.Item name='New' onClick={(e) => {this.onSubmitProd(e)}} style={this.isActiveExact('/production')?{'color': '#0d77b5'}:{'color': 'black'}}>
                      <Icon name='add' color={this.isActiveExact('/production','/orderresult')?'blue': 'black'}/>
                      <FormattedMessage id="productions.mobile.sidemenu.menu.elem1.title"
                                          defaultMessage='New task'/>
                    </Menu.Item>
                    <Menu.Item name='Recent' as={Link} to='/estimates' style={this.isActiveExact('/estimates')?{'color': '#0d77b5'}:{'color': 'black'}}>
                      <Icon name='folder open' color={this.isActive('/estimates')?'blue': 'black'}/>
                      <FormattedMessage id="productions.mobile.sidemenu.menu.elem3.title"
                                          defaultMessage='My estimates'/>
                    </Menu.Item>
                   <Menu.Item name='New'  as={Link} to='/newestimate' style={this.isActiveExact('/newestimate')?{'color': '#0d77b5'}:{'color': 'black'}}>
                      <Icon name='add' color={this.isActiveExact('/newestimate')?'blue': 'black'}/>
                      <FormattedMessage id="productions.mobile.sidemenu.menu.elem4.title"
                                          defaultMessage='New estimate'/>
                    </Menu.Item>
                 </Sidebar>
                <Sidebar.Pusher dimmed={this.state.visible} style={{minHeight: '100vh',overflow: 'auto', padding: '2em 0em 2em 0em' }}>
                      {this.props.children}
                </Sidebar.Pusher>
            </Sidebar.Pushable>
         </Responsive>
      </div>
    )
  }
}

//export default withRouter(SideLeftUncoverOrders)
function mapStateToProps(state) {
  const { subscription } = state.payment;
  return {
      subscription,
  };
}
export default connect(mapStateToProps)(withRouter(SideLeftUncoverProductions))