import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {Table,Grid,Dropdown,Button,Step,Icon,Image,Menu,Pagination,Dimmer,Loader,Checkbox,Header,Message,Popup,Breadcrumb} from 'semantic-ui-react'
import SideLeftUncoverIngredients from './SidebarIngredients';
import {ingredientsActions,paymentActions,alertActions} from '../_actions';
import {history,toaster_dispatch, trim,isVolume,isWeight, isQuantity,isFloat,isNumeric,isEmpty,csv_columns,isInteger,dateWithoutTimezone} from '../_helpers';
import {  WEIGHT_INSTRUCTION_LINE_TITLE,
    WEIGHT_INSTRUCTION_LINE_KG,
    WEIGHT_INSTRUCTION_LINE_G,
    WEIGHT_INSTRUCTION_LINE_MG,
    WEIGHT_INSTRUCTION_LINE_OZ,
    WEIGHT_INSTRUCTION_LINE_LB,
    VOLUME_INSTRUCTION_LINE_TITLE,
    VOLUME_INSTRUCTION_LINE_L,
    VOLUME_INSTRUCTION_LINE_ML,
    VOLUME_INSTRUCTION_LINE_CL,
    VOLUME_INSTRUCTION_LINE_FL,
    VOLUME_INSTRUCTION_LINE_GAL,
    VATValues
  } from '../_helpers';
import {FormattedMessage,injectIntl} from 'react-intl';
import Papa from 'papaparse';
import {styles} from './Styles';
import img_performance from '../Assets/performance.png'
import Dropzone from 'react-dropzone';


const options = [
    { key: '10', text: '10', value: 10 },
    { key: '20', text: '20', value: 20 },
    { key: '30', text: '30', value: 30 },
    { key: '50', text: '50', value: 50 },
  ]
const CSV_MIN_COLUMNS = 4
const CSV_MAX_COLUMNS = 22
const CSV_MAX_ROWS_LIMIT_PREMIUM = 300
const CSV_MAX_ROWS_LIMIT_NOT_PREMIUM = 15

let title = <FormattedMessage id="importingredients.file.error.title"
                            defaultMessage="Upload File Error"/>
let content_err_cols_size = <FormattedMessage id="importingredients.file.colminmax.error.content"
                                              defaultMessage="File columns number invalid - Change File/Use template to continue"/>
let content_err_cols_mandatory = <FormattedMessage id="importingredients.file.colmandatory.error.content"
                                            defaultMessage="Columns Name, Price, Quantity, Unit should be set (no duplicates)"/>
let content_err_cols_names = <FormattedMessage id="importingredients.file.colnames.error.content"
                                            defaultMessage="Some field(s) from column Name are invalid"/>
let content_err_cols_prices = <FormattedMessage id="importingredients.file.colprices.error.content"
                                              defaultMessage="Some field(s) from column Price should be numeric"/>
let content_err_cols_quantities = <FormattedMessage id="importingredients.file.colquantities.error.content"
                                                   defaultMessage="Some field(s) from column Quantity are invalid"/>
let content_err_cols_units = <FormattedMessage id="importingredients.file.colunits.error.content"
                                              defaultMessage="Some field(s) from column Units are not set"/>        
let content_err_cols_dates = <FormattedMessage id="importingredients.file.coldates.error.content"
                                              defaultMessage="Some field(s) from column Use By dates are not set"/>  
let content_err_cols_batches = <FormattedMessage id="importingredients.file.colbatch.error.content"
                                              defaultMessage="Some field(s) from column Batch Number are not set"/>                                  
let content_err_file_size = <FormattedMessage id="importingredients.file.size.error.content"
                                                 defaultMessage="File size invalid for import"/>  
let content_err_cols_pack = <FormattedMessage id="importingredients.file.colpacks.error.content"
                                                defaultMessage="Number by pack value should be an integer"/>  


function isValidDate(stringDate,locale) {
    if (locale == 'fr'){
         //FR :  DD/MM/YYY  DD-MM-YYY
        //const regex =/^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/;
        const regex =/^([0-2][0-9]|(3)[0-1])[\/|-](((0)[0-9])|((1)[0-2]))[\/|-]\d{4}$/;
        //console.log(stringDate)
        return regex.test(stringDate);
    }else{
        //US :  MM/DD/YYY  MM-DD-YYY  YYYY/MM/DD YYYY-MM-DD
        return !isNaN(Date.parse(stringDate));
    }
}
 
class ImportIngredients extends Component {
    constructor(props) {
      super(props);
      this.state = {
        csvfile: undefined,
        rejectedfile: undefined,
        listdata: null,
        isWithCol: true,
        cols: [],
        //submitted: false,
        page: 1,
        itemsPerPage: 20,
        stepIndex: 1,
        verified: false,

        optionsCategories : [
            { key: 'not used',
              text: props.intl.formatMessage(csv_columns.not_used),
              value: 'not used',
              color: "red",
            },
            { key: 'category',
              text: props.intl.formatMessage(csv_columns.category),
              value: 'category',
              color: "blue",
            },
            { key: 'supplier',
              text: props.intl.formatMessage(csv_columns.supplier),
              value: 'supplier',
              color: "blue",
            },
            { key: 'name',
              text:  props.intl.formatMessage(csv_columns.name),
              value: 'name',
              color: "blue",
            },
            { key: 'price',
              text:  props.intl.formatMessage(csv_columns.price),
              value: 'price',
              color: "blue",
            },
            { key: 'quantity',  
              text:  props.intl.formatMessage(csv_columns.quantity),
              value: 'quantity',
              color: "blue",
            },
            { key: 'unit',
              text:  props.intl.formatMessage(csv_columns.unit),
              value: 'unit',
              color: "blue",
            },
            { key: 'pack',
              text:  props.intl.formatMessage(csv_columns.pack),
              value: 'pack',
              color: "blue",
            },
            { key: 'internal code',
              text: props.intl.formatMessage(csv_columns.internal_code),
              value: 'internal code',
              color: "blue",
            },
            { key: 'supplier code',
              text: props.intl.formatMessage(csv_columns.supplier_code),
              value: 'supplier code',
              color: "blue",
            },
            { key: 'note',
              text: props.intl.formatMessage(csv_columns.note),
              value: 'note',
              color: "blue",
            },
            { key: 'allergen',
              text: props.intl.formatMessage(csv_columns.allergen),
              value: 'allergen',
              color: "blue",
            },
            { key: 'storage',
              text: props.intl.formatMessage(csv_columns.storage),
              value: 'storage',
              color: "blue",
            },
            { key: 'tax',
              text: props.intl.formatMessage(csv_columns.tax),
              value: 'tax',
              color: "blue",
            },
            { key: 'date expire',
              text: props.intl.formatMessage(csv_columns.date_expire),
              value: 'date expire',
              color: "blue",
            },
            { key: 'batch number',
              text: props.intl.formatMessage(csv_columns.batch_number),
              value: 'batch number',
              color: "blue",
            }
            ],

        DEFAULT_COLUMNS : [
            props.intl.formatMessage(csv_columns.category),
            props.intl.formatMessage(csv_columns.supplier),
            props.intl.formatMessage(csv_columns.name),
            props.intl.formatMessage(csv_columns.price),
            props.intl.formatMessage(csv_columns.quantity),
            props.intl.formatMessage(csv_columns.unit),
            props.intl.formatMessage(csv_columns.pack),
            props.intl.formatMessage(csv_columns.internal_code),
            props.intl.formatMessage(csv_columns.supplier_code),
            props.intl.formatMessage(csv_columns.note),
            props.intl.formatMessage(csv_columns.allergen),
            props.intl.formatMessage(csv_columns.storage),
            props.intl.formatMessage(csv_columns.tax),
            props.intl.formatMessage(csv_columns.date_expire),
            props.intl.formatMessage(csv_columns.batch_number)],

         COLUMN_NA_NAME : props.intl.formatMessage(csv_columns.not_used),
      };
      this.updateData = this.updateData.bind(this);
      this.setPageNum = this.setPageNum.bind(this);
      this.onElemPerPageChange = this.onElemPerPageChange.bind(this);
      props.dispatch(paymentActions.getSubscriptionState());
      props.dispatch(paymentActions.getCustomerMembership());
    }
  
    handleChange = event => {
      this.setState({csvfile: event.target.files[0]});
    };
  
    importCSV = () => {
      const { csvfile } = this.state;
      //const { dispatch } = this.props;
      if (csvfile) {
        Papa.parse(csvfile, {
                    complete: this.updateData,
                    skipEmptyLines : true,
                    comments: "#",
                    header: false,
                });
      }/* else{
        dispatch(alertActions.warn({title: 'File missing',
                                    content:'Select your file to proceed',
                                    stringMode: true }));
      } */
    };
  
    updateData(result) {
      const {isWithCol,COLUMN_NA_NAME} = this.state;
      const { dispatch } = this.props;
      let emptyRows = []

      result.data.forEach(row => {
        var allFieldsEmpty = true;
        for (let prop in row) {
          // Trim spaces from front and back 
          row[prop] = row[prop].trim();
          if (row[prop].length > 0)
          {
              allFieldsEmpty = false;
              break;
          }
        }
        if (allFieldsEmpty) emptyRows.push(result.data.indexOf(row));
      });

      var data = result.data.filter( (_,index) => !emptyRows.includes(index));    
      this.setState({ listdata: data});
      let cols={}

      if (isWithCol){
        data[0].map((i,index) => {
            if (i.charAt(i.length - 1) === 's') {
                i = i.substr(0, i.length - 1);
            }
            cols[index] = i.normalize('NFKD').toLowerCase().replace (/[\u0300-\u036F]/g, "")
        })
           
      }else{
        data[0].map((i,index) => {cols[index] = COLUMN_NA_NAME})
      }

      if (Object.keys(cols).length < CSV_MIN_COLUMNS || Object.keys(cols).length > CSV_MAX_COLUMNS){
          //console.log("Not supported - columns number invalid (use template)")
          this.setState({csvfile : undefined});

          dispatch(alertActions.error({title: 'importingredients.file.error.title',
                                      content:"importingredients.file.colminmax.error.content"}));

      }else{
        this.setState({stepIndex:2});
        this.setState({cols : cols});
      }
      //console.log(data)
    }

    getValueByNameColumns(name){
        const {optionsCategories} = this.state;
        let found = optionsCategories.find(element => element.text == name)
        if (found)
            return found.value
        else 
            return undefined
    }

    getNameByValueColumns(value){
        const {optionsCategories} = this.state;
        let found = optionsCategories.find(element => element.value == value)
        if (found)
            return found.text
        else 
            return undefined
    }

    changeColumnTitle(event,data,index){
        event.preventDefault();
        const {cols} = this.state;
        cols[index] = this.getNameByValueColumns(data.value)
        this.setState({cols : cols});
        //console.log(cols)
    }

    addColumns(){
        const {cols,verified} = this.state;
        const {optionsCategories,DEFAULT_COLUMNS,COLUMN_NA_NAME} = this.state;
        if (cols){
            return Object.keys(cols).map((key,index) => {
               if (index <= CSV_MAX_COLUMNS){
                    if (!verified){
                        //console.log(cols[key])
                        if (!DEFAULT_COLUMNS.includes(cols[key])){
                            cols[key] = COLUMN_NA_NAME
                        }
                        
                        return (
                            <Table.HeaderCell>
                                <Dropdown compact
                                    placeholder='Select'
                                    fluid
                                    selection
                                    value = {this.getValueByNameColumns(cols[key])}
                                    defaultValue= {this.getValueByNameColumns(COLUMN_NA_NAME)}
                                    options={optionsCategories}
                                    onChange={(e,data) => this.changeColumnTitle(e,data,key)} />
                            </Table.HeaderCell>
                        );

                    }else{//verified = true
                        if (cols[key] != COLUMN_NA_NAME ){
                            return (
                                <Table.HeaderCell>
                                    {cols[key]}
{/*                                     <Dropdown
                                        placeholder='Select'
                                        fluid
                                        selection
                                        disabled
                                        value = {this.getValueByNameColumns(cols[key])}
                                        defaultValue= {this.getValueByNameColumns(COLUMN_NA_NAME)}
                                        options={optionsCategories}
                                        onChange={(e,data) => this.changeColumnTitle(e,data,key)}
                                        /> */}
                                </Table.HeaderCell>
                            );
                        }
                    }
                }
              });
        }
    }

    addCells(row){
        const {cols,COLUMN_NA_NAME,verified} = this.state;
        return row.map((cell,idx) => {
            if (idx <= CSV_MAX_COLUMNS){
                if (!verified){
                    return (
                        <Table.Cell>
                            {cell}     
                        </Table.Cell>
                    );
                }else{// verified = true
                    if (cols[idx] != COLUMN_NA_NAME ){
                        return (
                            <Table.Cell>
                                {cell}     
                            </Table.Cell>
                        );
                    }
                }
            }
        });
    }

    addRows(items){
        const {isWithCol,page} = this.state;
        let startIndex = 0
        if (isWithCol) startIndex = 1

        return items.map((row,index) => {
             if (page == 1){
                if (index >= startIndex){
                    return (<Table.Row key={index}>
                        {this.addCells(row)}
                    </Table.Row>);
                }
             }else {
                return (<Table.Row key={index}>
                    {this.addCells(row)}
                </Table.Row>);
             }
        });
    }

    getColumnsIndex(name){
        const {cols} = this.state;
        let index = -1;
        index = Object.keys(cols).find(key => cols[key] === name);
        return index
    }

    createAllergensList = (allergens) => {
        let listAllergens = [];
        let normalized_allergens = allergens.normalize('NFKD').toLowerCase().replace (/[\u0300-\u036F]/g, "");
        normalized_allergens = normalized_allergens.replace("fruit a coque", "nuts");
        normalized_allergens = normalized_allergens.replace("fruit a coques", "nuts");
        normalized_allergens = normalized_allergens.replace("fruits a coques", "nuts");
        normalized_allergens = normalized_allergens.replace("fruits a coque", "nuts");

        let arrayAllergens = normalized_allergens.split(' ');  

        if (arrayAllergens){
          for (let elem of arrayAllergens){
            if (elem!==""){
                listAllergens.push({id:0,name:elem})
            }      
          }     
        }
        return listAllergens;  
    }

    assertAllNameFields(){
        let values = [];
        const {listdata,isWithCol} = this.state;
        const {intl} = this.props;
        let startIndex = 0
        if (isWithCol) startIndex = 1

        let name_index = this.getColumnsIndex(intl.formatMessage(csv_columns.name))
        listdata.map((row, idx) => {
            if (idx >= startIndex){
                if (row &&  (row[name_index] != "")
                    && (!isEmpty(row[name_index]))
                    &&(trim(row[name_index]).length>0)){
                        values.push(true)
                }else{
                    values.push(false)
                }
            }         
        });
        return values.every(v => v===true)
    }

    assertAllPriceFields(){
        const {listdata,isWithCol} = this.state;
        const {intl} = this.props;
        let startIndex = 0
        if (isWithCol) startIndex = 1
        let price_index = this.getColumnsIndex(intl.formatMessage(csv_columns.price))
        let values = [];
        listdata.map((row, idx) => {
            if (idx >= startIndex){
                let price = trim(row[price_index]).replace(/,/g, '.')
                if ((isFloat(price) || isNumeric(price))
                    && (row[price_index] != "")
                    && (!isEmpty(row[price_index]))
                    && Math.trunc(row[price_index]).toString().length<7){
                        values.push(true)
                }else{
                    values.push(false)
                }
            }       
        });
        return values.every(v => v===true)
    }

    assertAllPackFields(){
            const {listdata,isWithCol} = this.state;
            const {intl} = this.props;
            let startIndex = 0
            if (isWithCol) startIndex = 1
            let pack_index = this.getColumnsIndex(intl.formatMessage(csv_columns.pack))
            let values = [];
            listdata.map((row, idx) => {
                if (idx >= startIndex){
                    let units = Number(row[pack_index])
                    if ( isInteger(units)
                        && (units > 0)
                        && Math.trunc(units).toString().length<7){
                            values.push(true)
                    }else{
                        values.push(false)
                    }
                }       
            });
            return values.every(v => v===true)
        }

/*     assertAlTaxFields(){
        const {listdata,isWithCol} = this.state;
        const {intl} = this.props;
        let startIndex = 0
        if (isWithCol) startIndex = 1
        let tax_index = this.getColumnsIndex(intl.formatMessage(csv_columns.tax))
        let values = [];
        listdata.map((row, idx) => {
            if (idx >= startIndex){
                let tax = trim(row[tax_index]).replace(/,/g, '.')
                if ((isFloat(tax) || isNumeric(tax))
                    //&& (row[tax_index] != "")
                    //&& (!isEmpty(row[tax_index]))
                    && Math.trunc(row[tax_index]).toString().length<4){
                        values.push(true)
                }else{
                    values.push(false)
                }
            }       
        });
        return values.every(v => v===true)
    } */

    assertAllQuantityFields(){
        const {listdata,isWithCol} = this.state;
        const {intl} = this.props;
        let startIndex = 0
        if (isWithCol) startIndex = 1
        let quantity_index = this.getColumnsIndex(intl.formatMessage(csv_columns.quantity))
        let values = [];
        listdata.map((row, idx) => {
            if (idx >= startIndex){
                let quantity = trim(row[quantity_index]).replace(/,/g, '.')
                if ((isFloat(quantity) || isNumeric(quantity))
                    && (row[quantity_index] != "")
                    && (!isEmpty(row[quantity_index]))
                    && Math.trunc(row[quantity_index]).toString().length<10){
                        values.push(true)
                }else{
                    values.push(false)
                }
            }       
        });
        return values.every(v => v===true)
    }

    assertAllUnitsFieldsValidity(){
        const {listdata,isWithCol} = this.state;
        const {intl} = this.props;
        let startIndex = 0
        if (isWithCol) startIndex = 1
        let unit_index = this.getColumnsIndex(intl.formatMessage(csv_columns.unit))
        let values = [];
        listdata.map((row, idx) => {
            if (idx >= startIndex){
                if (row && (row[unit_index] != "")
                    && (!isEmpty(row[unit_index]))
                    &&(trim(row[unit_index]).length>0)){
                        values.push(true)
                }else{
                    values.push(false)
                }
            }         
        });
        return values.every(v => v===true)
    }


    assertAllDateUseByFields(){
        const {listdata,isWithCol} = this.state;
        const {intl} = this.props;
        let startIndex = 0
        if (isWithCol) startIndex = 1
        let date_expire_index = this.getColumnsIndex(intl.formatMessage(csv_columns.date_expire))
        let values = [];
        listdata.map((row, idx) => {
            if (idx >= startIndex){
                let valid_date = isValidDate(row[date_expire_index],intl.locale)
                //console.log(row[date_expire_index])
                //console.log(valid_date)
                if (valid_date || isEmpty(row[date_expire_index])){
                    values.push(true)
                }else{
                    values.push(false)
                }
            }       
        });
        return values.every(v => v===true)
    }


    assertAllBatchFields(){
        const {listdata,isWithCol} = this.state;
        const {intl} = this.props;
        let startIndex = 0
        if (isWithCol) startIndex = 1
        let batch_number_index = this.getColumnsIndex(intl.formatMessage(csv_columns.batch_number))
        let values = [];
        listdata.map((row, idx) => {
            if (idx >= startIndex){
                let batch = row[batch_number_index]
                if (isEmpty(batch) || trim(batch).length<40){
                        values.push(true)
                }else{
                    values.push(false)
                }
            }       
        });
        return values.every(v => v===true)
    }





    checkUniqueColumns(){
        const {cols,DEFAULT_COLUMNS} = this.state;
        var tmp = {};
        let count = 0;
        for(let i in cols){
            var val = cols[i];
            if (DEFAULT_COLUMNS.includes(val)){
                // Have we seen this value before?
                if(!tmp.hasOwnProperty(val)){
                    // Mark the value as seen for the first time
                    tmp[val] = []; // 0 duplicates
                }
                else{
                    // We've seen it before, save the duplicate key
                    tmp[val].push(i);
                    count+=1
                }
            }
        }
        if (count > 0){
            return false
        }
        return true
    }

    assertColumnsValidity(){
        const {cols} = this.state;
        const {intl} = this.props;
        //Assert mandatory fields are unique
        if (!this.checkUniqueColumns()){
            return false
        }
         //Assert columns mandatory are filled
        if (!this.getColumnsIndex(intl.formatMessage(csv_columns.name)) || 
            !this.getColumnsIndex(intl.formatMessage(csv_columns.price)) ||
            !this.getColumnsIndex(intl.formatMessage(csv_columns.quantity)) ||
            !this.getColumnsIndex(intl.formatMessage(csv_columns.unit)))
            return false

         //Assert columns at least min is filled
        if (Object.keys(cols).length<CSV_MIN_COLUMNS){
            return false
        }
        return true
    }

    createIngredients(e){
        e.preventDefault();
        const {listdata,isWithCol} = this.state;
        const {dispatch,intl } = this.props;
        let startIndex = 0
        if (isWithCol) startIndex = 1

        let category_index = this.getColumnsIndex(intl.formatMessage(csv_columns.category))
        let supplier_index = this.getColumnsIndex(intl.formatMessage(csv_columns.supplier))
        let name_index = this.getColumnsIndex(intl.formatMessage(csv_columns.name))
        let price_index = this.getColumnsIndex(intl.formatMessage(csv_columns.price))
        let quantity_index = this.getColumnsIndex(intl.formatMessage(csv_columns.quantity))
        let unit_index = this.getColumnsIndex(intl.formatMessage(csv_columns.unit))
        let internal_code_index = this.getColumnsIndex(intl.formatMessage(csv_columns.internal_code))
        let supplier_code_index = this.getColumnsIndex(intl.formatMessage(csv_columns.supplier_code))
        let note_index = this.getColumnsIndex(intl.formatMessage(csv_columns.note))
        let allergen_index = this.getColumnsIndex(intl.formatMessage(csv_columns.allergen))
        let storage_index = this.getColumnsIndex(intl.formatMessage(csv_columns.storage))
        let tax_index = this.getColumnsIndex(intl.formatMessage(csv_columns.tax))
        let pack_index = this.getColumnsIndex(intl.formatMessage(csv_columns.pack))
        let date_expire_index = this.getColumnsIndex(intl.formatMessage(csv_columns.date_expire))
        let batch_number_index = this.getColumnsIndex(intl.formatMessage(csv_columns.batch_number))

        if (!this.assertColumnsValidity()){
            dispatch(alertActions.error({title: 'importingredients.file.error.title',
                                        content:'importingredients.file.colmandatory.error.content'}));
            //console.log('columns mandatory incorrect => return')
            return;
        }

        if (!this.assertAllNameFields()){
            dispatch(alertActions.error({title: 'importingredients.file.error.title',
                                        content:'importingredients.file.colnames.error.content'}));
            return;
        }
    
        if (!this.assertAllPriceFields()){
            dispatch(alertActions.error({title: 'importingredients.file.error.title',
                                        content:'importingredients.file.colprices.error.content' }));
            //console.log('prices incorrect => return')
            return;
        }

/*         if (!this.assertAlTaxFields()){
            dispatch(alertActions.error({title: 'importingredients.file.error.title',
                                        content:'importingredients.file.coltax.error.content'}));
            return;
        } */

        if (!this.assertAllQuantityFields()){
            dispatch(alertActions.error({title: 'importingredients.file.error.title',
                                         content:'importingredients.file.colquantities.error.content' }));
            //console.log('quantity incorrect => return')
            return;
        }
      
        if (!this.assertAllUnitsFieldsValidity()){
            dispatch(alertActions.error({title: 'importingredients.file.error.title',
                                         content:'importingredients.file.colunits.error.content'}));
            //console.log('unit incorrect => return')
            return;
        }

        if (!this.assertAllPackFields()){
            dispatch(alertActions.error({title: 'importingredients.file.error.title',
                                         content:'importingredients.file.colpacks.error.content'}));
            return;
        }

/*         if (!this.assertAllDateUseByFields()){
            dispatch(alertActions.error({title: 'importingredients.file.error.title',
                                         content:'importingredients.file.coldates.error.content'}));
            return;
        } */

        if (!this.assertAllBatchFields()){
            dispatch(alertActions.error({title: 'importingredients.file.error.title',
                                         content:'importingredients.file.colbatch.error.content'}));
            return;
        }

        let ingredientsData = []
        //this.setState({ submitted: true });
        if (this.isPremiumUser()){
            ingredientsData = listdata.slice(startIndex,startIndex + CSV_MAX_ROWS_LIMIT_PREMIUM)
        }else{
            ingredientsData = listdata.slice(startIndex,startIndex + CSV_MAX_ROWS_LIMIT_NOT_PREMIUM)
        }

        let updates = []

        if (ingredientsData && ingredientsData.length>0) {
    
            ingredientsData.map((ingredient, idx) => {
              let purchase_weight = ""
              let purchase_volume = ""
              let purchase_quantity = ""

              if (isWeight(trim(ingredient[unit_index]))){
                    purchase_weight = "" + ingredient[quantity_index] + ingredient[unit_index]
                    purchase_weight = trim(purchase_weight).replace(/,/g, '.');
              }else if (isVolume(trim(ingredient[unit_index]))){
                    purchase_volume = "" + ingredient[quantity_index] + ingredient[unit_index]
                    purchase_volume = trim(purchase_volume).replace(/,/g, '.');
              }else if (isQuantity(trim(ingredient[unit_index]))){
                    purchase_quantity = "" + ingredient[quantity_index] + ingredient[unit_index]
                    purchase_quantity = trim(purchase_quantity);
              }

              let ingred_element = {
                name : trim(ingredient[name_index]),
                price : Number(trim(ingredient[price_index]).replace(/,/g, '.')).toFixed(3),
                purchase_quantity: purchase_quantity,
                purchase_volume : purchase_volume,
                purchase_weight : purchase_weight
              }

              if (category_index){
                if (ingredient[category_index]!=""){
                    Object.assign(ingred_element,{category :  {name:ingredient[category_index]} });
                }
              }
              if (supplier_index){
                if (ingredient[supplier_index]!=""){
                    Object.assign(ingred_element,{ supplier :  {name:ingredient[supplier_index]}});
                }
              }

              if (internal_code_index){
                if (ingredient[internal_code_index]!=""){
                    Object.assign(ingred_element,{ ref_internal :  trim(ingredient[internal_code_index]) });
                }
              }

              if (supplier_code_index){
                if (ingredient[supplier_code_index]!=""){
                    Object.assign(ingred_element,{ ref_supplier :  trim(ingredient[supplier_code_index]) });
                }
              }

              if (note_index){
                if (ingredient[note_index]!=""){
                    Object.assign(ingred_element,{ note :  ingredient[note_index] });
                }
              }

              if (allergen_index){
                if (ingredient[allergen_index]!=""){
                    Object.assign(ingred_element,{ allergens :  this.createAllergensList(ingredient[allergen_index]) });
                }
              }

              if (storage_index){
                if (ingredient[storage_index]!=""){
                    Object.assign(ingred_element,{ stock_storage :  {name:ingredient[storage_index]} });   
                }
              }

              if (tax_index){
                let str_tax = trim(ingredient[tax_index]).replace(/,/g, '.')
                if ((str_tax!="") && 
                    (isFloat(str_tax) || isNumeric(str_tax)) && 
                    (Math.trunc(str_tax).toString().length<3))
                {
                    let tax_value = Number(str_tax)
                    let tax_allowed = VATValues.reduce((prev, curr) => Math.abs(curr - tax_value) < Math.abs(prev - tax_value) ? curr : prev)

                    Object.assign(ingred_element,{ tax : Number(Number(tax_allowed)/100).toFixed(3) });   
                }
              }


              if (pack_index){
                if (ingredient[pack_index]!=""){
                    Object.assign(ingred_element,{ pack_units :  ingredient[pack_index] });
                }
              }

              if (date_expire_index){
                if (ingredient[date_expire_index]!="" 
                     && trim(ingredient[date_expire_index]).length < 50
                     && isValidDate(ingredient[date_expire_index],intl.locale)){

                    if (intl.locale == 'fr'){
                        let dateString = ingredient[date_expire_index]
                        var dateParts = dateString.split(/[/-]+/)
                        // month is 0-based, that's why we need dataParts[1] - 1
                        var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]); 
                        Object.assign(ingred_element,{ date_expire : dateWithoutTimezone(dateObject)});
                    }else{
                        let newDate = new Date(ingredient[date_expire_index])
                        Object.assign(ingred_element,{ date_expire :dateWithoutTimezone(newDate)});
                    }
                }
              }

              if (batch_number_index){
                if (ingredient[batch_number_index]!=""){
                    Object.assign(ingred_element,{ batch_number : trim(ingredient[batch_number_index]) });
                }
              }

              updates.push(ingred_element)
            });    
            this.setState({stepIndex:4});
            dispatch(ingredientsActions.addFromFile(updates));
        }
    }

    setPageNum = (event, { activePage }) => {
        this.setState({ page: activePage });
    };

    onElemPerPageChange = (e,data) => {
        e.preventDefault();
        this.setState({ itemsPerPage: data.value });
    }

    onDropAccepted(acceptedFiles) {
        //console.log(acceptedFiles[0])
        this.setState({csvfile:acceptedFiles[0]});
    }

    onDropRejected(rejectedFiles) {
        const {dispatch} = this.props;
        this.setState({rejectedFile:rejectedFiles[0]});

        dispatch(alertActions.error({title: 'importingredients.file.error.title',
                                    content:'importingredients.file.size.error.content'}));
    }

    onDeleteFile(e) {
        e.stopPropagation();
        this.setState({csvfile:undefined});
    }

    onSelect = (e,index) => {
        e.preventDefault();
        const { dispatch} = this.props;
       
        this.setState({stepIndex:index});

        if (index < 3){
            this.setState({verified:false});
        }else{
            this.setState({verified:true});
        }
    }

    onValidateStep(e){
        e.preventDefault();
        const { dispatch} = this.props;

        //Check at least one mandatory columns are set
        if (!this.assertColumnsValidity()){
                dispatch(alertActions.error({title: 'importingredients.file.error.title',
                                                    content:'importingredients.file.colmandatory.error.content'}));
                //console.log('columns mandatory incorrect => return')
                return;
        }    
        this.setState({stepIndex:3});
        this.setState({verified:true});
    } 

    onCheckboxChange = (e, data) => {
        this.setState({ isWithCol: data.checked});
    }

    upgradeSubscription = () => {
        history.push({pathname: '/profilepremium', state: {toggled: true}})
    }

    isPremiumUser(){
        const { subscription,customer } = this.props;
        let user = null;

        if (customer){
            user = customer.user;
            //console.log(user);
        } 

        if (user && user.bypass === true){
            return true
        } 

        if ((subscription && !isEmpty(subscription) && (subscription.active === true)) || (subscription.bypass === true)){
            return true
        }
        return false
    }


    render() {
      const {listdata,page,itemsPerPage,stepIndex,csvfile,isWithCol,cols} = this.state;
      const { alert,dispatch,intl,ingredients,adding} = this.props;
      let totalPages = 1;
      let items = [];
      let length = 0;
      let begin = 0;
      let end  = 0;

      if (listdata && listdata.length>0 ){
          length = listdata.length
          totalPages = Math.ceil( length / itemsPerPage);
          begin = (page - 1) * itemsPerPage;
          end = (page - 1) * itemsPerPage + itemsPerPage;
          if (end > length) end=length;
          items = listdata.slice(begin,end);
          //console.log(items)
      }

      let fileName = ''
      if (csvfile){
        fileName = csvfile.name
      }

      let content = csvfile 
      ? ( <span  style={{'font-family': 'Lato','font-size': '16px'}} color='blue'>{fileName} 
                <Icon name='trash alternate outline' color='blue' style={{'cursor': 'pointer'}} onClick={(event)=>this.onDeleteFile(event)}/>
          </span>) 
      : ( <span style={{'font-family': 'Lato','font-size': '16px'}}>
            <FormattedMessage id="importingredients.dropzone.text.line1"
                              defaultMessage="Drag 'n' drop your CSV file here, or"/>{' '}
                    <a href="#"><FormattedMessage id="importingredients.dropzone.text.line2"
                                                  defaultMessage="Click here to select"/></a></span> );

      return (
        <SideLeftUncoverIngredients>
            <div className="App">
{/*                 <h2><FormattedMessage id="importingredients.page.title"
                                      defaultMessage="Import your ingredients from a CSV file from Excel"/></h2>
                <br/> <br/> */}
                <Grid stackable style={styles.FontLato13Border} >
                   <Grid.Row columns={1}>
                     <Breadcrumb  style={{background:'#fcfcfc'}}>
                            <Breadcrumb.Section link as={Link} to={'/dashboard/'}><FormattedMessage id="importingredients.link.dashboard"
                                                                        defaultMessage='Dashboard'/></Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron'/>
                            <Breadcrumb.Section link as={Link} to={'/ingredients/'}><FormattedMessage id="importingredients.link.ingredients"
                                                                        defaultMessage='Ingredients'/></Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron'/>
                            <Breadcrumb.Section active><FormattedMessage id="importingredients.link.import"
                                                                        defaultMessage='Import'/></Breadcrumb.Section>
                     </Breadcrumb>
                    </Grid.Row>
                    <Grid.Row columns={1} >
                        <Grid.Column width={10}>
                            <Header as='h2' /* color='blue' */>
                                <Header.Content>
                                    <FormattedMessage id="importingredients.page.title"
                                      defaultMessage="Import (or add) your ingredients from a CSV file (Excel)"/>
                                </Header.Content>
                            </Header>
                            </Grid.Column>
                    </Grid.Row>
                    <br/>
                    <Grid.Row columns={1} /*style={{'overflow-x': 'auto'}}*/>
                        <Grid.Column /*width={14}*/  mobile={16} tablet={16} computer={14}> 
                            <Step.Group fluid stackable widths={4}>
                                <Step active={true}  disabled={stepIndex!=1} /*disabled={stepIndex==2 || stepIndex ==3} link={stepIndex==4} href='/importingredients'*/>
                                    <Icon name='cloud upload' color='blue'/>
                                    <Step.Content>
                                        <Step.Title><FormattedMessage id="importingredients.step1.title"
                                                                     defaultMessage="Load file"/></Step.Title>
                                        <Step.Description><FormattedMessage id="importingredients.step1.subtitle"
                                                                            defaultMessage="your CSV file"/>
                                      </Step.Description>
                                    </Step.Content>
                                </Step>
                                <Step active={true} disabled={stepIndex!=2}>
                                    <Icon name='table' color='yellow'/>
                                    <Step.Content>
                                        <Step.Title><FormattedMessage id="importingredients.step2.title"
                                                                     defaultMessage="Select your data"/></Step.Title>
                                        <Step.Description><FormattedMessage id="importingredients.step2.subtitle"
                                                                     defaultMessage="Set mandatory columns"/></Step.Description>
                                    </Step.Content>
                                </Step>
                                <Step active={true} disabled={stepIndex!=3}>
                                    <Icon name='clipboard list' color='teal'/>
                                    <Step.Content>
                                        <Step.Title><FormattedMessage id="importingredients.step3.title"
                                                                     defaultMessage="Check your data"/></Step.Title>
                                        <Step.Description><FormattedMessage id="importingredients.step3.subtitle"
                                                                     defaultMessage="As expected ?"/></Step.Description>
                                    </Step.Content>
                                </Step>
                                <Step active={true} disabled={stepIndex!=4}>             
                                    <Icon name='dolly' />
                                    <Step.Content>
                                        <Step.Title><FormattedMessage id="importingredients.step4.title"
                                                                     defaultMessage="Result"/></Step.Title>
                                        <Step.Description><FormattedMessage id="importingredients.step4.subtitle"
                                                                     defaultMessage="Import status"/></Step.Description>
                                    </Step.Content>
                                </Step>
                            </Step.Group>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1} style={{'padding': '0 0 0 0'}}/>
                    {stepIndex == 1 && <Grid.Row columns={1}>
                        <Grid.Column textAlign='left' mobile={8} tablet={8} computer={12}  style={{'paddingLeft': '2.5em'}}>
                            <Message fluid /*style={styles.FontLato13Bold}*/ warning icon >
                                    <Icon name='attention' size='large'/>
                                    <Message.Content>
                                        <Message.Header><FormattedMessage id="importingredients.step1.message.header"
                                                                defaultMessage="Here you can only do your initials ingredients import or add new items."/>
                                                    
                                        </Message.Header>
                                                <p style={{'font-weight':'bold'}} > <FormattedMessage id="importingredients.step1.message.subheader"
                                                                defaultMessage="If you need to update existing ingredients, please use side menu"/>
                                                                <Link /* to="#" */ to={process.env.PUBLIC_URL + '/updateingredients'}>{' '}
                                                                    <FormattedMessage id="importingredients.step1.message.subheader.link"
                                                                                    defaultMessage="Bulk Update"/>
                                                                </Link>
                                                </p>
                                    </Message.Content> 
                            </Message>
                            <Header size='small'>
                                  <Icon name='file excel outline'/> 
                                  <Header.Content>
                                         <FormattedMessage id="importingredients.template.file.title"
                                                         defaultMessage="Use our import template"/> {' '}
                                      <Link to={process.env.PUBLIC_URL + 'csv/'+intl.locale+'/import_template.csv'} target="_blank" download>
                                       <FormattedMessage id="importingredients.template.file.link"
                                                         defaultMessage="(download here)"/></Link>{'  '}
                                        <Popup trigger={<Icon color='teal' name='weight'/>} flowing hoverable style={{backgroundColor:'#f5f5f5'}}>
                                            <Grid centered divided columns={2} color='blue'>
                                            <Grid.Column textAlign='center'>
                                                <Header as='h5'>{WEIGHT_INSTRUCTION_LINE_TITLE}</Header>
                                                <p>{WEIGHT_INSTRUCTION_LINE_KG}</p>
                                                <p>{WEIGHT_INSTRUCTION_LINE_G}</p>
                                                <p>{WEIGHT_INSTRUCTION_LINE_MG}</p>
                                                <p>{WEIGHT_INSTRUCTION_LINE_OZ}</p>
                                                <p>{WEIGHT_INSTRUCTION_LINE_LB}</p>
                                            </Grid.Column>
                                            <Grid.Column textAlign='center'>
                                                <Header as='h5'>{VOLUME_INSTRUCTION_LINE_TITLE}</Header>
                                                <p>{VOLUME_INSTRUCTION_LINE_L}</p>
                                                <p>{VOLUME_INSTRUCTION_LINE_ML}</p>
                                                <p>{VOLUME_INSTRUCTION_LINE_CL}</p>
                                                <p>{VOLUME_INSTRUCTION_LINE_FL}</p>
                                                <p>{VOLUME_INSTRUCTION_LINE_GAL}</p>
                                            </Grid.Column>
{/*                                             <Grid.Column textAlign='center'>
                                                <Header as='h5'>{QUANTITY_INSTRUCTION_LINE_TITLE}</Header>
                                                <p>{QUANTITY_INSTRUCTION_LINE_NUMBER}</p>
                                            </Grid.Column>*/}
                                            </Grid> 
                                        </Popup>
                                  </Header.Content>
                                  <br/>
                                  <Header.Content> 
                                    <FormattedMessage id="importingredients.template.file.column.option"
                                                     defaultMessage='First line is column names'>
                                        { label => 
                                            <Checkbox label={label}
                                                    style={{margin:'0em 0em 0em 2em'}} 
                                                    onChange={this.onCheckboxChange}
                                                    checked={isWithCol}/>
                                        }
                                    </FormattedMessage>
                                  </Header.Content>
                            </Header>
                        </Grid.Column>
                    </Grid.Row>}
                    {/* <Grid.Row columns={1}/> */}
                    {stepIndex == 1 && <Grid.Row columns={2}>
                        <Grid.Column textAlign='right'  mobile={16} tablet={16} computer={14}>
                             <Button color='blue' onClick={this.importCSV} disabled={!csvfile}><FormattedMessage id="importingredients.step1.boutton.next"
                                                                                                                 defaultMessage="Next: Review your CSV upload"/></Button>
                        </Grid.Column>
                    </Grid.Row>}
                    {stepIndex == 1 &&
                       <Grid.Row columns={2}>
                        <Grid.Column textAlign='center'  mobile={16} tablet={16} computer={14}> 
                            <Dropzone onDropAccepted={this.onDropAccepted.bind(this)}
                                      onDropRejected={this.onDropRejected.bind(this)}
                                      multiple = {false}
                                      maxSize={10485760} //10MB
                                      accept=".csv"//, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                      >
                                {({getRootProps, getInputProps}) => (
                                       <section {...getRootProps()}
                                                style={{   'height': '200px',
                                                           'width': '100%',
                                                           'backgroundColor': '#f5f5f5',
                                                           'overflow': 'auto',
                                                           'text-align' : 'center'}}>
                                           <div  style={{ 'margin': '80px'}}>
                                               <input {...getInputProps()}/>
                                               <Icon name='file alternate outline' size='big'/>
                                               {content}
                                        </div>
                                    </section>)}
                            </Dropzone>
{/*                         <Segment size='large' placeholder style={{'border-style':'dashed'}}>
                            <label htmlFor="fileUpload">
                                <Icon name='cloud upload' color='blue' size='large'/>
                                 <p>Drag 'n' drop your CSV file here, or <a> Click to select your file</a></p>
                                 <p>{fileName}</p>
                            </label>
                            <Input                                  
                                id='fileUpload'
                                type="file"
                                ref={input => { this.filesInput = input;}}
                                //name="file"
                                color='blue'
                                onChange={(event) => this.handleChange(event)}
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                //accept=".xlsx, .xls, .csv"
                                style={{'display':'none'}}
                                //style={{width: '0.1px',height: '0.1px',opacity: '0',overflow: 'hidden',position: 'absolute','z-index': '-1'}} 
                             /> 
                          </Segment> */}
                        </Grid.Column>
                    </Grid.Row> }
                    {(stepIndex == 2 || stepIndex == 3) && 
                      <Grid.Row columns={1}>
                            <Grid.Column width={12}> 
                                <Message fluid /*style={styles.FontLato13Bold}*/ warning icon>
                                     <Icon name='info circle'/>
                                     <Message.Content>
                                    <Message.Header><FormattedMessage id="importingredients.step2.message.header"
                                                              defaultMessage="Select the columns to consider or ignore"/>
                                    </Message.Header>
                                    <Message.List>
                                        <Message.Item>
                                            <FormattedMessage id="importingredients.step2.message.mandatory"
                                                              defaultMessage="Mandatory: Name, Price, Quantity, Unit."/>
                                        </Message.Item>
                                        <Message.Item>
                                            <FormattedMessage id="importingredients.step2.message.optional"
                                                              defaultMessage="Optional: Category, Supplier, Tax, Case units, Internal Code, Supplier Code, Note, Allergen, Storage, Expiration date, Batch number."/>
                                        </Message.Item>
{/*                                         <Message.Item>
                                            <FormattedMessage id="importingredients.step2.message.premium"
                                                              defaultMessage="Not subscribed users are limited to 10 ingredients imported."/>
                                        </Message.Item> */}
                                    </Message.List>
                                    </Message.Content>
                                </Message>
                          </Grid.Column>
                          <br/> <br/> 
                    </Grid.Row>}
                    {stepIndex == 2 && 
                     <Grid.Row columns={3} style={{'overflow': 'auto'}}>
                        <Grid.Column textAlign='left' mobile={8} tablet={8} computer={7}>
                             <Button color='blue' onClick={(e)=>this.onSelect(e,1)}><FormattedMessage id="importingredients.step2.boutton.back"
                                                                                                       defaultMessage="Back: Change your file"/></Button>
                        </Grid.Column>
                        <Grid.Column textAlign='right' mobile={8} tablet={8} computer={7}> 
                        {items && items.length >0 &&          
                            <Button color='blue' onClick={(e)=> this.onValidateStep(e)}><FormattedMessage id="importingredients.step2.boutton.next"
                                                                                                            defaultMessage="Next: Verify the result"/></Button>}
                        </Grid.Column>
                    </Grid.Row>}
                    {stepIndex == 2 && 
                    <Grid.Row columns={1} style={{'overflow': 'auto'}}>
                        <Grid.Column /*width={14}*/  mobile={16} tablet={16} computer={14} > 
                        {items && items.length >0 && cols &&         
                                    <Table selectable  
                                            unstackable 
                                            compact 
                                            striped 
                                            singleLine 
                                            style={styles.FontLato10}>
                                            <Table.Header>
                                                <Table.Row>
                                                    {this.addColumns(cols)} 
                                                </Table.Row>
                                            </Table.Header>   
                                            <Table.Body>
                                                {this.addRows(items)}
                                            </Table.Body>
                                    </Table>}
                        </Grid.Column>
                    </Grid.Row>}
                    {stepIndex == 2 && 
                    <Grid.Row columns={3}>
                    {items && items.length >0 && 
                            <Grid.Column textAlign='left'>
                                  <span><Menu compact secondary centered style={styles.FontLato12}>
                                         <Dropdown defaultValue={20} 
                                                  options={options} 
                                                  item
                                                  inline
                                                  fluid
                                                  onChange={this.onElemPerPageChange}
                                                  />
                                  </Menu>&ensp;{begin+1} <FormattedMessage id="importingredients.pagination.to"
                                                              defaultMessage='to'/> {end} <FormattedMessage id="importingredients.pagination.over"
                                                              defaultMessage='of'/> {length}</span>
                                </Grid.Column>}
                        {items && items.length >0 && 
                                <Grid.Column textAlign='center'>
                                    <Pagination color='blue'   
                                        secondary
                                        activePage={page}
                                        totalPages={totalPages}
                                        siblingRange={4}
                                        boundaryRange ={0}
                                        //defaultActivePage={10}
                                        ellipsisItem={{ content: <Icon name='ellipsis horizontal' size='small'/>, icon: true }}
                                        //ellipsisItem={null}
                                        firstItem = {null} 
                                        lastItem = {null}
                                        onPageChange={this.setPageNum}
                                        size='small'
                                    />
                                </Grid.Column>}
                    </Grid.Row> }
                    {stepIndex == 3 && 
                     <Grid.Row columns={3} style={{'overflow': 'auto'}}>
                        <Grid.Column textAlign='left' mobile={8} tablet={8} computer={7}>
                             <Button color='blue' onClick={(e)=>this.onSelect(e,2)}><FormattedMessage id="importingredients.step3.boutton.back"
                                                                                                       defaultMessage="Back: Update columns"/></Button>
                        </Grid.Column>
                        <Grid.Column textAlign='right' mobile={8} tablet={8} computer={7}> 
                        {items && items.length >0 &&          
                            <Button color='blue' onClick={(e)=> this.createIngredients(e)}><FormattedMessage id="importingredients.step3.boutton.next"
                                                                                                            defaultMessage="Next: Create ingredients"/></Button>}
                        </Grid.Column>
                    </Grid.Row>}
                    {stepIndex == 3 && 
                    <Grid.Row columns={1} style={{'overflow': 'auto'}}>
                        <Grid.Column /*width={14}*/  mobile={16} tablet={16} computer={14} > 
                        {items && items.length >0 && cols &&         
                                    <Table selectable  
                                            unstackable 
                                            compact 
                                            striped 
                                            singleLine 
                                            style={styles.FontLato11} >
                                            <Table.Header>
                                                <Table.Row>
                                                    {this.addColumns(cols)} 
                                                </Table.Row>
                                            </Table.Header>   
                                            <Table.Body>
                                                {this.addRows(items)}
                                            </Table.Body>
                                    </Table>}
                        </Grid.Column>
                    </Grid.Row>}
                    {stepIndex == 3 && 
                    <Grid.Row columns={3}>
                    {items && items.length >0 && 
                            <Grid.Column textAlign='left'>
                                  <span><Menu compact secondary centered style={styles.FontLato12}>
                                         <Dropdown defaultValue={20} 
                                                  options={options} 
                                                  item
                                                  inline
                                                  fluid
                                                  onChange={this.onElemPerPageChange}
                                                  />
                                  </Menu>&ensp;{begin+1} <FormattedMessage id="importingredients.pagination.to"
                                                              defaultMessage='to'/> {end} <FormattedMessage id="importingredients.pagination.over"
                                                              defaultMessage='of'/> {length}</span>
                                </Grid.Column>}
                        {items && items.length >0 && 
                                <Grid.Column textAlign='center'>
                                    <Pagination color='blue'   
                                        secondary
                                        activePage={page}
                                        totalPages={totalPages}
                                        siblingRange={4}
                                        boundaryRange ={0}
                                        //defaultActivePage={10}
                                        ellipsisItem={{ content: <Icon name='ellipsis horizontal' size='small'/>, icon: true }}
                                        //ellipsisItem={null}
                                        firstItem = {null} 
                                        lastItem = {null}
                                        onPageChange={this.setPageNum}
                                        size='small'
                                    />
                                </Grid.Column>}
                    </Grid.Row> } 
                    <Grid.Row columns={1}/>  
                    {stepIndex == 4 &&  ingredients &&
                     <Grid.Row columns={3} >
                            <Dimmer active={adding} inverted>
                                <Loader indeterminate><FormattedMessage id="importingredients.step4.result.loading.text"
                                                                        defaultMessage='Import ongoing ...'/></Loader>
                            </Dimmer>
                            <Grid.Column textAlign='left'  mobile={10} tablet={10} computer={10}>
                               {!adding && ingredients.items && ingredients.items.length > 0 &&
                                    <Message icon fluid style={styles.FontLato13Bold}>
                                        <Icon name='thumbs up outline' size='small'/>
                                        <Message.Content>
                                            <Message.Header>
                                                <FormattedMessage id="importingredients.step4.result.success.header"
                                                                defaultMessage="Great job"/>
                                            </Message.Header>
                                            <Message.List>
                                                <Message.Item>
                                                    {ingredients.items[0].length}&nbsp;
                                                    <FormattedMessage id="importingredients.step4.result.success.status"
                                                                    defaultMessage="elements created with success (ready for work)."/>
                                                </Message.Item>
                                                {!this.isPremiumUser() &&
                                                <Message.Item>
                                                    <FormattedMessage id="importingredients.step4.result.success.premium"
                                                                    defaultMessage="Your are limited to 15 ingredients"/>
                                                </Message.Item>}
                                            </Message.List>
                                        </Message.Content>
                                    </Message>
                               }
                              {!adding && (!ingredients.items || ingredients.items[0].length == 0 ) &&
                                <Message icon fluid style={styles.FontLato13Bold}>
                                    <Icon name='redo' size='small'/>
                                    <Message.Content>
                                        <Message.Header>
                                                <FormattedMessage id="importingredients.step4.result.error.header"
                                                                defaultMessage="Oops something happened"/>
                                        </Message.Header>
                                        <Message.List>
                                                <Message.Item>
                                                    <FormattedMessage id="importingredients.step4.result.error.message"
                                                        defaultMessage="No element created (check your file and retry)."/>
                                                </Message.Item>
                                                {!this.isPremiumUser() &&
                                                <Message.Item>
                                                    <FormattedMessage id="importingredients.step4.result.success.premium"
                                                                    defaultMessage="Your are limited to 15 ingredients"/>
                                                </Message.Item>}
                                        </Message.List>
                                    </Message.Content>
                               </Message>
                               }
                               {!adding && !this.isPremiumUser() &&
                                    <Message fluid icon size='big' style={styles.FontLato12Bold} color='blue'>
                                        <Image src={img_performance} size ='small' rounded/>
                                        <br/>
                                        <Message.Content style={{marginLeft:'1em'}}>
                                            <Message.Header color='black'>
                                                                <FormattedMessage id="importingredients.premium.message.upgradeplan"
                                                                                    defaultMessage='PREMIUM Feature'/>
                                            </Message.Header>
                                            <br/>
                                            <Button basic color='blue' style={styles.FontLato12Bold} onClick={(e) => this.upgradeSubscription()}>
                                                                <FormattedMessage id="importingredients.premium.button.chooseplan"
                                                                            defaultMessage='Choose premium plan'/>
                                            </Button>                                      
                                        </Message.Content>
                                    </Message>}
                            </Grid.Column>
                     </Grid.Row>}
                </Grid>
            </div>
            {alert.message && toaster_dispatch(dispatch,alert,intl)}
        </SideLeftUncoverIngredients>
      );
    }
  }
  


  function mapStateToProps(state) {
    const { dispatch, alert,ingredients } = state;
    const { subscription,customer } = state.payment;
    const { adding } = state.ingredients;
    return {
        ingredients,
        adding,
        subscription,
        customer,
        dispatch,
        alert,
    };
  }
  
  
  const connectedImportIngredients = injectIntl(connect(mapStateToProps)(ImportIngredients));
  export { connectedImportIngredients as ImportIngredients };


 