import React, { Component } from 'react';
import { Table, Button, Grid, Modal,Sidebar,Header,Popup,Input,Icon,Dimmer,Loader,Checkbox,Segment,Form,Message,Image,Breadcrumb} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {recipesActions,ingredientsActions,alertActions,paymentActions,/*,sortingActions*/} from '../_actions'
import './Recipes.css';
import SideLeftUncoverMenu from './SidebarMenu';
import {history,toaster_dispatch,toaster_info,Capitalize,trim,isFloat,isNumeric,VAToptions,optimizeDecimalPart,MAX_NUMBER_INGREDIENTS_FREE_SUBSCRIBER,
      MAX_NUMBER_RECIPES_FREE_SUBSCRIBER,isEmpty,typeYield,isWeight,isVolume,mgrams,grams,kgrams,cliters,mliters,liters,GUEST} from '../_helpers';
import {FormattedMessage,FormattedNumber,injectIntl} from 'react-intl';
import {styles} from './Styles';
import img_pan from '../Assets/pan.png'
import img_bag from '../Assets/shopping-bag.png';
import img_performance from '../Assets/performance.png'
import './FloatingMenu.css';
import OutsideClickHandler from 'react-outside-click-handler';
import _ from 'lodash'


const DUPLICATE_ADD_RECIPE_MESSAGE_TITLE = <FormattedMessage id="editmenu.toaster.recipe.add.duplicate.message.title"
  defaultMessage='Adding recipe/ingredient ...'/>

const DUPLICATE_ADD_RECIPE_MESSAGE_CONTENT = <FormattedMessage id="editmenu.toaster.recipe.add.duplicate.message.content"
  defaultMessage='Already added to your menu'/>

const UNITS = <FormattedMessage id="editmenu.quantity.unit.plural.text"
                        defaultMessage='unit(s)'/>


class EditMenu extends Component {
    constructor(props) {
        super(props);
        let userIn = JSON.parse(localStorage.getItem('userIn'));
        this.hideModal = this.hideModal.bind(this);
        this.filterNameList = this.filterNameList.bind(this);
        this.state = { 
            prevRecipe : props.recipes,
            prevIngredient : props.ingredients,
            activeModal: null,
            actionEditModal: false,
            currency : userIn.user.currency,
            isMobile: false,
            actionModal: false,
            activeModalRecipeDelete: null,
            activeModalIngredientDelete: null,
            availableRecipesList:[],
            availableIngredientsList:[],
            menuRecipesList:[],
            menuIngredientsList:[],
            menuList: [],
            newQuantity:0,
            newPrice:0,
            newUnit: '',
            name: "",
            price: "",
            vat: 0,
            cost_total: 0,
            avg_margin: 0,
            food_cost: 0,
            //min_margin: 0,
            //max_margin: 0,
            isSelling: false,
            submitted: false,
            active_role: userIn.user.role,
            //column:"",
            //direction:"",
            //prevSorting: props.sorting,
            //currentSortColumn: ""
         }

        props.dispatch(recipesActions.getAll(true)); 
        props.dispatch(ingredientsActions.getAll(true));
        props.dispatch(recipesActions.getMenu(props.match.params.menuId));
        props.dispatch(paymentActions.getSubscriptionState());
        
    }

    static getDerivedStateFromProps(props, state) {
        if((props.recipes!==state.prevRecipe)&&(props.recipes.items)){
            return { prevRecipe : props.recipes,
                     availableRecipesList: props.recipes.items };
        }
        if((props.ingredients!==state.prevIngredient)&&(props.ingredients.items)){
            return { prevIngredient : props.ingredients,
                     availableIngredientsList: props.ingredients.items };
        }
        if((props.recipes!==state.prevRecipe) 
            && (props.recipes.menu_r_items) 
            && (props.recipes.menu_i_items) 
            && (props.recipes.menu)) {
            return { prevRecipe : props.recipes,
                     menuRecipesList: props.recipes.menu_r_items,
                     menuIngredientsList: props.recipes.menu_i_items,
                     name: props.recipes.menu.name,
                     price: optimizeDecimalPart(Number(props.recipes.menu.selling_price)).toString(),
                     isSelling: props.recipes.menu.is_selling,
                     cost_total: props.recipes.menu.cost_total,
                     avg_margin : props.recipes.menu.margin_avg,
                     food_cost : props.recipes.menu.food_cost,
                     //max_margin: props.recipes.menu.margin_max,
                     //min_margin: props.recipes.menu.margin_min,
                     vat: Number(props.recipes.menu.vat),
                    };
        }
/*         if((props.sorting!==state.prevSorting)){
            return {   prevSorting : props.sorting,
                       menuRecipesList: _.sortBy(props.sorting.data_r, props.sorting.column),
                       menuIngredientsList: _.sortBy(props.sorting.data_i, props.sorting.column),
                       new_sort_column: props.sorting.column,
                       new_sort_direction: props.sorting.direction
            };

        } */
        return null;
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }
    
    resize() {
        this.setState({isMobile: window.innerWidth <= 760});
        this.setState({isTablet: (window.innerWidth >= 760 && window.innerWidth < 900)});
    }

/*     setPageNum = (event, { activePage }) => {
        this.setState({ page: activePage });
        //this.resetSelectionList();
        //this.setState({isCheckedAll: false});
    };

    onElemPerPageChange = (e,data) => {
        e.preventDefault();
        this.setState({ itemsPerPage: data.value });
    } */


    hideModal() {
        this.setState({ activeModalRecipeDelete: false });
        this.setState({ activeModalIngredientDelete: false });
        this.setState({ actionEditModal: false });
        
    }

    OnNameChange = (e) => {
        this.setState({ name: e.target.value });
    }

    onCheckboxChange = (e, data) => {
        if (data.id == 'selling')
            this.setState({ isSelling: data.checked});
      }

    OnPriceChange = (e) => {
        this.setState({ price: e.target.value });
    }

    onVATChange = (e,data) => {
        e.preventDefault();
        this.setState({ vat: data.value});
    }

    assertNameField(){
        if ((this.state.name != "")
              &&(trim(this.state.name).length>0))
          return true;
        return false;
    }

    assertPriceField(){
        let price = trim(this.state.price).replace(/,/g, '.')
        if ((isFloat(price) || isNumeric(price))
          && (this.state.price != "")
          && Math.trunc(this.state.price).toString().length<7)
          return true;
        return false;
    }

    editMenu = (e) => {
        e.preventDefault();
        const { name,price,isSelling,vat} = this.state;
        const {menu} = this.props;
        this.setState({ submitted: true });

        if (!this.assertNameField()){
            return;
        }

        if (!this.assertPriceField()){
            return;
        }

        if (name && menu ){
            const menu_infos = {
                         name:trim(name),
                         selling_price:Number(trim(price).replace(/,/g, '.')).toFixed(3),
                         vat:vat,
                         is_selling: isSelling
            }
            this.props.dispatch(recipesActions.updateMenu(menu.id,menu_infos));
            this.hideModal()
        }
    }

    addToMenuList (event,recipe) {
        const {menu,dispatch,intl } = this.props;

         var found = this.state.menuRecipesList.find(x => x.recipe.id == recipe.id)
 
         if (!found){
            if (menu && recipe){
                dispatch(recipesActions.addRecipeMenu(menu.id,{recipe_id:recipe.id}));
            }
         }else{
             toaster_info(intl.formatMessage({ id: 'editmenu.toaster.recipe.add.duplicate.message.title' }),
                          intl.formatMessage({ id: 'editmenu.toaster.recipe.add.duplicate.message.content' }))
         }  
        
    }

    addIngredientToCartList (event,ingredient) {
        const {menu,dispatch,intl} = this.props;
        var found = this.state.menuIngredientsList.find(x => x.ingredient.id == ingredient.id)

        if (!found){
            if (menu && ingredient){
               dispatch(recipesActions.addIngredientMenu(menu.id,{ingredient_id:ingredient.id}));
            }
        }else{
            toaster_info(intl.formatMessage({ id: 'editmenu.toaster.recipe.add.duplicate.message.title' }),
                         intl.formatMessage({ id: 'editmenu.toaster.recipe.add.duplicate.message.content' }))
        }    
    }

    editQuantityHandlerInput (event,index,breakdown,type) {
        event.preventDefault();
        const {intl} = this.props; 
        //let quantity = intl.formatNumber(Number(breakdown.quantity_value).toFixed(2));
        let quantity = optimizeDecimalPart(Number(breakdown.quantity_value))
        this.setState({ newQuantity: quantity});
        let relevantUnit

        if (type == 'recipe'){
            this.setState({ menu_recipe: breakdown.recipe.id});

            if (this.state.newUnit == ''){
                if (breakdown.is_recipes_unit){
                    relevantUnit = 'recipe'
                   
                }else{
                    relevantUnit = breakdown.recipe.yield_units
                }
                
            }else{
                relevantUnit = this.state.newUnit
            }
            this.setState({ newUnit: relevantUnit});
            
            this.setState({ editCellRecipe: true});
            this.setState({ editCellIndexRecipe: index});
        }else{
            this.setState({ menu_ingredient: breakdown.ingredient.id});

            if (this.state.newUnit == ''){
                relevantUnit = breakdown.quantity_unit.toLowerCase()
                if (relevantUnit == ''){
                    relevantUnit = intl.formatMessage({ id: 'editmenu.quantity.unit.plural.text' }) 
                }
            }else{
                relevantUnit = this.state.newUnit
            }
            this.setState({ newUnit: relevantUnit});

            this.setState({ editCellIngredient: true});
            this.setState({ editCellIndexIngredient: index});
        }


        this.setState({ editCellRecipePrice: false});
        this.setState({ editCellIndexRecipePrice: null});

    };

    editPriceHandlerInput (event,index,breakdown) {
        event.preventDefault();
        let selling_price = optimizeDecimalPart(Number(breakdown.recipe.selling_price)/Number(breakdown.recipe.quantity_parts))

        this.setState({ newPrice: selling_price});


        this.setState({ menu_recipe: breakdown.recipe.id});
        this.setState({ editCellRecipePrice: true});
        this.setState({ editCellIndexRecipePrice: index});

        this.setState({ editCellRecipe: false});
        this.setState({ editCellIndexRecipe: null});
    };

    deleteItemClickHandler (event,index,breakdown,type) {
        event.preventDefault();
        if (type == 'recipe'){
            this.setState({ activeModalRecipeDelete: index});
            this.setState({ menu_recipe: breakdown.recipe.id});
        }else{
            this.setState({ activeModalIngredientDelete: index});
            this.setState({ menu_ingredient: breakdown.ingredient.id});
        }
    };

    deleteItemMenu(event,type){
        event.preventDefault();
        const {menu} = this.props; 
        const { menu_recipe,menu_ingredient } = this.state;
        if (type == 'recipe'){
            if (menu && menu_recipe){
                this.props.dispatch(recipesActions.deleteRecipeMenu(menu.id,
                                                                    {recipe_id:menu_recipe}));
                this.hideModal()
            }
        }else{
            if (menu && menu_ingredient){
                this.props.dispatch(recipesActions.deleteIngredientMenu(menu.id,
                                                                       {ingredient_id:menu_ingredient}));
                this.hideModal()
            }
        }
    }

    filterNameList(event){
        event.preventDefault();
        const {availableRecipesList,availableIngredientsList,toggledRecipes} = this.state;
        this.setState({nameSelected: event.target.value});
        if (toggledRecipes){
            let updatedList = availableRecipesList.filter((item) => {
                return (item.name).toLowerCase().search(event.target.value.toLowerCase()) !== -1;
              });
            this.setState({newItems: updatedList});
            this.setState({filtering: true});
        }else{
            let updatedList = availableIngredientsList.filter((item) => {
                return (item.name).toLowerCase().search(event.target.value.toLowerCase()) !== -1;
              });
            this.setState({newItems: updatedList});
            this.setState({filtering: true}); 
        }

    }

    seeRecipe (event,index) {
        event.preventDefault();
        history.push('/recipedetails/'+index);
    }

    seeIngredient (event,index) {
        event.preventDefault();
        history.push('/ingredientdetails/'+index);
    }

    createIngredient(){
        history.push('/newingredient/');
    }

    createRecipe(){
        history.push('/newrecipe/');
    }

    onNewQuantity = (e,item) => {
        //e.preventDefault();
        this.setState({ newQuantity: e.target.value});
    }

    onNewPrice = (e,item) => {
        //e.preventDefault();
        this.setState({ newPrice: e.target.value});
    }

    onNewUnit = (e,data) => {
        //e.preventDefault();
        this.setState({ newUnit: data.value});
    }

    assertQuantityField(quantity){
        if ((isFloat(quantity) || isNumeric(quantity))
          && (quantity != "")
          && Math.trunc(quantity).toString().length<10)
          return true;
        return false;
    }

     updateQuantityOnBlur (event,menu_id,breakdown,type) {
        event.preventDefault();
        const {newQuantity,newUnit,menu_recipe,menu_ingredient,active_role/*,currentSortColumn,menuIngredientsList,menuRecipesList,direction,column*/} = this.state;
        const {dispatch,intl} = this.props;

        let quantity = Number(trim(newQuantity.toString()).replace(/,/g, '.')).toFixed(3);
        let quantity_origin = breakdown.quantity_value;
        quantity_origin = Number(quantity_origin).toFixed(3) 

        let unit_origin = breakdown.quantity_unit
        if (breakdown.is_recipes_unit)
           unit_origin = 'recipe'; 
        let is_recipes_unit = (newUnit == 'recipe')

        let emptyIngredientUnit = intl.formatMessage({ id: 'editmenu.quantity.unit.plural.text' }) 

        const title = <FormattedMessage id="editmenu.quantity.check.error.title"
                                        defaultMessage='Quantity update error'/>

        if (this.assertQuantityField(quantity) && (quantity!=quantity_origin || unit_origin!=newUnit)){
            
            if (type == 'recipe'){
                if (active_role!= GUEST){
                    dispatch(recipesActions.updateRecipeMenu( menu_id,
                                                            { recipe_id:menu_recipe,
                                                            quantity_value:quantity,
                                                            is_recipes_unit:is_recipes_unit}));
                                                            //{column: column, direction: direction }));
                }else{
                    const content =  <FormattedMessage id="editmenu.quantity.privilege.error.message"
                                                        defaultMessage='You do not have Right Privilege'/>
                    dispatch(alertActions.error({title:'editmenu.quantity.privilege.error.message',
                                                content:'editmenu.quantity.check.error.title'}));  
                }
                this.setState({ editCellRecipe: false });
            }else{
                if (active_role!= GUEST){
                    dispatch(recipesActions.updateIngredientMenu(menu_id,{ingredient_id:menu_ingredient,
                                                                        quantity_value:quantity,
                                                                        quantity_unit: (newUnit!=emptyIngredientUnit?newUnit:"")}));
                }else{
                    const content =  <FormattedMessage id="editmenu.quantity.privilege.error.message"
                                                        defaultMessage='You do not have Right Privilege'/>
                    dispatch(alertActions.error({title:'editmenu.quantity.privilege.error.message',
                                                content:'editmenu.quantity.check.error.title'}));  
                }
                this.setState({ editCellIngredient: false });
            }
            this.setState({ newUnit: '' });
            //dispatch(sortingActions.sortDirection('APPLY_SORT',currentSortColumn,menuRecipesList,menuIngredientsList,direction))
        }else{
            if (Math.trunc(quantity).toString().length>=9){
                const content =  <FormattedMessage id="editmenu.quantity.check.error.message"
                                                  defaultMessage='Quantity field too big (must be less than 10 digits)'/>
                 dispatch(alertActions.error({title:'editmenu.quantity.check.error.title',
                                             content:'editmenu.quantity.check.error.message'}));   
             }
            this.setState({ editCellRecipe: false });
            this.setState({ newUnit: '' });
            this.setState({ editCellIngredient: false });
        }
    }

    updateQuantityOnEnter (event,menu_id,breakdown,type) {
        const {newQuantity,newUnit,menu_recipe,menu_ingredient,active_role/*,currentSortColumn,menuIngredientsList,menuRecipesList,direction,column*/} = this.state;
        const {dispatch,intl} = this.props;

        if (event.key === 'Enter') {
                let quantity = Number(trim(newQuantity.toString()).replace(/,/g, '.')).toFixed(3);
                let quantity_origin = breakdown.quantity_value;
                quantity_origin = Number(quantity_origin).toFixed(3)

                let unit_origin = breakdown.quantity_unit
                if (breakdown.is_recipes_unit)
                    unit_origin = 'recipe'; 
                let is_recipes_unit = (newUnit == 'recipe')

                let emptyIngredientUnit = intl.formatMessage({ id: 'editmenu.quantity.unit.plural.text' }) 
                const title = <FormattedMessage id="editmenu.quantity.check.error.title"
                                                defaultMessage='Quantity update error'/>
                
                if (this.assertQuantityField(quantity)  /*&& (quantity!=quantity_origin || unit_origin!=newUnit)*/){
                    if (type == 'recipe'){
                        if (active_role!= GUEST){
                            dispatch(recipesActions.updateRecipeMenu(menu_id,
                                                                    {recipe_id:menu_recipe,
                                                                    quantity_value:quantity,
                                                                    is_recipes_unit:is_recipes_unit}));
                                                                    //{column: column, direction: direction }));
                        }else{
                            dispatch(alertActions.error({title:'editmenu.quantity.privilege.error.message',
                                                        content:'editmenu.quantity.check.error.title'}));  
                        }
                        this.setState({ editCellRecipe: false });
                        
                    }else{
                        if (active_role!= GUEST){
                            dispatch(recipesActions.updateIngredientMenu(menu_id,{ingredient_id:menu_ingredient,    
                                                                                quantity_value:quantity,
                                                                                quantity_unit: (newUnit!=emptyIngredientUnit?newUnit:"")}));
                        }else{
                            dispatch(alertActions.error({title:'editmenu.quantity.privilege.error.message',
                                                         content:'editmenu.quantity.check.error.title'}));  
                        }
                        this.setState({ editCellIngredient: false });
                    }
                    this.setState({ newUnit: '' });
                    //dispatch(sortingActions.sortDirection('APPLY_SORT',currentSortColumn,menuRecipesList,menuIngredientsList,direction))
                }else{
                    if (Math.trunc(quantity).toString().length>=9){
                       const content =  <FormattedMessage id="editmenu.quantity.check.error.message"
                                                         defaultMessage='Quantity field too big (must be less than 10 digits)'/>
                        dispatch(alertActions.error({title:'editmenu.quantity.check.error.title',
                                                    content:'editmenu.quantity.check.error.message'}));   
                    }
                    this.setState({ editCellRecipe: false });
                    this.setState({ editCellIngredient: false });
                    this.setState({ newUnit: '' });
                }
        }
    }



    updatePriceOnEnter (event,menu_id,breakdown) {
        const {newPrice,menu_recipe,active_role} = this.state;
        const {dispatch} = this.props;

        if (event.key === 'Enter') {
                let price = Number(trim(newPrice.toString()).replace(/,/g, '.')).toFixed(3);
                price = price * Number(breakdown.recipe.quantity_parts)
                let price_origin = breakdown.recipe.selling_price;
                price_origin = Number(price_origin).toFixed(3)

                let quantity = Number(trim(breakdown.quantity_value.toString()).replace(/,/g, '.')).toFixed(3);

                let unit_origin = breakdown.quantity_unit
                if (breakdown.is_recipes_unit)
                    unit_origin = 'recipe'; 
                let is_recipes_unit = (unit_origin == 'recipe')

                const title = <FormattedMessage id="editmenu.price.check.error.title"
                                                defaultMessage='Price update error'/>
                
                if (this.assertQuantityField(price) && (price!=price_origin)){
                        if (active_role!= GUEST){

                            const recipe = {
                                id:menu_recipe,
                                name:breakdown.recipe.name,
                                selling_price: Number(trim(price.toString()).replace(/,/g, '.')).toFixed(3),
                            }

                            dispatch(recipesActions.update(menu_recipe,recipe));
                            breakdown.recipe.selling_price = price
                            setTimeout(() => {
                                dispatch(recipesActions.updateRecipeMenu(menu_id,
                                                                        {recipe_id:menu_recipe,
                                                                        quantity_value:quantity,
                                                                        is_recipes_unit:is_recipes_unit}));
                            },500);
                
                        }else{
                            dispatch(alertActions.error({title:'editmenu.quantity.privilege.error.message',
                                                        content:'editmenu.quantity.check.error.title'}));  
                        }
                        this.setState({ editCellRecipePrice: false });
                }else{
                    if (Math.trunc(price).toString().length>=9){
                       const content =  <FormattedMessage id="editmenu.price.check.error.message"
                                                         defaultMessage='Price field is invalid'/>
                        dispatch(alertActions.error({title:'editmenu.price.check.error.title',
                                                    content:'editmenu.price.check.error.message'}));   
                    }
                    this.setState({ editCellRecipePrice: false });
                }
        }
    }


    updatePriceOnBlur (event,menu_id,breakdown) {
        const {newPrice,menu_recipe,active_role} = this.state;
        const {dispatch} = this.props;

        let price = Number(trim(newPrice.toString()).replace(/,/g, '.')).toFixed(3);
        price = price * Number(breakdown.recipe.quantity_parts)
        let price_origin = breakdown.recipe.selling_price;
        price_origin = Number(price_origin).toFixed(3)

        let quantity = Number(trim(breakdown.quantity_value.toString()).replace(/,/g, '.')).toFixed(3);

        let unit_origin = breakdown.quantity_unit
        if (breakdown.is_recipes_unit)
            unit_origin = 'recipe'; 
        let is_recipes_unit = (unit_origin == 'recipe')

        const title = <FormattedMessage id="editmenu.price.check.error.title"
                                        defaultMessage='Price update error'/>
        
        if (this.assertQuantityField(price) && (price!=price_origin)){
                if (active_role!= GUEST){

                    const recipe = {
                        id:menu_recipe,
                        name:breakdown.recipe.name,
                        selling_price: Number(trim(price.toString()).replace(/,/g, '.')).toFixed(3),
                    }

                    dispatch(recipesActions.update(menu_recipe,recipe));
                    breakdown.recipe.selling_price = price
                    setTimeout(() => {
                        dispatch(recipesActions.updateRecipeMenu(menu_id,
                                                                {recipe_id:menu_recipe,
                                                                quantity_value:quantity,
                                                                is_recipes_unit:is_recipes_unit}));
                    },500);
        
                }else{
                    dispatch(alertActions.error({title:'editmenu.quantity.privilege.error.message',
                                                content:'editmenu.quantity.check.error.title'}));  
                }
                this.setState({ editCellRecipePrice: false });
        }else{
            if (Math.trunc(price).toString().length>=9){
               const content =  <FormattedMessage id="editmenu.price.check.error.message"
                                                 defaultMessage='Price field is invalid'/>
                dispatch(alertActions.error({title:'editmenu.price.check.error.title',
                                            content:'editmenu.price.check.error.message'}));   
            }
            this.setState({ editCellRecipePrice: false });
        }
    }


    getRelevantOptions = (unit,type) => {
        const {intl} = this.props;
        let options = []
        let emptyUnit = intl.formatMessage({ id: 'editmenu.quantity.unit.plural.text' }) 

        //unit =  unit.toLowerCase()
        if (type == 'recipe'){
            options= [{key:unit,text:unit,value:unit},
                      {key:'recipe',text:<FormattedMessage id="editmenu.quantity.recipe.text"
                                                           defaultMessage='recipe(s)'/>,value:'recipe'}] 
        }else{
            if (isWeight(unit)){
                 if (mgrams.includes(unit)){
                    options= [{key:unit,text:unit,value:unit},
                              {key:'g',text:'g',value:'g'},
                              {key:'kg',text:'kg',value:'kg'}]
                 }else if (grams.includes(unit)){
                    options= [{key:unit,text:unit,value:unit},
                              {key:'kg',text:'kg',value:'kg'}]
                 }else if (kgrams.includes(unit)){
                    options= [{key:unit,text:unit,value:unit},
                              {key:'g',text:'g',value:'g'}]
                 }
                 else{
                    options= [{key:unit,text:unit,value:unit}]
                 }   
            }
            else if (isVolume(unit)){
                if (cliters.includes(unit)){
                    options= [{key:unit,text:unit,value:unit},
                              {key:'ml',text:'ml',value:'ml'},
                              {key:'l',text:'l',value:'l'}]
                 }else if (mliters.includes(unit)){
                    options= [{key:unit,text:unit,value:unit},
                              {key:'cl',text:'cl',value:'cl'},
                              {key:'l',text:'l',value:'l'}]
                }else if (liters.includes(unit)){
                    options= [{key:unit,text:unit,value:unit},
                              {key:'cl',text:'cl',value:'cl'},
                              {key:'ml',text:'ml',value:'ml'}]
                 }else{
                    options= [{key:unit,text:unit,value:unit}]
                 }     

            }else{
                options= [{key:emptyUnit,text:emptyUnit,value:emptyUnit}]
            }
        }
        return options;
    }




    addRecipesRows(recipes){
        return recipes.map((recipe,index) => {
                return (
                    <Table.Row key={index} style={{'backgroundColor':'#fcfcfc'}}
                               /* onClick={(event)=>this.addToMenuList(event,recipe)} */>
                        <Table.Cell width={5}>
                            <Icon name='food' color='blue'/>
                            <a //href="#" 
                               //onClick={(event) => this.seeRecipe(event,recipe.id)}
                               style={{'color': 'black',fontWeight:'bolder'}}>
                                    {recipe.name.length >= 65 && 
                                    recipe.name.substring(0,65) + '..'}
                                    {recipe.name.length < 65 && 
                                    recipe.name}
                                   {/* {recipe.name.substr(0, 60)} */}
                            </a>
                        </Table.Cell>
                       {/*  <Table.Cell width={3} textAlign='center'>{recipe.category && recipe.category.name}</Table.Cell> */}
                       {/*  <Table.Cell width={3} textAlign='right'><FormattedNumber value={recipe.yield_count} /> {recipe.yield_units}</Table.Cell> */}
                        <Table.Cell width={2} textAlign='right'>
                                   <Button color='blue' circular className="ui small icon button" 
                                             onClick={(event)=>this.addToMenuList(event,recipe)}>
                                            <i className="add icon"></i>
                                   </Button>
                        </Table.Cell>
                     </Table.Row>
                );
        });
    }

    addIngredientsRows(ingredients){
        const {currency} = this.state;
        return ingredients.map((ingredient,index) => {

            return(
                    <Table.Row key={index} textAlign='left' style={{'backgroundColor':'#fcfcfc'}} 
                              /* onClick={(event)=>this.addIngredientToCartList(event,ingredient)} */>
                        <Table.Cell width={5} textAlign='left' >
                             <Icon name='shopping basket' color='teal' />
                             <a style={{color:'black',fontWeight:'bolder'}}>
                                {ingredient.name.length >= 45 && 
                                        ingredient.name.substring(0,45) + '..'}
                                 {ingredient.name.length < 45 && 
                                        ingredient.name}
                               {/*  {ingredient.name.substr(0, 60)} */}
                             </a>
                             <p style={{'marginLeft':'4em'}}>
                                 <span style={{'marginLeft':'0em', 'color':'#009b9b'}}>
{/*                                     <FormattedNumber value={ingredient.price_per_unit} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/> {' /'}
                                    {ingredient.purchase_quantity !== "" &&
                                        <FormattedMessage id="editmenu.quantity.unit.text"
                                                          defaultMessage='unit'/>}
                                    {((ingredient.purchase_volume != "" || ingredient.purchase_weight != "")) && 
                                        <FormattedMessage id="editmenu.row.input.ingredients.popup.unit.other.text"
                                                          defaultMessage='{unit}'
                                                          values={{unit:ingredient.unit}}/>} */}
{/*                                     <span style={{'marginLeft':'2em','color':'#969696'}}><FormattedNumber value={ingredient.stock_quantity} maximumFractionDigits="3"/>{' '}{ingredient.unit}{' '} 
                                        <FormattedMessage id="editmenu.row.input.ingredients.popup.stock.text"
                                                          defaultMessage='in stock'/></span> */}
                                     <span style={{'marginLeft':'2em','color':'#969696'}}>{/* {'  -  '} */}
                                        <span>{ingredient.supplier__name}</span>
                                    </span>
                                 </span>
                             </p>
                        </Table.Cell>
                        <Table.Cell width={2} textAlign='right'>
                                    <Button color='teal' circular  className="ui small icon button" 
                                            onClick={(event)=>this.addIngredientToCartList(event,ingredient)}>
                                            <i className="add icon"></i>
                                    </Button>
                        </Table.Cell>
                    </Table.Row>

            );
        })
    }

    addMenuRecipesRows(list){
        const{editCellRecipe,currency,editCellIndexRecipe,editCellRecipePrice,editCellIndexRecipePrice,newPrice,newQuantity,newUnit} = this.state;
        const {menu,updating_menu} = this.props;
        return list.map((breakdown_recipe,index) => {

                return (
                    <Table.Row key={index} /* active={editCellRecipe && index == editCellIndexRecipe} */>
                         {breakdown_recipe.recipe.category &&
                         <Table.Cell width={1}>
                             {breakdown_recipe.recipe && breakdown_recipe.recipe.category && breakdown_recipe.recipe.category.name.length >= 18 && 
                                breakdown_recipe.recipe.category.name.substring(0,18) + '..'}
                             {breakdown_recipe.recipe && breakdown_recipe.recipe.category && breakdown_recipe.recipe.category.name.length < 18 && 
                                 breakdown_recipe.recipe.category.name}
                           {/*  {breakdown_recipe.recipe && breakdown_recipe.recipe.category &&  breakdown_recipe.recipe.category.name} */}
                         </Table.Cell>}
                         {breakdown_recipe.recipe && !breakdown_recipe.recipe.category &&
                         <Table.Cell width={1}>
                         </Table.Cell>}
                        <Table.Cell width={5} >
                            <Icon name='food' color='grey'/>
                            <a href="#" 
                               onClick={(event) => this.seeRecipe(event,breakdown_recipe.recipe.id)}
                               style={{'color': 'black'}}>
                                   {breakdown_recipe.recipe && breakdown_recipe.recipe.name.length >= 65 && 
                                        breakdown_recipe.recipe.name.substring(0,65) + '..'}
                                    {breakdown_recipe.recipe && breakdown_recipe.recipe.name.length < 65 && 
                                        breakdown_recipe.recipe.name}
                                   {/* {breakdown_recipe.recipe && breakdown_recipe.recipe.name.substr(0, 60)} */}
                            </a>
                        </Table.Cell>
                       <Table.Cell width={3} textAlign='right' verticalAlign='middle' onClick={(event) => this.editQuantityHandlerInput(event,index,breakdown_recipe,'recipe')} >
                        {(!editCellRecipe || (editCellRecipe && index != editCellIndexRecipe) ) && !(breakdown_recipe.is_recipes_unit) &&
                            <a href='#'><FormattedNumber value={Number(breakdown_recipe.quantity_value).toFixed(2)}/>{' '}{breakdown_recipe.quantity_unit}</a>}
                        {(!editCellRecipe || (editCellRecipe && index != editCellIndexRecipe) ) && (breakdown_recipe.is_recipes_unit) &&
                            <a href='#'><FormattedNumber value={Number(breakdown_recipe.quantity_value).toFixed(2)}/>{' '}{<FormattedMessage id="editmenu.quantity.recipe.text"
                                                                                                                                             defaultMessage='recipe(s)'/>}</a>}
                        {editCellRecipe && index == editCellIndexRecipe && 
                        <OutsideClickHandler  //onOutsideClick={(event) => {this.setState({ editCellRecipe: false})}}
                                             onOutsideClick={(event) => this.updateQuantityOnBlur(event,menu.id,breakdown_recipe,'recipe')}>                    
                             <Form>
                               <Form.Group widths='equal'>
                                        <Form.Input type="text"
                                                id="quantity"
                                                value={newQuantity}
                                                onChange={this.onNewQuantity}
                                                //onBlur={(event) => this.updateQuantityOnBlur(event,menu.id,breakdown_recipe,'recipe')} 
                                                onKeyPress={(event) => this.updateQuantityOnEnter(event,menu.id,breakdown_recipe,'recipe')} 
                                                autoFocus
                                                style={{height:'2em',minWidth:'4em',maxWidth:'4em'/* , padding:'0em 0.5em 0em 0.5em' */}}
                                        />
                                        {/* {' '}{breakdown_recipe.quantity_unit} */}
                                        <Form.Dropdown key={index} 
                                                                //button //basic
                                                                options={this.getRelevantOptions(breakdown_recipe.recipe.yield_units,'recipe')}
                                                                value={newUnit}
                                                                onChange={this.onNewUnit}
                                                                //style={{minWidth:'5em',maxWidth:'8em'}} 
                                                                style={styles.ButtonGreenNoMarginMenu}
                                            />
                                </Form.Group>
                            </Form> 
                         </OutsideClickHandler>
                        }
                        </Table.Cell>
                        <Table.Cell width={3} textAlign='right'>
                          {breakdown_recipe.recipe && typeYield(breakdown_recipe.recipe.yield_units) == 'quantity' &&
                           <span><FormattedNumber value={breakdown_recipe.recipe.cost_unit} 
                                            style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>
                             {' / '}{breakdown_recipe.recipe.yield_units}</span>
                            }
                          {breakdown_recipe.recipe && typeYield(breakdown_recipe.recipe.yield_units) != 'quantity' &&
                            <span><FormattedNumber value={breakdown_recipe.recipe.cost_total} 
                                            style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>
                            {' / '}<FormattedMessage id="editmenu.quantity.recipe.singular.text"
                                                           defaultMessage='recipe'/></span>
                          }
                        </Table.Cell>
                        <Table.Cell width={3} textAlign='center'>
                                <FormattedNumber value={breakdown_recipe.item_cost} 
                                                 style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>
                        </Table.Cell>
                   {breakdown_recipe.recipe.is_subrecipe == false &&         
                    <Table.Cell width={2} textAlign='center' onClick={(event) => this.editPriceHandlerInput(event,index,breakdown_recipe)}>
                      {(!editCellRecipePrice || (editCellRecipePrice && index !== editCellIndexRecipePrice) ) && 
                       <a href='#'>
                         <FormattedNumber value={Number(breakdown_recipe.recipe.selling_price)/Number(breakdown_recipe.recipe.quantity_parts)} style="currency" currencyDisplay="symbol" currency={currency}/>
                         <p style={{'color':'#b5b1b1','font-size':'11px'}}><i>
                            <FormattedNumber value={(Number(breakdown_recipe.recipe.selling_price)/Number(breakdown_recipe.recipe.quantity_parts))/(1+Number(breakdown_recipe.recipe.selling_vat))} style="currency" currencyDisplay="symbol" currency={currency}/></i></p>
                        </a>}
                     
                        {editCellRecipePrice && index === editCellIndexRecipePrice && 
                        <OutsideClickHandler onOutsideClick={(event) => this.updatePriceOnBlur(event,menu.id,breakdown_recipe)}>                    
                            {/*  <Form>
                              <Form.Group widths='equal'> */}
                                        <Input type="text"
                                                id="quantity"
                                                value={newPrice}
                                                onChange={this.onNewPrice}
                                                onBlur={(event) => this.updatePriceOnBlur(event,menu.id,breakdown_recipe)} 
                                                onKeyPress={(event) => this.updatePriceOnEnter(event,menu.id,breakdown_recipe)} 
                                                autoFocus
                                                style={{height:'2.6em',minWidth:'6em',maxWidth:'6em'}}
                                                label={{ basic: false, content: this.currencySymbol() }}
                                                labelPosition='right'
                                        />{/* <div style={{'marginTop':'0.4em'}} >{this.currencySymbol()}</div> */}
                              {/* </Form.Group>
                               </Form>  */}
                         </OutsideClickHandler>}   
                        </Table.Cell> }
                        {breakdown_recipe.recipe.is_subrecipe == true &&         
                        <Table.Cell width={2} textAlign='center'>
                            <FormattedMessage id="editmenu.cell.margin.info.text"
                                                  defaultMessage='No sell'/>
                        </Table.Cell> }
{/*                        <Table.Cell width={2} textAlign='center'>
                               <FormattedNumber value={breakdown_recipe.recipe.selling_price} style="currency" currencyDisplay="symbol" currency={currency}/>
                               <p style={{'color':'#b5b1b1','font-size':'11px'}}><i><FormattedNumber value={Number(breakdown_recipe.recipe.selling_price)/(1+Number(breakdown_recipe.recipe.selling_vat))} style="currency" currencyDisplay="symbol" currency={currency}/></i></p>
                        </Table.Cell> */}
                        {breakdown_recipe.recipe.is_subrecipe == false &&     
                           <Table.Cell width={2} textAlign='center'>
                            {(!updating_menu || (updating_menu && (index !== editCellIndexRecipePrice)) ) &&
                             <div><FormattedNumber value={breakdown_recipe.recipe.gross_margin} maximumFractionDigits="1" style="percent"/>
                             <p style={{'color':'#b5b1b1','font-size':'11px'}}><i><FormattedNumber value={breakdown_recipe.recipe.food_cost} maximumFractionDigits="1" style="percent"/></i></p>
                             </div>
                            }
                            { updating_menu && (index == editCellIndexRecipePrice) &&
                             <Dimmer.Dimmable>
                                    <Dimmer active={updating_menu} inverted style={{'backgroundColor':'#fcfcfc'}}>
                                        <Loader size='small' />
                                   </Dimmer> 
                             </Dimmer.Dimmable>
                            } 
                      </Table.Cell>}
                      {breakdown_recipe.recipe.is_subrecipe == true &&         
                        <Table.Cell width={2} textAlign='center'>
                            <FormattedMessage id="editmenu.cell.margin.info.text"
                                                  defaultMessage='No sell'/>
                        </Table.Cell> }
                        <Table.Cell width={1} textAlign='right'>
                            <Popup trigger={<Icon color='grey' /* className="ui small icon button" */
                                                name="trash alternate outline"
                                                style={{cursor:'pointer'}}
                                                size='large'
                                                onClick={(event) => this.deleteItemClickHandler(event,index,breakdown_recipe,'recipe')}>
                                                {/* <i className="trash alternate outline"></i> */}
                                            </Icon>}
                                content={<FormattedMessage id="editmenu.popup.delete.text"
                                                                   defaultMessage='Delete'/>}
                                inverted
                                size='small'/>
                        </Table.Cell>
                         <Modal id={index}
                                   open={this.state.activeModalRecipeDelete === index}
                                   dimmer='default' 
                                   size='tiny' 
                                   onClose={this.hideModal}
                                   style={styles.Modal}
                                   closeIcon>
                                <Header icon='trash alternate outline' content={<FormattedMessage id="editmenu.modal.recipe.deletion.title"
                                                                                                    defaultMessage='Deletion recipe from menu'/>} />
                                <Modal.Content>
                                    <p><FormattedMessage id="editmenu.modal.recipe.deletion.message"
                                                         defaultMessage='Really sure to delete {recipe} from menu {menu} ?'
                                                         values={{menu: menu && menu.name,
                                                                 recipe : breakdown_recipe.recipe && breakdown_recipe.recipe.name}} /></p>
                                </Modal.Content>
                                <Modal.Actions>
                                       <Button style={styles.ButtonGrey}//basic color='grey' 
                                               onClick={this.hideModal}>
                                            <FormattedMessage id="editmenu.modal.recipe.deletion.button.no"
                                                              defaultMessage='No'/>
                                        </Button>
                                        <Button style={styles.ButtonRed}//basic color='teal' 
                                                labelPosition='right' 
                                                content={<FormattedMessage id="editmenu.modal.recipe.deletion.button.yes"
                                                                          defaultMessage='Yes'/> }
                                                icon='checkmark' 
                                                onClick={(event) =>this.deleteItemMenu(event,'recipe')}/>
                                </Modal.Actions>
                            </Modal>
                     </Table.Row>
                );
        });
    }

    addMenuIngredientsRows(list){
        const{editCellIngredient,currency,editCellIndexIngredient,newQuantity,newUnit} = this.state;
        const {menu} = this.props;
        return list.map((breakdown,index) => {

                let price_per_unit = breakdown.ingredient.price_per_unit
                let unit = breakdown.ingredient.unit
                if (breakdown.ingredient.default_supplier == false){
                        for (let s of breakdown.ingredient.extra_suppliers){
                            if (s.default_supplier == true){
                                price_per_unit = s.price_per_unit
                                unit = s.unit
                                break
                            }
                        }
                }

                return (
                    <Table.Row key={index} /* active={editCellIngredient && index == editCellIndexIngredient} */>
                         {breakdown.ingredient.category &&
                         <Table.Cell width={1} >
                             {breakdown.ingredient && breakdown.ingredient.category && breakdown.ingredient.category.name.length >= 18 && 
                                breakdown.ingredient.category.name.substring(0,18) + '..'}
                            {breakdown.ingredient && breakdown.ingredient.category && breakdown.ingredient.category.name.length < 18 && 
                                 breakdown.ingredient.category.name}
                            {/* {breakdown.ingredient && breakdown.ingredient.category &&  breakdown.ingredient.category.name} */}
                         </Table.Cell>}
                         {breakdown.ingredient && !breakdown.ingredient.category &&
                         <Table.Cell width={1} >
                         </Table.Cell>}
                        <Table.Cell width={5} >
                            <Icon name='shopping basket' color='grey'/>
                            <a href="#" 
                               onClick={(event) => this.seeIngredient(event,breakdown.ingredient.id)}
                               style={{'color': 'black'}}>
                                {breakdown.ingredient && breakdown.ingredient.name.length >= 55 && 
                                        breakdown.ingredient.name.substring(0,55) + '..'}
                                {breakdown.ingredient && breakdown.ingredient.name.length < 55 && 
                                        breakdown.ingredient.name}
                            {/* {breakdown.ingredient && breakdown.ingredient.name.substr(0, 60)} */}
                            </a>
                        </Table.Cell>
                       <Table.Cell width={3} textAlign='right' secondary verticalAlign='middle' onClick={(event) => this.editQuantityHandlerInput(event,index,breakdown,'ingredient')}>
                        {(!editCellIngredient || (editCellIngredient && index != editCellIndexIngredient) ) && (breakdown.quantity_unit != "") &&
                            <a href='#'><FormattedNumber value={Number(breakdown.quantity_value).toFixed(2)}/>{' '}{breakdown.quantity_unit}</a>}
                        {(!editCellIngredient || (editCellIngredient && index != editCellIndexIngredient) ) && (breakdown.quantity_unit == "") &&
                            <a href='#'><FormattedNumber value={Number(breakdown.quantity_value).toFixed(2)}/>{/* {' '}{<FormattedMessage id="editmenu.quantity.unit.text"
                                                                                                                                             defaultMessage='unit'/>} */}</a>}
                        {editCellIngredient && index == editCellIndexIngredient &&
                        <OutsideClickHandler /* onOutsideClick={(event) => {this.setState({ editCellIngredient: false})}} */
                                                onOutsideClick={(event) => this.updateQuantityOnBlur(event,menu.id,breakdown,'ingredient')}>
                            <Form>
                               <Form.Group widths='equal'>
                                    <Form.Input type="text"
                                            id="quantity"
                                            value={newQuantity}
                                            onChange={this.onNewQuantity}
                                            //onBlur={(event) => this.updateQuantityOnBlur(event,menu.id,breakdown,'ingredient')} 
                                            onKeyPress={(event) => this.updateQuantityOnEnter(event,menu.id,breakdown,'ingredient')} 
                                            autoFocus
                                            style={{height:'2.5em',minWidth:'5em',maxWidth:'8em'/* , padding:'0em 0.5em 0em 0.5em' */}}
                                    />
                                {/*{' '}{breakdown.quantity_unit} */}
                                <Form.Dropdown key={index} 
                                              //button //basic
                                              options={this.getRelevantOptions(unit,'ingredient')}
                                              value={newUnit}
                                              onChange={this.onNewUnit}
                                              //style={{minWidth:'5em',maxWidth:'8em'}} 
                                              style={styles.ButtonGreenNoMarginMenu}
                                />
                                </Form.Group>
                            </Form> 
                        </OutsideClickHandler>
                        }  
                        </Table.Cell>
                        <Table.Cell width={3} textAlign='right'>
                          {breakdown.ingredient &&  typeYield(unit) == "quantity" &&
                            <span><FormattedNumber value={price_per_unit} 
                                            style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>
                                {' / '}<FormattedMessage id="editmenu.quantity.unit.text"
                                                             defaultMessage='unit'/></span>
                          }
                           {breakdown.ingredient &&   typeYield(unit)!="quantity" &&
                           <div>
                            { grams.includes(unit) && 
                            <span><FormattedNumber value={Number(price_per_unit) * 1000} 
                                            style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>
                                {' / kg'}</span>}
                            { mgrams.includes(unit) && 
                            <span><FormattedNumber value={Number(price_per_unit) * 1e6} 
                                            style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>
                                {' / kg'}</span>}
                            { cliters.includes(unit) && 
                            <span><FormattedNumber value={Number(price_per_unit) * 100} 
                                            style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>
                                {' /  L'}</span>}
                            { mliters.includes(unit) && 
                            <span><FormattedNumber value={Number(price_per_unit) * 1000} 
                                            style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>
                                {' / L'}{unit}</span>}
                            { !grams.includes(unit) && !mgrams.includes(unit) && !cliters.includes(unit) && !mliters.includes(unit) &&
                            <span><FormattedNumber value={price_per_unit} 
                                            style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>
                                {' / '}{unit}</span>}
                           </div>
                          }
                        </Table.Cell>
                        <Table.Cell width={3} textAlign='center'>
                                <FormattedNumber value={breakdown.item_cost} 
                                                 style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>
                        </Table.Cell>
                       <Table.Cell width={2} textAlign='right'> </Table.Cell>
                       <Table.Cell width={2} textAlign='right'> </Table.Cell>
{/*                         <Table.Cell width={1} textAlign='right'> </Table.Cell>  */}
                        <Table.Cell width={1} textAlign='right'>
                            <Popup trigger={<Icon color='grey' /* className="ui small icon button" */
                                                name="trash alternate outline"
                                                style={{cursor:'pointer'}}
                                                size='large'
                                                onClick={(event) => this.deleteItemClickHandler(event,index,breakdown,'ingredient')}>
                                                {/* <i className="trash alternate outline"></i> */}
                                            </Icon>}
                                content={<FormattedMessage id="editmenu.popup.delete.text"
                                                                   defaultMessage='Delete'/>}
                                inverted
                                size='small'/>
                        </Table.Cell>
                         <Modal id={index}
                                   open={this.state.activeModalIngredientDelete === index}
                                   dimmer='default' 
                                   size='tiny' 
                                   onClose={this.hideModal}
                                   style={styles.Modal}
                                   closeIcon>
                                <Header icon='trash alternate outline' content={<FormattedMessage id="editmenu.modal.recipe.deletion.title"
                                                                                                    defaultMessage='Deletion recipe from menu'/>} />
                                <Modal.Content>
                                    <p><FormattedMessage id="editmenu.modal.recipe.deletion.message"
                                                         defaultMessage='Really sure to delete {recipe} from menu {menu} ?'
                                                         values={{menu: menu && menu.name,
                                                                 recipe : breakdown.ingredient && breakdown.ingredient.name}} /></p>
                                </Modal.Content>
                                <Modal.Actions>
                                       <Button style={styles.ButtonGrey}//basic color='grey' 
                                               onClick={this.hideModal}>
                                            <FormattedMessage id="editmenu.modal.recipe.deletion.button.no"
                                                              defaultMessage='No'/>
                                        </Button>
                                        <Button style={styles.ButtonRed}//basic color='teal' 
                                                labelPosition='right' 
                                                content={<FormattedMessage id="editmenu.modal.recipe.deletion.button.yes"
                                                                          defaultMessage='Yes'/> }
                                                icon='checkmark' 
                                                onClick={(event) =>this.deleteItemMenu(event,'ingredient')}
                                                />
                                </Modal.Actions>
                            </Modal>
                     </Table.Row>
                );
        });
    }

/*     toggleMenu() {
		this.setState({toggled: !this.state.toggled});
    } */
    
    toggleItemsMenu(e,type){
        this.setState({sidebarToggled: !this.state.sidebarToggled});
        //this.setState({toggled: false});
        this.setState({filtering: false});
        this.setState({nameSelected: ""});
        if (type == 'recipe'){
            this.setState({toggledRecipes: true});
            this.setState({toggledIngredients: false});
        }else{
            this.setState({toggledIngredients: true});
            this.setState({toggledRecipes: false});
        }

    } 

    toggleEditMenu(){
        this.setState({actionEditModal: true});
        this.setState({submitted: false});
    } 

/*     sortOnClick(column){
        const{menuRecipesList,menuIngredientsList} = this.state;
        const {dispatch} = this.props;
        dispatch(sortingActions.sort('CHANGE_SORT',column,menuRecipesList,menuIngredientsList))
        this.setState({currentSortColumn: column});
    }

    sortOnClick2(selected_column){
        const{menuRecipesList,menuIngredientsList,direction,column} = this.state;
        if (column === selected_column) {
            this.setState({menuRecipesList: menuRecipesList.reverse()});
            this.setState({menuIngredientsList: menuIngredientsList.reverse()});
            this.setState({direction:  direction === 'ascending' ? 'descending' : 'ascending'});
        }else{
            this.setState({menuRecipesList: _.sortBy(menuRecipesList, selected_column)});
            this.setState({menuIngredientsList: _.sortBy(menuIngredientsList, selected_column)});
            this.setState({direction:  direction === 'ascending' ? 'descending' : 'ascending'});
            this.setState({column:selected_column})
        }
    } */


    toggleAllergensMatrix(){
        const { menuRecipesList,menuIngredientsList } = this.state;
        const { dispatch,intl } = this.props;

        let allRecipesIds = []
        let allIngredientsIds = []
        menuRecipesList.map((bd_recipes,index) => {
            allRecipesIds.push(bd_recipes.recipe.id);
        });
        menuIngredientsList.map((bd_ingredients,index) => {
            allIngredientsIds.push(bd_ingredients.ingredient.id);
        });

        if (allRecipesIds.length > 0 || allIngredientsIds.length > 0 ){
            
            dispatch(recipesActions.allergensMatrix(false,allRecipesIds,allIngredientsIds))
            toaster_info(intl.formatMessage({ id: 'allergens.toaster.message.title' }),
                                              intl.formatMessage({ id: 'allergens.toaster.message.content' }))   
        }
    }

    isUnSubscribedUser(){
        const { subscription } = this.props;
        if (subscription && !isEmpty(subscription) && (subscription.active == false) && (subscription.bypass == false)){
            return true
        }
        return false
    }

    upgradeSubscription = () => {
        history.push({pathname: '/profilepremium', state: {toggled: false}})
    }


    currencySymbol(){    
        const {currency} = this.state;
        const {intl} = this.props;
        let output_currency_symbol = ''
    
        if (currency == 'None') 
            return output_currency_symbol
    
        let value = Intl.NumberFormat(intl.locale,{
                                                  style:'currency',
                                                  currency: currency,
                                                  currencyDisplay: 'symbol',
                                      }).format(0)
        value = value.replace(/,/g, '.');
        value +='' 
        value = value.replace('.','')
    
        let regexp = /\D+/g;
        let symbol = [...value.matchAll(regexp)];      
        
        if (symbol && symbol.length > 0)
          output_currency_symbol = symbol[0][0]
        
        return ''+output_currency_symbol
      }

    render() { 

        const {menu,alert,dispatch,loading,intl,adding,updating_menu,deleting} = this.props;
        const {filtering,isMobile,isTablet,currency,newItems,sidebarToggled,availableRecipesList,availableIngredientsList,
            menuRecipesList,menuIngredientsList,isSelling,submitted,name, price,cost_total,avg_margin,food_cost,toggledRecipes,
            toggledIngredients,nameSelected } = this.state;
        let isLimited = this.isUnSubscribedUser()
        let className = "floating-menu";
        let items = [];

        if (toggledRecipes){
            if (filtering){
                items = newItems
            }else{
                items = availableRecipesList
            }
            //Filter and notify restrictions if user unsubscribed
            if (this.isUnSubscribedUser() && items){
                items = items.slice(0,MAX_NUMBER_RECIPES_FREE_SUBSCRIBER);
            }
        }
        if (toggledIngredients){
            if (filtering){
                items = newItems
            }else{
                items = availableIngredientsList
            }

            //Filter and notify restrictions if user unsubscribed
            if (isLimited && items){
                items = items.slice(0,MAX_NUMBER_INGREDIENTS_FREE_SUBSCRIBER);
            }
        }

        return ( 
            <div>
            { loading &&
                   <SideLeftUncoverMenu>      
                        <Dimmer active={(loading)} blurring inverted>
                                <Loader inverted content='Loading' />
                        </Dimmer> 
                    </SideLeftUncoverMenu>
             } 
            { !loading &&
            <div>
            <Sidebar as={Segment}
                            animation={'overlay'}
                            direction={'right'}
                            visible={sidebarToggled}
                            vertical
                            width= {isMobile?'wide':'very wide'}
                            icon='labeled'
                            style={{'backgroundColor':'#fcfcfc'/*,'overflow-y': 'hidden','overflow-x': 'auto;','overflow': '-moz-scrollbars-vertical;'*/}}
                            >
                        <Grid textAlign='center' /*style={{'overflow-y': 'hidden','overflow-x': 'auto;','overflow': '-moz-scrollbars-vertical;'}}*/>
                            <Grid.Row columns={1}>
                                <Grid.Column /*mobile={16} tablet={12} computer={8}*/ fluid>
                                    <div style={{display: 'inline-block;',position:'absolute'}}>
                                        <Icon name='close' size='large' color='grey' onClick={(e) => this.toggleItemsMenu(e)}/>
                                    </div>
                                    <Form>
                                    <Form.Field align='left' style={{'margin':'2em 0em 1em 1em'}}>
                                        <Header as='h2' style={{'marginLeft': '1em'}}>
                                        {toggledRecipes && 
                                            <img src={img_pan} style={{width:'55px'}}/>}
                                        {toggledIngredients && 
                                            <img src={img_bag} style={{width:'50px'}}/>}
                                        <Header.Content>
                                            {toggledRecipes && 
                                                <FormattedMessage id="editmenu.form.input.recipe.title"
                                                                  defaultMessage='Add recipes'/>}
                                            {toggledIngredients && 
                                                <FormattedMessage id="editmenu.form.input.ingredient.title"
                                                                  defaultMessage='Add ingredients'/>}
                                        </Header.Content>
                                    </Header>
                                    <br/>
                                    </Form.Field>
                                    <Form.Field align='left' style={{'margin':'0em 0.5em 1em 0.5em'}}>
                                        <p style={styles.FontLato13}>
                                                <FormattedMessage id="editmenu.form.input.search.title"
                                                                defaultMessage='Search' />
                                        </p>
                                            <FormattedMessage id="editmenu.form.input.search.placeholder"
                                                        defaultMessage="Enter a name ...">
                                            {placeholder => 
                                                    <Input
                                                        fluid
                                                        onChange={this.filterNameList}
                                                        color='teal'
                                                        size='large'
                                                        icon='filter'
                                                        value={nameSelected}
                                                        placeholder={placeholder}
                                                        disabled={isLimited}
                                                        style={{'backgroundColor':'#f5f5f5'}}
                                                    />
                                            } 
                                            </FormattedMessage> 
                                    </Form.Field>
                                    </Form>
                                </Grid.Column>
                            </Grid.Row>  
                            <Grid.Row columns={1}>
                                <Grid.Column /*mobile={16} tablet={16} computer={16}*/ fluid >
                                    <Table  unstackable 
                                            compact 
                                            singleLine 
                                            style={styles.FontLato13Border}
                                            //style={{'overflow-y': 'hidden','overflow-x': 'auto;','overflow': '-moz-scrollbars-none;','-ms-overflow-style':'none;'}}
                                            >
                                            <Table.Body>
                                            {toggledIngredients && availableIngredientsList.length > 0 && items &&
                                                    this.addIngredientsRows(items)
                                                }
                                            {toggledRecipes && availableRecipesList.length > 0 && items &&
                                                this.addRecipesRows(items)
                                            }
                                            {toggledRecipes && availableRecipesList.length == 0 && 
                                            <Table.Row textAlign='left' style={{'backgroundColor':'#fcfcfc','font-family': 'Lato','font-size': '14px'}}>
                                                <Table.Cell>
                                                    <FormattedMessage id="editmenu.list.recipe.empty.message"
                                                                        defaultMessage='No recipe created yet' />
                                                </Table.Cell>
                                                <Table.Cell width={1} textAlign='right'>
                                                    <Button color='teal' circular  className="ui small icon button" 
                                                            onClick={this.createRecipe}>
                                                            <i className="add icon"></i>
                                                    </Button>
                                                </Table.Cell>
                                            </Table.Row>
                                            }
                                            {toggledIngredients && availableIngredientsList.length == 0 && 
                                                <Table.Row textAlign='left' style={{'backgroundColor':'#fcfcfc','font-family': 'Lato','font-size': '14px'}}>
                                                    <Table.Cell>
                                                        <FormattedMessage id="editmenu.list.ingredient.empty.message"
                                                                          defaultMessage='No ingredient created yet' />
                                                    </Table.Cell>
                                                    <Table.Cell width={1} textAlign='right'>
                                                        <Button color='teal' circular  className="ui small icon button" 
                                                                onClick={this.createIngredient}>
                                                                <i className="add icon"></i>
                                                        </Button>
                                                    </Table.Cell>
                                                </Table.Row>
                                                }         
                                        </Table.Body>
                                        {isLimited && items &&
                                                <Table.Footer /*fullWidth*/ width={4}>
                                                        <Table.HeaderCell colSpan='2'>
                                                        <Message fluid icon size='big' style={styles.FontLato12Bold} color='blue'>
                                                                <Image src={img_performance} size ='tiny' rounded/>
                                                                <br/>
                                                                <Message.Content style={{marginLeft:'1em'}}>
                                                                    <Message.Header color='black'>
                                                                        <FormattedMessage id="editmenu.list.premium.title.message.upgradeplan"
                                                                                            defaultMessage='Recipes/Ingredients limit exceeded'/> 
                                                                    </Message.Header>
                                                                    <br/>
                                                                    {toggledRecipes && <div style={styles.FontLato13}>
                                                                        <FormattedMessage id="editmenu.list.premium.message.upgradeplan.line1"
                                                                                        defaultMessage='Your have'/>{' '}{availableRecipesList.length}{' '}
                                                                        <FormattedMessage id="editmenu.list.premium.message.upgradeplan.line2"
                                                                                        defaultMessage='recipes but limited to'/>{' '}{MAX_NUMBER_RECIPES_FREE_SUBSCRIBER}{'.'} 
                                                                    </div>}
                                                                    {toggledIngredients && <div style={styles.FontLato13}>
                                                                        <FormattedMessage id="editmenu.list.premium.message.upgradeplan.line1"
                                                                                        defaultMessage='Your have'/>{' '}{availableIngredientsList.length}{' '}
                                                                        <FormattedMessage id="editmenu.list.premium.message.upgradeplan.line3"
                                                                                        defaultMessage='ingredients but limited to'/>{' '}{MAX_NUMBER_INGREDIENTS_FREE_SUBSCRIBER}{'.'} 
                                                                    </div>}
                                                                    <br/><br/>
                                                                    <Button basic color='blue' style={styles.FontLato12Bold} onClick={(e) => this.upgradeSubscription()}>
                                                                        <FormattedMessage id="editmenu.list.premium.button.chooseplan"
                                                                                    defaultMessage='Choose premium plan'/>
                                                                    </Button>                                      
                                                                </Message.Content>
                                                            </Message>
                                                        </Table.HeaderCell>
                                            </Table.Footer>}
                                </Table>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Sidebar>
                <SideLeftUncoverMenu callback={() => this.toggleEditMenu()} 
                                     id={this.props.match.params.menuId}>
                        <Grid stackable style={styles.FontLato13Border}>
                            <Grid.Row columns={1}>
                                <Breadcrumb  style={{background:'#fcfcfc'}}>
                                    <Breadcrumb.Section link as={Link} to={'/dashboard/'}><FormattedMessage id="editmenu.link.dashboard"
                                                                defaultMessage='Dashboard'/></Breadcrumb.Section>
                                    <Breadcrumb.Divider icon='right chevron'/>
                                    <Breadcrumb.Section link as={Link} to={'/menus/'}><FormattedMessage id="editmenu.link.menus"
                                                                defaultMessage='Menus'/></Breadcrumb.Section>
                                    <Breadcrumb.Divider icon='right chevron'/>
                                    <Breadcrumb.Section active>{menu && menu.name}</Breadcrumb.Section>
                                    <Breadcrumb.Divider icon='right chevron'/>
                                    <Breadcrumb.Section active><FormattedMessage id="editmenu.link.edit"
                                                                defaultMessage='Edit'/></Breadcrumb.Section>
                                </Breadcrumb>
                            </Grid.Row>
                             <Grid.Row columns={1}>
                                <Grid.Column width={8}>
                                <Header as='h2'>
                                    <Icon name='block layout' size='mini' color='blue'/>
                                    <Header.Content>
                                        <FormattedMessage id="editmenu.header.title"
                                                            defaultMessage='Editing menu (or combo)'/>
                                        <Header.Subheader>{menu && menu.name}</Header.Subheader>
                                        </Header.Content>
                                </Header>
                                </Grid.Column >
                             </Grid.Row> 
                            {alert.message && toaster_dispatch(dispatch,alert,intl)}
                            <Grid.Row columns={1}/>
                            <Grid.Row columns={4} stretched widths={14} /*style={{paddingLeft:'1em'}}*/>
                                {!isTablet && <Grid.Column mobile={0} tablet={0} computer={5}/>}
                                <Grid.Column mobile={4} tablet={4} computer={3}  onClick={this.toggleEditMenu.bind(this)} style={{cursor:'pointer'}} > 
                                  {menu &&  menu.is_selling && 
                                    <Segment style={{'background-color':'#e8f3fa', textAlign:'right'}}>
                                        <Header as='h4' color='black'>
                                                <FormattedMessage id="editmenu.page.price.title"
                                                              defaultMessage='SELLING PRICE'/>
                                        </Header>
                                        <Dimmer.Dimmable>
                                            <Dimmer active={loading} inverted>
                                                <Loader inverted content='Loading'/>
                                            </Dimmer> 
                                            <Header as='h2' color='grey'>
                                                <FormattedNumber value={menu.selling_price} 
                                                                style="currency" 
                                                                maximumFractionDigits="2" 
                                                                currencyDisplay="symbol" 
                                                                currency={currency}/>
                                                                                                <br/>
                                                <span style={{fontSize:'14px', color:'#00b3b3'}}>
                                                   <FormattedMessage id="editmenu.page.vat.title"
                                                                     defaultMessage='Tax'/>{'  '}
                                                    <FormattedNumber value={menu.vat} style="percent" maximumFractionDigits="2"/>
                                                </span>
                                            </Header>
                                        </Dimmer.Dimmable>
                                    </Segment>}
                                </Grid.Column>
                                <Grid.Column mobile={4} tablet={4} computer={3}> 
                                {menu &&  menu.is_selling && <Segment style={{'background-color':'#e8f3fa', textAlign:'right'}} >
                                        <Header as='h4' color='black'>
                                                <FormattedMessage id="editmenu.page.margin.title"
                                                              defaultMessage='AVERAGE MARGIN'/>
                                        </Header>
                                        <Dimmer.Dimmable>
                                            <Dimmer active={adding||updating_menu||deleting} 
                                                    inline
                                                    style={{'background-color':'#e8f3fa'}} 
                                                    inverted>
                                                <Loader content='Loading'/>
                                            </Dimmer>
                                            <Header as='h2' color='blue'>
                                                <FormattedNumber value={avg_margin} style="percent" maximumFractionDigits="2"/>
                                                <br/>
                                                 <span style={{fontSize:'14px', color:'#00b3b3'}}>
                                                    <FormattedMessage id="editmenu.page.margin.foodcost"
                                                                      defaultMessage='FOOD COST'/>{'  '}
                                                    <FormattedNumber value={food_cost} style="percent" maximumFractionDigits="2"/>
                                                </span>
                                            </Header>
                                        </Dimmer.Dimmable>
                                    </Segment>}
                                </Grid.Column>
                                <Grid.Column mobile={4} tablet={4} computer={3}> 
                                <Segment style={{'background-color':'#e8f3fa', textAlign:'right'}} >
                                        <Header as='h4' color='black'>
                                                <FormattedMessage id="editmenu.cost.price.title"
                                                              defaultMessage='COST TOTAL'/>
                                        </Header>
                                        <Dimmer.Dimmable>
                                            <Dimmer active={adding||updating_menu||deleting} 
                                                    inline 
                                                    style={{'background-color':'#e8f3fa'}} 
                                                    inverted>
                                                <Loader content='Loading' />
                                            </Dimmer> 
                                            <Header as='h2' color='blue'>
                                                <FormattedNumber value={cost_total} 
                                                                style="currency" 
                                                                maximumFractionDigits="2" 
                                                                currencyDisplay="symbol" 
                                                                currency={currency}/>
                                            </Header>
                                        </Dimmer.Dimmable>
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1}/>
                            <Grid.Row columns={3}/>
                            <Grid.Row columns={3}/>
                            <Grid.Row columns={3}>
                                <Grid.Column  mobile={8} tablet={4} computer={4}/> 
                                <Grid.Column mobile={6} tablet={0} computer={4}/>
                                <Grid.Column mobile={4} tablet={16} computer={6}> 
                                  <div className={className}>

                                  <Popup trigger=
                                            { <Button circular 
                                                      color='green'
                                                      size='small' 
                                                      //icon='table' 
                                                      onClick={this.toggleAllergensMatrix.bind(this)}
                                                      ><Icon name='table' />{' '}<FormattedMessage id="editmenu.floatingmenu.item0.button"
                                                      defaultMessage='Allergens'/>
                                                      </Button>}
                                            content={<FormattedMessage id="editmenu.floatingmenu.item0.title"
                                                                       defaultMessage='Export allergens matrix'/>}
                                            position='top center'
                                            inverted
                                    />
                                    <Popup trigger=
                                            { <Button circular 
                                                      color='blue'
                                                      size='small' 
                                                      //icon='food' 
                                                      onClick={(e) => this.toggleItemsMenu(e,'recipe')}
                                                      ><Icon name='add circle' />{' '}<FormattedMessage id="editmenu.floatingmenu.item1.button"
                                                      defaultMessage='Recipes'/></Button>}
                                             content={<FormattedMessage id="editmenu.floatingmenu.item1.title"
                                                                        defaultMessage='Add a recipe'/>}
                                            position='top center'
                                            inverted
                                    />
                                   <Popup trigger=
                                            { <Button circular 
                                                      color='teal'
                                                      size='small' 
                                                      //icon='shopping basket' 
                                                      onClick={(e)=>this.toggleItemsMenu(e,'ingredient')}
                                                      ><Icon name='add circle' />{' '}<FormattedMessage id="editmenu.floatingmenu.item3.button"
                                                      defaultMessage='Ingredients'/></Button>}
                                             content={<FormattedMessage id="editmenu.floatingmenu.item3.title"
                                                                        defaultMessage='Add ingredient'/>}
                                            position='top center'
                                            inverted
                                    />&nbsp;&nbsp;&nbsp;
                                    <Popup trigger=
                                            { <Icon  color='grey'
                                                     style ={{ cursor : 'pointer'}}
                                                     size='big' 
                                                     name='settings' 
                                                     onClick={this.toggleEditMenu.bind(this)}
                                                      ></Icon>}
                                             content={<FormattedMessage id="editmenu.floatingmenu.item2.title"
                                                                        defaultMessage='Edit'/>}
                                            position='top center'
                                            inverted
                                    />
                                  </div>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1} style={{'overflow-x': 'auto'}}>
                                
                                <Grid.Column  /*width={14}*/ stretched mobile={18} tablet={16} computer={14}> 
                                   <Dimmer.Dimmable>
                                        <Dimmer active={loading||deleting} blurring inverted>
                                           <Loader inverted content='Loading' />
                                        </Dimmer> 
                                    <Table definition
                                           //basic='very'
                                           basic
                                           //sortable
                                           unstackable
                                           compact 
                                           singleLine 
                                           style={styles.FontLato13Border}>
                                        <Table.Header>
                                            <Table.Row> 
                                                <Table.HeaderCell
                                                    //sorted={column === 'category' ? direction : null}
                                                    //onClick= {() => this.sortOnClick2('category')}  
                                                    width={1}><FormattedMessage id="editmenu.table.header.column.category"
                                                                          defaultMessage='Category'/></Table.HeaderCell> 
                                                <Table.HeaderCell 
                                                        //sorted={column === 'name' ? direction : null}
                                                        //onClick= {() => this.sortOnClick2('name')}  
                                                        width={5}/* colSpan='2 textAlign='left'*/>
                                                        <span style={{marginLeft:'0.5em'}}><FormattedMessage id="editmenu.table.header.column.name"
                                                                          defaultMessage='Composition'/></span>
                                                </Table.HeaderCell>
                                                <Table.HeaderCell                                             
                                                       width={3} textAlign='right'>
                                                        <FormattedMessage id="editmenu.table.header.column.quantity"
                                                                          defaultMessage='Quantity'/>
                                                </Table.HeaderCell>
                                                <Table.HeaderCell  width={3} textAlign='right'>
                                                        <FormattedMessage id="editmenu.table.header.column.unitcost"
                                                                          defaultMessage='Cost unit'/>
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={3} textAlign='center'
/*                                                         sorted={column === 'item_cost' ? direction : null}
                                                        onClick= {() => this.sortOnClick('item_cost')}  */
                                                        >
                                                        <FormattedMessage id="editmenu.table.header.column.cost"
                                                                          defaultMessage='Cost'/>
                                                </Table.HeaderCell>
                                               <Table.HeaderCell  width={2} textAlign='center'>
                                                        <FormattedMessage id="editmenu.table.header.column.sellingprice"
                                                                          defaultMessage='Selling unit price'/>
                                                        <p style={{'color':'#b5b1b1','font-size':'11px'}}><FormattedMessage id="editmenu.table.header.column.sellingNoVat"
                                                                          defaultMessage='VAT exluded'/></p>
                                                </Table.HeaderCell>
{/*                                                 <Table.HeaderCell  width={1} textAlign='center'>
                                                        <FormattedMessage id="editmenu.table.header.column.foodcost"
                                                                          defaultMessage='Food Cost'/>
                                                </Table.HeaderCell> */}
                                                <Table.HeaderCell  width={2} textAlign='center'>
                                                        <FormattedMessage id="editmenu.table.header.column.margin"
                                                                          defaultMessage='Margin'/>
                                                        <p style={{'color':'#b5b1b1','font-size':'11px'}}><FormattedMessage id="editmenu.table.header.column.foodcost"
                                                                          defaultMessage='Food Cost'/></p>
                                                </Table.HeaderCell>

                                                <Table.HeaderCell width={1}></Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {menu && (menuRecipesList.length == 0 && menuIngredientsList.length == 0) &&
                                            <Table.Row style={{'background-color':'#f5f5f5'}}>
                                                <Table.Cell> <FormattedMessage id="editmenu.table.header.divider.noitems"
                                                                            defaultMessage='No items'/></Table.Cell>
                                                <Table.Cell colspan='7'></Table.Cell>
                                            </Table.Row>}
                                            {menu && menuRecipesList.length > 0 &&<Table.Row style={{'background-color':'#f5f5f5'}}>
                                                <Table.Cell style={{color:'#00b3b3'}}> {menuRecipesList.length+' '}
                                                          <FormattedMessage id="editmenu.table.header.divider.recipes"
                                                                            defaultMessage='RECIPES'/></Table.Cell>
                                                <Table.Cell colspan='7'></Table.Cell>
                                            </Table.Row>}
                                            {menu && menuRecipesList.length > 0 && this.addMenuRecipesRows(menuRecipesList)}
                                            {menu && menuIngredientsList.length > 0 &&
                                                <Table.Row style={{'background-color':'#f5f5f5',color:'#00b3b3'}}>
                                                <Table.Cell style={{color:'#00b3b3'}}>{menuIngredientsList.length+' '}
                                                          <FormattedMessage id="editmenu.table.header.divider.ingredients"
                                                                            defaultMessage='INGREDIENTS'/></Table.Cell>
                                                <Table.Cell colspan='7'></Table.Cell>
                                            </Table.Row>}
                                            {menu && menuIngredientsList.length > 0 && this.addMenuIngredientsRows(menuIngredientsList)}
                                        </Table.Body>
                                    </Table>
                                     <br/> <br/> <br/>
                                    </Dimmer.Dimmable>                               
                                </Grid.Column>
                            </Grid.Row>
                            <Modal open={this.state.actionEditModal === true}
                               dimmer='default' 
                               size='tiny' 
                               style={styles.Modal} 
                               onClose={this.hideModal}
                               closeIcon>
                                <Header as='h2' icon='edit' content={<FormattedMessage id="editmenu.modal.select.create.title"
                                                                                defaultMessage="Edit your menu (or combo)"/>}  />
                                <Modal.Content style={{paddingBottom:'12em'}}>
                                    <Form style={{paddingLeft:'0em'}}>
                                            <Form.Field width='8'> 
                                                    <label style={styles.LabelGrey}><FormattedMessage id="editmenu.modal.form.input.title"
                                                                              defaultMessage='Name'/></label>
                                                    <FormattedMessage id="editmenu.modal.form.input.name.placeholder"
                                                                    defaultMessage='menu name'>
                                                        {placeholder =>                                                                                
                                                                    <Form.Input type="text" 
                                                                        placeholder={placeholder}
                                                                        id="name" 
                                                                        fluid
                                                                        value={name}
                                                                        onChange={this.OnNameChange}
                                                                        error={submitted && !this.assertNameField()}
                                                                        />}
                                                    </FormattedMessage>
                                                    {submitted && !this.assertNameField()  &&
                                                        <label style={{color:'red', }}><i>
                                                            <FormattedMessage id="editmenu.form.name.error.message"
                                                                                defaultMessage='name is empty'/>
                                                        </i></label> 
                                                    }
                                            </Form.Field>
                                            <Form.Field width='8'> 
                                                    <FormattedMessage id="editmenu.modal.form.input.selling.placeholder"
                                                                    defaultMessage='with selling price'>
                                                            {label => 
                                                                <Checkbox label={label}
                                                                        id='selling'
                                                                        defaultChecked = {this.state.isSelling}
                                                                        onChange={this.onCheckboxChange}/>}
                                                    </FormattedMessage>
                                            </Form.Field>
                                            {isSelling &&
                                            <Form.Group>
                                                <Form.Field width='5'> 
                                                    <label style={styles.LabelGrey}><FormattedMessage id="editmenu.modal.form.input.price"
                                                                              defaultMessage='Price'/></label>
                                                    <FormattedMessage id="editmenu.modal.form.input.price.placeholder"
                                                                    defaultMessage='price'>
                                                        {placeholder =>                                                                                
                                                                    <Form.Input fluid type="text" 
                                                                        placeholder={placeholder}
                                                                        id="price" 
                                                                        value={price}
                                                                        //disabled={!isSelling}
                                                                        onChange={this.OnPriceChange}
                                                                        error={submitted && !this.assertPriceField()}
                                                                        />}
                                                    </FormattedMessage>
                                                    {submitted && !this.assertPriceField()  &&
                                                        <label style={{color:'red', }}><i>
                                                            <FormattedMessage id="editmenu.modal.input.price.error.message"
                                                                                defaultMessage='price is numeric'/>
                                                        </i></label> 
                                                    }
                                                </Form.Field>
                                                <Form.Field width='3'> 
                                                    <label style={styles.LabelGrey}><FormattedMessage id="editmenu.modal.form.input.vat"
                                                                                    defaultMessage='Tax'/></label>
                                                    <FormattedMessage id="editmenu.modal.form.input.VAT.placeholder"
                                                                defaultMessage='Tax'>
                                                        {placeholder =>                                      
                                                                <Form.Select
                                                                    fluid
                                                                    options={VAToptions} 
                                                                    type="text"
                                                                    placeholder={placeholder}
                                                                    id="VAT"
                                                                    //disabled={!isSelling}
                                                                    value={this.state.vat}
                                                                    onChange={this.onVATChange}
                                                                    focus/>}
                                                 </FormattedMessage> 
                                                </Form.Field>
                                            </Form.Group>}
                                    </Form>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button style={styles.ButtonGrey} //basic color='grey' 
                                        onClick={this.hideModal}>
                                        <FormattedMessage id="editmenu.modal.update.button.no"
                                                              defaultMessage='Cancel'/>
                                    </Button>
                                    <Button style={styles.ButtonBlue} //basic color='teal' 
                                        labelPosition='right' 
                                        content={<FormattedMessage id="editmenu.modal.update.button.yes"
                                                                    defaultMessage='Save'/> }
                                        icon='checkmark' 
                                        onClick={(event) => this.editMenu(event)}/>
                                </Modal.Actions>
                            </Modal>
                        </Grid>
                        </SideLeftUncoverMenu> 
                    </div>}
                </div>
        )
    }
}

function mapStateToProps(state) {
    const { recipes,ingredients,sorting,alert,dispatch } = state;
    const { loading,adding,updating_menu,deleting, menu,menu_i_items,menu_r_items} = state.recipes;
    const { subscription } = state.payment;
    //const { data_i,data_r,column,direction } = state.sorting;
    return {
        recipes,
        ingredients,
        menu,
        menu_i_items,
        menu_r_items,
        alert,
        dispatch,
        loading,
        adding,
        updating_menu,
        deleting,
        sorting,
        subscription,
        //data_i,
        //data_r
        //column,
        //direction
    };
}

const connectedEditMenu = injectIntl(connect(mapStateToProps)(EditMenu));
export { connectedEditMenu as EditMenu };
 